import React, { useEffect, useState } from "react";
import mobileImg from "../../assets/ContactUs/1-form-imageweb.webp";
import banner from "../../assets/ContactUs/3-banner-small-imageweb.webp";
import banner2 from "../../assets/ContactUs/2-banner-imageweb.webp";
import indFlag from "../../assets/ContactUs/India_flag_icon.png";
import usaFlag from "../../assets/ContactUs/106-usa_generated.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import {
  faUser,
  faEnvelope,
  faClipboard,
  faComment,
  faLocationDot,
  faMobileScreenButton,
  faMobilePhone,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import "./contactus.style.css";
import SEOComponent from "../SEO/SEOComponent";
import Remove from "../../assets/custom/images/remove.png";
import "react-toastify/dist/ReactToastify.css";

const Contactus = () => {
  const [screenWidth, setScreenWidth] = useState(0);

  const MapStyle = {
    width: "100%",
    height: 400,
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    contact_number: "",
  });
  const [toastMessage, setToastMessage] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      // setIsMenuOpen(!isMenuOpen);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name.trim()) {
      toast.error("Please enter your name.");
      return;
    }

    if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!formData.subject.trim()) {
      toast.error("Please enter a subject.");
      return;
    }
    try {
      debugger;
      const response = await fetch("https://admin.kanraj.com/api/inquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(formData),
      });
      if (response.ok) {
        // Handle successful response
        toast.success(
          "Thanks for submittting you inquiry, We will touch you soon!"
        );
        clearForm();
      } else {
        // Handle error response
        toast.error("Error submitting form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      setToastMessage("Error submitting form. Please try again.");
    }
  };

  const clearForm = () => {
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <>
      <ToastContainer />

      <div className="data">
        <SEOComponent
          title="Gaming Developer | Contact Us - Kanraj Infotech"
          description="Connect with us and let's turn your dream gaming ideas into reality. our game development team is here to assist you. Reach out today and let's build something amazing together!"
          url="https://kanraj.com/contact-us"
        />
        <div className="background-container  py-3">
          <h2 className="center underline white-text">Contact Us</h2>
          <h2
            className="center white-text"
            style={{ fontSize: "40px", fontWeight: "700" }}
          >
            Reach Our Expert Team
          </h2>
          <div className="center white-text">
            <p style={{ fontSize: "20px" }}>
              Send a message through the given form. If your enquiry is
              time-sensitive, please use the contact details below.
            </p>
          </div>
          <div className="form-container container">
            <form className="form" onSubmit={handleSubmit}>
              <label htmlFor="name" style={{ color: "#00A796" }}>
                Your Name
              </label>
              <div className="input-container">
                <input
                  type="text"
                  id="name"
                  placeholder="Enter Your Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <FontAwesomeIcon icon={faUser} className="fa-icon" />
              </div>

              <label htmlFor="email" style={{ color: "#00A796" }}>
                Your Email
              </label>
              <div className="input-container">
                <FontAwesomeIcon icon={faEnvelope} className="fa-icon" />
                <input
                  type="text"
                  id="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <label htmlFor="contact_number" style={{ color: "#00A796" }}>
                Contact Number
              </label>
              <div className="input-container">
                <FontAwesomeIcon icon={faMobile} className="fa-icon" />
                <input
                  type="tel"
                  id="contact_number"
                  placeholder="Enter Your Contact Number"
                  value={formData.contact_number}
                  onChange={handleChange}
                />
              </div>

              <label htmlFor="subject" style={{ color: "#00A796" }}>
                Subject
              </label>
              <div className="input-container">
                <FontAwesomeIcon icon={faClipboard} className="fa-icon" />
                <input
                  type="text"
                  id="subject"
                  placeholder="Enter Subject"
                  value={formData.subject}
                  onChange={handleChange}
                />
              </div>

              <label htmlFor="message" style={{ color: "#00A796" }}>
                Your Message (optional)
              </label>
              <div className="input-container">
                <FontAwesomeIcon
                  icon={faComment}
                  className="fa-icon"
                  style={{
                    color: "#00A796",
                    position: "absolute",
                    top: "25%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
                <textarea
                  id="message"
                  placeholder="Enter Your Message (optional)"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button
                type="submit"
                style={{
                  border: "1px solid #08A7A8",
                  borderRadius: "15px 0px",
                  background: "#FFFFFF",
                  color: "#000000",
                  minWidth: "180px",
                  width: "unset",
                  margin: "auto",
                  marginTop: "10px",
                  padding: "10px",
                }}
              >
                Submit
              </button>
            </form>
            <div className="image-container">
              <img src={mobileImg} alt="" />
            </div>
          </div>
        </div>

        <div
          className="background-container"
          style={{
            backgroundImage: `url(${banner2})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100vw",
          }}
        >
          <div
            className="details container"
            style={{ display: "flex", alignItems: "center", color: "white" }}
          >
            <div style={{ flex: 1 }}>
              <img
                src={banner}
                alt=""
                style={{ maxWidth: "100%", objectFit: "cover" }}
              />
            </div>
            <div style={{ flex: 1, marginLeft: "20px" }}>
              <h2
                style={{
                  borderBottom: "2px solid white",
                  display: "inline-block",
                  fontWeight: "600",
                  fontSize: "25px",
                }}
              >
                We'd love to hear from you!
              </h2>
              <h1 style={{ fontWeight: "700" }}>Get in touch!</h1>
              <p style={{ textAlign: "justify" }}>
                Do you have an idea and would like to come in to discuss your
                game project requirements? Just inform us. We are here for you!
                You can discuss anything you want to know about.
              </p>
              <div>
                <button
                  style={{
                    border: "1px solid #08A7A8",
                    borderRadius: "15px 0px",
                    background: "#FFFFFF",
                    color: "#000000",
                    minWidth: "180px",
                    width: "unset",
                    margin: "auto",
                    marginTop: "10px",
                    padding: "10px",
                  }}
                >
                  Contact us
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid details d-flex justify-content-center py-3"
          style={{ background: "#00a796" }}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="card m-3" style={{ backgroundColor: "#E1F4F4" }}>
                <div className="card-body px-5">
                  <h2
                    className="fs-5 text-center d-flex "
                    style={{
                      color: "#00a796",
                      fontWeight: "500",
                      flexDirection: "column",
                    }}
                  >
                    <span className="d-flex justify-content-evenly">
                      <span>
                        <img src={Remove} alt="" width={"25px"} />
                      </span>
                      <span>HEADQUARTER</span>
                      <span>
                        <span>
                          <img src={Remove} alt="" width={"25px"} />
                        </span>
                      </span>
                    </span>
                    DEVELOPMENT & SALES MARKETING
                  </h2>
                  <div className="d-flex justify-content-center align-items-center">
                    <h2 className="card-subtitle mb-4  px-3">
                      Kanraj Infotech
                    </h2>
                    <img
                      src={indFlag}
                      alt="Company Flag"
                      className="flag img-fluid mb-4 "
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div
                      className="row mb-3"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-auto">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="fa-icon"
                          style={{
                            position: "sticky",
                            left: "90%",
                            marginTop: "25px",
                            height: "30px",
                          }}
                        />
                      </div>
                      <div className="col py-2">
                        <h2
                          style={{
                            color: "black",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        >
                          Gujarat, India
                        </h2>
                        <span>Ahmedabad</span>
                      </div>
                    </div>

                    <div
                      className="row mb-3"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-auto">
                        <FontAwesomeIcon
                          icon={faMobileScreenButton}
                          className="fa-icon"
                          style={{
                            position: "sticky",
                            left: "90%",
                            marginTop: "25px",
                            height: "30px",
                          }}
                        />
                      </div>

                      <div className="col py-2">
                        <h2
                          style={{
                            color: "black",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          Contact & WhatsApp
                        </h2>
                        <span>+91 9510354183</span>
                      </div>
                    </div>

                    <div
                      className="row mb-3 py-2"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-auto">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="fa-icon"
                          style={{
                            position: "sticky",
                            left: "90%",
                            marginTop: "25px",
                            height: "30px",
                          }}
                        />
                      </div>

                      <div className="col py-2">
                        <h2
                          style={{
                            color: "black",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        >
                          Email
                        </h2>
                        <span>For bussiness - sales@kanraj.com</span>
                        <br />
                        <span>Job opportunity - hr@kanraj.com</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="card m-3 con_details"
                style={{
                  backgroundColor: "#E1F4F4",
                  height: `${screenWidth > 983 ? "28.5rem" : "auto"}`,
                }}
              >
                <div className="card-body px-5" style={{}}>
                  <h2
                    className="fs-5 text-center"
                    style={{ color: "#00a796", fontWeight: "500" }}
                  >
                    <span className="d-flex justify-content-evenly">
                      <span>
                        <img src={Remove} alt="" width={"25px"} />
                      </span>

                      <span>SALES & MARKETING</span>
                      <span>
                        <span>
                          <img src={Remove} alt="" width={"25px"} />
                        </span>
                      </span>
                    </span>
                  </h2>
                  <div className="d-flex justify-content-center align-items-center">
                    <h2 className="card-subtitle mb-4  px-3">EVMARK Inc</h2>
                    <img
                      src={usaFlag}
                      alt="Company Flag"
                      className="flag img-fluid mb-4 "
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div
                      className="row mb-3"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-auto">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="fa-icon"
                          style={{
                            position: "sticky",
                            left: "90%",
                            marginTop: "25px",
                            height: "30px",
                          }}
                        />
                      </div>

                      <div className="col py-2">
                        <h2
                          style={{
                            color: "black",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        >
                          USA
                        </h2>
                        <span>
                          25101 The Old Road Suite 155 Santa Clarita, CA 91381
                        </span>
                      </div>
                    </div>

                    <div
                      className="row mb-3"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-auto">
                        <FontAwesomeIcon
                          icon={faMobileScreenButton}
                          className="fa-icon"
                          style={{
                            position: "sticky",
                            left: "90%",
                            marginTop: "25px",
                            height: "30px",
                          }}
                        />
                      </div>

                      <div className="col py-2">
                        <h2
                          style={{
                            color: "black",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          Contact & WhatsApp
                        </h2>
                        <span>(818) 284-6450</span>
                      </div>
                    </div>

                    <div
                      className="row mb-3"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-auto">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="fa-icon"
                          style={{
                            position: "sticky",
                            left: "90%",
                            marginTop: "25px",
                            height: "30px",
                          }}
                        />
                      </div>

                      <div className="col py-2">
                        <h2
                          style={{
                            color: "black",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          Email
                        </h2>
                        <span>For bussiness - contactus@evmarkinc.com</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid g-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d470026.5769951059!2d72.577909!3d23.020738!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848aba5bd449%3A0x4fcedd11614f6516!2sAhmedabad%2C%20Gujarat%2C%20India!5e0!3m2!1sen!2sus!4v1707283732924!5m2!1sen!2sus"
            width={MapStyle.width}
            height={MapStyle.height}
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="Humanity First Indonesia"
          />
        </div>
      </div>
    </>
  );
};

export default Contactus;
