import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

const Cards = ({ img, name, type }) => {
  return (
    <>
      <div className='col-md-4 col-sm-6 wow bounceInUp' data-wow-delay='1.2s'>
        <Card className='fables-second-hover-text-color fables-team-block my-2'>
          <Link
            to={type}
            style={{
              textDecoration: "none",
              color: "#000",
            }}>
            <div className='image-container shine-effect'>
              <Card.Img
                variant='top'
                src={img}
                className=''
                style={{ cursor: "pointer" }}
              />
            </div>
            <Card.Body>
              <Card.Title style={{ cursor: "pointer" }}>{name}</Card.Title>
              {/* <Card.Text>{type}</Card.Text> */}
            </Card.Body>
          </Link>
        </Card>
      </div>
    </>
  );
};

export default Cards;
