import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import SEOComponent from "../SEO/SEOComponent";

const Blogs = ({ id }) => {
  const navigate = useNavigate();
  const [blogDetails, setBlogDetails] = useState();
  useEffect(() => {
    doGetBlog(id);
  }, [id]);

  // useEffect(() => {
  //   if (blogDetails?.length > 0) {
  //     document.title = blogDetails[0]?.title || "";
  //     const metaDescription = document.querySelector(
  //       'meta[name="description"]'
  //     );
  //     if (metaDescription) {
  //       metaDescription.setAttribute(
  //         "content",
  //         "This is the description of my page"
  //       );
  //     } else {
  //       const newMetaDescription = document.createElement("meta");
  //       newMetaDescription.name = "description";
  //       newMetaDescription.content = "This is the description of my page";
  //       document.head.appendChild(newMetaDescription);
  //     }

  //     const metaKeywords = document.querySelector('meta[name="keywords"]');
  //     if (metaKeywords) {
  //       metaKeywords.setAttribute("content", "react, helmet, seo");
  //     } else {
  //       const newMetaKeywords = document.createElement("meta");
  //       newMetaKeywords.name = "keywords";
  //       newMetaKeywords.content = "react, helmet, seo";
  //       document.head.appendChild(newMetaKeywords);
  //     }

  //     const metaOgTitle = document.querySelector('meta[property="og:title"]');
  //     if (metaOgTitle) {
  //       metaOgTitle.setAttribute(
  //         "content",
  //         blogDetails[0]?.title || "Default Open Graph Title"
  //       );
  //     } else {
  //       const newMetaOgTitle = document.createElement("meta");
  //       newMetaOgTitle.setAttribute("property", "og:title");
  //       newMetaOgTitle.content =
  //         blogDetails[0]?.title || "Default Open Graph Title";
  //       document.head.appendChild(newMetaOgTitle);
  //     }

  //     const metaOgDescription = document.querySelector(
  //       'meta[property="og:description"]'
  //     );
  //     if (metaOgDescription) {
  //       metaOgDescription.setAttribute(
  //         "content",
  //         "This is the Open Graph description"
  //       );
  //     } else {
  //       const newMetaOgDescription = document.createElement("meta");
  //       newMetaOgDescription.setAttribute("property", "og:description");
  //       newMetaOgDescription.content = "This is the Open Graph description";
  //       document.head.appendChild(newMetaOgDescription);
  //     }

  //     const metaOgImage = document.querySelector('meta[property="og:image"]');
  //     if (metaOgImage) {
  //       metaOgImage.setAttribute("content", "https://example.com/image.jpg");
  //     } else {
  //       const newMetaOgImage = document.createElement("meta");
  //       newMetaOgImage.setAttribute("property", "og:image");
  //       newMetaOgImage.content = "https://example.com/image.jpg";
  //       document.head.appendChild(newMetaOgImage);
  //     }

  //     const linkCanonical = document.querySelector('link[rel="canonical"]');
  //     if (linkCanonical) {
  //       linkCanonical.setAttribute("href", "https://example.com");
  //     } else {
  //       const newLinkCanonical = document.createElement("link");
  //       newLinkCanonical.rel = "canonical";
  //       newLinkCanonical.href = "https://example.com";
  //       document.head.appendChild(newLinkCanonical);
  //     }
  //   }
  // }, []);

  const doGetBlog = async () => {
    try {
      const response = await fetch("https://admin.kanraj.com/api/blogs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ page_master_id: id }),
      });

      const result = await response.json();
      setBlogDetails(result?.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const doGetBlogDetails = async (slug) => {
    navigate(`/blog/${slug}`, { state: slug });
  };

  return (
    <>
      <SEOComponent
        title={blogDetails && blogDetails[0]?.title}
        description={blogDetails && blogDetails[0]?.description}
        // url={blogDetails && blogDetails[0]?.images}
      />
      <div className='container'>
        <div className='row' style={{ marginBottom: 100 }}>
          <h2
            className='font_700 text-center'
            style={{ paddingTop: "40px", paddingBottom: 46 }}>
            Blogs
          </h2>
          {blogDetails &&
            blogDetails.map((item, index) => {
              // console.log(item,"item...")
              return (
                <>
                  <div className='col-md-4 mb-4'>
                    <div class='card'>
                      <img
                        src={item?.images}
                        class='card-img-top'
                        alt='...'
                        width={"100%"}
                        height={"100%"}
                        style={{ borderRadius: 12 }}
                      />
                      <div class='card-body'>
                        <h5 class='card-title blog-heading'>{item?.title}</h5>
                        <p class='card-text blog-text'>
                          {item?.short_description}
                        </p>
                        <button
                          type='button'
                          className='btn btn-outline-success'
                          onClick={() => {
                            doGetBlogDetails(item?.slug);
                          }}>
                          View More
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Blogs;
