import React from "react";
import Logo from "../../assets/custom/images/Logo.png";
import Unity from "../../assets/technologies/1-Unityweb.webp";
import Android from "../../assets/technologies/2-Android-studioweb.webp";
import GameAnalytic from "../../assets/technologies/3-Game-Analytics.webp";
import Oculus from "../../assets/technologies/4-Oculus-VRweb.webp";
import Unreal from "../../assets/technologies/5-Unreal-Engine-Logoweb.webp";
import Cocos from "../../assets/technologies/6-cocos-2D-x-logoweb.webp";
import HTML5web from "../../assets/technologies/7-HTML5web.webp";
import Logoweb from "../../assets/technologies/8-WebGL_Logoweb.webp";
import Card from "./Cards";
import MobileGame from "../../assets/technologies/1-Mobile-Gameweb-pygz7tmr5vu0cdtpwu7hpgxuycgf3umxaims2h4720.webp";
import AndroidGame from "../../assets/technologies/2-Android-gameweb-pygz7uklcpvanzscrcm49ypbjqbsbjqnmna9jr2svs.webp";
import IOSGame from "../../assets/technologies/3-IOS-gameweb-pygz7uklcpvanzscrcm49ypbjqbsbjqnmna9jr2svs.webp";
import UnityGame from "../../assets/technologies/4-Unity-3D-Gameweb.webp";
import MetaverseGame from "../../assets/technologies/5-Metaverse-gameweb.webp";
import FantasyGamling from "../../assets/technologies/6-Fantasy-Gameweb-pygz7xe3x7z5mto9avtzzfzpbvxvyn1un18pzkymd4.webp";
import NFTGame from "../../assets/technologies/7-NFT-Gameweb-pygz7yby420fyfmw5e8mjxr5x9t96c5kz5w7gux86w.webp";
import RealMoney from "../../assets/technologies/8-Real-Money-Gameweb-pygz7yby420fyfmw5e8mjxr5x9t96c5kz5w7gux86w.webp";
import UnrealEngine from "../../assets/technologies/10-Unreal-Gameweb.webp";
import ARGame from "../../assets/technologies/9-AR-Gameweb.webp";
import VRGame from "../../assets/technologies/11-VR-Gameweb.webp";
import FacebookGame from "../../assets/technologies/12-Facebook-Gameweb.webp";
import HTMLWeb from "../../assets/technologies/13-HTML5-Gameweb.webp";
import OnlineGame from "../../assets/technologies/14-Online-Gameweb.webp";
import WindowsGame from "../../assets/technologies/15-Windows-Gameweb.webp";
import CrossPlatform from "../../assets/CasinoGame/services.webp";
import DesktopPC from "../../assets/technologies/17-Desktop-PC-Gameweb.webp";
import ConsoleGame from "../../assets/technologies/18-Console-Gameweb.webp";
import { Link } from "react-router-dom";

const ToolsAndTechnologies = () => {
  return (
    <>
      <div className='' style={{ display: "" }}>
        <p
          class='fables-forth-text-color font-18 mb-2'
          style={{ textAlign: "justify", color: "#000" }}>
          Kanraj Infotech provides all types of game development services at
          affordable rates. Our 50+ team has experienced working on some of the
          top games in the industry. We have created games for mobile, PC, and
          online platforms, utilising cutting-edge development technologies in
          the right way to deliver you the Best games.
        </p>
      </div>
      <div className=''>
        <h2 class='text-center fables-main-text-color font-35 my-4 my-lg-5 smaller-head-text'>
          Mobile Game Development Tools & Technologies we using
        </h2>
        <div className='row my-3 my-md-5 overflow-hidden technologies'>
          <div className='col-md-3 col-sm-6 d-flex justify-content-center'>
            <img
              src={Unity}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 col-sm-6 d-flex justify-content-center'>
            <img
              src={Android}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 col-sm-6 d-flex justify-content-center'>
            <img
              src={GameAnalytic}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 col-sm-6 d-flex justify-content-center'>
            <img
              src={Oculus}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className='row my-3 my-md-5 overflow-hidden technologies'>
          <div className='col-md-3 col-sm-6 d-flex justify-content-center'>
            <img
              src={Unreal}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 col-sm-6 d-flex justify-content-center'>
            <img
              src={Cocos}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 col-sm-6 d-flex justify-content-center'>
            <img
              src={HTML5web}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 col-sm-6 d-flex justify-content-center'>
            <img
              src={Logoweb}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>

      <div class='container'>
        <div class='row mb-0 mb-lg-5 overflow-hidden'>
          <Card
            img={MobileGame}
            name={"Mobile Game Development"}
            type={"mobilegame"}
          />

          <Card
            img={AndroidGame}
            name={"Android Game Development"}
            type={"androidgame"}
          />
          <Card img={IOSGame} name={"IOS Game Development"} type={"iosgame"} />
        </div>

        <div class='row my-3 my-md-5 overflow-hidden'>
          <Card
            img={UnityGame}
            name={"Unity 3D & Game Development"}
            type={"unity3D"}
          />
          <Card
            img={MetaverseGame}
            name={"Metaverse Game Development"}
            type={"metaversegame"}
          />
          <Card
            img={FantasyGamling}
            name={"Fantasy gamling App"}
            type={"fantasygamling"}
          />
        </div>
        <div class='row my-3 my-md-5 overflow-hidden'>
          <Card img={NFTGame} name={"NFT Game Development"} type={"nftgame"} />
          <Card
            img={RealMoney}
            name={"Real Money Game Development"}
            type={"realmoneygame"}
          />
          <Card
            img={UnrealEngine}
            name={"Unreal engine Game Development"}
            type={"unrealengine"}
          />
        </div>
        <div class='row my-3 my-md-5 overflow-hidden'>
          <Card img={ARGame} name={"AR Game Development"} type={"argame"} />
          <Card img={VRGame} name={"VR Game Development"} type={"vrgame"} />
          <Card
            img={FacebookGame}
            name={"Facebook Game Development"}
            type={"facebookgame"}
          />
        </div>
        <div class='row my-3 my-md-5 overflow-hidden'>
          <Card
            img={HTMLWeb}
            name={"HTML5 Web Game Development"}
            type={"html5webgame"}
          />
          <Card
            img={OnlineGame}
            name={"Online Game Development"}
            type={"onlinegame"}
          />
          <Card
            img={WindowsGame}
            name={"Windows Game Development"}
            type={"windowsgame"}
          />
        </div>
        <div class='row my-3 my-md-5 overflow-hidden'>
          <Card
            img={CrossPlatform}
            name={"Casino Game Development"}
            type={"casinogame"}
          />
          <Card
            img={DesktopPC}
            name={"Desktop & PC Game Development"}
            type={"desktopPC"}
          />
          <Card
            img={ConsoleGame}
            name={"Consol Game Development"}
            type={"consolegame"}
          />
        </div>
      </div>
    </>
  );
};

export default ToolsAndTechnologies;
