import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// import Banner from "../GameDevelopment/Banner";
import Blogbanner from "./Blogbanner";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import SEOComponent from "../SEO/SEOComponent";
const BlogDetails = () => {
  const location = useLocation();
  const [details, setDetails] = useState();
  console.log("isMobile", isMobile);
  const { slug } = useParams();

  useEffect(() => {
    if (slug != undefined) {
      doGetDetails(slug);
    }
  }, []);

  const doGetDetails = async (slug) => {
    try {
      const response = await fetch(
        `https://admin.kanraj.com/api/blogs/${slug}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();

      setDetails(result?.data);
      // setBlogDetails(result?.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {/* <Blogbanner banner={details?.images} /> */}
      <SEOComponent
        title={details?.title}
        description={details?.meta_description}
        image={details?.images}
        url={details?.slug ? `https://kanraj.com/blog/${details?.slug}` : ""}
        author={details?.meta_author}
        keyword={details?.meta_keyword}
      />
      <div
        className={isMobile ? "mobile-blog-container" : "container"}
        style={{ padding: isMobile ? "3px 22px 42px" : "3px 46px 46px 46px" }}
      >
        <h2
          style={{
            margin: "14px 0",
            fontSize: isMobile ? 25 : 42,
            fontWeight: 800,
          }}
        >
          {details?.title}
        </h2>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <BrowserView>
            <img
              src={details?.images}
              alt={details?.title}
              style={{ maxHeight: "70vh", borderRadius: 8 }}
            />
          </BrowserView>
          <MobileView>
            <img
              src={details?.images}
              alt={details?.title}
              style={{ maxWidth: "100vw", borderRadius: 8 }}
            />
          </MobileView>
        </div>
        <h4 style={{ paddingTop: 10, paddingBottom: 10 }}>
          {details?.short_description}
        </h4>
        <div
          dangerouslySetInnerHTML={{ __html: details?.description }}
          style={{ fontSize: "15px" }}
        />
      </div>
    </>
  );
};

export default BlogDetails;
