import React from "react";
// import CountUp from "react-countup/build/CountUp";
import CountUp from "react-countup";

const Counter = ({ number }) => {
  return (
    <>
      <div style={{ fontSize: "70px", color: "#39C4A8", fontWeight: "600" }}>
        <CountUp start={0} end={number} duration={3} />+
      </div>
    </>
  );
};

export default Counter;
