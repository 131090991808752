import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Counters from "../../Counters";
import CountUp from "react-countup";
import Accordiondata from "../../Accordiondata";
import Banner from "../Banner";
import Background from "../../../assets/Mobilegames/1-Mobile-game-BannerWeb.webp";
import Mobilegame from "../../../assets/Mobilegames/2-Mobile-GameWeb.webp";
import Multiegames from "../../../assets/Mobilegames/3-Multi-GamesWeb.webp";
import Casino from "../../../assets/Mobilegames/1-Casino-GameWeb.webp";
import Bord from "../../../assets/Mobilegames/2-Board-GameWeb.webp";
import Cardgame from "../../../assets/Mobilegames/3-Card-GameWeb.webp";
import Action from "../../../assets/Mobilegames/4-Action-GameWeb.webp";
import Casual from "../../../assets/Mobilegames/5-Casual-GameWeb.webp";
import Social from "../../../assets/Mobilegames/6-Social-GameWeb.webp";
import Puzzle from "../../../assets/Mobilegames/7-Puzzle-GameWeb.webp";
import Fantasy from "../../../assets/Mobilegames/8-Fantasy-GameWeb.webp";
import Education from "../../../assets/Mobilegames/9-Educational-GameWeb.webp";
import Hyper from "../../../assets/Mobilegames/10-Hyper-Casual-GameWeb.webp";
import RPGames from "../../../assets/Mobilegames/11-RPG-GameWeb.webp";
import Node from "../../../assets/Mobilegames/nodejs-ar21.svg";
import Typeofgames from "../Typeofgames";
import Androidwab from "../../../assets/Mobilegames/4-AndroidWeb-180x300.webp";
import IOSdwab from "../../../assets/Mobilegames/5-IOSWeb-180x300.webp";
import Crosswab from "../../../assets/Mobilegames/6-Cross-platformWeb-180x300.webp";
import Unity from "../../../assets/technologies/1-Unityweb.webp";
import Android from "../../../assets/technologies/2-Android-studioweb.webp";
import GameAnalytic from "../../../assets/technologies/3-Game-Analytics.webp";
import Oculus from "../../../assets/technologies/4-Oculus-VRweb.webp";
import Unreal from "../../../assets/technologies/5-Unreal-Engine-Logoweb.webp";
import Cocos from "../../../assets/technologies/6-cocos-2D-x-logoweb.webp";
import HTML5web from "../../../assets/technologies/7-HTML5web.webp";
import Logoweb from "../../../assets/technologies/8-WebGL_Logoweb.webp";
import Swift from "../../../assets/Mobilegames/11-swift-logoWeb.webp";
import Smartfox from "../../../assets/Mobilegames/9-smartfox-serverWeb.webp";
import Photon from "../../../assets/Mobilegames/10-PhotonWeb.webp";
import Customized from "../../../assets/Mobilegames/7-BenefitsWeb.webp";
import Transparency from "../../../assets/Mobilegames/8-BenefitsWeb.webp";
import Technologies from "../../../assets/Mobilegames/9-BenefitsWeb.webp";
import Million from "../../../assets/Mobilegames/10-BenefitsWeb.webp";
import Teamimg from "../../../assets/Mobilegames/11-TeamWeb.webp";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  const gamesType = [
    {
      img: Casino,
      title: "Casino Games",
    },
    {
      img: Bord,
      title: "Board Games",
    },
    {
      img: Cardgame,
      title: "Card Games",
    },
    {
      img: Action,
      title: "Action Games",
    },
    {
      img: Casual,
      title: "Casual Games",
    },
    {
      img: Social,
      title: "Social Games",
    },
    {
      img: Puzzle,
      title: "Puzzle Games",
    },
    {
      img: Fantasy,
      title: "Fantasy Games",
    },
    // {
    //   img: Education,
    //   title: "Education Games",
    // },
    // {
    //   img: Hyper,
    //   title: "Hyper Casual Games",
    // },
    // {
    //   img: RPGames,
    //   title: "RPG Games",
    // },
  ];

  return (
    <div className='data'>
      <SEOComponent
        title='Mobile Game Development Company -  Kanraj Infotech'
        description='At Kanraj Infotech, We use industry-leading tools like Unity, Unreal Engine, Cocos-2d, Android studio for mobile game development. Join us in shaping your next gaming adventure! Explore our mobile game offerings now.'
        url='https://kanraj.com/mobilegame'
      />
      <Banner banner={Background} text={"Mobile Game Development"} />
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <h6
              style={{
                color: "#078586",
                fontSize: "20px",
                fontWeight: "700",
                textDecoration: "underline",
                textDecorationColor: "#078586",
              }}>
              Game Development
            </h6>
            {/* <span
              style={{
                border: "2px solid #078586 ",
                display: "block",
                width: "70px",
                marginBottom: "10px",
              }}></span> */}
            <h2 style={{ fontWeight: "700" }}>Mobile Game Development</h2>
            <div>
              {" "}
              <p style={{ textAlign: "justify" }}>
                Kanraj Infotech is India’s top mobile game development company
                serving a voluminous global clientele. With 11+ years of
                extensive experience, Our Mobile Game Developers are highly
                skilled in delivering game development services for Android and
                iOS platforms using industry-leading tools like Unity, Unreal
                Engine, Cocos-2d, Android studio, etc.
              </p>
              <p style={{ textAlign: "justify" }}>
                Get access to a competent mobile game developer with domain
                knowledge. As the leading mobile gaming company, we have
                delivered and created a wide range of mobile games using
                different technologies and tools such as AR/VR, streaming games,
                unreal engine, and many more!!
              </p>
              <p style={{ textAlign: "justify" }}>
                Having a good record of successful game development for Android
                and iOS platforms, Kanraj Infotech has emerged to be the best
                mobile game app development company in India as well as in the
                global market. Get our mobile game development services for
                innovative custom game development services now!
              </p>
            </div>
          </div>

          <div className='col-md-6'>
            <img
              src={Mobilegame}
              alt=''
              width={"100%"}
              height={"550px"}
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
      <div className='container-fluid' style={{ background: "#078586" }}>
        <div className='container py-5'>
          <div className='text-center'>
            <h3 className='text-white'>Our Mobile Game Development Services</h3>
            <p style={{ fontSize: "18px", fontWeight: "500SS", color: "#fff" }}>
              We have dozens of created mobile games on our account, so we know
              how they should be to impress the player and stand out among
              hundreds of analogues. See what we can offer you.
            </p>
          </div>

          <div className='row'>
            <div className='col-md-4'>
              <div className='py-4'>
                <Card.Body>
                  <Card.Title
                    className='text-white text-center py-2'
                    style={{ fontSize: "25px" }}>
                    FULL-CYCLE MOBILE GAME DEVELOPMENT
                  </Card.Title>

                  <Card.Text
                    className='text-white'
                    style={{ textAlign: "justify" }}>
                    We will carry out your project at all stages from game
                    development to post-release support, using the rich
                    experience and creativity of our experts. Make your gaming
                    dream and the most daring ideas come true with our custom
                    full-cycle mobile game development services.
                  </Card.Text>
                </Card.Body>
              </div>
              <div>
                <Card.Body>
                  <Card.Title
                    className='text-white text-center py-2'
                    style={{ fontSize: "25px" }}>
                    TESTING
                  </Card.Title>
                  <Card.Text
                    className='text-white'
                    style={{ textAlign: "justify" }}>
                    Our testers provide accurate and effective quality assurance
                    for the game and match its parameters with the development
                    goals. Performance, gameplay, UX/UI, device compatibility,
                    social integration and other aspects are under our complete
                    control.
                  </Card.Text>
                </Card.Body>
              </div>
            </div>
            <div className='col-md-4'>
              <img
                src={Multiegames}
                alt=''
                width={"100%"}
                height={"480px"}
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className='col-md-4'>
              <div className='py-4'>
                <Card.Body>
                  <Card.Title
                    className='text-white text-center py-2'
                    style={{ fontSize: "25px" }}>
                    FUTURE-PROOF ARCHITECTURE
                  </Card.Title>
                  <Card.Text
                    className='text-white'
                    style={{ textAlign: "justify" }}>
                    Our experts create a game architecture that allows content
                    to scale. By conducting post-launch analytics and assessing
                    game viability, we ensure regular game content updates to
                    keep the player engaged by creating additional challenges,
                    characters, and more
                  </Card.Text>
                </Card.Body>
              </div>
              <div>
                <Card.Body>
                  <Card.Title
                    className='text-white text-center py-2'
                    style={{ fontSize: "25px" }}>
                    MOBILE GAME ART ASSET CREATION
                  </Card.Title>

                  <Card.Text
                    className='text-white'
                    style={{ textAlign: "justify" }}>
                    Kanraj Infotech team has a unique approach to every project
                    to develop vibrant graphics, lively animations and engaging
                    game assets that will keep the player's attention until the
                    end of the game session. All game elements are superbly
                    optimized for the best performance
                  </Card.Text>
                </Card.Body>
              </div>
            </div>
          </div>

          <div className='text-center pt-3'>
            <h4 className='text-white'>POST-RELEASE SUPPORT</h4>
            <p
              style={{ fontSize: "18px", fontWeight: "500SS", color: "white" }}>
              We continue to provide support for the game after its release.
              This is realized by tracking game behavior, monitoring reviews,
              fixing bugs, game elements features that do not suit players,
              redesigning individual elements, improving gameplay based on
              player and client feedback.
            </p>
          </div>
        </div>
      </div>

      <div className='container py-5 text-center'>
        <h2 style={{ fontWeight: "700" }}>Types Of Mobile Games We Develop</h2>
        <p>
          Kanraj Team has good knowledge about game mechanics, design and
          gameplay of mobile games of all genres most loved by players. We're
          not just developing a game, we're bringing a successful product to the
          stage that truly engages the players.
        </p>

        <div className='row'>
          {gamesType?.map((item, index) => {
            return (
              <div className='col-md-3' key={index}>
                <Typeofgames image={item?.img} title={item?.title} />
              </div>
            );
          })}

          {/* {
      img: Education,
      title: "Education Games",
    },
    {
      img: Hyper,
      title: "Hyper Casual Games",
    },
    {
      img: RPGames,
      title: "RPG Games",
    }, */}

          <div className='col-md-4'>
            <Typeofgames image={Education} title={"Education Games"} />
          </div>
          <div className='col-md-4'>
            <Typeofgames image={Hyper} title={"Hyper Casual Games"} />
          </div>
          <div className='col-md-4'>
            <Typeofgames image={RPGames} title={"RPG Games"} />
          </div>
        </div>
      </div>

      <div className='container-fluid' style={{ background: "#40444A" }}>
        <div className='container text-white p-4'>
          <h1 className='text-center pb-4' style={{ fontWeight: "700" }}>
            We Develop Games For These Key Platforms
          </h1>
          <div
            className='row text-center'
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div className='col-md-4'>
              <img
                src={Androidwab}
                alt=''
                width={"250px"}
                height={"300px"}
                style={{ objectFit: "contain" }}
              />
              <Card.Body className='pt-2'>
                <Card.Title style={{ fontSize: "28px", fontWeight: "700" }}>
                  Android
                </Card.Title>
                <Card.Text style={{ fontSize: "18px" }}>
                  We design, develop, test and launch the most addicting Android
                  games with a potential reach of up to 2 billion players
                  worldwide.
                </Card.Text>
              </Card.Body>
            </div>
            <div className='col-md-4'>
              <img
                src={IOSdwab}
                alt=''
                width={"250px"}
                height={"300px"}
                style={{ objectFit: "contain" }}
              />
              <Card.Body className='pt-2'>
                <Card.Title style={{ fontSize: "28px", fontWeight: "700" }}>
                  IOS
                </Card.Title>
                <Card.Text style={{ fontSize: "18px" }}>
                  Our iOS mobile games offer exciting game mechanics, responsive
                  UI features and stunning design tailored to the needs of the
                  client.
                </Card.Text>
              </Card.Body>
            </div>{" "}
            <div className='col-md-4'>
              <img
                src={Crosswab}
                alt=''
                width={"250px"}
                height={"300px"}
                style={{ objectFit: "contain" }}
              />
              <Card.Body className='pt-2'>
                <Card.Title style={{ fontSize: "28px", fontWeight: "700" }}>
                  CROSS-PLATFORM
                </Card.Title>
                <Card.Text style={{ fontSize: "18px" }}>
                  Erase the platform constraints and engage us to create a
                  cross-platform masterpiece using the most relevant
                  technologies.
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
      <div className='container text-center py-4'>
        <h2>Technologies We Are Well Versed In</h2>
        <div className='row my-3 my-md-5 overflow-hidden technologies'>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Unity}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Android}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={GameAnalytic}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Oculus}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className='row my-3 my-md-5 overflow-hidden technologies my-5'>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Unreal}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Cocos}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={HTML5web}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Logoweb}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className='row my-3 my-md-5 overflow-hidden technologies my-5'>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Smartfox}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Swift}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Photon}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-center'>
            <img
              src={Node}
              alt=''
              width={"150px"}
              height={"150px"}
              style={{ cursor: "pointer", objectFit: "cover" }}
            />
          </div>
        </div>

        <div>
          <h3 style={{ fontWeight: "700" }}>
            Quick Facts About Our Game Development
          </h3>
          <p style={{ fontSize: "20px" }}>
            We focus on the provision of mobile development services to create
            projects that stand out and remain in memory for a long time. Our
            mobile game app developers have vast experience that allows us to
            help global gaming companies with their popular game titles.
          </p>
          <div
            className='row'
            style={{ display: "flex", justifyContent: "center" }}>
            <div
              className='col-md-4'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <Counters number={40} />
              <p style={{ fontSize: "20px", fontWeight: "700" }}>
                GAME ARTISTS AND DEVELOPERS
              </p>
            </div>
            <div
              className='col-md-4'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <div
                style={{
                  fontSize: "70px",
                  color: "#39C4A8",
                  fontWeight: "600",
                }}>
                <CountUp start={0} end={12} duration={3} />
              </div>
              <p style={{ fontSize: "20px", fontWeight: "700" }}>
                YEARS OF EXPERIENCE
              </p>
            </div>
            <div
              className='col-md-4'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <div
                style={{
                  fontSize: "70px",
                  color: "#39C4A8",
                  fontWeight: "600",
                }}>
                <CountUp start={0} end={95} duration={3} />%
              </div>
              <p style={{ fontSize: "20px", fontWeight: "700" }}>
                REPEAT BUSINESS
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='contanier-fluid py-3' style={{ background: "#078586" }}>
        <div className='container'>
          <div className='row'>
            <h3
              className='text-white text-center'
              style={{ fontWeight: "700" }}>
              Benefits Of Mobile Game Development With Kanraj
            </h3>
            <p
              className='text-white text-center pb-3'
              style={{ fontSize: "17px" }}>
              Our mobile game development agency approaches each project with
              technical meticulousness and artistic vision that allows us to
              clearly follow the wishes of the client, while adhering to the
              best industry standards and implementing advanced practices to
              achieve the best result.
            </p>
            <div className='col-md-3'>
              <Card style={{ background: "#078586", border: "none" }}>
                <Card.Img variant='top' src={Customized} />
                <Card.Body className='text-white'>
                  <Card.Title style={{ fontSize: "20px", fontWeight: "700" }}>
                    Customized Mobile Game Development
                  </Card.Title>
                  <Card.Text style={{ fontSize: "16px", textAlign: "justify" }}>
                    We create games carefully tailored to your specific needs
                    and budget expectations. We hire mobile game developers who
                    are professionals in each game development area: it helps to
                    form customized approaches to your project to match the
                    goals and scale.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-3'>
              <Card style={{ background: "#078586", border: "none" }}>
                <Card.Img variant='top' src={Transparency} />
                <Card.Body className='text-white'>
                  <Card.Title style={{ fontSize: "20px", fontWeight: "700" }}>
                    Transparency And Reliable Partnership
                  </Card.Title>
                  <Card.Text style={{ fontSize: "16px", textAlign: "justify" }}>
                    Thanks to an open dialogue, transparent communication and
                    strict adherence to the wishes of the client, our team
                    ensures complete satisfaction with the implemented projects
                    and secures repeat business in 90% of cases.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-3'>
              <Card style={{ background: "#078586", border: "none" }}>
                <Card.Img variant='top' src={Technologies} />
                <Card.Body className='text-white'>
                  <Card.Title style={{ fontSize: "20px", fontWeight: "700" }}>
                    State-Of-The-Art Technologies
                  </Card.Title>
                  <Card.Text style={{ fontSize: "16px", textAlign: "justify" }}>
                    We adhere to the highest standards in the mobile gaming
                    industry, follow the latest trends in the gaming market and
                    use the most advanced technologies, approaches and creative
                    ideas to always be one step ahead.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-3'>
              <Card style={{ background: "#078586", border: "none" }}>
                <Card.Img variant='top' src={Million} />
                <Card.Body className='text-white'>
                  <Card.Title style={{ fontSize: "20px", fontWeight: "700" }}>
                    Mobile Games with 50+ Million downloads
                  </Card.Title>
                  <Card.Text style={{ fontSize: "16px", textAlign: "justify" }}>
                    We are focused on creating exclusive and unique games that
                    resonate in the gaming world, collect thousands of comments,
                    get into the features lists of the Google Play Store and App
                    Store and gain over a million downloads.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div className='contanier-fluid py-4' style={{ background: "#40444A" }}>
        <div className='container'>
          <h2 className='text-center text-white' style={{ fontWeight: "700" }}>
            The Team For Developing Your Ideal Mobile Game
          </h2>
          <div className='row'>
            <div className='col-md-4'>
              <Card
                className='text-white'
                style={{ backgroundColor: "#40444A", border: "none" }}>
                <Card.Body>
                  <Card.Title
                    style={{
                      fontWeight: "700",
                    }}>
                    GAME DESIGNERS
                  </Card.Title>
                  <Card.Text style={{ textAlign: "justify" }}>
                    The balanced game design, harmoniously combining the visual
                    component and technical features, forms the overall look of
                    the mobile game. Therefore, game designers of our mobile
                    game app development company carefully work out the smallest
                    details: storylines, character history, level structure,
                    missions and quests. This allows them to create the most
                    engaging gameplay in line with the genre and platform
                    requirements.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4'>
              <Card
                className='text-white'
                style={{ backgroundColor: "#40444A", border: "none" }}>
                <Card.Body>
                  <Card.Title
                    style={{
                      fontWeight: "700",
                    }}>
                    ART DIRECTOR
                  </Card.Title>
                  <Card.Text style={{ textAlign: "justify" }}>
                    Our experienced art director has all the stylistic and genre
                    knowledge of the most important visual aspects of mobile
                    games. Thanks to this, he clearly defines the desired game
                    style in accordance with the client’s requirements and
                    communicates with artists in order to convey the idea to
                    them as best as possible and implement a mobile game that
                    the target audience will definitely like.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4'>
              <Card
                className='text-white'
                style={{ backgroundColor: "#40444A", border: "none" }}>
                <Card.Body>
                  <Card.Title
                    style={{
                      fontWeight: "700",
                    }}>
                    UNITY DEVELOPERS
                  </Card.Title>
                  <Card.Text style={{ textAlign: "justify" }}>
                    Mobile games and the Unity engine are best friends, so we
                    have gathered the best Unity specialists to create mobile
                    games of various complexity and genres. Our Unity developers
                    can easily cope with both a simple but addictive casual
                    project and a large-scale action-adventure game with an open
                    world. We form our team according to the complexity of the
                    project and always do our best to exceed the client’s
                    expectations.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className='row'>
            <img src={Teamimg} alt='' style={{ objectFit: "cover" }} />
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <Card
                className='text-white'
                style={{ backgroundColor: "#40444A", border: "none" }}>
                <Card.Body>
                  <Card.Title
                    style={{
                      fontWeight: "700",
                    }}>
                    TEAM LEAD
                  </Card.Title>
                  <Card.Text style={{ textAlign: "justify" }}>
                    The team leader is the person who monitors the correctness
                    of work performed by each department and monitors progress
                    in accordance with the established deadline. Thanks to his
                    vigilant control, timely adjustments and deep knowledge in
                    the technical and artistic direction, the project progresses
                    very quickly, and all edits are implemented almost
                    instantly.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4'>
              <Card
                className='text-white'
                style={{ backgroundColor: "#40444A", border: "none" }}>
                <Card.Body>
                  <Card.Title
                    style={{
                      fontWeight: "700",
                    }}>
                    PROJECT MANAGER
                  </Card.Title>
                  <Card.Text style={{ textAlign: "justify" }}>
                    The work of a qualified project manager is the life-giving
                    and driving force behind the development of any mobile game.
                    The PM takes over all the functions of creating a strategy,
                    a plan and effective team management, while at the same time
                    remaining the main link between the client and the team. It
                    is the project manager who is always in touch with the
                    client and is ready to answer any question and provide
                    instant feedback on any aspect of the work.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4'>
              <Card
                className='text-white'
                style={{ backgroundColor: "#40444A", border: "none" }}>
                <Card.Body>
                  <Card.Title
                    style={{
                      fontWeight: "700",
                    }}>
                    ARTISTS
                  </Card.Title>
                  <Card.Text style={{ textAlign: "justify" }}>
                    Creating a product that will be a real eye-catcher is the
                    main task that artists face. In accordance with the chosen
                    concept, they need to work out every visual detail of the
                    future mobile game. Their wealth of skills and vast
                    experience give them the ability to combine different
                    winning techniques to create an authentic and engaging
                    product.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div className='container my-4'>
        <h2
          style={{ fontSize: "35px", fontWeight: "600" }}
          className='text-center'>
          Mobile Game Development FAQ's
        </h2>
        {/* <Accordion defaultActiveKey='0' flush>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>
              <button
                style={{
                  border: "none",
                  background: "none",
                  margin: "0px 15px 15px 0px",
                }}>
                <span
                  style={{
                    background: "#39c4a8",
                    border: "none",
                    padding: "6px 8px",
                    margin: "0px 15px 15px 0px",
                    borderRadius: "8px",
                  }}>
                  <img src={Arrow} alt='' height={"15px"} width={"15px"} />
                </span>

                <span style={{ fontSize: "20px", fontWeight: "400" }}>
                  How to start my mobile game development project?
                </span>
              </button>
            </Accordion.Header>
            <Accordion.Body>
              You can kick-start your dream mobile game app development project
              just by contacting The Kanraj infotech and availing our mobile
              game app development services. We are an esteemed mobile game app
              development company known for creating robust, engaging and
              visually appealing mobile app development for games at affordable
              prices.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> */}

        <Accordiondata
          eventKey={"0"}
          title={"How to start my mobile game development project?"}
          details={
            "You can kick-start your dream mobile game app development project just by contacting The Kanraj infotech and availing our mobile game app development services. We are an esteemed mobile game app development company known for creating robust, engaging and visually appealing mobile app development for games at affordable prices."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />

        <Accordiondata
          eventKey={"1"}
          title={
            "What makes our mobile game development company different from others?"
          }
          details={
            "The Kanraj infotech uses the latest tools and technologies to build high-end games that run seamlessly on multiple devices. We are a trusted mobile game development studio in India offering custom mobile game development services at pocket-friendly prices. We have expertise in Unity game Development, Mobile Games Development, Unreal game Development, and many more. Our project manager and development team specializes in developing games for Mobile, PC, and Console."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"2"}
          title={"How can I create my own mobile game?"}
          details={
            "If you want to build a game by yourself then before jumping into the process, first you need to learn the art of mobile app development for games by taking some introductory courses on making mobile games. Or you can simply hire mobile game developers by availing of mobile game development services from a reputed company like The Kanraj infotech."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"3"}
          title={"How do you make a mobile game go viral?"}
          details={
            "If you want to create an android games or iOS mobile games, one thing is for sure that we all want it to become so popular that it goes viral as PUBG and Pokémon go. You can integrate the ‘wow factor’ in your addictive gameplay in terms of graphics concept art, characters and effects. Add a challenge that is difficult to make players talk to each other. Encourage users to share such games with target audience in different marketing strategies. All these practices will help your game to become popular."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"4"}
          title={"What are the stages of mobile game development?"}
          details={
            "<p>If you want to create a mobile game with agile methodology then you need to know the various stages of mobile game development services which are as follows:</p><ul><li>Pre-Production</li><li>Production</li><li>Testing</li><li>Pre-Launch</li><li>Pre-Launch</li><li>Launch on google play & app stores</li><li>Post-Production</li></ul>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"5"}
          title={
            "Is my mobile game can run on multiple mobile platforms like iOS and android?"
          }
          details={
            "Yes, we will create mobile games for you that can run on multiple operating systems and devices without any hassle. For that, you just need to specify your requirements and budget."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"6"}
          title={"How much does it cost to make a mobile game?"}
          details={
            "  The cost to make a mobile game depends upon various factors like the scale of the game, number of developers involved, the size of the team and number of mobile platforms – iOS, Android or Both. All these factors contribute to determining the overall budget of your project. In general, the cost can range from $1,000 to $1 million."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"7"}
          title={"How much time to develop a mobile game?"}
          details={
            "There is no specific time for Mobile Game development but it all depends upon the complexity of your gaming app and the structure of your project. Each stage takes a significant amount of time to complete successful project."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
      <Blogs id={3} />
    </div>
  );
};

export default Index;
