import React from "react";
import Banner from "../Banner/index";
import Background from "../../../assets/FacebookGame/1-Facebook-Game-Bannerc.jpg";
import FacebookGame from "../../../assets/FacebookGame/1-Facebook-GameWeb.webp";
import icon from "../../../assets/ConsoleGame/icon-image-nike.png";
import Smallcard from "../Smallcard";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  return (
    <div className='data'>
      <SEOComponent
        title='Facebook Game Development Company - Kanraj Infotech'
        description='With Kanraj Game Development, create Facebook games in no time. We empower developers to create meaningful connections between players on Facebook.'
        url='https://kanraj.com/facebookgame'
      />
      <Banner banner={Background} text={"Facebook Game Development"} />
      <div className='container'>
        <h6
          style={{
            color: "#078586",
            fontSize: "20px",
            fontWeight: "700",
            textDecoration: "underline",
            textDecorationColor: "#078586",
          }}>
          Game Development
        </h6>
        <h2 className='font_700'>Facebook Game Development services</h2>
        <p className='pera_one'>Create facebook games in no time</p>
        <p className='pera'>
          Facebook helps the game developer to build, grow and monetize your
          game across multiple screens. More than 800 hundred billion people
          play games on facebook and facebook connected devices monthly. Kanraj
          makes it easy for developers to deliver a substantial connection to
          its players' experience in facebook. It helps the player to share vast
          experiences in the game and outside of it, with some excellent way out
          that allows them to experience the mode of playing the game together.
          It also helps to share great content about the game onto the user to
          understand the actual strategy, rules, and regulation regarding the
          game.
        </p>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <Smallcard
              image={icon}
              title={"Background Checks"}
              text={
                "These cases are perfectly simple and easy to distinguish. In a free hour when our power."
              }
            />
            <img
              src={FacebookGame}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
                marginTop: "2rem",
              }}
            />
          </div>
          <div className='col-xs-12 col-md-6 mt-3'>
            <Smallcard
              image={icon}
              title={"Position Description"}
              text={
                "Trouble that are bound to ensue and equal blame belongs those who fail in their duty."
              }
            />
            <div className='m-3'></div>
            <Smallcard
              image={icon}
              title={"Profile Assessments"}
              text={
                "Indignation and men who are so beguiled and demoralized by the charms blinded."
              }
            />
            <h6 className='py-4 font_700' style={{ fontSize: "15px" }}>
              Tools which will help you to set free games whenever your palyer
              wants to play
            </h6>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Facebook Instant Games</h4>
            </div>
            <p className='pera'>
              Instant game is an advanced approach for people to experience
              games across the Facebook platform, powered by HTML5 technology
              through messenger or news feed across devices.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Facebook Gameroom</h4>
            </div>
            <p className='pera'>
              Players can experience both online games and the native game built
              excellently for the platform within the modern facebook gameroom.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Games on Facebook</h4>
            </div>
            <p className='pera'>
              The core Facebook is experienced deeply by integrating a player
              which is allowed by building a game on facebook.com
            </p>
          </div>
        </div>
      </div>
      <div className='mt-4' style={{ backgroundColor: "#151414" }}>
        <div className='container py-4' style={{ color: "white" }}>
          <h3 className='text-center px-2 font_700'>
            Facebook game services we offer
          </h3>
          <p className='px-3'>
            Facebook offers a group of similar products and services that are
            specifically crucial for game developers to build, grow, and
            monetize their game across multiple screens.
          </p>
          <div className='row'>
            <div className='col-xs-12 col-md-6'>
              <h4 className='pb-2 font_700'>Sharing</h4>
              <div
                className='my-1'
                style={{
                  width: "20px",
                  height: "3px",
                  backgroundColor: "#00ce1b",
                  alignItems: "center",
                }}></div>
              <p
                className='py-2'
                style={{ fontSize: "18px", textAlign: "justify" }}>
                Enhanced and reached your game by delivering people the
                authentic opportunities to share.
              </p>
              <h4 className='pb-2 font_700'>GAME REQUEST</h4>
              <div
                className='my-1'
                style={{
                  width: "20px",
                  height: "3px",
                  backgroundColor: "#00ce1b",
                  alignItems: "center",
                }}></div>
              <p
                className='py-2'
                style={{ fontSize: "18px", textAlign: "justify" }}>
                Let players invite their friends to game, send in-game gifts,
                messages, or allow them to come to know their turn in a
                multiplayer match.
              </p>
              <h4 className='pb-2 font_700'>LIVE GAME STREAMING</h4>
              <div
                className='my-1'
                style={{
                  width: "20px",
                  height: "3px",
                  backgroundColor: "#00ce1b",
                  alignItems: "center",
                }}></div>
              <p
                className='py-2'
                style={{ fontSize: "18px", textAlign: "justify" }}>
                It enables players to stream live gameplay to engage players in
                a fun and powerful way.
              </p>
            </div>
            <div className='col-xs-12 col-md-6'>
              <h4 className='pb-2 font_700'>APP NOTIFICATION</h4>
              <div
                className='my-1'
                style={{
                  width: "20px",
                  height: "3px",
                  backgroundColor: "#00ce1b",
                  alignItems: "center",
                }}></div>
              <p
                className='py-2'
                style={{ fontSize: "18px", textAlign: "justify" }}>
                Busy players with less, free-form messages that call them to
                action tell them about vital events
              </p>
              <h4 className='pb-2'>MEASUREMENT FOR GAME DEVELOPERS</h4>
              <div
                className='my-1'
                style={{
                  width: "20px",
                  height: "3px",
                  backgroundColor: "#00ce1b",
                  alignItems: "center",
                }}></div>
              <p
                className='py-2'
                style={{ fontSize: "18px", textAlign: "justify" }}>
                Players are interacting with the game and learning more about
                the games by integrating on tools and features like app events.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Blogs id={14} />
    </div>
  );
};

export default Index;
