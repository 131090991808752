import React from "react";
import Banner from "../Banner";
import BgBanner from "../../../assets/iosgames/1-ISO-Game-Banner.jpg";
import Counters from "../../Counters";
import Smallcard from "../Smallcard";
import Services from "../../../assets/iosgames/1-ISOWeb.webp";
import Technical from "../../../assets/iosgames/2-ISOWeb.webp";
import Technologies from "../../../assets/iosgames/3-ISOWeb.webp";
import Faster from "../../../assets/Androidgames/2-Faster-Returns.png";
import Reduced from "../../../assets/Androidgames/3-Reduced-Expenditures.png";
import Quality from "../../../assets/Androidgames/4-Quality-Android-Games.png";
import Checks from "../../../assets/Androidgames/5-Background-Checks.png";
import Holistic from "../../../assets/Androidgames/6-Holistic-Approach.png";
// import Services from "../../../assets/Androidgames/1-AndroidWeb.webp";
import Gamedeveloper from "../../../assets/Androidgames/2-AndroidWeb.webp";
import Mobiledeveloper from "../../../assets/Androidgames/3-AndroidWeb.webp";
import Sports from "../../../assets/Androidgames/5-AR-Sports.png";
import Poker from "../../../assets/Androidgames/2-poker-game.png";
import Cardgame from "../../../assets/Androidgames/3-card-game.png";
import Bord from "../../../assets/Androidgames/4-board-game.png";
import Racing from "../../../assets/Androidgames/5-race-game.png";
import Role from "../../../assets/Androidgames/6-roll-playing-game.png";
import Casual from "../../../assets/Androidgames/casual.png";
import Action from "../../../assets/Androidgames/7-action-game.png";
import Typeofgames from "../Typeofgames";
import Accordiondata from "../../Accordiondata";
import Rightarrow from "../../../assets/iosgames/gaming.png";
import { useState } from "react";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  const gamesType = [
    {
      img: Sports,
      title: "Sports Game",
    },
    {
      img: Poker,
      title: "Poker Games",
    },
    {
      img: Cardgame,
      title: "Card Games",
    },
    {
      img: Bord,
      title: "Board Games",
    },
    {
      img: Racing,
      title: "Racing Games",
    },
    {
      img: Role,
      title: "Role Games",
    },
    {
      img: Casual,
      title: "Casual Games",
    },
    {
      img: Action,
      title: "Action Games",
    },
  ];

  return (
    <div className='data'>
      <SEOComponent
        title='IOS Game Development Company -  Kanraj Infotech'
        description='Unlock the full potential of iOS gaming with our seasoned team of iPhone game developers. With a wealth of experience and expertise, we craft feature-rich games for all iOS devices.'
        url='https://kanraj.com/iosgame'
      />
      <Banner banner={BgBanner} text={"IOS Game Development"} />
      <div className='container'>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <h6
              className='font_700'
              style={{
                color: "#078586",
                fontSize: "20px",
                textDecoration: "underline",
                textDecorationColor: " #078586",
              }}>
              Game development
            </h6>
            <h2 style={{ fontWeight: "600" }}>IOS game development</h2>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Crafting engaging iOS mobile games in various game genres is our
              purpose. We are a seasoned iOS Game Development Company having 10+
              skilled iPhone game developers in creating feature-rich games for
              all iOS devices with the art of thrilling storylines, excellent
              visuals, and incredible sounds. Outsource our iPhone game
              development services today to lead the competition!
            </p>
          </div>
          <div
            className='col-md-4'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Counters number={2} />
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game designers
            </p>
          </div>
          <div
            className='col-md-4'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Counters number={10} />
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game developers
            </p>
          </div>
          <div
            className='col-md-4'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Counters number={100} />
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Gaming projects
            </p>
          </div>
        </div>
      </div>

      <div className='container mt-4'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 style={{ fontWeight: "600" }}>
              Our Robust Suite Of iOS Game Development Services
            </h3>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              With our client-centric approach, we have emerged as one of the
              best iOS game development companies rendering quality app services
              in India, the USA, the UK, UAE and other areas of the world. Our
              creative and efficient iOS game development services can help you
              grow your business in quick turnaround times. Get top-notch game
              development services from Kanraj infotech for innovative,
              immersive and out-of-the-box games
            </p>
            <h6
              style={{ color: "#078586", fontSize: "25px", fontWeight: "600" }}>
              Asset Creation
            </h6>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Based on our client’s requirements, our skilled and expert design
              team develop different types of art assets for iOS, whether
              cartoon or realistic. Our app development on iOS devices optimizes
              game sprites, environment, multi-touch screen controls so that
              they run smoothly on various iOS devices.
            </p>
          </div>
          <div className='col-md-6'>
            <img
              src={Services}
              alt=''
              width={"100%"}
              height={"450px"}
              style={{ borderRadius: "12px", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <div className='container my-4'>
        <div className='row'>
          <div className='col-md-6'>
            <img
              src={Technical}
              alt=''
              width={"100%"}
              height={"450px"}
              style={{ borderRadius: "12px", objectFit: "cover" }}
            />
          </div>
          <div className='col-md-6'>
            <h2 style={{ fontWeight: "600" }}>
              Our Technical Expertise In iOS Game Development
            </h2>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              With our devoted iOS games development team, we have successfully
              created stunning games with flawless graphics that work on all iOS
              devices like iPhone and iPad. Our remarkable technical expertise
              in Unity game development and other high-end technologies allows
              us to test your app rigorously at every stage to assure incredible
              quality results.
            </p>
            <ul>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Unity Game
              </li>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                AR/VR Games
              </li>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Oculus Rift Games
              </li>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                2D And 3D Game
              </li>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Construct3 Games
              </li>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Facebook Games
              </li>{" "}
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Action And Educative Games
              </li>{" "}
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Multiplayer Games{" "}
              </li>{" "}
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Adventure Games
              </li>
            </ul>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-md-6'>
            <h2 style={{ fontWeight: "600" }}>
              Our iPhone Game Development Technologies And Tools{" "}
            </h2>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Our industry veterans in India work together in different
              technological aspects towards the sole purpose of developing a fun
              and engaging gaming app that can drive business. Kanraj infotech
              gives quality assurance at a reasonable and cost-effective price
              with an exciting UI, features, and the functionality iOS games
              require. We use state-of-art technology and tools to deliver
              marvelous games as the best android game and iPhone game
              development company in India.
            </p>
            <ul>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Swift
              </li>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Unity
              </li>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Cocos2D
              </li>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Cocos3D
              </li>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Corona SDK
              </li>
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Unreal Engine
              </li>{" "}
              <li>
                {" "}
                <span style={{ paddingRight: "6px" }}>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                OpenGL
              </li>
            </ul>
          </div>
          <div className='col-md-6'>
            <img
              src={Technologies}
              alt=''
              width={"100%"}
              height={"450px"}
              style={{ borderRadius: "12px", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <div className='container my-4'>
        <Accordiondata
          eventKey={"0"}
          title={
            "  Why Choose Kanraj Infotech as Your Android Game Development Company?"
          }
          details={
            "   With our experienced and successful team of android game developers, we have established a reputation for delivering the finest quality game engine across the world within your budget. Being top Android game development company, We aim to compete with popular offerings such as Hay day, Monument valley, Nonstop knight, dynasty warriors, walking dead, toy blast, etc."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />

        <Accordiondata
          eventKey={"1"}
          title={"Cost Savings"}
          details={
            "By outsourcing our android game development company and engaging developers on a contract basis, you can save up to 70% on your project costs."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"2"}
          title={"Inter-Apps Integration"}
          details={
            "We create a google play console that enables inter-process architecture and allows apps to run on a variety of platforms and devices."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"3"}
          title={"Custom Games"}
          details={
            "As a leading Android game development company, we keep our client requirements at priority to develop tailor-made mobile games."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"4"}
          title={"Memory & Performance"}
          details={
            "Our quick and interactive applications enable us to create memory-efficient and high-performance gaming apps."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
      <Blogs id={4} />
    </div>
  );
};

export default Index;
