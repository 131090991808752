import React from "react";
import Location from "../../assets/custom/images/location.png";
import Phone from "../../assets/custom/images/telephone.png";
import Mail from "../../assets/custom/images/mail.png";

const Header = () => {
  return (
    <>
      {/* ======================== */}
      <div className='container-fluid' style={{ background: "#282f3b" }}>
        <div className='container py-3'>
          <div className='row'>
            <div className='header_info' style={{}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}>
                <div className='mx-2 location'>
                  <span
                    style={{
                      color: "#AAAFB8",
                      cursor: "pointer",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}>
                    <img
                      className='me-1'
                      src={Location}
                      height={"18px"}
                      width={"18px"}
                      alt=''
                    />{" "}
                    <span className='me-1' style={{ color: "#ffff" }}>
                      {" "}
                      Location{" "}
                    </span>
                    Ahmedabad
                  </span>
                </div>

                <div className='mx-2 mail'>
                  <span
                    style={{
                      color: "#AAAFB8",
                      cursor: "pointer",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}>
                    <img
                      className='me-1'
                      src={Mail}
                      height={"18px"}
                      width={"18px"}
                      alt=''
                    />{" "}
                    <span className='me-1' style={{ color: "#ffff" }}>
                      {" "}
                      Email{" "}
                    </span>{" "}
                    sales@kanraj.com
                  </span>
                </div>
              </div>

              <div className=' mx-2 phone'>
                <div>
                  <span
                    style={{
                      color: "#AAAFB8",
                      cursor: "pointer",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}>
                    <img
                      className='me-1'
                      src={Phone}
                      height={"18px"}
                      width={"18px"}
                      alt=''
                    />
                    <span className='me-1' style={{ color: "#ffff" }}>
                      {" "}
                      Phone{" "}
                    </span>{" "}
                    +91 9510354183
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
