import React from "react";
import Banner from "../Banner/index";
import Background from "../../../assets/OnlineGame/1-Online-Game-Banner.jpg";
import OnlineGame from "../../../assets/OnlineGame/1-Online-GameWeb.webp";
import OnlineGame2 from "../../../assets/OnlineGame/2-Online-GameWeb.webp";
import OnlineGame3 from "../../../assets/OnlineGame/3-Online-GameWeb.webp";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  return (
    <div className='data'>
      <SEOComponent
        title='Game Development Services - Kanraj Infotech'
        description="Elevate your gaming experience with Kanraj Infotech's premier online game development services. Our expert team specializes in crafting immersive and engaging online games that captivate players worldwide."
        url='https://kanraj.com/onlinegame'
      />
      <Banner banner={Background} text={"Online Game Development"} />
      <div className='container'>
        <h6
          style={{
            color: "#078586",
            fontSize: "20px",
            fontWeight: "700",
            textDecoration: "underline",
            textDecorationColor: "#078586",
          }}>
          Online Game Development
        </h6>
        {/* <span
          style={{
            border: "2px solid #078586 ",
            display: "block",
            width: "70px",
            marginBottom: "10px",
          }}
        ></span> */}
        <h2 className='font_700'>Online Game Development services</h2>
        <p className='pera'>
          In today’s world, online games are played across many platforms and
          devices like Smartphones, desktop and console on browsers. Online
          games have come a long way since the '90s. Online games are not just
          played for entertainment but they have briefly served as a good
          educational medium.
        </p>
        <div className='row py-4'>
          <div className='col-xs-12 col-md-6'>
            <p className='pera'>
              Kanraj Infotech has taken in consideration the exact need of its
              users who are interested in playing online games by offering them
              the complete range of services in online game development
              including conceptual frameworks, Art, level of design, coding,
              scripting, UI/XI, development plus deployment. Today everyone can
              experience fascinating games, thrilling games, board games, war
              games, and adventure games on all modern platforms of gaming like
              PCs, Mobile, and browser. Because of the internet and smart
              devices, online games have captured the pinnacle of growing an
              ecosystem associated with the digital revolution.
            </p>
          </div>
          <div className='col-xs-12 col-md-6'>
            <img
              style={{
                height: "270px",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
              src={OnlineGame}
              alt=''
            />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#2B2B2B" }}>
        <div className='container py-4'>
          <div className='row'>
            <div className='col-xs-12 col-md-6 pb-2'>
              <img
                src={OnlineGame2}
                alt=''
                style={{
                  height: "350px",
                  maxWidth: "100%",
                  border: "none",
                  borderRadius: "8px",
                }}
              />
            </div>
            <div className='col-xs-12 col-md-6' style={{ color: "white" }}>
              <h4>Types of online games</h4>
              <p style={{ marginBottom: "0.5rem" }}>
                {" "}
                ~ First-person shooter game (FPS)
              </p>
              <p style={{ marginBottom: "0.5rem" }}>
                {" "}
                ~ Real-time strategy game (RTS)
              </p>
              <p style={{ marginBottom: "0.5rem" }}>~ PVP games</p>
              <p style={{ marginBottom: "0.5rem" }}>
                ~ Massively multiplayer online game (MMO)
              </p>
              <p style={{ marginBottom: "0.5rem" }}>
                ~ Multiplayer online battle arena game (MOBA)
              </p>
              <p style={{ marginBottom: "0.5rem" }}>~ Battle Royale games.</p>
              <p style={{ marginBottom: "0.5rem" }}>~ MUD.</p>
              <p style={{ marginBottom: "0.5rem" }}>~ Other notable games</p>
              <p style={{ marginBottom: "0.5rem" }}>~ Real money games</p>
              <p style={{ marginBottom: "0.5rem" }}>~ Online casinos</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <h2 className='font_700'>Online Game Services We Offer</h2>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Kanraj Team</h4>
            </div>
            <p className='pera px-3'>
              We are a highly professional and experienced team who has immense
              knowledge and experience of developing online games with a range
              of latest technologies.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Quality Zone</h4>
            </div>
            <p className='pera px-3'>
              We are totally focused on our game quality because we examine our
              games through a rigorous testing process by our own quality
              analyst from our quality department.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Global Existence</h4>
            </div>
            <p className='pera px-3'>
              Kanraj Infotech has delivered games on several platforms to its
              clients in many countries like USA, UK, Germany, India, Canada ,
              etc. Just because of the multinational presence we have built a
              strong relationship with our client in the sector of online game
              development worldwide.
            </p>
          </div>
          <div className='col-xs-12 col-md-6'>
            <img
              src={OnlineGame3}
              alt=''
              style={{
                height: "450px",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
        </div>
      </div>
      <div className='container'>
        <h3 className='font_700'>Why kanraj infotech </h3>
        <p className='pera'>
          Kanraj Infotech is one of the most leading online game development
          companies in India who have delivered high-quality online games across
          different genres. We have offered an efficient communication process
          for our customer with a collaborative approach and belief in
          flexibility with holding a transparent relationship with our clients.
        </p>
      </div>
      <Blogs id={16} />
    </div>
  );
};

export default Index;
