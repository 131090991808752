import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slid1 from "../../../assets/3DGame/1-AAA-Game-Slider.jpg";
import Slid2 from "../../../assets/3DGame/2-AR-VR-Slider.jpg";
import Slid3 from "../../../assets/3DGame/3-Gamification-Slider.jpg";
import Slid4 from "../../../assets/3DGame/4-Cross-Platform-Sliderc.jpg";
import Slid5 from "../../../assets/3DGame/5-Quickly-Done-Slider.jpg";
import Slid6 from "../../../assets/3DGame/6-Store-Slider.jpg";
import { faL } from "@fortawesome/free-solid-svg-icons";

const SliderData = () => {
  const settings = {
    // Add your Slick settings here
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      {/* <div className='container-fluid g-0' style={{}}> */}
      {/* <Slider {...settings}>
          <div>
            <div
              style={{
                
                backgroundImage: `url(${Slid1})`,
                height: "300px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                padding:"0px 1rem",
                backgroundSize:'cover',
                backgroundPosition: 'top',
               
              }}>
                

              <h2 style={{fontWeight:"600"}}>Cross-platform Game Development</h2>
              <h6 style={{textAlign:"justify",color:"#fff",fontSize:"18px"}}>
                Super strong cross-platform game development Engine. The Engine
                supports more than 25 platforms for game development with s
                single-click deployment feature.
              </h6>
               
            </div>
          </div>
          <div>
            <div            
              style={{
                backgroundImage: `url(${Slid2})`,
                height: "300px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                padding:"0px 1rem",
                backgroundSize:'cover',
                backgroundPosition: 'top',
              }}>
              <h2 style={{fontWeight:"600"}}>Support AAA Quality Games </h2>
              <p style={{textAlign:"justify",color:"#fff",fontSize:"18px"}}>
                Equipped with all the libraries, assets, and features that are
                needed to develop a AAA Quality Game with magnificent 3D Design,
                arts, and characters.{" "}
              </p>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Slid3})`,
                height: "300px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                padding:"0px 1rem",
                backgroundSize:'cover',
                backgroundPosition: 'top',
              }}>
              <h2 style={{fontWeight:"600"}}>Gamification </h2>
              <p style={{textAlign:"justify",color:"#fff",fontSize:"18px"}}>
                Unity3D game development engine is not just limited to
                developing full-fledged mobile games, but, this engine is also
                used to add the gamification feature in mobile apps.
              </p>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Slid4})`,
                height: "300px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                padding:"0px 1rem",
                backgroundSize:'cover',
                backgroundPosition: 'center',
              }}>
              <h2 style={{fontWeight:"600"}}>VR/AR Friendly Engine </h2>
              <p style={{textAlign:"justify",color:"#fff",fontSize:"18px"}}>
                Provide a perfect environment for AR/VR needs of your app. More
                than 60% of Augmented Reality and Virtual Reality content is
                created using the Unity3D Engine.{" "}
              </p>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Slid5})`,
                height: "300px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                padding:"0px 1rem",
                backgroundSize:'cover',
                backgroundPosition: 'top',
              }}>
              <h2 style={{fontWeight:"600"}}>Quickness and Agility</h2>
              <p style={{textAlign:"justify",color:"#fff",fontSize:"18px"}}>
                Super fast engine that allows the developers to get things done
                quickly. Its quickness is an asset that proves another strength
                of this game development engine.{" "}
              </p>
            </div>
          </div>

          <div>
            <div
              style={{
                backgroundImage: `url(${Slid6})`,
                height: "300px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                padding:"0px 1rem",
                backgroundSize:'cover',
                backgroundPosition: 'top',
              }}>
              <h2 style={{fontWeight:'600'}}>Large and Varied Asset Store </h2>
              <p style={{textAlign:"justify",color:"#fff",fontSize:"18px"}}>
                It has more than 56,000 packages available in the asset store.
                This sums up to over a million assets for the users to choose
                from and use in their project.
              </p>
            </div>
          </div>
        </Slider> */}
      {/* </div> */}

      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        emulateTouch={false}
        showIndicators={false}>
        <div className='' style={{ display: "flex", alignItems: "center" }}>
          <img src={Slid1} />

          <div
            className='legend'
            style={{
              background: "transactions ",
            }}>
            <h2 className='header-font'>Support AAA Quality Games</h2>
            <h6 className='info-font'>
              Equipped with all the libraries, assets, and features that are
              needed to develop a AAA Quality Game with magnificent 3D Design,
              arts, and characters.
            </h6>
          </div>
        </div>
        <div className='' style={{ display: "flex", alignItems: "center" }}>
          <img src={Slid3} />

          <div
            className='legend'
            style={{
              background: "transactions ",
            }}>
            <h2 className='header-font'>Gamification </h2>
            <h6 className='info-font'>
              Unity3D game development engine is not just limited to developing
              full-fledged mobile games, but, this engine is also used to add
              the gamification feature in mobile apps.
            </h6>
          </div>
        </div>
        <div className='' style={{ display: "flex", alignItems: "center" }}>
          <img src={Slid2} />

          <div
            className='legend'
            style={{
              background: "transactions ",
            }}>
            <h2 className='header-font'>VR/AR Friendly Engine </h2>
            <h6 className='info-font'>
              Provide a perfect environment for AR/VR needs of your app. More
              than 60% of Augmented Reality and Virtual Reality content is
              created using the Unity3D Engine.
            </h6>
          </div>
        </div>
        <div className='' style={{ display: "flex", alignItems: "center" }}>
          <img src={Slid5} />

          <div
            className='legend'
            style={{
              background: "transactions ",
            }}>
            <h2 className='header-font'>Quickness and Agility</h2>
            <h6 className='info-font'>
              Super fast engine that allows the developers to get things done
              quickly. Its quickness is an asset that proves another strength of
              this game development engine.
            </h6>
          </div>
        </div>
        <div className='' style={{ display: "flex", alignItems: "center" }}>
          <img src={Slid4} />

          <div
            className='legend'
            style={{
              background: "transactions ",
            }}>
            <h2 className='header-font'>Cross-platform Game Development </h2>
            <h6 className='info-font'>
              Super strong cross-platform game development Engine. The Engine
              supports more than 25 platforms for game development with s
              single-click deployment feature.
            </h6>
          </div>
        </div>
        <div className='' style={{ display: "flex", alignItems: "center" }}>
          <img src={Slid6} />

          <div
            className='legend'
            style={{
              background: "transactions ",
            }}>
            <h2 className='header-font'>Large and Varied Asset Store </h2>
            <h6 className='info-font'>
              It has more than 56,000 packages available in the asset store.
              This sums up to over a million assets for the users to choose from
              and use in their project.
            </h6>
          </div>
        </div>
      </Carousel>
    </>
  );
};

export default SliderData;
