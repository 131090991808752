import React, { useState } from "react";
import Accordiondata from "../Accordiondata";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Arrow from "../../assets/custom/images/right-arrow.png";

const ProjectAccordion = ({ children, eventKey }) => {
  // const [activeKey, setActiveKey] = useState('0');
  //   const handleAccordionToggle = (eventKey) => {
  //   setActiveKey((prevKey) => (prevKey === eventKey ? null : eventKey));
  // };
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };

  return (
    <>
      <div className='container my-4'>
        <h2
          style={{ fontSize: "34px", fontWeight: "500" }}
          className='text-center'>
          {" "}
          Why Kanraj Infotech For Game Development ?
        </h2>
        <Accordiondata
          eventKey={"0"}
          title={"Confidentiality"}
          details={
            "Your business privacy and confidentiality are the important part of your project. And we understand this, we also sign the strict NDA with the employees and the clients. Your data will be secure and safe."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"1"}
          title={"Quality Assurance"}
          details={
            "We Provide Bug free final product, in this step, we will test your product and it will go through various rigorous tests. We run the app on various devices, and through various scenarios, we see if there are any bugs in the app. This process ensures that you have the best version of your product. Once we receive the green light from the testing team, we are ready for the app launch with your satisfied Yes !"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />{" "}
        <Accordiondata
          eventKey={"2"}
          title={"Result oriented Approach"}
          details={
            "We do not believe in quantity. Instead, we believe in Quality and results. Client focus is our main goal. Therefore, we analyzed and followed the structured guideline."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />{" "}
        <Accordiondata
          eventKey={"3"}
          title={"Cost Effective Solutions"}
          details={
            "We carefully follow the budget and time estimations. We try to provide the best optimal solution within the budget. Our recommendations are always growth-oriented."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />{" "}
        <Accordiondata
          eventKey={"4"}
          title={"Professional & Experienced Team"}
          details={
            "We are a highly professional group of teams which are always ready to work on new challenges. In addition, our developers are passionate about developing next-gen solutions."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"5"}
          title={"Willingness and Passion"}
          details={
            "We believe that without willingness and passion there is no great output no matter how much resources you have ! We are Lucky to have a great team who will take care of your product and service."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
    </>
  );
};

export default ProjectAccordion;
