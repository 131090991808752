import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide1 from "../../../assets/ARGame/8-Game-StructureWeb1.webp";
import Slide2 from "../../../assets/ARGame/9-Game-WireframeWeb1.webp";
import Slide3 from "../../../assets/ARGame/10-Game-DevelopmentWeb.webp";
import Slide4 from "../../../assets/ARGame/11-Game-TestingWeb.webp";
import Slide5 from "../../../assets/ARGame/12-LaunchWeb.webp";
import Slide6 from "../../../assets/ARGame/13-SupportWeb1.webp";

const index = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500, // Adjust speed as needed
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true, // Show navigation arrows
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500, // Set autoplay speed (in milliseconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div
        className='container'
        style={{ margin: "0 auto", textAlign: "-webkit-center" }}>
        <div className='row' style={{ overflow: "hidden" }}>
          {/* <Slider {...settings}>
            <div>
              <div
                style={{
                  backgroundImage: `url(${Slide1})`,
                  height: "200px",
                  width: "100%",
                  backgroundSize: "cover",
                  // margin:"0px 5rem"
                }}></div>
              <p className='ps-5'>Game Structure</p>
            </div>
            <div style={{ margin: "0px 5rem" }}>
              <div
                style={{
                  backgroundImage: `url(${Slide2})`,
                  height: "200px",
                  width: "100%",
                  backgroundSize: "cover",
                }}></div>
              <p className='ps-5'>Game Wireframe</p>
            </div>
            <div>
              <div
                style={{
                  backgroundImage: `url(${Slide3})`,
                  height: "200px",
                  width: "100%",
                  backgroundSize: "cover",
                }}></div>

              <p className='ps-5'>Game Development</p>
            </div>
            <div>
              <div
                style={{
                  backgroundImage: `url(${Slide4})`,
                  height: "200px",
                  width: "100%",
                  backgroundSize: "cover",
                }}></div>
              <p className='ps-5'> Game Testing</p>
            </div>
            <div>
              <div
                style={{
                  backgroundImage: `url(${Slide5})`,
                  height: "200px",
                  width: "100%",
                  backgroundSize: "cover",
                }}></div>
              <p className='ps-5'>Launch</p>
            </div>
            <div>
              <div
                style={{
                  backgroundImage: `url(${Slide6})`,
                  height: "200px",
                  width: "100%",
                  backgroundSize: "cover",
                }}></div>
              <p className=''>Support&Maintanance</p>
            </div>
          </Slider> */}
          <Slider {...settings}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}>
              <img
                src={Slide1}
                alt=''
                width={"88%"}
                height={"250px"}
                style={{ objectFit: "fill", borderRadius: "10px" }}
              />
              <p
                className=''
                style={{
                  textAlign: "center",
                  marginRight: "0rem",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}>
                Game Structure
              </p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}>
              <img
                src={Slide2}
                alt=''
                width={"88%"}
                height={"250px"}
                style={{ objectFit: "fill", borderRadius: "10px" }}
              />

              <p
                style={{
                  textAlign: "center",
                  marginRight: "0rem",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}>
                Game Wireframe
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}>
              <img
                src={Slide3}
                alt=''
                width={"88%"}
                height={"250px"}
                style={{ objectFit: "fill", borderRadius: "10px" }}
              />

              <p
                style={{
                  textAlign: "center",
                  marginRight: "0rem",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}>
                Game Development
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}>
              <img
                src={Slide4}
                alt=''
                width={"88%"}
                height={"250px"}
                style={{ objectFit: "fill", borderRadius: "10px" }}
              />

              <p
                style={{
                  textAlign: "center",
                  marginRight: "0rem",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}>
                {" "}
                Game Testing
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}>
              <img
                src={Slide5}
                alt=''
                width={"88%"}
                height={"250px"}
                style={{ objectFit: "fill", borderRadius: "10px" }}
              />

              <p
                style={{
                  textAlign: "center",
                  marginRight: "0rem",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}>
                Launch
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}>
              <img
                src={Slide6}
                alt=''
                width={"88%"}
                height={"250px"}
                style={{ objectFit: "fill", borderRadius: "10px" }}
              />

              <p
                style={{
                  textAlign: "center",
                  marginRight: "0rem",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}>
                Support&Maintanance
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default index;
