import React, { useState } from "react";
import Banner from "../Banner";
import BgBanner from "../../../assets/Androidgames/1-Android-game-Banner.jpg";
import Counters from "../../Counters";
import Smallcard from "../Smallcard";
import Seamless from "../../../assets/Androidgames/1-Seamless-Integration.png";
import Faster from "../../../assets/Androidgames/2-Faster-Returns.png";
import Reduced from "../../../assets/Androidgames/3-Reduced-Expenditures.png";
import Quality from "../../../assets/Androidgames/4-Quality-Android-Games.png";
import Checks from "../../../assets/Androidgames/5-Background-Checks.png";
import Holistic from "../../../assets/Androidgames/6-Holistic-Approach.png";
import Services from "../../../assets/Androidgames/1-AndroidWeb.webp";
import Gamedeveloper from "../../../assets/Androidgames/2-AndroidWeb.webp";
import Mobiledeveloper from "../../../assets/Androidgames/3-AndroidWeb.webp";
import Sports from "../../../assets/Androidgames/5-AR-Sports.png";
import Poker from "../../../assets/Androidgames/2-poker-game.png";
import Cardgame from "../../../assets/Androidgames/3-card-game.png";
import Bord from "../../../assets/Androidgames/4-board-game.png";
import Racing from "../../../assets/Androidgames/5-race-game.png";
import Role from "../../../assets/Androidgames/6-roll-playing-game.png";
import Casual from "../../../assets/Androidgames/casual.png";
import Action from "../../../assets/Androidgames/7-action-game.png";
import Typeofgames from "../Typeofgames";
import Accordiondata from "../../Accordiondata";

import Rightarrow from "../../../assets/Androidgames/play.png";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  const gamesType = [
    {
      img: Sports,
      title: "Sports Game",
    },
    {
      img: Poker,
      title: "Poker Games",
    },
    {
      img: Cardgame,
      title: "Card Games",
    },
    {
      img: Bord,
      title: "Board Games",
    },
    {
      img: Racing,
      title: "Racing Games",
    },
    {
      img: Role,
      title: "Role Games",
    },
    {
      img: Casual,
      title: "Casual Games",
    },
    {
      img: Action,
      title: "Action Games",
    },
  ];

  return (
    <div className='data'>
      <SEOComponent
        title='Android Game Development Company -  Kanraj Infotech'
        description="Expand your gaming business swiftly with our Android game development services. We are adept at launching customized 2D and 3D game engines that are user-friendly with excellent UIs. Explore our portfolio and let's bring your vision to life!"
        url='https://kanraj.com/androidgame'
      />
      <Banner banner={BgBanner} text={"Android Game Development"} />
      <div className='container'>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <h6
              style={{
                color: "#078586",
                fontSize: "20px",
                fontWeight: "700",
                textDecoration: "underline",
                textDecorationColor: "#078586",
              }}>
              Game Development
            </h6>
            <h2 style={{ fontWeight: "600" }}>Android game development</h2>
            <p style={{ fontSize: "18px" }}>
              We exceed our clients’ expectations through the expertise of our
              Android game developers which has positioned us as a leading
              Android game development company across the globe. We treat each
              game genre with a unique and uncommon approach while turning ideas
              into real-life experience Android games.
            </p>
          </div>
          <div
            className='col-md-4'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Counters number={3} />
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game designers
            </p>
          </div>
          <div
            className='col-md-4'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Counters number={15} />
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game developers
            </p>
          </div>
          <div
            className='col-md-4'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Counters number={300} />
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Gaming projects
            </p>
          </div>
        </div>
      </div>

      <div className='container mt-4'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 style={{ fontWeight: "600" }}>
              Android game development Services
            </h3>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              With innovative and cost-effective 2D gaming and 3D game
              development services, our Android game development services help
              you expand your gaming business promptly. We are adept at
              launching customized 2D and 3D game engines that are user-friendly
              with excellent user interface designs.
              <ul>
                <li>
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>{" "}
                  Concept Art Design
                </li>
                <li>
                  {" "}
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>{" "}
                  Google Play Store
                </li>
                <li>
                  {" "}
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>
                  UI/UX Design
                </li>
                <li>
                  {" "}
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>{" "}
                  Custom Game
                </li>
                <li>
                  {" "}
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>{" "}
                  Asset Game Design
                </li>
                <li>
                  {" "}
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>{" "}
                  Live Game Operations
                </li>
                <li>
                  {" "}
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>
                  In-App Billing
                </li>
                <li>
                  {" "}
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>
                  Game Center Integration
                </li>
                <li>
                  {" "}
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>
                  Single & Multiplayer
                </li>
                <li>
                  {" "}
                  <span>
                    <img
                      src={Rightarrow}
                      alt=''
                      width={"20px"}
                      height={"20x"}
                    />
                  </span>
                  Support And Maintenance
                </li>
              </ul>
            </p>
          </div>
          <div className='col-md-6'>
            <img
              src={Services}
              alt=''
              width={"100%"}
              height={"450px"}
              style={{ borderRadius: "12px", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <div className='container my-5 '>
        <h3 style={{ fontWeight: "600" }}>
          Benefits Of Android Game Development
        </h3>
        <p style={{ color: "#000", fontSize: "18px" }}>
          As smartphones have become more prevalent in our lives, the android
          game development industry is at an all-time high. Android devices
          offer a lot of flexibility, which is one of the main reasons why firms
          make a lot of money from android games.
        </p>
        <div className='row my-4'>
          <div className='col-md-4'>
            <Smallcard
              image={Seamless}
              title={"Seamless Integration"}
              text={
                "Best android game developers develop all types of game engines compatible with all kinds of android devices. The hands-on experience in android programming results in top-notch gaming integration."
              }
            />
          </div>

          <div className='col-md-4'>
            <Smallcard
              image={Reduced}
              title={"Reduced Expenditures"}
              text={
                "Reduced ExpendituresIncreased game downloads enable you to communicate directly with your clients or users. The free to play games advertise your brand across different markets while also lowering your marketing and promotional costs."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={Faster}
              title={"Faster Returns"}
              text={
                "In-line to iOS game development, the codes and android game programming patterns in the Android game development area enables your game streaming to go faster than anticipated."
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4'>
            <Smallcard
              image={Quality}
              title={"Quality Android Games"}
              text={
                "Whether you need a new game developed from scratch or specialized services like asset production or code integration, usage of the latest sophisticated tools creates quality game libraries."
              }
            />
          </div>

          <div className='col-md-4'>
            <Smallcard
              image={Holistic}
              title={"Holistic Approach"}
              text={
                "Android studio adopts a holistic approach to launching games, which includes the user interface, mechanics, art, design, as well as performance issues. This creates consistent products and prioritizes the player experience."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={Checks}
              title={"Background Checks"}
              text={
                "These cases are perfectly simple and easy to distinguish. In a free hour when our power."
              }
            />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <img
              src={Gamedeveloper}
              alt=''
              width={"100%"}
              height={"450px"}
              style={{ borderRadius: "12px", objectFit: "cover" }}
            />
          </div>
          <div className='col-md-6'>
            <h2 style={{ fontWeight: "600" }}>
              Our Android Game developers Expertise
            </h2>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Leveraging our outstanding Android game development services,
              We’ve mastered the art of thrilling tales, outstanding images, and
              incredible audio, to name a few. The visual studio competes with
              prominent players such as Machine zone, Jam city, Square Enix, etc
              and creates mobile and pc games that boost your business. Our
              technical experience allows our Android game developers to
              thoroughly test the application at every level, ensuring
              exceptional quality results.
            </p>
            <ul>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Android Studio
              </li>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Unity
              </li>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Cocos2D
              </li>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Corona SDK
              </li>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Unreal Engine
              </li>
            </ul>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-md-6'>
            <h2 style={{ fontWeight: "600" }}>
              Create Mobile Games With Award-Winning Android Game Design Studio
            </h2>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Our android game development company is extremely enthusiastic
              about learning advanced innovations in launching application
              downloads that are in line with current market trends. Outsource
              our android game development services to develop bespoke games, of
              all genres.
            </p>
            <ul>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Android Studio
              </li>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Unity
              </li>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Cocos2D
              </li>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Corona SDK
              </li>
              <li>
                {" "}
                <span>
                  <img src={Rightarrow} alt='' width={"20px"} height={"20x"} />
                </span>
                Unreal Engine
              </li>
            </ul>
          </div>
          <div className='col-md-6'>
            <img
              src={Mobiledeveloper}
              alt=''
              width={"100%"}
              height={"450px"}
              style={{ borderRadius: "12px", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <div className='container'>
        <h3 style={{ fontWeight: "600" }}>
          Our Android Studio Game Development Expertise
        </h3>
        <p style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
          Entrepreneurs see the gaming sector as a great opportunity as the
          market for Android mobile devices continues to grow. Kanraj Infotech
          is the best Android game development company helping companies to
          unleash their creativity with incredible software development
          abilities. Whether it is NFT gaming or AR gaming concepts, we treat
          each gaming genre with a unique and centered approach while turning
          your ideas into real-life experience games.
        </p>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          {gamesType?.map((item, index) => {
            return (
              <div className='col-md-3' key={index}>
                <Typeofgames image={item?.img} title={item?.title} />
              </div>
            );
          })}
        </div>
      </div>

      <div className='container my-4'>
        <Accordiondata
          eventKey={"0"}
          title={
            " Why Choose Kanraj Infotech as Your Android Game Development Company?"
          }
          details={
            " With our experienced and successful team of android game developers, we have established a reputation for delivering the finest quality game engine across the world within your budget. Being top Android game development company, We aim to compete with popular offerings such as Hay day, Monument valley, Nonstop knight, dynasty warriors, walking dead, toy blast, etc."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />

        <Accordiondata
          eventKey={"1"}
          title={"Cost Savings"}
          details={
            "By outsourcing our android game development company and engaging developers on a contract basis, you can save up to 70% on your project costs."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"2"}
          title={"How can I create my own mobile game?"}
          details={
            "If you want to build a game by yourself then before jumping into the process, first you need to learn the art of mobile app development for games by taking some introductory courses on making mobile games. Or you can simply hire mobile game developers by availing of mobile game development services from a reputed company like The Kanraj infotech."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"3"}
          title={"Inter-Apps Integration"}
          details={
            "We create a google play console that enables inter-process architecture and allows apps to run on a variety of platforms and devices."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"4"}
          title={"Custom Games"}
          details={
            "As a leading Android game development company, we keep our client requirements at priority to develop tailor-made mobile games."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"5"}
          title={"Memory & Performance"}
          details={
            "Our quick and interactive applications enable us to create memory-efficient and high-performance gaming apps."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
      <Blogs id={2} />
    </div>
  );
};

export default Index;
