import React from "react";
import Banner from "../Banner/index";
import Background from "../../../assets/WindowsGame/1-Windows-Game-Bannerb.jpg";
import windowGame from "../../../assets/WindowsGame/1-Windows-Game-Web.webp";
import windowGame2 from "../../../assets/WindowsGame/2-Windows-Game-Web.webp";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  return (
    <div className='data'>
      <SEOComponent
        title='Windows Game Development Company - Kanraj Infotech'
        description='Looking for a Windows game development company? Kanraj Infotech specializes in crafting thrilling 2D and 3D games tailored for Windows devices, spanning smartphones and PCs.'
        url='https://kanraj.com/windowsgame'
      />
      <Banner banner={Background} text={"Windows GameDevelopment"} />
      <div className='container'>
        <div className='pb-4'>
          <h6
            style={{
              color: "#078586",
              fontSize: "20px",
              fontWeight: "700",
              textDecoration: "underline",
              textDecorationColor: "#078586",
            }}>
            Game Development
          </h6>
        </div>
        <h2 className='font_700'>Desktop Game Development services</h2>
        <div className='row pb-3'>
          <div className='col-xs-12 col-md-6'>
            <p className='pera'>
              Games have become a mode of entertainment in the coming years. We
              can find games on TV, Mobiles, Tablets, and Desktops. But the
              foremost familiar indoor games in today’s world are Pc games. Many
              people inculcate themselves in playing games for entertainment,
              relaxation, and knowledge. Pc & desktop games are the simplest
              games played by every child and youngster. Games similar to a car
              race, Action, Adventure, and brain training have given immense
              pleasure and amusement for the players on Desktop. The main
              intentions of designing Desktop & PC games are to give popularity
              and bring awareness to each and every user who has an engagement
              on PC for their work.
            </p>
          </div>
          <div className='col-xs-12 col-md-6'>
            <img
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
              src={windowGame}
              alt=''
            />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#2B2B2B" }}>
        <div className='container py-4'>
          <h2
            className='text-center pb-2'
            style={{ color: "white", fontWeight: "700" }}>
            Windows Game Services We offer
          </h2>
          <div className='row'>
            <div className='col-xs-12 col-md-6'>
              <img
                src={windowGame2}
                alt=''
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  border: "none",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className='col-xs-12 col-md-6'>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#fff",
                    alignItems: "center",
                  }}></div>
                <h5
                  className='px-2'
                  style={{ color: "white", fontWeight: "700" }}>
                  Developers Program
                </h5>
              </div>
              <p
                className='px-3'
                style={{
                  color: "white",
                  fontSize: "16px",
                  textAlign: "justify",
                }}>
                Microsoft has launched many opportunities for developers
                programs to help programmers to develop and publish windows
                games. Consider joining a developer program if anyone is
                interested in developing games for Xbox One and integrating Xbox
                live features in games. For publishing a game in the Microsoft
                store, you will need assistance to create a developer account in
                the partner center.
              </p>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#fff",
                    alignItems: "center",
                  }}></div>
                <h5
                  className='px-2'
                  style={{ color: "white", fontWeight: "700" }}>
                  Partner Center
                </h5>
              </div>
              <p
                className='px-3'
                style={{
                  color: "white",
                  fontSize: "16px",
                  textAlign: "justify",
                }}>
                The first step for publishing your Windows game is by
                registering a developer's account in the partner center. A
                developer's account allows you to register your games name and
                submit free or paid games to the Microsoft store for all Windows
                devices.
              </p>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#fff",
                    alignItems: "center",
                  }}></div>
                <h5
                  className='px-2'
                  style={{ color: "white", fontWeight: "700" }}>
                  Xbox One
                </h5>
              </div>
              <p
                className='px-3'
                style={{
                  color: "white",
                  fontSize: "16px",
                  textAlign: "justify",
                }}>
                The Xbox program gives consent to anyone for integrating Xbox
                live into their heading and publishing to Xbox one and window.
                We can deploy design, and also publish your game in the
                developer program without a dedicated Dev kit using only retail
                hardware. If anyone wants to access more Xbox live capabilities
                dedicated marketing and development support and chance to be
                featured in the main Xbox one store.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <h3 className='font_700'>Why kanraj infotech</h3>
        <p className='pera'>
          Kanraj Infotech provides an end-to-end collection of information and
          resources that will support you tremendously to develop your game. The
          divisions are arranged according to levels of game development so you
          will get an idea to grow through the information when you need it. If
          you are fresh in game development on Windows or Xbox Aaryavarta will
          assist you on its level best from where you want to start. Kanraj
          Infotech provides a session of game development resources with a
          high-level survey of documentation, programs and other resources that
          are helpful while designing a game.
        </p>
      </div>
      <Blogs id={17} />
    </div>
  );
};

export default Index;
