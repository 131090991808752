import React from "react";

const TypesofGames = ({ title, detail }) => {
  return (
    <>
      <div className='text-white px-2' style={{ width: "15rem" }}>
        <h2 className='text-center'>{title}</h2>
        <p
          className='text-center'
          style={{
            lineHeight: "1.5rem",
            color: "#dbd5d5",
            fontSize: "20px",
            fontWeight: "400",
            textAlign: "justify",
          }}>
          {detail}
        </p>
      </div>
    </>
  );
};

export default TypesofGames;
