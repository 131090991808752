import React, { useState } from "react";
import Banner from "../Banner";
import Background from "../../../assets/UnrealEngine/1-Unreal-Game-Banner.jpg";
import UnrealGame from "../../../assets/UnrealEngine/1-Game-Development.png";
import UnrealGame2 from "../../../assets/UnrealEngine/2-App-Develpment.png";
import UnrealGame3 from "../../../assets/UnrealEngine/3-Real-Mone-Game.png";
import UnrealGame4 from "../../../assets/UnrealEngine/4-AR-Vr.png";
import UnrealGame5 from "../../../assets/UnrealEngine/1-Unreal-GameWeb.webp";
import UnrealGame6 from "../../../assets/UnrealEngine/2-Unreal-GameWeb.webp";
import UnrealGame7 from "../../../assets/UnrealEngine/3-Unreal-GameWeb.webp";
import NextArrow from "../../../assets/UnrealEngine/next.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import Accordiondata from "../../Accordiondata";
import Smallcard from "../Smallcard";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  return (
    <div className='data'>
      <SEOComponent
        title='Unreal Game Development - Kanraj Infotech'
        description="Our unreal game development team is qualified to develop supreme 2D/3D games using Unreal Engine 4. Let's create something extraordinary together!"
        url='https://kanraj.com/unrealengine'
      />
      <Banner banner={Background} text={"Unreal Game Development"} />
      <div className='container'>
        <div className='first'>
          <h6
            className='font_700'
            style={{
              color: "#078586",
              fontSize: "20px",
              textDecoration: "underline",
              textDecorationColor: " #078586",
            }}>
            Game development
          </h6>
          <h2 className='font_700'>Unreal engine game development</h2>{" "}
          <p className='pera'>
            Kanraj Infotech is a leading Unreal engine game development company
            providing full cycle unreal engine 4 game development services. We
            have a qualified team of Unreal Developers who are qualified to
            develop supreme 2D/3D games using Unreal Engine 4. The games
            developed by us look and feel amazing and as they are
            performance-oriented, they produce the best and fruitful results for
            you. Moreover, Unreal Engine 4 is the engine behind various AAA
            games on mobile phones, consoles, and PC.
          </p>
        </div>
        <div className='row second'>
          <div className='col-md-6'>
            <div className='py-2'>
              <Smallcard
                image={UnrealGame}
                title={"Game Development"}
                text={
                  "Unreal Game Development Engine offers complete freedom to the developers to develop any type of 3D game that they want. From small games to huge games to complex AAA games, the Unreal engine is suitable for them all."
                }
              />
            </div>
            <div className='py-2'>
              <Smallcard
                image={UnrealGame2}
                title={"Real Money Gambling Games"}
                text={
                  "Real Money Gambling Games is experiencing huge demand. Gambling games are fun, and when the real money factor is added to these games, the fun raises ten folds. We offer Real Money Gambling Game Development services using Unreal Engine."
                }
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='py-2'>
              <Smallcard
                image={UnrealGame3}
                title={"App Development"}
                text={
                  "Kanraj Infotech have immense experience in developing Mobile Apps using Unreal engine. Unreal Engine is mainly used in developing small mobile apps with 3D graphics and a low budget. We use robust technologies and the latest tools and techniques to develop amazing Unreal mobile apps."
                }
              />
            </div>
            <div className='py-2'>
              <Smallcard
                image={UnrealGame4}
                title={"Extended AR/VR Support"}
                text={
                  "AR/VR is the technology that can take your app/game experience to the next level. Startups and enterprises are looking for AR/VR development to drive business. We provide AR/VR solutions using cutting-edge technology to deliver apps/games that will deliver the best user experience."
                }
              />
            </div>
          </div>
        </div>
        <div className='row third'>
          <h3 className='text-center font_700'>
            Why should you consider Unreal Engine for game development?
          </h3>
          <p className='text-center pera_one'>
            Unreal Engine is one the best heavy-duty engines ever. Unreal Game
            Engine provides support for AAA, AAA+, III and more heavy games too.
            Often, it is used for creating action-packed high graphics games.
            Here are some advantages of the Unreal Engine.
          </p>
          <div className='col-md-6'>
            <img
              src={UnrealGame5}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
          <div className='col-md-6'>
            <div className='d-flex align-items-center py-3'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Multiple Graphic Options</h4>
            </div>
            <p className='pera'>
              The best thing about the Unreal game development engine is that
              this engine can be used to develop games with AAA-quality
              graphics. The engine has more and better features for graphics
              than any other Game Development Engine.
            </p>
            <div className='d-flex align-items-center py-3'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>
                Various Tools and Visual Editors
              </h4>
            </div>
            <p className='pera'>
              The Engine wants to facilitate the development of anything that
              you can imagine and therefore it is equipped with various tools
              and features to make it possible. The tools can be used to develop
              AAA games in no time.
            </p>
            <div className='d-flex align-items-center py-3'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Speed</h4>
            </div>
            <p className='pera'>
              Unreal Engine enables the developers to develop mobile games at a
              faster rate and more effectively than other game development
              engines. The features and easy coding helps in faster and smoother
              mobile game development.
            </p>
            <div className='d-flex align-items-center py-3'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>High Game Optimization</h4>
            </div>
            <p className='pera'>
              When it comes to the optimization of the game, the best tool that
              is available out there is the Unreal Game Development Engine. Our
              developers are expert and certified Unreal Mobile Game Developers
              that can do this job without any hassle.
            </p>
          </div>
        </div>
        <div className='row fourth'>
          <div className='col-md-6'>
            <h2 className='py-2 font_700'>Unreal Game Development Process</h2>
            <p className='pera'>
              Kanraj Infotech delivers robust, scalable, and high-performance
              mobile game development services to help you harness the power of
              technology to get the perfect mobile game. Our Unreal Game
              Development Process ensures that we cost-effectively develop
              quality products.
            </p>
            <div className='container'>
              <div className='d-flex'>
                <FontAwesomeIcon
                  icon={faGamepad}
                  className='fa-icon'
                  style={{
                    position: "sticky",
                    left: "0",
                    height: "23px",
                    width: "45px",
                  }}
                />
                <p className='pera_one'>Design Game Structure</p>
              </div>
              <div className='d-flex'>
                <FontAwesomeIcon
                  icon={faGamepad}
                  className='fa-icon'
                  style={{
                    position: "sticky",
                    left: "0",
                    height: "23px",
                    width: "45px",
                  }}
                />
                <p className='pera_one'>Create Game Wireframe</p>
              </div>
              <div className='d-flex'>
                <FontAwesomeIcon
                  icon={faGamepad}
                  className='fa-icon'
                  style={{
                    position: "sticky",
                    left: "0",
                    height: "23px",
                    width: "45px",
                  }}
                />
                <p className='pera_one'>Developing the Mobile Game</p>
              </div>
              <div className='d-flex'>
                <FontAwesomeIcon
                  icon={faGamepad}
                  className='fa-icon'
                  style={{
                    position: "sticky",
                    left: "0",
                    height: "23px",
                    width: "45px",
                  }}
                />
                <p className='pera_one'>Testing Mobile Game</p>
              </div>
              <div className='d-flex'>
                <FontAwesomeIcon
                  icon={faGamepad}
                  className='fa-icon'
                  style={{
                    position: "sticky",
                    left: "0",
                    height: "23px",
                    width: "45px",
                  }}
                />
                <p className='pera_one'>Launch</p>
              </div>
              <div className='d-flex'>
                <FontAwesomeIcon
                  icon={faGamepad}
                  className='fa-icon'
                  style={{
                    position: "sticky",
                    left: "0",
                    height: "23px",
                    width: "45px",
                  }}
                />
                <p className='pera_one'>Support and Maintenance </p>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <img
              src={UnrealGame6}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
        </div>
        <div className='row fifth'>
          <div className='col-md-6'>
            <img
              src={UnrealGame7}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
          <div className='col-md-6'>
            <h5 className='my-3 font_700' style={{ fontSize: "30px" }}>
              Why is Kanraj Infotech the best unreal engine game development
              company?
            </h5>
            <p className='my-4 pera'>
              At Kanraj, our developers are well-acquainted with this powerful
              tool with the experience, they are very effective in developing
              Unreal Mobile Games. Developing a game with Unreal Engine is a
              hard task, but the results are worth the hard work. Using Unreal
              Engine, our developer can turn your idea into a AAA game with
              high-quality graphics.
            </p>
            <p className='pera'>
              With years of experience, our developers know how to leverage the
              best tools and features of the Unreal Game Development Engine, and
              thus they are able to develop Unreal games with truly impressive
              graphics and gameplay.
            </p>
            <div className='container'>
              <div className='d-flex'>
                <span className='px-2'>
                  <img src={NextArrow} alt='' />
                </span>
                <p className='pera_one'>Design Game Structure</p>
              </div>
              <div className='d-flex'>
                <span className='px-2'>
                  <img src={NextArrow} alt='' />
                </span>
                <p className='pera_one'>Create Game Wireframe</p>
              </div>
              <div className='d-flex'>
                <span className='px-2'>
                  <img src={NextArrow} alt='' />
                </span>
                <p>Developing the Mobile Game</p>
              </div>
              <div className='d-flex'>
                <span className='px-2'>
                  <img src={NextArrow} alt='' />
                </span>
                <p className='pera_one'>Testing Mobile Game</p>
              </div>
              <div className='d-flex'>
                <span className='px-2'>
                  <img src={NextArrow} alt='' />
                </span>
                <p className='pera_one'>Launch</p>
              </div>
              <div className='d-flex'>
                <span className='px-2'>
                  <img src={NextArrow} alt='' />
                </span>
                <p className='pera_one'>Support and Maintenance </p>
              </div>
            </div>
          </div>
        </div>

        <div className='my-4'>
          <h2
            style={{ fontSize: "30px", fontWeight: "700" }}
            className='text-center'>
            Unreal Game Development FAQs
          </h2>
          <p className='pera'>
            We want to solve all the queries you may have and answer all the
            questions that may enter your mind. Therefore, here is a curated
            list of Frequently Asked Questions about Unreal Development that
            most clients ask. If you have any questions other than these, you
            can drop a message below or call us.
          </p>

          <Accordiondata
            eventKey={"0"}
            title={"What is video game development with Unreal Engine?"}
            details={
              "<p>Unreal Engine is a game engine created by the Epic Games company, which was initially shown in the 1998 first-person shooter game Unreal. Although it was developed primarily for first-person shooter games, it has been used successfully in a variety of other genres, including stealth, fighting, MMORPGs, and other role-playing video games. With its code written in C ++, Unreal Engine features a high degree of portability and is a tool currently used by many game developers.</p></br><h5>Why Use Unreal Engine for Game Development?</h5><ul><li>Industry-Leading Features The Unreal 5 game engine has several industry-best features, from Nanite, Lumen, the Unreal Editor, Forward Rendering, World-Partition System, etc. With them, the right team of Unreal engine game developers can develop quality games in a short time.</li><li>From Creators, For Creators Unreal is developed by Epic, a notable game development company, and publisher. Epic themselves use the same game engine to develop their games. Epic understands what developers require to conceptualize and create the best possible games. Hence, all the features of the Unreal Engine are carefully selected, developed, and tailored for the needs of developers.</li><li>Flexible for All Projects Unreal is suitable for the workflow of teams of all sizes and projects of all complexities. Developers can execute Unreal engine mobile game development projects, AAA 3D games, and AR/VR/MR solutions.</li><li>Single Development. Single Deployment With a single development process, Unreal game developers like us can develop for multiple platforms including Android, iOS, Xbox, PlayStation, Windows, Mac, and various AR and VR platforms.</li><li>Source Code Access Epic allows Unreal game developers to access and alter the source code of the engine, and adapt it to suit the specific needs of their projects. It helps developers create their games better and quicker.</li></ul>"
            }
            openSection={openSection}
            onToggle={handleToggle}
          />

          <Accordiondata
            eventKey={"1"}
            title={"What are the steps in making an Unreal Engine game?"}
            details={
              "<p>The development of video games with the Unreal tool is not usually very simple and requires specialized developers in this area and in this tool. Of course, the steps to follow to create a video game using Unreal follow a generic structure that is as follows:</p><ul><li><b>Preliminary development of the video game:</b>the concept, the storyboard, the environment and its characteristics, the characters and the special effects must be chosen and defined in this phase. Each of them will conjugate the final video game.</li><li><b>Game development:</b>UI and UX design is part of this phase, along with script development. All this will give a definitive shape to the video game, which will have to go through a final phase of tests to verify that everything works correctly.</li><li><b>Release:</b>this is the test phase that we mentioned in the previous point. Players should check that the video game performance is correct taking into account the gaming experience and user behavior, the dialogue between the video game and the player. The possible flaws found will make the developers have to rectify the code of the video game and submit it to a final phase of testing.</li></ul>"
            }
            openSection={openSection}
            onToggle={handleToggle}
          />
          <Accordiondata
            eventKey={"2"}
            title={"What language does Unreal Engine use?"}
            details={
              "Unreal Engine uses C++, an object-oriented programming language widely used today for its powerful capabilities. This language offers the high degree of flexibility needed for superior game engineering. On top of that, Unreal Engine elevates the game development experience by integrating visual scripting called Blueprints. These Blueprints apply a faster programming option via drag-and-drop. With such brilliant tools at hand, our Unreal Engine game developers team makes the most of the software to bring you exceptional results."
            }
            openSection={openSection}
            onToggle={handleToggle}
          />
        </div>
      </div>
      <Blogs id={11} />
    </div>
  );
};

export default Index;
