import { useRouteError } from "react-router-dom";
import Notfound from "../assets/custom/images/pagenotfound.jpg"

export default function ErrorPage() {
  //   const error = useRouteError();
  return (
    <>
      <div className="container-fluid" >
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

          <img src={Notfound} alt="" height={"350px"} width={"60%"} />
        </div>
      </div>

    </>
  );
}
