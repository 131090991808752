import React, { useState } from "react";
import Banner from "../Banner/index";
import Background from "../../../assets/ConsoleGame/1-Console-Game-Banner.jpg";
import icon from "../../../assets/ConsoleGame/icon-image-nike.png";
import consoleGame from "../../../assets/ConsoleGame/1-Console-Game-Web.webp";
import Gamepad from "../../../assets/ConsoleGame/game-pad.png";
import sampleImg from "../../../assets/ConsoleGame/2-Console-Game-Web.webp";
import Accordiondata from "../../Accordiondata";
import Smallcard from "../Smallcard";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

// import "./console.style.css";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  return (
    <div className='data'>
      <SEOComponent
        title='Console Game Development - Kanraj Infotech'
        description='Trust in our expertise to bring your console gaming vision to life with unparalleled quality and innovation. Kanraj Infotech have certified developers for PlayStation, Xbox, and Nintendo. Start your Console Game Development Now!'
        url='https://kanraj.com/consolegame'
      />
      <Banner banner={Background} text={"Console Game Development"} />
      <div className='container'>
        <h6
          style={{
            color: "#078586",
            fontSize: "20px",
            fontWeight: "700",
            textDecoration: "underline",
            textDecorationColor: "#078586",
          }}>
          Game Development
        </h6>

        <h2 className='font_700'>Console Game Development</h2>
        <p className='pera'>
          As certified developers for PlayStation, Xbox, and Nintendo, with over
          10 years of experience, Kanraj Infotech has delivered several solid
          console titles through full development.
        </p>

        <div className='row'>
          <div className='col-md-6'>
            <p className='pera'>
              In addition, we’ve provided comprehensive art and programming
              service support to clients. Being a leading console game
              development company, our game developers can blend the right
              strategy with the requisite skills and ensure a successful
              delivery. We are here to help you save resources and solve any
              production challenges.
            </p>
            <div className='mb-4'>
              <h2 className='font_700'>Types of Console we develop</h2>
              <p className='pera'>
                Whether you need assistance on conceptualization, story
                development, design, UI/UX, or full-stack console game
                development, we’ve got you covered. At Kanraj Infotech, we
                endeavor to build exciting games that people want to play. Our
                developers are passionate players themselves, so they can
                understand the mindset of your players and create a successful
                console game for our clients.
              </p>
            </div>
            <Smallcard
              image={icon}
              title={"NINTENDO"}
              text={
                "Games always stand out when they have something. Make your console games unique and let them stand out by embracing our Nintendo console game develop-ment services, the only platform that provides multi-player options and interactive abilities."
              }
            />
          </div>
          <div className='col-md-6'>
            <Smallcard
              image={icon}
              title={"XBOX"}
              text={
                " Power up your players’ dreams by serving them our blockbuster Xbox console games. We provide industry-leading and compelling features, top quality results throughout, and great graphics. By embracing our finest console game development services, you are keeping your foot firmly on the path of gaming progress."
              }
            />
            <div className='mt-4'>
              <Smallcard
                image={icon}
                title={"PLAYSTATION"}
                text={
                  "Unlock new gaming possibilities that your players never antic-ipated to play; we break the loop of offering usual gaming experiences and instead move to build a modern era of Play-Station games that truly provide non-stop entertainment."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container text-center'>
        <h2 className='font_700'>
          Our Console Game Development Solution For Different Genres
        </h2>
        <p className='pera'>
          Console gaming is all about user interaction and gameplay and we
          exhibit our talents for creating finely tuned user experience in all
          our games developed so far. Whether it’s action, arcade, adventure,
          simulation, or strategy, we can take it on and provide what you need.
        </p>

        <div className='row'>
          <div className='col-md-6 py-2'>
            <img
              src={consoleGame}
              alt=''
              height={"550px"}
              width={"100%"}
              style={{ borderRadius: "12px" }}
            />
          </div>
          <div className='col-md-6'>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>ARCADE GAMES</h4>
            </div>
            <p className='pera'>
              Our team of skilled developers offer thrilling games that harken
              back to the classic arcade experience.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>ADVENTURE GAMES</h4>
            </div>
            <p className='pera'>
              With staggering visual effects, interesting characters, and
              appealing sound effects, let your players feel the exciting
              experience of adventure games.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>STRATEGY GAMES</h4>
            </div>
            <p className='pera'>
              Our developers create world-class… and encourage players to think
              strategically to succeed.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>ACTION GAMES</h4>
            </div>
            <p className='pera'>
              Using our expertise as long-standing professionals, we aim to
              develop hair-raising and blood-pumping action games.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>SIMULATION GAMES</h4>
            </div>
            <p className='pera'>
              Let your players live the fascinating and thrilling experiences
              offered in the wonderful world of simulation games.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>PUZZLE GAMES</h4>
            </div>
            <p className='pera'>
              Puzzle games are always a com-bination of logical thinking and
              fun. With beautiful graphics and interesting levels, our
              developers push the players to stick with it and find the way
              through.
            </p>
          </div>
        </div>
      </div>

      <div className='container-fluid' style={{ background: "#2A2929" }}>
        <div className='container py-4'>
          <div className='row'>
            <div className='col-md-6'>
              <h2 style={{ color: "white" }} className='font_700'>
                TECHNOLOGIES EXPERTISE
              </h2>
              <p
                style={{
                  color: "white",
                  textAlign: "justify",
                  fontSize: "18px",
                }}>
                We work with all the latest game development tools and platforms
                to help you build stunning games with superior graphic
                rendering, sound, animation, and display. Our team is highly
                competent in
              </p>
              <div>
                <ul className='text-white'>
                  <li
                    style={{ fontSize: "20px", fontWeight: "700" }}
                    className='my-2'>
                    <span className='px-2'>
                      <img
                        src={Gamepad}
                        alt=''
                        height={"30px"}
                        width={"30px"}
                      />
                    </span>
                    Unity
                  </li>
                  <li
                    style={{ fontSize: "20px", fontWeight: "700" }}
                    className='my-2'>
                    <span className='px-2'>
                      <img
                        src={Gamepad}
                        alt=''
                        height={"30px"}
                        width={"30px"}
                      />
                    </span>
                    Unreal Engine
                  </li>
                  <li
                    style={{ fontSize: "20px", fontWeight: "700" }}
                    className='my-2'>
                    <span className='px-2'>
                      <img
                        src={Gamepad}
                        alt=''
                        height={"30px"}
                        width={"30px"}
                      />
                    </span>
                    OpenGL
                  </li>
                  <li
                    style={{ fontSize: "20px", fontWeight: "700" }}
                    className='my-2'>
                    <span className='px-2'>
                      <img
                        src={Gamepad}
                        alt=''
                        height={"30px"}
                        width={"30px"}
                      />
                    </span>
                    Android
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-6'>
              <img
                src={sampleImg}
                alt=''
                width={"100%"}
                style={{ borderRadius: "12px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='container py-4'>
        <h2 className='font_700 pb-2'>Frequently Asked Questions</h2>

        <Accordiondata
          eventKey={"0"}
          title={"Why outsource console game development services?"}
          details={
            "<p>Outsourcing video games means delegating various development tasks to external studios, artists and developers. From 2D or 3D animation to character design, audio creation, programming, QA testing and more, outsourcing can cover any and all of your development needs.</p></br><ul><li><b>Full cycle development includes three major productive stages: </b>pre-production, production and post-production. Each of these, especially the production stage, requires large and highly skilled teams. Outsourcing helps to overcome many of the challenges associated with the cost and talent requirements.</li><li>Outsourcing is essential for the modern-day video game industry. It has become a fundamental practice to keep in business, get new concepts, and compete with current and new generation gaming developments. With the growth of competition, outsourcing video games is commonplace as a method of reducing costs and creating next-level games. Due to the vast advantages that come with it, even huge companies have admitted to outsourcing aspects of their gaming projects.</li></ul>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />

        <Accordiondata
          eventKey={"1"}
          title={"Are graphics better on PC or console?"}
          details={
            "<ul><li>A PC offers better graphics, more upgrade options, and a greater variety of video games than consoles. Likewise, these video games are generally cheaper, not only in their final price, but also in their creation and development.</li><li>A console is better in a marathon than a sprint. Maintaining a high-end PC is an expensive endeavor. A console will perform better for longer for the average user. While developing a console game is more expensive, its platform is far more affordable and accessible. In previous generations, graphically, the consoles would fall short. But this gap is shortening significantly as consoles take huge leaps of performance and quality with each generation.</li></ul>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />

        <Accordiondata
          eventKey={"2"}
          title={"What are the tools used for console game development?"}
          details={
            "<p>The most common tools to develop video games for consoles are Unity and Unreal. They are combined with specific applications for creation of characters and environments, setting development and level design.</p>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />

        <Accordiondata
          eventKey={"3"}
          title={"What programming language does Xbox use?"}
          details={
            "<p>The most high-end games played on consoles, like Xbox and PlayStation, utilize C++. C++ is also the language used for Unreal Engine and CryEngine. These are the two most advanced game engines available.</p>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />

        <Accordiondata
          eventKey={"4"}
          title={"What are the stages of console game development?"}
          details={
            "<p>The 5 stages in the development of a video game can be applied to mobile, computer or console games. They are:<ul><li>Prototyping</li><li>Progress</li><li>Ship</li><li>Watch</li><li>Repeat</li></ul></p>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"5"}
          title={"What is console game development?"}
          details={
            "<p>The development of video games for consoles is similar to those for other platforms. The challenges of this process are linked to the development code and the needs of each console. It is also important to consider all steps from the beginning such as storytelling, setting and characters, production, realisation and monetization. The developer must be used to these types of processes and tasks, something that happens in Kanraj Infotech. As certified developers for PlayStation, Xbox and Nintendo, with over 10 years’ experience, Kanraj Infotech has delivered many console titles through full development.</p>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
      <Blogs id={20} />
    </div>
  );
};

export default Index;
