import React from "react";
import facebook from "../../assets/custom/images/facebook.png";
import instagram from "../../assets/custom/images/instagram.png";
import twitter from "../../assets/custom/images/twitter.png";
import youtube from "../../assets/custom/images/youtube.png";
import footer from "../../assets/custom/images/footer_logo.png";
import { useNavigate, Link, Outlet } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className='footer-container'>
        <footer
          className='footer-section'
          style={{ background: "#282F3B", position: "relative" }}>
          <div className='container'>
            <div className='footer-content pt-3 pb-3'>
              <div className='row' style={{}}>
                <div className='col-xl-3 col-lg-3 col-md-6'>
                  <div className='footer-widget mb-3 ps-3'>
                    <div className='footer-logo'>
                      <a href='index.html'>
                        <img
                          src={footer}
                          className='img-fluid'
                          alt='logo'
                          style={{ height: "80px", margin: "10px 0px" }}
                        />
                      </a>
                    </div>
                    <div className='footer-text mt-3'>
                      <p
                        style={{
                          color: "#aaafb8",
                          textAlign: "justify",
                          lineHeight: "1.5rem",
                        }}>
                        Kanraj Infotech is the leading Game development company
                        providing cost-effective and sustainable solutions
                        through state-of-the-art technology on global level
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-6'>
                  <div className='footer-widget ps-5 '>
                    <div className='footer-widget-heading mt-2'>
                      <h4 style={{ color: "#fff" }}>Get In Touch</h4>
                      <span
                        style={{
                          border: "2px solid #078586 ",
                          display: "block",
                          width: "70px",
                        }}></span>
                    </div>
                    <div className='mt-2'>
                      <div className='pt-2'>
                        <h6
                          style={{
                            fontSize: "18px",
                            color: "#fff",
                            fontWeight: "600",
                            marginBottom: "8px",
                          }}>
                          Location
                        </h6>
                        <p
                          style={{
                            color: "#aaafb8",
                            textAlign: "justify",
                            lineHeight: "20px",
                            lineHeight: "1.5rem",
                          }}>
                          {" "}
                          INDIA -Nr Sola Over Bridge, Science City Rd, Ahmedabad
                          (GJ), 380060 ,
                        </p>
                        <p
                          className='pt-2'
                          style={{
                            color: "#aaafb8",
                            textAlign: "justify",
                            lineHeight: "20px",
                            lineHeight: "1.5rem",
                          }}>
                          USA - 25101 The Old Road Suite 155 Santa Clarita, CA
                          91381
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-6'>
                  <div className='ps-5'>
                    <h4 className='mt-2' style={{ color: "#fff" }}>
                      Contact
                    </h4>
                    <span
                      style={{
                        border: "2px solid #078586 ",
                        display: "block",
                        width: "70px",
                        marginBottom: "8px",
                      }}></span>
                    <div class='con_content text-white mt-3'>
                      <p style={{ marginBottom: "0.5rem" }}>
                        {" "}
                        INDIA :{" "}
                        <span>
                          <a
                            href='tel:9510354183'
                            style={{
                              fontSize: "16px",
                              lineHeight: "5px",
                              color: "#aaafb8",
                            }}>
                            {" "}
                            +91 9510354183
                          </a>
                        </span>
                      </p>
                      <p style={{ marginBottom: "0.5rem" }}>
                        USA :{" "}
                        <span>
                          <a
                            href='tel:(818) 284-6450'
                            style={{
                              color: "#aaafb8",
                              fontSize: "16px",
                              lineHeight: "5px",
                            }}>
                            {" "}
                            (818) 284-6450
                          </a>
                        </span>
                      </p>
                      <span style={{ marginBottom: "0.5rem" }}>Mail Us:</span>
                      <br />
                      <span>
                        <a
                          href='tel:sales@kanraj.com'
                          style={{
                            color: "#aaafb8",
                            fontSize: "16px",
                          }}>
                          {" "}
                          sales@kanraj.com
                        </a>
                      </span>
                      <br />
                      <span>
                        <a
                          href='tel:hr@kanraj.com'
                          style={{
                            color: "#aaafb8",
                            fontSize: "16px",
                            // lineHeight: "26px",
                          }}>
                          {"        "}
                          hr@kanraj.com
                        </a>
                      </span>

                      <p
                        className=''
                        style={{
                          textDecoration: "underline",
                          marginBottom: "0.5rem",
                        }}>
                        <Link
                          className='pb-1'
                          to='contact-us'
                          style={{
                            fontSize: "16px",
                            lineHeight: "26px",
                            color: "#fff",
                          }}>
                          Contact Us
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-6'>
                  <div
                    className='footer-social-icon mt-2 ps-4'
                    style={{ marginBottom: "11px" }}>
                    <h4 className='mt-2' style={{ color: "#fff" }}>
                      Follow us
                    </h4>

                    <span
                      style={{
                        border: "2px solid #078586 ",
                        display: "block",
                        width: "70px",
                        marginBottom: "10px",
                      }}></span>
                    <div className='icons mt-3' style={{ marginLeft: "-7px" }}>
                      <div>
                        <img
                          src={facebook}
                          alt='facebook'
                          className='img-fluid rounded'
                          style={{
                            height: "45px",
                            margin: "0 10px",
                            cursor: "pointer",
                          }}
                        />
                        <img
                          src={instagram}
                          alt='instagram'
                          className='img-fluid rounded'
                          style={{
                            height: "45px",
                            margin: "0 10px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div className='my-3'>
                        <img
                          src={twitter}
                          alt='twitter'
                          className='img-fluid rounded'
                          style={{
                            height: "45px",
                            margin: "0 10px",
                            cursor: "pointer",
                          }}
                        />
                        <img
                          src={youtube}
                          alt='youtube'
                          className='img-fluid rounded'
                          style={{
                            height: "45px",
                            margin: "0 10px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
