import React, { useEffect } from "react";
import "./ProgressBar.css"; // Import CSS styles

const ProgressBar = () => {
  // Progress circle
  const updateProgressCircle = () => {
    const progressElement = document.querySelector(
      ".progress-circle-container"
    );
    const scrollToTopElement = document.querySelector(".scroll-to-top");
    const totalHeight = document.body.scrollHeight - window.innerHeight;
    let progress = (window.pageYOffset / totalHeight) * 283;
    progress = Math.min(progress, 283);
    progressElement.style.opacity = window.pageYOffset === 0 ? "0" : "1"; // Hide progress circle if at the top
    const progressCircle = document.querySelector(".progress-circle-bar");
    progressCircle.style.strokeDashoffset = 283 - progress;

    // Hide scroll-to-top button if user is at the top of the page
    scrollToTopElement.style.opacity = window.pageYOffset === 0 ? "0" : "1";
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    updateProgressCircle();
    window.addEventListener("scroll", updateProgressCircle);
    window.addEventListener("resize", updateProgressCircle);
    return () => {
      window.removeEventListener("scroll", updateProgressCircle);
      window.removeEventListener("resize", updateProgressCircle);
    };
  }, []);

  return (
    <div className='progress-circle-container'>
      {/* Progress circle */}
      <svg className='progress-circle' viewBox='0 0 100 100'>
        <circle className='progress-background' cx='50' cy='50' r='45'></circle>
        <circle className='progress-circle-bar' cx='50' cy='50' r='45'></circle>
      </svg>
      <div className='scroll-to-top' onClick={scrollToTop}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'>
          <path d='M12 19V5M5 12l7-7 7 7' />
        </svg>
      </div>
    </div>
  );
};

export default ProgressBar;
