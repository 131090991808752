import React, { useState } from "react";
import Banner from "../Banner/index";
import CountUp from "react-countup";
import Accordiondata from "../../Accordiondata";
import Background from "../../../assets/HTML5WebGame/1-HTML5-Game-Bannerc.jpg";
import HtmlGame from "../../../assets/HTML5WebGame/1-HTML5-Game-DevelopmentWeb.webp";
import HtmlGame2 from "../../../assets/HTML5WebGame/2-HTML5-UI-DesignWeb.webp";
import HtmlGame3 from "../../../assets/HTML5WebGame/3-HTML5-Real-MoneyWeb.webp";
import HtmlGame4 from "../../../assets/HTML5WebGame/4-HTML5-SupportWeb.webp";
import HtmlGame5 from "../../../assets/HTML5WebGame/5-HTML5-AR-VRWeb.webp";
import HtmlGame6 from "../../../assets/HTML5WebGame/6-CocosWeb.webp";
import HtmlGame7 from "../../../assets/HTML5WebGame/7-ConstructWeb.webp";
import HtmlGame8 from "../../../assets/HTML5WebGame/8-GDevelopWeb.webp";
import HtmlGame9 from "../../../assets/HTML5WebGame/9-HTML5-Premier-GameWeb.webp";
import HtmlGame10 from "../../../assets/HTML5WebGame/10-HTML5-Game-ToolsWeb.webp";
import HtmlGame11 from "../../../assets/HTML5WebGame/11-HTML5-Game-SolutionsWeb.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  return (
    <div className='data'>
      <SEOComponent
        title='HTML5 Game Development | Mobile Browser Games - Kanraj Infotech'
        description='Kanraj Infotech - your premier destination for cutting-edge HTML5 game development. With a focus on mobile browser games, our expert team brings extensive experience to the table.'
        url='https://kanraj.com/html5webgame'
      />
      <Banner banner={Background} text={"HTML5 Web Game Development"} />
      <div className='container'>
        <div className='pb-4'>
          <span className='first' style={{ borderBottom: "2px solid #078586" }}>
            <span
              className='font_700'
              style={{
                color: "#078586",
                fontSize: "20px",
              }}>
              HTML5 Web Game Development
            </span>
          </span>
        </div>

        <h2 className='font_700'>HTML5 Game Development services</h2>
        <p className='pera'>
          Kanraj Infotech is an HTML5 game development company that specializes
          in developing mobile browser games. The team of experts has
          considerable experience in developing high-quality HTML5 games across
          a variety of genres and game engines that can compete with the best in
          the gaming market.
        </p>
        <p className='pera'>
          As the HTML5 gaming market evolves, Kanraj infotech will be at the
          forefront of innovation, improving its technology and capabilities,
          and delivering the best possible gaming experience.
        </p>
      </div>
      <div className='container'>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "70px", fontWeight: "600" }}>
              <CountUp start={0} end={1} duration={5} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>Game Designer</p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "70px", fontWeight: "600" }}>
              <CountUp start={0} end={7} duration={3} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game Developer
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "70px", fontWeight: "600" }}>
              <CountUp start={0} end={50} duration={3} />+
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Gaming projects
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <h2 className='text-center font_700'>
          HTML5 Game Development services
        </h2>
        <p className='text-center pera_one'>
          Our team of experienced developers is skilled in using frameworks like
          Phaser and Cocos2d to create your own games. Kanraj Infotech offers
          custom HTML5 game development services for creating game assets, level
          design, animation, and sound design. Our game engine is capable of
          delivering cross-platform games with high frame rates. Our aim is to
          provide our clients with the best possible gaming experience by
          delivering high-quality games that are engaging and fun to play.
        </p>
      </div>
      <div className='container'>
        <div className='row p-2'>
          <div className=' col-md-4'>
            <img
              src={HtmlGame}
              alt=''
              style={{
                height: "100%",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className=' col-md-8'>
            <h3 className='py-2 font_700'>HTML5 Game Development</h3>
            <p className='pera'>
              We use HTML5 to develop games that can be used to target multiple
              platforms. Our games are filled with great features, epic sounds,
              amazing graphics, and a very engaging storyline. With HTML5 Game
              Development, we can turn your game idea into an amazing product.
            </p>
          </div>
        </div>
        <div className='row p-2'>
          <div className=' col-md-4'>
            <img
              src={HtmlGame2}
              alt=''
              style={{
                height: "100%",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className=' col-md-8'>
            <h3 className='py-2 font_700'>HTML5 UI Design and Prototyping</h3>
            <p className='pera'>
              Our team of designers have extensive experience in designing the
              perfect Website or app UI and prototype based on the client’s
              needs and wants. We will conduct a niche-research and then we will
              design the most useful and reliable UI and prototype for your
              solution.
            </p>
          </div>
        </div>
        <div className='row p-2'>
          <div className=' col-md-4'>
            <img
              src={HtmlGame3}
              alt=''
              style={{
                height: "100%",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className=' col-md-8'>
            <h3 className='py-2 font_700'>HTML5 Real Money Game Development</h3>
            <p className='pera'>
              Real money games are dominating the gaming industry. Get the best
              out of this scenario with Kanraj Infotech HTML5 Real Money Game
              Development service and get your real money game developed now.
              Let the user’s play and use their skills to earn real cash.
            </p>
          </div>
        </div>
        <div className='row p-2'>
          <div className=' col-md-4'>
            <img
              src={HtmlGame4}
              alt=''
              style={{
                height: "100%",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className=' col-md-8'>
            <h3 className='py-2 font_700'>HTML5 Support and Maintenance</h3>
            <p className='pera'>
              We also provide extensive support and maintenance service to the
              clients. We also provide updates for your HTML5 website and app.
              Our developers will make sure that your website or app runs at
              100% capacity and they will solve all the technical errors and
              challenges ASAP.
            </p>
          </div>
        </div>
        <div className='row p-2'>
          <div className=' col-md-4'>
            <img
              src={HtmlGame5}
              alt=''
              style={{
                height: "100%",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className=' col-md-8'>
            <h3 className='py-2 font_700'>Extended AR/VR Support</h3>
            <p className='pera'>
              AR/VR are emerging technologies that provide your HTML5 website or
              app with an extraordinary advantage over the competitors. Provide
              your users with the best experience and increase your user
              satisfaction. Upgrade your app or website now!
            </p>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#F2F5FF" }}>
        <div className='container py-4'>
          <h2 className='text-center font_700'>
            HTML5 Game Development services
          </h2>
          <p className='text-center pera_one'>
            HTML5 delivers a slew of cutting-edge features to create engaging
            HTML5 games and websites. Apart from this, there are plenty of
            reasons to create browser-based HTML5 games.
          </p>
          <div className='row'>
            <div className='col-md-4'>
              <h3
                className='text-center py-2 font_700'
                style={{ fontSize: "20px", letterSpacing: "1px" }}>
                Earn more revenue
              </h3>
              <div className='d-flex justify-content-center'>
                <span
                  style={{
                    border: "1px solid #00ce1b ",
                    display: "block",
                    width: "5rem",
                    marginBottom: "10px",
                  }}></span>
              </div>
              <p className='text-center pera_one'>
                With HTML5 you can use the same code for multiple platforms
                therefore you can earn more revenue by targeting more audiences
                on your app.
              </p>
            </div>
            <div className='col-md-4'>
              <h3
                className='text-center py-2'
                style={{
                  fontSize: "20px",
                  letterSpacing: "2px",
                  fontWeight: "700",
                }}>
                Easy deployability
              </h3>
              <div className='d-flex justify-content-center'>
                <span
                  style={{
                    border: "1px solid #00ce1b ",
                    display: "block",
                    width: "5rem",
                    marginBottom: "10px",
                  }}></span>
              </div>
              <p className='text-center pera_one'>
                Games in HTML5 can also be viewed in browsers and users can also
                share the game easily using a link.
              </p>
            </div>
            <div className='col-md-4'>
              <h3
                className='text-center py-2'
                style={{
                  fontSize: "20px",
                  letterSpacing: "2px",
                  fontWeight: "700",
                }}>
                Cross platform
              </h3>
              <div className='d-flex justify-content-center'>
                <span
                  style={{
                    border: "1px solid #00ce1b ",
                    display: "block",
                    width: "5rem",
                    marginBottom: "10px",
                  }}></span>
              </div>
              <p className='text-center pera_one'>
                HTML5 allows game developers to focus on the gameplay and how
                your cross-platform game will respond to various screen sizes
                and input types.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <h3
                className='text-center py-2'
                style={{
                  fontSize: "20px",
                  letterSpacing: "2px",
                  fontWeight: "700",
                }}>
                Easily integrate advertisements
              </h3>
              <div className='d-flex justify-content-center'>
                <span
                  style={{
                    border: "1px solid #00ce1b ",
                    display: "block",
                    width: "5rem",
                    marginBottom: "10px",
                  }}></span>
              </div>
              <p className='text-center pera_one'>
                Integrating the advertisements in HTML5 games is a comparatively
                easy task. You can show the advertisements anywhere.
              </p>
            </div>
            <div className='col-md-4'>
              <h3
                className='text-center py-2'
                style={{
                  fontSize: "20px",
                  letterSpacing: "2px",
                  fontWeight: "700",
                }}>
                Quick development process
              </h3>
              <div className='d-flex justify-content-center'>
                <span
                  style={{
                    border: "1px solid #00ce1b ",
                    display: "block",
                    width: "5rem",
                    marginBottom: "10px",
                  }}></span>
              </div>
              <p className='text-center pera_one'>
                Developing a game in HTML5 is really speedy and fast. You don’t
                have to wait for compilation, updates and debugging in
                real-time.
              </p>
            </div>
            <div className='col-md-4'>
              <h3
                className='text-center py-2'
                style={{
                  fontSize: "20px",
                  letterSpacing: "2px",
                  fontWeight: "700",
                }}>
                Cost Effective
              </h3>
              <div className='d-flex justify-content-center'>
                <span
                  style={{
                    border: "1px solid #00ce1b ",
                    display: "block",
                    width: "5rem",
                    marginBottom: "10px",
                  }}></span>
              </div>
              <p className='text-center pera_one'>
                The game developed using HTML5 can work on all the latest mobile
                devices, and can target any OS using the same coding.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <h2 className='text-center font_700'>HTML 5 Game Engines</h2>
        <p className='text-center pera_one'>
          Our few tried and true high-performance game engines allow for speedy
          development, visually appealing enthralling games with excellent
          graphics, impeccable gameplay, and animation. We are well versed in:
        </p>
        <div className='row my-3'>
          <div className='col-md-6 pb-2'>
            <img
              src={HtmlGame6}
              alt=''
              style={{
                height: "100%",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            className='col-md-6'
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <div>
              <h3 className='font_700'>Cocos2d-X</h3>
              <span
                style={{
                  border: "1px solid #078586 ",
                  display: "block",
                  width: "5rem",
                  marginBottom: "10px",
                }}></span>
              <p className='pera'>
                Cocos2d-X is a package of game development tools that supports
                cross-browser and cross-platform game development. It is a
                script development and data-driven development tool.
              </p>
            </div>
          </div>
        </div>
        <div className='row my-3'>
          <div
            className='col-md-6 '
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <div>
              <h3 className='font_700'>Construct 3</h3>
              <span
                style={{
                  border: "1px solid #078586 ",
                  display: "block",
                  width: "5rem",
                  marginBottom: "10px",
                }}></span>
              <p className='pera'>
                Created by Scirra, Construct 3 is an HTML5 engine that is used
                to develop amazing 2D games. It is a powerful tool that comes
                with numerous great features to develop engaging games.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <img
              src={HtmlGame7}
              alt=''
              style={{
                height: "100%",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
        <div className='row my-3'>
          <div className='col-md-6 pb-2'>
            <img
              src={HtmlGame8}
              alt=''
              style={{
                height: "100%",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            className='col-md-6'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h3 className='font_700'>GDevelop</h3>
              <span
                style={{
                  border: "1px solid #078586 ",
                  display: "block",
                  width: "5rem",
                  marginBottom: "10px",
                }}></span>
              <p className='pera'>
                GDevelop is a 2D cross-platform, free and open-source game
                engine, which mainly focuses on creating HTML5 games playable in
                the browser and on the mobile devices.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#40444A", color: "white" }}>
        <div className='container py-4'>
          <div className='row'>
            <div className='col-md-6'>
              <h2 className='py-2 font_700' style={{ fontSize: "25px" }}>
                Make Interoperable HTML5 Games With A Premier Game Design Studio
              </h2>
              <p
                className='py-2 '
                style={{ fontSize: "18px", textAlign: "justify" }}>
                Whether you are a budding entrepreneur or an established
                business, we offer a wide range of HTML5 game services using
                innovative ideas and emerging technologies to create iOS games
                as well as Android games.
              </p>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>3D ENVIRONMENT</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Adventure HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Educational HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>PvP Battle HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Card HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Racing HTML5</h5>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Weapons HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Simulation HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Board HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Casino HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Fantasy HTML5 Sports</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Puzzle HTML5</h5>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='col-md-6 pb-2'
              style={{ display: "grid", justifyItems: "center" }}>
              <img
                src={HtmlGame9}
                alt=''
                style={{
                  height: "auto",
                  width: "100%",
                  maxWidth: "66%",
                  border: "none",
                  objectFit: "fill",
                  width: " min-content",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <div className='row'>
          <div
            className='col-md-6'
            style={{ display: "grid", justifyItems: "center" }}>
            <img
              src={HtmlGame10}
              alt=''
              style={{
                height: "auto",
                width: "100%",
                maxWidth: "66%",
                border: "none",
                objectFit: "fill",
                width: "min-content",
                borderRadius: "10px",
              }}
            />
          </div>
          <div className='col-md-6'>
            <h2 className='py-2 font_700' style={{ fontSize: "25px" }}>
              HTML5 Game Development Tools and Technologies
            </h2>
            <p className='py-2 pera'>
              We leverage a wide variety of technologies to build your ideal
              game. Depending on the functionality necessary, we count
              everything from selecting the right database, to language,
              framework appropriate tools for your project.
            </p>
            <div className='row'>
              <div className='col-md-6'>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon icon={faStar} className='px-3' />
                  <h5 className='font_700'>WEBGL</h5>
                </div>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon icon={faStar} className='px-3' />
                  <h5 className='font_700'>CONSTRUCT 3</h5>
                </div>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon icon={faStar} className='px-3' />
                  <h5 className='font_700'>UNREAL ENGINE5</h5>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon icon={faStar} className='px-3' />
                  <h5 className='font_700'>THREE.JS</h5>
                </div>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon icon={faStar} className='px-3' />
                  <h5 className='font_700'>COCOS2D-X</h5>
                </div>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon icon={faStar} className='px-3' />
                  <h5 className='font_700'>UNITY</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#40444A", color: "white" }}>
        <div className='container py-4'>
          <div className='row'>
            <div className='col-md-6'>
              <h2 className='py-2 "font_700' style={{ fontSize: "25px" }}>
                Our HTML5 Game Solutions{" "}
              </h2>
              <p
                className='py-2'
                style={{ fontSize: "18px", textAlign: "justify" }}>
                Whether it is Flash or HTML5, we have mastered the latest
                technical expertise to treat each game genre with a unique and
                uncommon development approach. Our HTML5 game developers
                leverage technologies like Browser and JavaScript to turn your
                ideas into live HTML5 games that stand out in the market.
              </p>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Action HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Adventure HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Educational HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>PvP Battle HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Card HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Racing HTML5</h5>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Weapons HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Simulation HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Board HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Casino HTML5</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Fantasy HTML5 Sports</h5>
                  </div>
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faStar} className='px-3' />
                    <h5 className='font_700'>Puzzle HTML5</h5>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='col-md-6 pb-2'
              style={{ display: "grid", justifyItems: "center" }}>
              <img
                src={HtmlGame11}
                alt=''
                style={{
                  height: "auto",
                  width: "100%",
                  maxWidth: "66%",
                  border: "none",
                  objectFit: "fill",
                  width: " min-content",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-md-6'>
            <h5
              className='my-5 font_700'
              style={{ fontSize: "30px", marginBottom: "20px" }}>
              Why Choose Kanraj Infotech to Hire HTML5 Game Development?
            </h5>
            <p className='pera'>
              With years of expertise, we have established a reputation for
              delivering the finest quality multiplayer HTML5 games across the
              world within your budget. Hire 3D game developers from us to
              maximize the creative aspect, power, and performance of your HTML5
              games.
            </p>
          </div>
          <div className='col-md-6'>
            <div class='accordion' id='accordionPanelsStayOpenExample'>
              <div class='accordion-item'>
                <h2
                  class='accordion-header font_700'
                  id='panelsStayOpen-headingOne'>
                  <button
                    class='accordion-button font_700'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseOne'
                    aria-expanded='true'
                    aria-controls='panelsStayOpen-collapseOne'>
                    Agile Methodology
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseOne'
                  class='accordion-collapse collapse show'
                  aria-labelledby='panelsStayOpen-headingOne'>
                  <div class='accordion-body pera'>
                    Our iterative approach helps us to deliver value to our
                    clients faster and without any hassles.
                  </div>
                </div>
              </div>
              <div class='accordion-item'>
                <h2
                  class='accordion-header font_700'
                  id='panelsStayOpen-headingTwo'>
                  <button
                    class='accordion-button font_700 collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseTwo'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseTwo'>
                    100% Transparency
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseTwo'
                  class='accordion-collapse collapse'
                  aria-labelledby='panelsStayOpen-headingTwo'>
                  <div class='accordion-body pera'>
                    Feel free to collaborate with us and we make sure your game
                    project plans are completely safe and secure with us
                  </div>
                </div>
              </div>
              <div class='accordion-item'>
                <h2 class='accordion-header' id='panelsStayOpen-headingThree'>
                  <button
                    class='accordion-button font_700 collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseThree'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseThree font_700'>
                    Use Latest Game Trends
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseThree'
                  class='accordion-collapse collapse'
                  aria-labelledby='panelsStayOpen-headingThree'>
                  <div class='accordion-body pera'>
                    From Flash games to browser-based and cross-platform
                    Augmented Reality games, we continue to improve our
                    technical skills to be on the top.
                  </div>
                </div>
              </div>
              <div class='accordion-item'>
                <h2 class='accordion-header' id='panelsStayOpen-headingThree'>
                  <button
                    class='accordion-button font_700 collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseThree'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseThree font_700'>
                    Cost-Effective Games
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseThree'
                  class='accordion-collapse collapse'
                  aria-labelledby='panelsStayOpen-headingThree'>
                  <div class='accordion-body pera'>
                    Our services are cost-effective. For example, you can save
                    up to 70% by outsourcing our services.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container my-4'>
        <h3 className='font_700'>Frequently Asked Questions</h3>
        <p className='my-4 pera'>
          We are here to help/assist you throughout to find answers to all your
          queries. Below are some of the commonly asked questions about the
          services that we offer. For more assistance, you may directly contact
          us through our contact us page and get an immediate solution.
        </p>
        <Accordiondata
          eventKey={"0"}
          title={"Is HTML5 good for game development?"}
          details={
            " Yes, HTML5 is good for game development as it offers several cutting-edge features, including unified support for the most popular web browsers, 2D and 3D graphics, audio APIs, offline asset storage, and more. HTML5 is also preferred by web developers and game development studios alike."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"1"}
          title={"How Much Does HTML5 Game Development Cost?"}
          details={
            "The cost of outsourcing HTML5 game services starts anywhere $1000 to $10,000 for a single platform with basic functionality and Game with all the advanced features will cost up to $25,000 with support for multiple platforms."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"2"}
          title={"How to Create an Html5 Game?"}
          details={
            "<p>Before creating any HTML 5 game you must take care of few things that is listed below:</p><ul><li>Defining Goals</li><li>Set-up an HTML5 file with a canvas</li><li>Handling User Interaction </li><li>Animate objects and use easings</li><li>Graphics & Sprite Sheets</li><li>Control game objects with user input</li></ul>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"3"}
          title={"What are the stages of HTML5 game development?"}
          details={
            "<p>The HTML5 Game Development Process has a few stages to create browser games, let’s take a look at it.</p><ul><li><b>Requirement Gathering : </b>The consultation meeting incorporates listening to the client’s requirements and gathering details to formulate a potential future game idea.</li> <li><b>Game Development:</b>The developers engage in extensive coding and tutorials to create animations, audio, graphics, search input, backgrounds, and more.</li> <li><b>Game Design:</b>Building intriguing tales, characters, goals, and rules are all part of the design stage.</li> <li><b>Game Delivery:</b>This stage demands the integration of all components, including the look, colors, music, search input, difficulty-level, and point-scoring system</li> </ul>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
      <Blogs id={15} />
    </div>
  );
};

export default Index;
