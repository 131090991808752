import React from 'react';
import './TechnologyCard.css'; 

const TechnologyCard = ({ img, title }) => {
  return (
    <>
      <div className='technology-card  py-3 px-3 my-3'>
        <div className='p-3'>
          <img src={img} alt='' width={"70px"} height={"70px"} />
        </div>
        <div className=''>
          <h5>{title}</h5>
        </div>
      </div>
    </>
  );
};

export default TechnologyCard;
