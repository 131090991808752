import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import TechnologyCard from "./TechnologyCard";
import Accordiondata from "../../Accordiondata";
import useGenerateRandomColor from "./useGenerateRandomColor";
import Background from "../../../assets/CasinoGame/First-page.mp4";
import casinoGame1 from "../../../assets/CasinoGame/second-page.mp4";
import casinoGame2 from "../../../assets/CasinoGame/WhatsAppVideo.mp4";
// "C:\Users\ketan_vo3jnl9\OneDrive\Desktop\gamedev\Kanraj_react\src\assets\CasinoGame\WhatsAppVideo.mp4";
import fcm from "../../../assets/CasinoGame/fcm.webp";
import febric from "../../../assets/CasinoGame/fabric-ios.webp";
import androidstudio from "../../../assets/CasinoGame/android-studio.webp";
import node from "../../../assets/CasinoGame/nodejs.png";
import Unity from "../../../assets/technologies/1-Unityweb.webp";
import aws from "../../../assets/CasinoGame/aws.webp";
import android from "../../../assets/CasinoGame/android.webp";
import MEAN from "../../../assets/CasinoGame/mean-stack.webp";
import googleapi from "../../../assets/CasinoGame/google-api.webp";
import googlewebmaster from "../../../assets/CasinoGame/Webmasters.webp";
import GoogleAnalytics from "../../../assets/CasinoGame/google-analytics.webp";
import Sass from "../../../assets/CasinoGame/sass.webp";
import SwiftIOS from "../../../assets/CasinoGame/swift-ios.webp";
import Xcode from "../../../assets/CasinoGame/xcode.webp";
import stripe from "../../../assets/CasinoGame/stripe.webp";
import Sketch from "../../../assets/CasinoGame/sketch.webp";
import mobileicon from "../../../assets/CasinoGame/mobile-icon.svg";
import pcicon from "../../../assets/CasinoGame/desktop-icon.svg";
import website from "../../../assets/CasinoGame/web-icon.svg";
import checked from "../../../assets/CasinoGame/checked.png";
import maskGroup from "../../../assets/CasinoGame/Subtraction 8.png";
import rightArrow from "../../../assets/CasinoGame/right-arrow.png";
import chips from "../../../assets/CasinoGame/Chips`.gif";
import dice from "../../../assets/CasinoGame/Dice.gif";
import spin from "../../../assets/CasinoGame/Spin.gif";
import image1 from "../../../assets/CasinoGame/gameicon/1 poker.png";
import image2 from "../../../assets/CasinoGame/gameicon/2 Roulette.png";
import image3 from "../../../assets/CasinoGame/gameicon/3 Dragon vs tiger.png";
import image4 from "../../../assets/CasinoGame/gameicon/4 Black jack.png";
import image5 from "../../../assets/CasinoGame/gameicon/5 Fishing Rush.png";
import image6 from "../../../assets/CasinoGame/gameicon/6 teen patti.png";
import image7 from "../../../assets/CasinoGame/gameicon/7 Rummy.png";
import image8 from "../../../assets/CasinoGame/gameicon/8 Andar bahar.png";
import image9 from "../../../assets/CasinoGame/gameicon/9 7 up down.png";
import image10 from "../../../assets/CasinoGame/gameicon/10 Fruit line slot.png";
import image11 from "../../../assets/CasinoGame/gameicon/11 baccarat.png";
import image12 from "../../../assets/CasinoGame/gameicon/12 Crash.png";
import image13 from "../../../assets/CasinoGame/gameicon/13 Ludo.png";
import image14 from "../../../assets/CasinoGame/gameicon/14 3 card Poker.png";
import image15 from "../../../assets/CasinoGame/gameicon/15 Horse racing.png";
import image16 from "../../../assets/CasinoGame/gameicon/16 sports.png";
import image17 from "../../../assets/CasinoGame/gameicon/17 zoo roulette.png";
import image18 from "../../../assets/CasinoGame/gameicon/23 Teen patti 20-20.png";
import image19 from "../../../assets/CasinoGame/gameicon/18 Mines.png";
import image20 from "../../../assets/CasinoGame/gameicon/20 Variation.png";
import image21 from "../../../assets/CasinoGame/gameicon/21 Bingo.png";
import image22 from "../../../assets/CasinoGame/gameicon/17 10 cards.png";
import image23 from "../../../assets/CasinoGame/gameicon/22 Best of five.png";
import image24 from "../../../assets/CasinoGame/gameicon/24 whot.png";
import image25 from "../../../assets/CasinoGame/gameicon/25 car roulette.png";
import "./TechnologyCard.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  // const { color, generateColor } = useGenerateRandomColor();

  const { color, generateColor } = useGenerateRandomColor();

  const [openSection, setOpenSection] = useState("0");
  const navigate = useNavigate();

  const dohandleclick = (type) => {
    navigate("/portfolio", { state: type });
  };

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  useEffect(() => {
    generateColor();
  }, []);

  return (
    <div className={`data`}>
      <SEOComponent
        title='Casino Game Development Company - Kanraj Infotech'
        description='Kanraj Infotech is a leading casino game development company in India and USA. We craft immersive experiences that redefine entertainment.'
        url='https://kanraj.com/casinogame'
      />
      <div className='container-fluid'>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            maxHeight: "none",
            minHeight: "auto",
          }}>
          <video
            autoPlay
            muted
            loop
            style={{
              marginBottom: "20px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}>
            <source src={Background} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <div
            className='container'
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            <div className='row'>
              <div className='col-md-6'>
                <h1
                  className='text-white banner_text'
                  style={{ fontSize: "50px" }}>
                  Casino Game Development{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <h6
          style={{
            color: "#078586",
            fontSize: "20px",
            fontWeight: "700",
            textDecoration: "underline",
            textDecorationColor: "#078586",
          }}>
          Game Development
        </h6>

        <div className='row my-4'>
          <div className='col-md-6'>
            <h2 className='font_700'>Casino Game Development</h2>
            <p className='pera'>
              Kanraj Infotech is a leading casino game development company in
              India and USA. Our casino game development team is known for
              delivering best services to clients. Our dedication and perfection
              in developing apps focused on the quality of code, uniqueness, and
              personalization have made us India’s best casino game development
              services provider. Being a leading casino game software developer
              in India and USA, Kanraj Infotech caters to all clients’
              requirements and provides secure, trustworthy, appealing, and
              realistic casino game development services. Our talented team of
              casino games developers offers creative designs and high-end
              gaming solutions to clients that ensure excellent performances and
              high returns on their investments.
            </p>
          </div>
          <div className='col-md-6'>
            <video
              autoPlay
              muted
              loop
              style={{
                marginBottom: "20px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}>
              <source src={casinoGame1} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className='container my-4'>
        <h2 className='text-center font_700'>
          Our Milestone in Casino Game Development
        </h2>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "80px", fontWeight: "500" }}>
              <CountUp start={0} end={10} duration={5} />+
            </div>
            <p
              className='text-center'
              style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              years experience in Casino Game development
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "80px", fontWeight: "500" }}>
              <CountUp start={0} end={60} duration={3} />+
            </div>
            <p
              className='text-center'
              style={{ fontSize: "20px", fontWeight: "500" }}>
              Project Launched
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "80px", fontWeight: "500" }}>
              <CountUp start={0} end={50} duration={3} />+
            </div>
            <p
              className='text-center'
              style={{ fontSize: "20px", fontWeight: "500" }}>
              Team Members
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "80px", fontWeight: "500" }}>
              <CountUp start={0} end={40} duration={3} />+
            </div>
            <p
              className='text-center'
              style={{ fontSize: "20px", fontWeight: "500" }}>
              Satisfied Customers
            </p>
          </div>
        </div>
      </div>
      <div className='container-fluid my-4'>
        <h2 className='text-center font_700'>
          Different types of Casino Games we have developed
        </h2>
        <div className='row'>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            <div className='px-2'>
              {" "}
              <img
                src={image1}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image2}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image3}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image4}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image5}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            <div className='px-2'>
              {" "}
              <img
                src={image6}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image7}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image8}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image9}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image10}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            <div className='px-2'>
              {" "}
              <img
                src={image11}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image12}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image13}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image14}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image15}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            <div className='px-2'>
              {" "}
              <img
                src={image16}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image17}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image18}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image19}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image20}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            <div className='px-2'>
              {" "}
              <img
                src={image21}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image22}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image23}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image24}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className='px-2'>
              {" "}
              <img
                src={image25}
                className='img-fluid img_effect'
                alt='Image 1'
                width={"225px"}
                height={"225px"}
                style={{
                  objectFit: "cover",
                  transition: ` transform .1s`,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='mb-3 d-flex justify-content-center align-items-center'>
        <button
          className='btn custom-button font_700'
          onClick={() => dohandleclick("Card & Casino games")}
          style={{
            padding: "16px",
            textAlign: "center",
            fontSize: "24px",
            backgroundColor: "white",
            color: "black",

            borderRadius: "50px",
            border: "1px solid black",
            outline: "none",
          }}>
          Our Casino Game Development work
        </button>
      </div>

      <div style={{ backgroundColor: "#f6f6f6" }}>
        <div className='container py-4'>
          <h2 className='text-center font_700'>Features of Our Casino Games</h2>
          <div className='row'>
            <div className='col-md-6'>
              <div
                className='my-3 p-3 '
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Play Online with Real Money and Practice Coin</>
                </h5>
                <p className='m-0 pera'>
                  To feel the thrill and excitement you can play with real
                  money, also users can practice with free bonus coins.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Invite and Play</>
                </h5>
                <p className='m-0 pera'>
                  Players can invite their social media friends and can play
                  with them, they will appear in their friend's list.{" "}
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Play as a guest</>
                </h5>
                <p className='m-0 pera'>
                  By using this feature, players can play casino games without
                  sign-up or giving any information about them.{" "}
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Tournament Option</>
                </h5>
                <p className='m-0 pera'>
                  This feature helps players to participate in a tournament that
                  counts more than one match and offers you an opportunity to
                  make a considerable amount.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>MULTI-LANGUAGE SUPPORT</>
                </h5>
                <p className='m-0 pera'>
                  Supporting many common languages removes a barrier to global
                  players and opens the game up to a wider audience.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Protect Your Account with OTP</>
                </h5>
                <p className='m-0 pera'>
                  To protect your information you can add your phone number and
                  email id and after entering OTP you can access your account.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Random Number Generator</>
                </h5>
                <p className='m-0 pera'>
                  The RNG system is used to offer you fair gameplay like when
                  you deal cards or roll a dice, then each time a unique number
                  will be there.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Refer & Earn</>
                </h5>
                <p className='m-0 pera'>
                  To attract new users and engage existing users, refer and earn
                  bonuses and rewards are used. You can invite your friends by
                  sending a link, if he accepts, you earn real money.{" "}
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Voice and Chat Feature</>
                </h5>
                <p className='m-0 pera'>
                  To make gameplay more exciting and realistic, we offer voice
                  and chat features so players can decide their next move and
                  can make a winning strategy.{" "}
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Online Multiplayer functionality</>
                </h5>
                <p className='m-0 pera'>
                  Multiplayer gaming allows players to compete or collaborate
                  with others in real-time, either through integrated chat
                  functions or live video. This social element can help build
                  excitement and engagement.
                </p>
              </div>
              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Private Table</>
                </h5>
                <p className='m-0 pera'>
                  Users can play with their desired player in private table
                  mode.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Game History</>
                </h5>
                <p className='m-0 pera'>
                  Help you to know the game history in detail like win or loss
                  status, the game variations, played games, winning and losing
                  ratio, etc.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Multiplatform Support</>
                </h5>
                <p className='m-0 pera'>
                  Help you to know the game history in detail like win or loss
                  status, the game variations, played games, winning and losing
                  ratio, etc.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>MULTI-CURRENCY SUPPORT</>
                </h5>
                <p className='m-0 pera'>
                  Accepting multiple real-world currencies makes deposits and
                  withdrawals more convenient for the global player base.
                  Bitcoin integration allows access for crypto users.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Multiple Payment Gateways</>
                </h5>
                <p className='m-0 pera'>
                  Players can choose their desired method of payment to make a
                  deposit.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5>
                  <b>Live Support</b>
                </h5>
                <p className='m-0 pera'>
                  If you face any issue when you are playing, then live support
                  helps you to resolve your issue at the same time so you can
                  focus on the game.
                </p>
              </div>

              <div
                className='my-3 p-3'
                style={{
                  background: "white",
                  color: "#404040",
                  borderRadius: "4px",
                  borderLeft: `4px solid #39C4A8`,
                  fontSize: "16px",
                  // padding: "2px 20px",
                }}>
                <h5 className='font_700'>
                  <>Bonus and jackpot</>
                </h5>
                <p className='m-0 pera'>
                  Daily Bonus, Weekly Bonus, Monthly Bonus, Referral Bonus,
                  Recharge bonus, Jackpot during game play etc.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "#31B079",
        }}>
        <div className='container py-4'>
          <h2 className='text-center'>
            <b>Admin Panel Features for Casino Game Development</b>
          </h2>
          <p className='text-center pera_one'>
            Admin has control over the casino game platform because they control
            the users and various other game functions. Admin feature helps them
            to manage the casino games effectively and they can make their
            desired changes anytime
          </p>
          <div className='row my-3 pt-4' style={{ color: "white" }}>
            <div className='col-md-4 my-2'>
              <div
                className='p-3 p-md-4 feature-card '
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "100%",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  User Management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  This helps in streamlining registration,Login, Permissions and
                  account termination and User KYC in a smooth way.
                </p>
              </div>
            </div>

            <div className='col-md-4 my-2'>
              <div
                className='p-3 p-md-4 feature-card '
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "100%",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Player Management
                </h3>
                <p style={{ textAlign: "left", fontSize: "18px" }}>
                  This enables viewing and editing players profiles, setting
                  permissions/limits, issuing bonuses or resolving disputes, add
                  or remove chips etc.
                </p>
              </div>
            </div>

            <div className='col-md-4 my-2'>
              <div
                className='p-3 p-md-4 feature-card '
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Admin Dashboard
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  To analyze ongoing games and their performance, and to know
                  the behavior pattern of players dashboards are a must. You can
                  also get ideas about the profit of your game.
                </p>
              </div>
            </div>
          </div>

          <div className='row my-3' style={{ color: "white" }}>
            <div className='col-md-4 my-2'>
              <div
                className='p-3 p-md-4 feature-card'
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Backup Payment System
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  If your payment gateway fails to provide you with the best
                  service, we have developed a backup payment system for your
                  game and you can control all payment transactions from the
                  admin panel.
                </p>
              </div>
            </div>

            <div className='col-md-4 my-2'>
              <div
                className='p-3 p-md-4 feature-card'
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Game management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  Each game has its functionality and specification and to get a
                  personalized gaming experience you can change game settings
                  like the sound, language, play mode, notifications, guidance,
                  security, access, etc.
                </p>
              </div>
            </div>

            <div className='col-md-4 my-2'>
              <div
                className='p-3 p-md-4 feature-card '
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Tournament Management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  Organizing casino game tournaments becomes so much easier by
                  using this feature and you can decide and change the pot value
                  and winning amount.
                </p>
              </div>
            </div>
          </div>

          <div className='row my-3' style={{ color: "white" }}>
            <div className='col-md-4  my-2'>
              <div
                className='p-3 p-md-4 feature-card'
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Payment Management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  This is the most crucial feature of the casino game. By this,
                  you can manage all transactions and help you change the price
                  of game chips.
                </p>
              </div>
            </div>

            <div className='col-md-4  my-2 '>
              <div
                className='p-3 p-md-4 feature-card'
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Chips Management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  Chips are used to place wagers on casino games and this
                  feature helps you to manage the game chips, and you can also
                  fix the rate of chips by it.
                </p>
              </div>
            </div>

            <div className='col-md-4  my-2'>
              <div
                className='p-3 p-md-4 feature-card '
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Table Management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  You can specify your game table according to your choices like
                  table size, the number of players, table color, private table,
                  and many more.
                </p>
              </div>
            </div>
          </div>

          <div className='row my-3' style={{ color: "white" }}>
            <div className='col-md-4  my-2'>
              <div
                className='p-3 p-md-4 feature-card'
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Tournament Management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  Organizing casino game tournaments becomes so much easier by
                  using this feature and you can decide and change the pot value
                  and winning amount.
                </p>
              </div>
            </div>

            <div className='col-md-4  my-2'>
              <div
                className='p-3 p-md-4 feature-card'
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Bonus and Promotion Management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  This helps to create promotional campaigns, coupon/referral
                  codes, different bonuses, etc and track their effectiveness
                  for marketing purposes.
                </p>
              </div>
            </div>

            <div className='col-md-4  my-2 '>
              <div
                className='p-3 p-md-4 feature-card '
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Content Management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  Admin can upload new graphics, Banners, videos, Send mail into
                  the game from admin panel
                </p>
              </div>
            </div>
          </div>

          <div className='row my-3' style={{ color: "white" }}>
            <div className='col-md-4  my-2'>
              <div
                className='p-3 p-md-4 feature-card'
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Affiliate management
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  Affiliate management includes onboarding partners, tracking
                  referrals, payouts and affiliate campaign ROI for
                  profit-sharing marketing initiatives.
                </p>
              </div>
            </div>

            <div className='col-md-4  my-2'>
              <div
                className='p-3 p-md-4 feature-card '
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Analytics and Reports
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  This generated stats on KPIs like, retention, profit,
                  recharge, users payment, top games, top users etc. which will
                  help you to improve your marketing and promotion
                </p>
              </div>
            </div>
            <div className='col-md-4  my-2  '>
              <div
                className='p-3 p-md-4 feature-card'
                style={{
                  border: "1px solid #6cd7b6",
                  borderRadius: "5px",
                  marginBottom: "0px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "none",
                  minHeight: "auto",
                }}>
                <h3
                  style={{
                    fontSize: "1.3rem",
                    marginBottom: "3px",
                    fontWeight: "700",
                  }}>
                  Security and Fraud Detection
                </h3>
                <p style={{ fontSize: "18px", textAlign: "left" }}>
                  Admin can Monitor for unauthorized access, suspicious account
                  activity and transactions to protect players and the financial
                  system from exploitation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <h2 className='text-center my-3 font_700'>
          Casino Game Development Company in India
        </h2>
        <div className='row' style={{ display: "flex", alignItems: "center" }}>
          <div className='col-md-6'>
            <video
              autoPlay
              muted
              loop
              style={{
                marginBottom: "20px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}>
              <source src={casinoGame2} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            className='col-md-6 casino-card'
            style={{
              backgroundColor: "white",
              boxShadow: "0 0 8px #cac7c7",
              maxWidth: "640px",
              padding: "35px 25px",
              height: "fit-content",
            }}>
            <p style={{ textAlign: "justify" }}>
              Kanraj Infotech is a top-notch game development company in India
              delivering novelty and unique game solutions with 100%
              personalization. We optimize the gaming solutions that match the
              current standards and provide a better user experience. Our casino
              game developers ensure that your solutions would offer the best
              features and lucrative payouts. Intending to implement amazing
              ideas to gaming portals, we thrive to include the latest features
              and modules to improve the overall gaming experience.
            </p>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <h2 className='text-center font_700'>
          Technology & Tools We Use for Casino Game Development
        </h2>
        <div className='row'>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={fcm} title={"FCM"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={febric} title={"Fabric iOS"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={androidstudio} title={"Android Studio"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={node} title={"Node JS"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={Unity} title={"Unity3D"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={aws} title={"AWS"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={android} title={"Android"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={MEAN} title={"MEAN Stack"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={googleapi} title={"Google API"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={googlewebmaster} title={"Google Webmasters"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={GoogleAnalytics} title={"Google Analytics"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={Sass} title={"Sass"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={SwiftIOS} title={"swift iOS"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={Xcode} title={"Xcode"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={stripe} title={"stripe"} />
          </div>
          <div
            className='col-lg-3 col-md-6 col-sm-12'
            style={{ cursor: "pointer" }}>
            <TechnologyCard img={Sketch} title={"Sketch"} />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#2d2c2c", color: "white" }}>
        <div className='container py-4'>
          <h2 className='text-center mb-3 font_700'>
            How Much Does It Cost to Develop a Casino Game App & Website?
          </h2>
          <p
            className='text-center'
            style={{
              fontSize: "18px",
              lineHeight: "1.6rem",
              fontWeight: "500",
            }}>
            We know how important transparency is for any startup or business
            owner. That's why we make both our services and pricing readily
            available for you to see. Our goal is to help you get your casino
            game idea off the ground as quickly as possible.
          </p>
          <p
            className='text-center'
            style={{
              fontSize: "18px",
              lineHeight: "1.6rem",
              fontWeight: "500",
            }}>
            As the industry leaders, we have the expertise to develop your
            vision into a successful reality. Ours is the optimal option for
            those seeking an immediate start on casino game creation. Don't
            delay your project any longer - reach out today for a consultation.
          </p>
          <div className='d-flex justify-content-center align-items-center'>
            <button
              className='custom-button font_700'
              onClick={() => dohandleclick("Card & Casino games")}
              style={{
                padding: "16px",
                textAlign: "center",
                fontSize: "24px",
                backgroundColor: "white",
                color: "black",

                borderRadius: "50px",
                border: "1px solid black",
                outline: "none",
              }}>
              Our Casino Game Development work
            </button>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <h2
          className='text-center font_700'
          style={{
            paddingBottom: "2rem",
          }}>
          We Build Casino Games For All Digital Platforms
        </h2>
        <div className='row'>
          <div className='col-md-4'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <img className='py-2' src={mobileicon} alt='' />
              <h3 className='font_700'>Mobile Version</h3>
              <p className='text-center pera_one'>
                The mobile version of our Casino Games for all kinds of Android,
                iOS, Microsoft and other mobile devices. We build games exactly
                compatible with all screen sizes and aspect Ratios for mobile
                devices.
              </p>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <img className='py-2' src={pcicon} alt='' />
              <h3 className='font_700'>PC Version</h3>
              <p className='text-center pera_one'>
                We create Casino Games for all PC platforms. These include
                versions of Mac, Windows, Chromebooks, etc.
              </p>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <img className='py-2' src={website} alt='' />
              <h3 className='font_700'>Website Version</h3>
              <p className='text-center pera_one'>
                Our Casino Games run on all popular web browsers. These include:
                Chrome, Edge, Opera, Firefox and others. This version brings a
                better and faster gaming experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <h2 className='text-center my-3 font_700'>
          WHY KANRAJ INFOTECH FOR CASINO GAME DEVELOPMENT?
        </h2>
        <p className='text-center my-1 pera_one'>
          Kanraj Infotech as a leading Casino Game Development Company provides
          best-in-class casino game solutions with developers who have profound
          knowledge of metaverse, blockchain, AR, VR, NFT, Web3, etc. We
          maintain consistent communication and ensure the timely delivery of
          our solutions. Our extensive expertise in casino game development
          allows us to create a diverse range of games, each with unique
          features and capabilities. Whether it's providing consultation, game
          design, programming, or QA testing, we offer comprehensive services to
          meet your needs. Our end-to-end approach ensures that you can find all
          the services and top-notch technical expertise you require in one
          convenient place.
        </p>
      </div>
      <div
        className='container-fluid my-3 py-4'
        style={{ backgroundColor: "#f0f8ff" }}>
        <h4 className='text-center font_700'>
          <>
            Here are some compelling reasons why clients choose us over other
            providers
          </>
        </h4>
        <div className='container'>
          <div className='row'>
            <div
              className='col-md-6'
              style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "fitContent" }}>
                <div className=' d-flex justify-content-start align-items-center my-3'>
                  <img
                    className='mx-2'
                    src={checked}
                    alt=''
                    style={{ height: "20px", width: "20px" }}
                  />
                  <p className='m-0 pera'>PROFESSIONAL CASINO EXPERTS</p>
                </div>
                <div className=' d-flex justify-content-start align-items-center my-3'>
                  <img
                    className='mx-2'
                    src={checked}
                    alt=''
                    style={{ height: "20px", width: "20px" }}
                  />
                  <p className='m-0 pera'>CUSTOMIZED GAME DEVELOPMENT</p>
                </div>
                <div className=' d-flex justify-content-start align-items-center my-3'>
                  <img
                    className='mx-2'
                    src={checked}
                    alt=''
                    style={{ height: "20px", width: "20px" }}
                  />
                  <p className='m-0 pera'>ADVANCED TECH SOLUTIONS</p>
                </div>
              </div>
            </div>
            <div
              className='col-md-6'
              style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "fitContent" }}>
                <div className=' d-flex justify-content-start align-items-center my-3'>
                  <img
                    className='mx-2'
                    src={checked}
                    alt=''
                    style={{ height: "20px", width: "20px" }}
                  />
                  <p className='m-0 pera'>EFFICIENT TEAM COLLABORATION</p>
                </div>
                <div className=' d-flex justify-content-start align-items-center my-3'>
                  <img
                    className='mx-2'
                    src={checked}
                    alt=''
                    style={{ height: "20px", width: "20px" }}
                  />
                  <p className='m-0 pera'>ON-TIME DELIVERY</p>
                </div>
                <div className=' d-flex justify-content-start align-items-center my-3'>
                  <img
                    className='mx-2'
                    src={checked}
                    alt=''
                    style={{ height: "20px", width: "20px" }}
                  />
                  <p className='m-0 pera'>24/7 CUSTOMER SUPPORT</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='container my-2 ' style={{ backgroundColor: "#130031" }}>
        <h2 className=' text-center font_600 text-white'>
          Our Casino Game Development work
        </h2>
        <div className='row'>
          <div className='col-md-6'>
            <img src={maskGroup} alt='' />
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-5 my-3 d-flex flex-column justify-content-center '>
            <h2 style={{ color: "#AC91F1" }}>70+ PREMIUM GAMES COLLECTIONS</h2>
            <button
              onClick={dohandleclick}
              className='p-1'
              style={{
                backgroundColor: "#641776",
                fontSize: "1rem",
                color: "#fff",
                width: "fit-content",
              }}>
              <span className='px-2'>See Gallery</span>
              <img src={rightArrow} alt='' />
            </button>
          </div>
        </div>
      </div> */}
      <div className='container py-4'>
        <h2 className='font_700 mb-3'>
          {" "}
          Casino Game Development: Frequently Asked Questions
        </h2>
        <Accordiondata
          eventKey={"0"}
          title={"What types of casino games can you develop?"}
          details={
            "We develop a diverse range of casino game experiences such as Rummy, Teen Patti, Andar Bahar, Dragon vs Tiger, Ludo, 7 up down, Fruit Cutter, Spin 2 win, Roulette, Variation, ICC T20, Sports, Baccarat, Crash, Fruit line, Slot machine, Black jack, 10 cards, Poker, Zoo Roulette, Best of 5, Fishing Rush, Car Roulette."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"1"}
          title={"What is the cost for the development of a casino game?"}
          details={
            "Casino game development software costs vary according to your casino game solution features and functionalities. The more advanced the feature and function the more the cost will be. Basic feature-packed Casino games can cost you starting from $1500 and can go up to $50,000 for a single platform Android or iOS. However, the cost can be higher if the requirements are also high."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"2"}
          title={
            "What technology and Tools do you use for casino game development?"
          }
          details={
            "Game developers typically use industry-standard programming languages like C++, Java, or HTML5 for web-based games. Graphics and animations are powered by advanced engines such as Unity. Other than these we are using Node JS, AWS, Swift iOS, Android studio etc."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"3"}
          title={"How long does it take to develop a casino game?"}
          details={
            "The time to develop a casino depends on complexity, features, and design. Simple games take a few months, while more elaborate projects take a year or more. So many games are ready to make it live."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"4"}
          title={"What is your process for the development of casino games?"}
          details={
            "<p>Our casino game development process takes place in several stages and each stage holds its importance. To ensure the created game is entertaining, engaging as well as profitable, our casino game development process involves various technical specialists. Our game development process:</p> <ul> <ol>Deciding the Game Genre</ol> <ol>Brainstorming Sessions</ol>  <ol>Requirement Outlining</ol><ol>Sketching and Wireframing</ol><ol>Game Design</ol><ol>Game Development</ol><ol>Quality Analysis</ol><ol>Go Live</ol>   </ul>"
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"5"}
          title={"What analytics and features can you incorporate into games?"}
          details={
            "Kanraj Infotech excels in integrating advanced analytics and features into casino games, from real-time player behavior analysis and in-game statistics to customizable features and achievement systems; our approach is tailored to enhance engagement and retention."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />{" "}
        <Accordiondata
          eventKey={"6"}
          title={"Do you offer maintenance and updates for existing games?"}
          details={
            "We offer comprehensive support by offering maintenance and updates for existing games. We understand the importance of keeping games up-to-date, secure, and in line with new technologies."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />{" "}
        <Accordiondata
          eventKey={"7"}
          title={"What types of AI capabilities can you build into games?"}
          details={
            "Kanraj Infotech brings advanced AI capabilities to casino gaming to produce an innovative experience. Our AI expertise creates intelligent opponents with adaptive strategies, implementing dynamic difficulty adjustments and personalizing gaming experiences based on player preferences."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />{" "}
        <Accordiondata
          eventKey={"8"}
          title={
            "Does incorporating AI significantly increase development costs?"
          }
          details={
            "Integrating AI into casino games can contribute to development costs, with the extent depending on the complexity of the AI features. While there may be an initial investment, the long-term benefits of increased player engagement and satisfaction often outweigh the costs."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
      <Blogs id={9} />
    </div>
  );
};

export default Index;
