
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes.js";
import WhatsApp from "./assets/custom/images/whatsapp.png"

import Footer from "./component/Footer/index.jsx";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './component/Navbar/index.jsx'
import { useState } from "react";
import ScrollButton from "./component/ScrollButton/ScrollButton.jsx";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop.jsx";
import ProgressBar from "./component/ProgressBar/ProgressBar.jsx";
// import ProgressCircle from "./component/ProgressCircle/ProgressCircle.jsx";




function App() {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (

    <BrowserRouter>

      <ScrollToTop />
      <Navbar />
      <div className="">
        <Router />
      </div>
      <Footer />
      {/* <ScrollButton /> */}
      <ProgressBar />

      <a
        href="https://wa.me/9510354183"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={WhatsApp} alt="WhatsApp" height={45} width={45} />
        <span className="whatsapp_text">WhatsApp Us</span>
      </a>


    </BrowserRouter>
  );
}

export default App;

