import React from "react";
// import Header from "../Header";
import Carouselfile from "./Carouselfile";
import ToolsAndTechnologies from "./ToolsAndTechnologies";
import TypesofGames from "./TypesofGames";
import Partners from "./Partners";
import ProjectAccordion from "../Home/ProjectAccordion";
import Footer from "../Footer";
import Countup from "../Counters";
import Journy from "./Journy";
import Testimonial from "./Testimonial";
import Gallery from "../Portfolio/Gallery";
import MobileGame from "../GameDevelopment/MobileGame";
import SEOComponent from "../SEO/SEOComponent";
import Blogs from "../Blog/Blogs";

const Home = () => {
  return (
    <div>
      <SEOComponent
        title='Game Development Company - Kanraj Infotech'
        description='Kanraj Infotech is the leading Game development company providing cost-effective and sustainable solutions through state-of-the-art technology at global stage.'
        url='https://kanraj.com/'
      />
      <Carouselfile />

      <div className='container'>
        <ToolsAndTechnologies />
      </div>
      <div className='container-fluid py-4' style={{ background: "#2d2c2c" }}>
        <div className='container'>
          <h3 className='text-white text-center'>
            Types of Games develop at Kanraj Infotech
          </h3>
          <div
            className='d-flex py-5 tools_tec'
            style={{ justifyContent: "center" }}>
            <TypesofGames
              title={"Board"}
              detail={"Real Money Ludo, Chess, Carrom, 8 ball pool etc."}
            />
            <TypesofGames
              title={"Card"}
              detail={
                "Real Money Teen Patti, Rummy, Solitaire, Uno, Poker etc."
              }
            />
            <TypesofGames
              title={"Action"}
              detail={
                "Sniper, Super Hero, Action Shooting, Single Player and Multiplayer."
              }
            />
            <TypesofGames
              title={"Adventure"}
              detail={
                "Clone of Temple run, Subway surf, Galaxy Adventure, etc."
              }
            />
            <TypesofGames
              title={"Hyper Casual"}
              detail={
                "Stack ball clone, Bridge race cone, join clash clone, count master clone etc."
              }
            />
          </div>
          <div
            className='d-flex  justify-content-center tools_tec'
            style={{ justifyContent: "center" }}>
            <TypesofGames
              title={"Casual"}
              detail={
                "Match 3 like candy crush, Bubble shooter clone, match master clone, save the girl etc."
              }
            />
            <TypesofGames
              title={"Puzzle"}
              detail={
                "Quiz Master, Super Brain, Tic Tac Toe, Find it, Find the difference, Princess Rescue etc."
              }
            />
            <TypesofGames
              title={"Racing"}
              detail={
                "Car Racing, Real time multiplayer racing, clone of Hill climb etc."
              }
            />
            <TypesofGames
              title={"Sports"}
              detail={
                "Archery, Football Soccer, Cricket, Tennis, Golf, Basketball etc."
              }
            />
            <TypesofGames
              title={"Word"}
              detail={"Crossword, Word Making etc."}
            />
          </div>
        </div>
      </div>
      <Journy />
      <Partners />
      <Testimonial />
      <Gallery />
      <ProjectAccordion />
      <Blogs id={1} />
    </div>
  );
};

export default Home;
