import React from "react";
import Counters from "../Counters";
const Journy = () => {
  return (
    <>
      <div className='container my-5 '>
        <div className='row' style={{ alignItems: "center" }}>
          <div className='col-md-3' style={{ display: "flex", 
              alignItems:"center",flexDirection:"column"}}>
            <h4>Our Journey of Game development
            <span
              style={{
                border: "1.5px solid #078586 ",
                display: "block",
                width: "65px",
                marginBottom: "10px",
              }}></span>
    </h4>
          </div>
          <div className='col-md-3' style={{ display: "flex", 
              flexDirection: "column",alignItems:"center"}}>
            <Counters number={50} />
            <p>GAME DEVELOPERS</p>
          </div>
          <div className='col-md-3' style={{ display: "flex", 
              flexDirection: "column",alignItems:"center"}}>
            <Counters number={400} />
            <p>GAMING PROJECTS</p>
          </div>
          <div className='col-md-3' style={{ display: "flex", 
              flexDirection: "column",alignItems:"center"}}>
            <Counters number={12} />
            <p>YEARS OF EXPERIENCE</p>
          </div>
        </div>
      </div>
   
    </>
  );
};

export default Journy;
