import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import data from "./dataJson";
import "./Image.style.css";
import { isDisabled } from "@testing-library/user-event/dist/utils";

function Gamedetails() {
  const [gameData, setGameData] = useState();
  const { id } = useParams();

  useEffect(() => {
    const filteredData = data.filter((item) => item.name === id);
    if (filteredData.length === 0) {
      console.error(`No data found for the specified name: ${id}`);
      setGameData(null);
    } else {
      setGameData(filteredData[0]);
    }
  }, [isDisabled]);

  return (
    <>
      <div className='container my-4'>
        {gameData ? (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <img
                  src={gameData.img}
                  alt={gameData?.name}
                  className='img-fluid rounded'
                />
              </div>
              <div className='col-md-6'>
                <h2>{gameData?.name}</h2>
                <p>{}</p>
                <h5>{gameData?.heading}</h5>
                <h5>Overview :</h5>
                <p>{gameData?.overview}</p>
                {gameData?.howtoplay && (
                  <>
                    <h5 style={{ fontWeight: "bolder" }}>HOW TO PLAY :</h5>
                    <ul className='custom-list'>
                      {gameData?.howtoplay?.map((howtoplay, index) => (
                        <li key={index}>{howtoplay}</li>
                      ))}
                    </ul>
                  </>
                )}
                {gameData?.data && gameData?.data2 && (
                  <>
                    <div className='d-flex'>
                      <ul className='custom-list'>
                        {gameData?.data?.map((data, index) => (
                          <li key={index}>{data}</li>
                        ))}
                      </ul>
                      <div></div>
                      <div>
                        <ul className='custom-list'>
                          {gameData?.data2?.map((data, index) => (
                            <li key={index}>{data}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                <div className='d-flex'>
                  <a href={gameData?.pLink} target='_blank'>
                    <img
                      src={gameData?.playStoreImage}
                      alt=''
                      className='p-2'
                    />
                  </a>
                  <a href={gameData?.aLink} target='_blank'>
                    <img src={gameData?.appStoreImage} alt='' className='p-2' />
                  </a>
                </div>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-6'>
                <h3>Features:</h3>
                <ul className='custom-list'>
                  {gameData.features.map((feature, index) => (
                    <li
                      className='mb-2'
                      key={index}
                      style={{ fontWeight: "bolder" }}>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <div className='col-md-6'>
                <h3>Specifications:</h3>
                <ul className='custom-list '>
                  {gameData.specification.map((spec, index) => (
                    <li
                      className='mb-2'
                      key={index}
                      style={{ fontWeight: "bolder" }}>
                      {Object.keys(spec)[0] === "Demo" ? (
                        <>
                          {Object.keys(spec)[0]} :{" "}
                          <a href={Object.values(spec)[0]}>
                            {Object.values(spec)[0]}
                          </a>
                        </>
                      ) : (
                        <>
                          {Object.keys(spec)[0]} : {Object.values(spec)[0]}
                        </>
                      )}
                    </li>
                  ))}
                </ul>

                {gameData?.Premiumfeature && (
                  <>
                    <h3>Premiumfeature</h3>
                    <ul className='custom-list'>
                      {gameData?.Premiumfeature?.map((feature, index) => (
                        <li
                          className='mb-2'
                          key={index}
                          style={{ fontWeight: "bolder" }}>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div>No data found for the specified name.</div>
        )}
      </div>
    </>
  );
}

export default Gamedetails;
