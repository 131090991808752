import React from "react";
import Partnerone from "../../assets/custom/images/ink-1.png";
import Partnertwo from "../../assets/custom/images/ink-2.png";
import Partnerthree from "../../assets/custom/images/ink-3.png";
import Partnerfour from "../../assets/custom/images/ink-4.png";
import Partnerfive from "../../assets/custom/images/ink-5.png";
import Partnersix from "../../assets/custom/images/6-circel-logob.png";
import Partnersaven from "../../assets/custom/images/ink-7.png";
import Partnereight from "../../assets/custom/images/5-R-Logob.png";

const Partners = () => {
  return (
    <>
      <div className='container-fluid py-4 ' style={{ background: "#e9ecef" }}>
        <div className='container'>
          <h2 className='text-center' style={{ color: "#000" }}>
            Our Partners
          </h2>
          <div className='row pb-5' style={{ alignItems: "center" }}>
            <div
              className='col-md-3'
              style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Partnerone}
                alt=''
                width={"250px"}
                height={"150px"}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div
              className='col-md-3'
              style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Partnertwo}
                alt=''
                width={"180px"}
                height={"30px"}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div
              className='col-md-3'
              style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Partnerthree}
                alt=''
                width={"200px"}
                height={"200px"}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div
              className='col-md-3'
              style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Partnerfour}
                alt=''
                width={"200px"}
                height={"200px"}
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div className='row' style={{ alignItems: "center" }}>
            <div
              className='col-md-3'
              style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Partnerfive}
                alt=''
                width={"250px"}
                height={"200px"}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div
              className='col-md-3'
              style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Partnersix}
                alt=''
                width={"200px"}
                height={"200px"}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div
              className='col-md-3'
              style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Partnersaven}
                alt=''
                width={"200px"}
                height={"100px"}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div
              className='col-md-3'
              style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Partnereight}
                alt=''
                width={"200px"}
                height={"200px"}
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
