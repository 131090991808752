

import React, { useState, useEffect } from "react";
import { Image } from "./Image";
import data from "./dataJson";
import "./Image.style.css";
import { useLocation, useNavigate } from "react-router-dom";
import SEOComponent from "../SEO/SEOComponent";

const Gallery = (props) => {
  const [gameData, setGameData] = useState(data);
  const [selectedType, setSelectedType] = useState("all");
  const uniqueTypes = [...new Set(data.map((item) => item.type))];
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a location state and if it's different from the current selectedType
    if (location.state && location.state !== selectedType) {
      setSelectedType(location.state);
    }
  }, [location.state, selectedType]);

  useEffect(() => {
    // Filter data based on the selected type
    const filteredData =
      selectedType === "all"
        ? data
        : data.filter((item) => item.type === selectedType);
    setGameData(filteredData);
  }, [selectedType]);

  const handleTypeChange = (type) => {
    if (type === "all") {
      setSelectedType("all");
      navigate(location.pathname != "/" ? "/portfolio" : "/"); // Navigate to clear location state
    } else {
      setSelectedType(type);
      navigate(
        location.pathname != "/" ? ("/portfolio", { state: type }) : "/"
      ); // Navigate to update location state
    }
  };

  

  return (
    <>
      <SEOComponent
        title='Game Developers | Portfolio - Kanraj Infotech'
        description='Kanraj Infotech provides the best game development services to develop your dream games. Explore our portfolio and envision your next digital masterpiece with us today!'
        url='https://kanraj.com/portfolio'
      />
      <div id='portfolio' className='text-center'>
        <div className='container py-5'>
          <div style={{ lineHeight: "20px" }}>
            {/* All button with default style */}
            <button
              className='default-button mb-4'
              style={{
                background: selectedType === "all" ? "#078586C7" : "#333",
                color: "#fff",
                padding: "8px 12px",
                marginRight: "3px",
                marginBottom: "3px",
                fontSize: "18px",
                outline: "none",
                transition: "all 0.3s ease-in-out",
                borderRadius: "20px",
                border: "none",
              }}
              onClick={() => {
                handleTypeChange("all");
              }}>
              All
            </button>

            {/* Mapping over the unique types and rendering buttons */}
            {uniqueTypes.map((type) => (
              <button
                key={type}
                className='default-button'
                style={{
                  background: selectedType === type ? "#078586C7" : "#333",
                  color: "#fff",
                  padding: "8px 12px",
                  marginRight: "3px",
                  marginBottom: "3px",
                  fontSize: "18px",
                  outline: "none",
                  transition: "all 0.3s ease-in-out",
                  borderRadius: "20px",
                  border: "none",
                }}
                onClick={() => {
                  handleTypeChange(type);
                }}>
                {type}
              </button>
            ))}
          </div>

          <div className='row my-3'>
            {gameData ? (
              gameData.map((item, i) => (
                <div key={`${i}-${i}`} className='col-md-4 mb-4'>
                  <Image
                    title={item.name}
                    playStoreImage={item.playStoreImage}
                    smallImage={item.img}
                    type={item.type}
                    features={item.features}
                    overview={item.overview}
                    specification={item.specification}
                    heading={item.heading}
                    data={item.data}
                    data2={item.data2}
                    style={{ borderRadius: "12px" }}
                  />
                </div>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
