import React from "react";
import { Helmet } from "react-helmet-async";

const SEOComponent = ({ title, description, url, image, author, keyword }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta
        property="og:image"
        content={
          image ||
          "https://kanraj.com/wp-content/uploads/2022/10/Final-logo-color.png"
        }
      />
      <meta property="og:description" content={description} />
      {author && <meta property="og:author" content={author} />}
      {keyword && <meta property="og:keyword" content={keyword} />}
    </Helmet>
  );
};

export default SEOComponent;
