import React from "react";
import Banner from "../Banner/index";
import Background from "../../../assets/VRGame/1-VR-Game-Bannerc.jpg";
import Robust from "../../../assets/VRGame/Robust-solution.png";
import focused from "../../../assets/VRGame/focused-quality.png";
import Accurate from "../../../assets/VRGame/Accurate-rate.png";
import VRGame from "../../../assets/VRGame/1-VR-GameWeb.webp";
import Smallcard from "../Smallcard";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  return (
    <div className='data'>
      <SEOComponent
        title='VR Game Development |VR games - Kanraj Infotech'
        description='Explore our cutting-edge VR game development expertise and embark on thrilling adventures like never before. we build software, in which we represent the real experience environment that the user believes and accepts it as a real environment.'
        url='https://kanraj.com/vrgame'
      />
      <Banner banner={Background} text={"VR Game Development"} />
      <div className='container'>
        <h6
          style={{
            color: "#078586",
            fontSize: "20px",
            fontWeight: "700",
            textDecoration: "underline",
            textDecorationColor: "#078586",
          }}>
          Game Development
        </h6>
        <h2 className='font_700'>VR Game Development</h2>
        <p className='pera'>
          Virtual reality creates an artificial environment through the
          computer. For this, we build software, in which we represent the real
          experience environment that the user believes and accepts it as a real
          environment. It is a high demand in today’s digitally enhanced world.
        </p>
        <p className='pera'>
          Virtual Reality is one of the most significant and challenging
          technologies that can only be developed by highly skilled developers
          like us.
        </p>
      </div>
      <div className='container'>
        <div className='row '>
          <div className='col-md-6'>
            <h3 className='font_700'>VR Game Development @ Kanraj</h3>
            <p className='py-2 pera'>
              To make the designs of the VR games more tangible and realistic,
              we adopt agile methodologies and follow the proven steps of
              developing VR games.
            </p>
            <p className='py-2 pera'>
              We establish the games integrating effective storytelling and
              realistic sound effects.
            </p>
            <p className='py-2 pera'>
              We provide striking visuals with high impacting features.
            </p>
            <p className='py-2 pera'>
              We deliver VR games on-time that makes us the most trusted virtual
              reality development company in the industry.
            </p>
            <Smallcard
              image={Robust}
              title={"Robust Solutions"}
              text={
                "We always deliver extraordinary services to our global clients and we take care of everything related to IT solutions and customer queries. Our such qualities make us different from the competitors."
              }
            />
          </div>
          <div className='col-md-6'>
            <h3 className='bold'>What we offer</h3>
            <div className='py-3'>
              <Smallcard
                image={focused}
                title={"Focused Quality"}
                text={
                  "Kanraj Infotech is dedicated to delivering the best quality, value and service to meet our client's needs. Quality is a attribute that we appreciate and If you are in search for high-quality IT services, that's where our expertise is at your fingertips"
                }
              />
            </div>
            <div className='py-3'>
              <Smallcard
                image={Accurate}
                title={"Accurate Rates"}
                text={
                  "Kanraj team always believes in delivering outstanding solutions for small to huge projects. We have a tailor-made pricing list as per client requirements. At Kanraj, we believe in client's satisfaction when it comes to work delivery."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container my-4'>
        <div className='row'>
          <div className='col-md-6 pb-1'>
            <img
              src={VRGame}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
          <div className='col-md-6'>
            <h3 className='font_700'>Why kanraj infotech?</h3>
            <p className='pera'>
              Be it a startup or big company, Kanraj Infotech provides brilliant
              tech solutions to the enterprises. We believe in prompt solutions
              when it comes to client projects.
            </p>
            <div className='d-flex align-items-center py-3'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2'>ON DEMAND DEVOTED RESOURCES</h4>
            </div>
            <div className='d-flex align-items-center py-3'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2'>
                COST EFFECTIVE SOLUTION - FAST AND FLEXIBLE
              </h4>
            </div>
            <div className='d-flex align-items-center py-3'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2'>SINGLE DAY FOR JUMP-START A PROJECT</h4>
            </div>
            <div className='d-flex align-items-center py-3'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2'>SCHEDULED DELIVERY</h4>
            </div>
          </div>
        </div>
      </div>
      <Blogs id={13} />
    </div>
  );
};

export default Index;
