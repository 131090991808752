import React, { useState } from "react";
import Banner from "../Banner";
import BgBanner from "../../../assets/ARGame/1-AR-Game-Banner.jpg";
import icon from "../../../assets/ConsoleGame/icon-image-nike.png";
import Smallcard from "../Smallcard";
import Augmented from "../../../assets/ARGame/1-AR-GameWeb.webp";
import Geolocation from "../../../assets/ARGame/1-AR-Geolocation.png";
import FPS from "../../../assets/ARGame/2-AR-FPS.png";
import RPG from "../../../assets/ARGame/3-AR-RPG.png";
import Shooting from "../../../assets/ARGame/4-AR-Shooting.png";
import Sports from "../../../assets/ARGame/5-AR-Sports.png";
import Turn from "../../../assets/ARGame/6-AR-Turn-Based.png";
import Table from "../../../assets/ARGame/7-AR-Table.png";
import Card from "../../../assets/ARGame/8-AR-Card.png";
import Notifications from "../../../assets/ARGame/9-Notifications.png";
import Fraud from "../../../assets/ARGame/10-Anti-Fraud.png";
import Voice from "../../../assets/ARGame/11-Chat-Voice.png";
import Payment from "../../../assets/ARGame/12-Multiple-Payment.png";
import Earn from "../../../assets/ARGame/13-Refer-Earn.png";
import Technology from "../../../assets/ARGame/14-RNG.png";
import AugmentedReality from "../ARGame/AugmentedReality";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide1 from "../../../assets/ARGame/2-ARCoreWeb.webp";
import Slide2 from "../../../assets/ARGame/3-ARKitWeb.webp";
import Slide3 from "../../../assets/ARGame/4-VuforiaWeb.webp";
import Slide4 from "../../../assets/ARGame/5-ARToolkitWeb.webp";
import Slide5 from "../../../assets/ARGame/6-WikitudeWeb.webp";
import Slide6 from "../../../assets/ARGame/7-Vuzix-BladeWeb.webp";
import Accordiondata from "../../Accordiondata";
import Develops from "../../../assets/ARGame/14-AR-GameWeb.webp";
import Team from "../../../assets/ARGame/15-AR-GameWeb.webp";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1000, // Set autoplay speed (in milliseconds)
  };

  return (
    <div className='data'>
      <SEOComponent
        title='Augmented Reality Game Development - Kanraj Infotech'
        description="Experience the future of gaming with Kanraj Infotech's AR game development. Dive into immersive augmented reality experiences that redefine gaming. Design & develop the most effective AR games with us."
        // url='https://kanraj.com/argame'
        url='https://kanraj.com/argame'
      />
      <Banner banner={BgBanner} text={"AR Game Development"} />
      <div className='container'>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <h6
              style={{
                color: "#078586",
                fontSize: "20px",
                fontWeight: "700",
                textDecoration: "underline",
                textDecorationColor: "#078586",
              }}>
              Game Development
            </h6>
          </div>
          <h2 className='font_700'>
            Augmented Reality Game Development services
          </h2>
          <p className='pera'>
            Kanraj Infotech is a pioneer Augmented Reality Game Development
            company. Our experienced team of Augmented Reality developers can
            design & develop the most effective AR games.
          </p>

          <div className='col-md-6'>
            <h4 className='font_700'>
              Leading AR App & Game Development Solutions
            </h4>
            <p className='pera'>
              Kanraj Infotech is one of the leading Augmented Reality App & Game
              Development Company that delivers impressive AR visualisations for
              the worldwide base audiences. Combining innovation and creativity
              after performing research, we provide unmatched augmented reality
              game development services to our clients.
            </p>
            <div>
              <h4 className='font_700'>Gaming for all Screens</h4>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h6 className='px-2 pera'>iPhone</h6>
              </div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h6 className='px-2 pera'>iPed</h6>
              </div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h6 className='px-2 pera'>Android Phone</h6>
              </div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h6 className='px-2 pera'>Android Tablet</h6>
              </div>
            </div>
            <div>
              <Smallcard
                image={icon}
                title={"AR Integration Service"}
                text={
                  "If you wish to upgrade your app or game, and you want to integrate Augmented Reality features in the app/game, you can do so with kanraj AR Integration service. We will transform your app or game into a futuristic one with AR integration service."
                }
              />
              <div className='text-center'>
                <img
                  src={Augmented}
                  alt=''
                  height={"450px"}
                  width={"80%"}
                  style={{
                    borderRadius: "12px",
                    objectFit: "fill",
                    width: "min-contain",
                  }}
                />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <p className='pera'>
              Kanraj Infotech is among the leading Augmented reality game and
              App Development company. With wide experience in Augmented Reality
              Game and App Development, and along with our certified and expert
              Augmented Reality developers, we offer full-cycle development of
              Interacting games and appealing Unreal apps. Here are the
              Augmented Reality Services that we offer:
            </p>
            <div>
              <Smallcard
                image={icon}
                title={"AR Game Development Service"}
                text={
                  "Our Augmented Reality Game developers have the utmost experience in developing AR games. They have a grip over the technology that enables them to develop AR games that provide the best gaming experience that will keep the users hooked to the game."
                }
              />
              <Smallcard
                image={icon}
                title={"AR App Development Service"}
                text={
                  "Kanraj Infotech have immense experience in developing Mobile Apps using Augmented Reality. With our Augmented Reality app solutions, you can provide an unimaginable and futuristic experience to your users."
                }
              />
              <Smallcard
                image={icon}
                title={"AR Real Money Game Development Service"}
                text={
                  "Provide your users with the next-gen real money game which is bestowed with the Augmented Reality feature. With this, the users can just point their phone to a flat surface, and the game with other online players will be projected."
                }
              />
            </div>

            <div>
              <h5 className='font_700'>Augmented Reality</h5>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h6 className='px-2 font_700'>The future of gaming</h6>
              </div>
              <p className='pera'>
                Augmented reality gaming is the integration of game visual and
                audio content with the user’s environment in real time. With
                this, the users can use their smartphone to transport all the
                assets of the game into the real world.
              </p>
              <p className='pera'>
                This will provide an excellent gaming experience to the users,
                as they can play the game in the real world. This opens the door
                to endless opportunities. Develop the next-gen mobile game with
                Kanraj Infotech’s Augmented Reality Game Development Service.
              </p>
            </div>
          </div>
          <div className='my-3'>
            <h2
              className='text-center font_700'
              style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
              Types of AR Games We Can Build
            </h2>
            <p>
              The Augmented Reality’s gaming scenario is a constantly evolving
              one, and although not all the genres of mobile gaming has been
              merged with this technology, there are various game types that are
              prominently using this technology to take their gaming experience
              to the new heights. Here are the Augmented Reality games that we
              effortlessly build:
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <Smallcard
              image={Geolocation}
              title={"AR Geolocation games"}
              text={
                "Hunting or collecting games has been the fantasy of the people, and with geo-location based games, you can provide the users with the same."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={FPS}
              title={"AR FPS games"}
              text={
                "Transform the user’s environment into a fascinating battle ground using the Augmented Reality technology in the First Person Shooting games."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={RPG}
              title={" AR RPG games"}
              text={
                "Transport the users into the mesmerising world of Role-Playing Games, where you can take the users to the amazing environment of your creation."
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <Smallcard
              image={Shooting}
              title={"AR Shooting games"}
              text={
                "Multiple shooting games like gun shooting, archery, etc can be taken to the next level by integrating them with the Augmented Reality technology."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={Sports}
              title={"AR Sports games"}
              text={
                "Take the users to the ground filled with crowd and other online real players and let them enjoy the ultimate experience of AR Sports game."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={Turn}
              title={"AR Turn based games"}
              text={
                "The new era of AR games brings the AR Turn based game for the users who want to play the game with their friends and family turn-by-turn."
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <Smallcard
              image={Table}
              title={"AR Table games"}
              text={
                "Be it Poker, or our favourite childhood games like Ludo, Snake and Ladder, etc, the experience of all these games can be boosted by developing AR table games."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={Card}
              title={"AR Card games"}
              text={
                "Introduce the AR feature in the famous and best time killer games like Teen Patti, Poker, etc and provide the users with the best mobile card game experience."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={Notifications}
              title={"Smart Notifications"}
              text={
                "Smart Notifications allow you to send push notifications to all the users. You can target these notifications to motivate them to play the game now."
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <Smallcard
              image={Fraud}
              title={"Anti-Fraud System"}
              text={
                "We use state of the art security tools and protocols to ensure that the Ludo game that we develop is safe against online frauds."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={Voice}
              title={"Chat & Voice Functionality"}
              text={
                "We can integrate real-time voice and chat functionality to make the game more exciting."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={Payment}
              title={"Multiple Payment Options"}
              text={
                "We can integrate various payment Gateways including various cards, Paytm, Gpay, Stripe, and many more."
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <Smallcard
              image={Earn}
              title={"Refer & Earn Functionality"}
              text={
                "We have added the refer and earn functionality to the ludo game that provides a unique invite code to each user and provides easy to earn referral money."
              }
            />
          </div>
          <div className='col-md-4'>
            <Smallcard
              image={Technology}
              title={"RNG+ Technology Certification"}
              text={
                "Random Number Generator technology means that whenever the dice is rolled, the number that shows up on the dice is a totally random number. RNG+ Certification ensures fair gameplay in our Ludo games."
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='my-3'>
            <h2
              className='text-center'
              style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
              Types of AR Games We Can Build
            </h2>
            <p className='pera'>
              Depending on the functionality necessary – 3D tracking, smart
              glasses compatibility, geolocation, and so on – we work with the
              appropriate AR platforms for your project. Our AR mobile app
              development team uses state-of-art technology and tools to deliver
              marvelous augmented reality games and high-functionality apps, as
              the best game development company in India. Our developers
              leverage the below technology for immersive experiences
            </p>
          </div>

          <div
            className='col-md-4'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h5
                className=''
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                ARCore
              </h5>
              <p className='pera'>
                Powered by Google, also popularly known as Google Play Services
                for AR enables our augmented reality development to seamlessly
                blend the digital and physical world. The technology uses
                different APIs to sense the environment of your mobile phones,
                understand the world and interact with information.
              </p>
            </div>
          </div>

          <div className='col-md-4'>
            <Slider {...settings}>
              <div>
                <div
                  style={{
                    backgroundImage: `url(${Slide1})`,

                    height: "300px",
                    width: "-webkit-fill-available",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "10px",
                  }}></div>

                <h6 className='text-center'>ARKit</h6>
              </div>
              <div>
                <div
                  style={{
                    backgroundImage: `url(${Slide2})`,
                    height: "300px",
                    width: "-webkit-fill-available",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "10px",
                  }}></div>
              </div>
            </Slider>
          </div>

          <div
            className='col-md-4'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h5
                className=''
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                ARKit
              </h5>
              <p className='pera'>
                ARKit is an augmented reality framework that uses a device’s
                motion sensors, combined with visual information from the
                camera, to track the real world. The AR technology support is
                compatible with iOS devices such as iPhones and pads.
              </p>
            </div>
          </div>
        </div>

        <div className='row my-3'>
          <div
            className='col-md-4'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h5
                className=''
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                Vuforia
              </h5>
              <p className='pera'>
                Vuforia is an augmented reality SDK for mobile devices that
                enables our mobile app development services to create augmented
                reality applications smoothly. The main advantage of Vuforia is
                that it comes with a test app with annotations that demonstrate
                the device’s capabilities and support for virtual reality
                applications.
              </p>
            </div>
          </div>

          <div className='col-md-4'>
            <Slider {...settings}>
              <div>
                <div
                  style={{
                    backgroundImage: `url(${Slide3})`,
                    height: "300px",
                    width: "-webkit-fill-available",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "10px",
                  }}></div>

                <h6 className='text-center'>ARToolKit</h6>
              </div>
              <div>
                <div
                  style={{
                    backgroundImage: `url(${Slide4})`,
                    height: "300px",
                    width: "-webkit-fill-available",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "10px",
                  }}></div>
              </div>
            </Slider>
          </div>

          <div
            className='col-md-4'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h5
                className=''
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                ARToolKit
              </h5>
              <p className='pera'>
                ARToolKit is a free, open-source computer tracking library for
                building powerful AR apps for mobile phones that overlay virtual
                computer vision on real-world objects. We leverage ARToolkit to
                develop top-notch image recognition and tracking.
              </p>
            </div>
          </div>
        </div>

        <div className='row mb-3'>
          <div
            className='col-md-4'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h5
                className=''
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                Wikitude
              </h5>
              <p className='pera'>
                Wikitude Our mobile app development team leverages Wikitude to
                create augmented reality apps for mobile phones that can enhance
                the gaming experience for your users. The computer vision
                technology of Wikitude allows users to see more than their eyes
                can capture.
              </p>
            </div>
          </div>

          <div className='col-md-4'>
            <Slider {...settings}>
              <div>
                <div
                  style={{
                    backgroundImage: `url(${Slide5})`,
                    height: "300px",
                    width: "-webkit-fill-available",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "10px",
                  }}></div>

                <h6 className='text-center'>Wikitude</h6>
              </div>
              <div>
                <div
                  style={{
                    backgroundImage: `url(${Slide6})`,
                    height: "300px",
                    width: "-webkit-fill-available",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "10px",
                  }}></div>
              </div>
            </Slider>
          </div>

          <div
            className='col-md-4'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h5
                className=''
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                Vuzix Blade
              </h5>
              <p className='pera'>
                Vuzix Blade AR smart glasses use Vuzix’s unique waveguide optics
                and Cobra II display engine to provide a wearable smart display
                with a see-through viewing experience. Vuzix Blade is considered
                the best glasses for augmented reality games and works with both
                Android and iOS.
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='my-3'>
            <h2
              className='text-center'
              style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
              Augmented Reality Game Development Process
            </h2>
            <p className='pera'>
              Kanraj Infotech delivers robust, scalable, and high-performance
              Augmented Reality game development services to help you harness
              the power of technology to get the perfect mobile game. Our
              Augmented Reality Game Development Process ensures that we
              cost-effectively develop quality products.
            </p>
          </div>
          <AugmentedReality />
          <div className='mb-3'>
            <h2
              className='text-center'
              style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
              Augmented Reality Game Development Advantages
            </h2>
            <p className='text-center pera_one'>
              We offer various advantages with our Augmented Reality Game
              Development Service that keeps us ahead of the competition and our
              clients happy.
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <div className='py-2' style={{ borderBottom: "1px solid black" }}>
              <h5
                className='mb-3'
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                Robust AR Game Performance
              </h5>
              <p className='pera_one'>
                The work that we do defines us and we want to be defined as the
                best and therefore, all our apps and games work seamlessly
                across all devices. App/Games developed by us never lags.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='py-2' style={{ borderBottom: "1px solid black" }}>
              <h5
                className='mb-3'
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                Visionary Game Concepts
              </h5>
              <p className='pera_one'>
                Our developers are serious game lovers, and therefore they know
                what the audience wants and needs, and with this, they can
                develop visionary game concepts for you.
              </p>
            </div>
          </div>
        </div>

        <div className='row my-3'>
          <div className='col-md-6'>
            <div className='py-2' style={{ borderBottom: "1px solid black" }}>
              <h5
                className='mb-3'
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                Creative UI & Character Design
              </h5>
              <p className='pera_one'>
                Looks do matter when it comes to games. Our talented designers
                and developers use the most advanced tools and techniques to
                develop amazing UI & characters for the game.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='py-2' style={{ borderBottom: "1px solid black" }}>
              <h5
                className='mb-3'
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                Cost-Effective Solutions
              </h5>
              <p className='pera_one'>
                We understand the cut-throat competition that prevails in the
                market and therefore we have kept our pricing policy of all our
                services highly flexible that can fit every budget.
              </p>
            </div>
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-md-6'>
            <div className='py-2' style={{ borderBottom: "1px solid black" }}>
              <h5
                className='mb-3'
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                End-to-End Support
              </h5>
              <p className='pera_one'>
                You just have to share your idea, the rest lies to us. From
                planning, design, development, deployment, testing, launching to
                after services, we will take care of it all.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='py-2' style={{ borderBottom: "1px solid black" }}>
              <h5
                className='mb-3'
                style={{ fontFamily: "sans-serif", fontWeight: "700" }}>
                Scheduled Delivery
              </h5>
              <p className='pera_one'>
                As we follow the agile approach, you don’t have to worry about
                the deadline. Having a team of expert developers is a really
                great asset that contributes towards timely delivery.
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div
            className='col-md-4'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h2 style={{ color: "#000" }} className='font_700'>
                Why is Kanraj Infotech the Best Augmented Reality Game
                Development Company?
              </h2>
              <span
                style={{
                  border: "2px solid #078586 ",
                  display: "block",
                  width: "70px",
                  marginBottom: "10px",
                }}></span>
            </div>
          </div>
          <div className='col-md-4'>
            <img
              src={Develops}
              alt=''
              height={"300px"}
              width={"100%"}
              style={{ borderRadius: "12px" }}
            />
            <p className='pera'>
              Kanraj Infotech develops award-winning AR game apps utilising
              multiple and advanced technologies. Our team of app developers
              understands the psychology of the online users, and likewise, we
              extend the gaming solutions that ensure to provide a high-defined
              gaming experience.
            </p>
          </div>
          <div className='col-md-4'>
            <p className='pera'>
              The team of AR game developers are highly creative and dedicated
              to developing AR game apps with high-quality graphics, sound,
              architecture, and functions.
            </p>
            <img
              src={Team}
              alt=''
              height={"300px"}
              width={"100%"}
              style={{ borderRadius: "12px" }}
            />
          </div>
        </div>

        <div className='row py-4'>
          <h2 style={{ fontWeight: "700" }} className='text-start'>
            Augmented Reality Development FAQs
          </h2>
          <p className='pera_one'>
            We want to solve all the queries you may have and answer all the
            questions that may enter your mind. Therefore, here is a curated
            list of Frequently Asked Questions about Augmented Reality
            development that most clients ask. If you have any questions other
            than these, you can drop a message below or call us.
          </p>

          <Accordiondata
            eventKey={"0"}
            title={
              "How much time does it take to develop an Augmented reality mobile game?"
            }
            details={
              "It will take around 12 weeks to develop an Augmented Reality Mobile App, and the timeline can increase up to 20 weeks,depending on the features and number of platforms."
            }
            openSection={openSection}
            onToggle={handleToggle}
          />
          <Accordiondata
            eventKey={"1"}
            title={"Do you provide support for existing game projects?"}
            details={
              "We provide extensive support for our existing game projects."
            }
            openSection={openSection}
            onToggle={handleToggle}
          />
          <Accordiondata
            eventKey={"2"}
            title={"Do you help us to increase levels in-game?"}
            details={
              "Yes, we do help you in increasing as many levels in the game as you want. In fact, increasing the levels of the game falls under our game development process only, and therefore you don't have to worry about the same."
            }
            openSection={openSection}
            onToggle={handleToggle}
          />
          <Accordiondata
            eventKey={"3"}
            title={
              "We only have a game concept, would you be able to help us to move forward?"
            }
            details={
              "Definitely, Yes, you just need to share your game concept, and we will come up with a cool and appealing game for you."
            }
            openSection={openSection}
            onToggle={handleToggle}
          />
          <Accordiondata
            eventKey={"4"}
            title={"Can we create cross-platform AR games?"}
            details={
              "Yes, at Kanraj infotech, we provide cross-platform AR game and app development service, and therefore, you can get your cross-platform AR game developed by us."
            }
            openSection={openSection}
            onToggle={handleToggle}
          />
          <Accordiondata
            eventKey={"5"}
            title={" Which technologies do you use to develop AR Games?"}
            details={
              "<p>  We use the following technologies to develop impactful and effective Augmented Reality Mobile apps and games:</p><ul className=list-group><li className=list-group-item>AR Toolkit</li> <li className=list-group-item>Vuforia</li> <li className=list-group-item>Kudan </li> <li className=list-group-item>Wikitude</li> <li className=list-group-item>Microsoft Hololense</li> <li className=list-group-item>Apple iBeacon</li><li className=list-group-item>Google Project Tango</li></ul>"
            }
            openSection={openSection}
            onToggle={handleToggle}
          />
        </div>
      </div>
      <Blogs id={12} />
    </div>
  );
};

export default Index;
