import { useRoutes } from "react-router-dom";
import Home from "./component/Home/Home";
import Gallery from "./component/Portfolio/Gallery";
import Contactus from "./component/Contact-Us/Contactus";
import Gamedetails from "./component/Portfolio/Gamedetails";
import ErrorPage from "./component/RouteError";
import MobileGame from "./component/GameDevelopment/MobileGame/index"
import AndroidGame from "./component/GameDevelopment/AndroidGame/Index"
import IOSGame from "./component/GameDevelopment/IOSGame/Index"
import Unity3D from "./component/GameDevelopment/Unity3DGame/Index"
import MetaverseGame from "./component/GameDevelopment/MetaverseGame/Index"
import Fantasygamling from "./component/GameDevelopment/Fantasygamling/Index"
import NFTGame from "./component/GameDevelopment/NFTGame/Index"
import RealMoneyGame from "./component/GameDevelopment/RealMoneyGame/Index"
import UnrealEngine from "./component/GameDevelopment/UnrealEngineGame/Index"
import ARGame from "./component/GameDevelopment/ARGame/Index"
import VRGame from "./component/GameDevelopment/VRGame/Index"
import FacebookGame from "./component/GameDevelopment/FacebookGame/Index"
import HTML5WebGame from "./component/GameDevelopment/HTML5WebGame/Index"
import OnlineGame from "./component/GameDevelopment/OnlineGame/Index"
import WindowsGame from "./component/GameDevelopment/WindowsGame/Index"
import CrossPlatform from "./component/GameDevelopment/Cross-PlatformGame/Index"
import DesktopPC from "./component/GameDevelopment/DesktopPCGame/Index"
import ConsoleGame from "./component/GameDevelopment/ConsoleGame/Index"
import CasinoGame from "./component/GameDevelopment/CasinoGame/Index"
import BlogDetails from "./component/Blog/BlogDetails";

export default function Router() {
  let element = useRoutes([
    { path: "*", element: <ErrorPage /> },
    {
      path: "/",
      element: <Home />,
    },

    {
      path: "/portfolio",
      element: <Gallery />,
    },
    {
      path: "/portfolio/:id",
      element: <Gamedetails />,
    },

    {
      path: "/contact-us",
      element: <Contactus />,
    },
    {
      path: "/mobilegame",
      element: <MobileGame />,
    }, {
      path: "/androidgame",
      element: <AndroidGame />,
    }, {
      path: "/iosgame",
      element: <IOSGame />,
    }, {
      path: "/unity3D",
      element: <Unity3D />,
    }, {
      path: "/metaversegame",
      element: <MetaverseGame />,
    }, {
      path: "/fantasygamling",
      element: <Fantasygamling />,
    }, {
      path: "/nftgame",
      element: <NFTGame />,
    }, {
      path: "/realmoneygame",
      element: <RealMoneyGame />,
    }, {
      path: "/unrealengine",
      element: <UnrealEngine />,
    }, {
      path: "/argame",
      element: <ARGame />,
    },
    {
      path: "/vrgame",
      element: <VRGame />,
    },
    {
      path: "/facebookgame",
      element: <FacebookGame />,
    },
    {
      path: "/html5webgame",
      element: <HTML5WebGame />,
    },
    {
      path: "/onlinegame",
      element: <OnlineGame />,
    },
    {
      path: "/windowsgame",
      element: <WindowsGame />,
    },
    {
      path: "/crossplatform",
      element: <CrossPlatform />,
    },

    {
      path: "/desktopPC",
      element: <DesktopPC />,
    },

    {
      path: "/consolegame",
      element: <ConsoleGame />,
    },
    {
      path: "/casinogame",
      element: <CasinoGame />,
    },
    {
      path: "/blog/:slug",
      element: <BlogDetails/>,
    },
  ]);

  return element;
}
