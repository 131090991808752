import React from "react";
import Banner from ".././Banner/index";
import Background from "../../../assets/DesktopPCGame/1-Desktop-Game-Banner.jpg";
import DesktopGameWeb from "../../../assets/DesktopPCGame/1-Desktop-Game-Web-1.webp";
import DesktopGameWeb2 from "../../../assets/DesktopPCGame/2-Desktop-Game-Web-1.webp";
import DesktopGameWeb3 from "../../../assets/DesktopPCGame/3-Desktop-Game-Web-1.webp";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  return (
    <div className='data'>
      <SEOComponent
        title='Desktop Game Development Company - Kanraj Infotech'
        description='Explore the realm of gaming with Desktop game development company, Kanraj Infotech. Experience our expertise in crafting captivating PC games and elevate your gaming journey today.'
        url='https://kanraj.com/desktopPC'
      />
      <Banner banner={Background} text={"Desktop & PC Game Development"} />
      <div className='container'>
        <h6
          style={{
            color: "#078586",
            fontSize: "20px",
            fontWeight: "700",
            textDecoration: "underline",
            textDecorationColor: "#078586",
          }}>
          Game Development
        </h6>
        <div className='row'>
          <h2 className='font_700'>Desktop Game Development services</h2>
          <div className='col-xs-12 col-md-6'>
            <p className='pera'>
              Games have become a mode of entertainment in the coming years. We
              can find games on TV, Mobiles, Tablets, and Desktops. But the
              foremost familiar indoor games in today’s world are Pc games. Many
              people inculcate themselves in playing games for entertainment,
              relaxation, and knowledge. Pc & desktop games are the simplest
              games played by every child and youngster. Games similar to a car
              race, Action, Adventure, and brain training have given immense
              pleasure and amusement for the players on Desktop. The main
              intentions of designing Desktop & PC games are to give popularity
              and bring awareness to each and every user who has an engagement
              on PC for their work.
            </p>
          </div>
          <div className='col-xs-12 col-md-6'>
            <img
              src={DesktopGameWeb}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
        </div>
      </div>
      <div className='container py-4' style={{ backgroundColor: "#40444A" }}>
        <h2 className='text-center font_700 pb-2' style={{ color: "white" }}>
          Why Kanraj Infotech
        </h2>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <img
              src={DesktopGameWeb2}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
          <div className='col-xs-12 col-md-6'>
            <p
              style={{
                color: "white",
                textAlign: "justify",
                fontSize: "18px",
              }}>
              Kanraj Infotech has had immense experience in developing PC and
              Desktop games for many years. We have delivered many games to our
              clients of their own requirements with great Graphics, Framework,
              and standard. We are still in the process of designing more
              interesting games which will be giving extreme knowledge to all
              age groups from a child until youngsters till old age people. We
              have realized the persistence of desktop games lifting it up at
              the superior level, in which we are one of the greatest top
              leading companies in Desktop and PC games Development in India and
              glob.
            </p>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <p className='pera'>
              We are pleased to assist our client from overseas whom we have
              delivered Desktop games regarding Adventure, Mystery, Education,
              Knowledge, also enjoyment. We have a strong team of developers who
              have a plethora of innovation in their skill sets which helps to
              reach the actual need of our clients and we work on it. Since
              unity3D supports both 2D and 3D games development across various
              platforms, this tool has become very easy to handle and work on by
              our developers. Our Developers have developed games for operating
              systems similar to Windows, Mac, and Linux through the stream.
            </p>
          </div>
          <div className='col-xs-12 col-md-6'>
            <img
              src={DesktopGameWeb3}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
        </div>
      </div>
      <Blogs id={19} />
    </div>
  );
};

export default Index;
