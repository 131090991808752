import React, { useRef, useState } from "react";
import Sunil from "../../assets/technologies/sunil.webp";
import Fasial from "../../assets/technologies/faisal.webp";
import Melanie from "../../assets/technologies/melanie.jpg";

import Sideimage from "../../assets/technologies/testimonials.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import quote from "../../assets/custom/images/quote.png";
import arrowRight from "../../assets/custom/images/rightside-arrow.png";
import arrowLeft from "../../assets/custom/images/leftside-arrow.png";
const Testimonial = () => {
  let sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const next = () => {
    sliderRef.slickNext();
    setCurrentSlide((prev) => (prev === 3 ? 1 : prev + 1));
  };
  const previous = () => {
    sliderRef.slickPrev();
    setCurrentSlide((prev) => (prev === 1 ? 3 : prev - 1));
  };
  const settings = {
    // Add your Slick settings here
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current) => setCurrentSlide(current + 1),
  };

  const starWidth = `${(5 / 5) * 100}%`;
  return (
    <>
      <div
        className="container-fluid m-0 p-0"
        style={{ background: "#101010cf" }}
      >
        <div className="row m-0 p-0">
          <div className="col-md-6 m-0 p-0">
            <img
              src={Sideimage}
              alt=""
              style={{
                borderRadius: "20px",
                width: "100%",
                height: "100%",
                maxWidth: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            className="col-md-6  "
            style={{ marginTop: "5%", textAlign: "start" }}
          >
            <div className="sideLine" style={{ marginLeft: "5rem" }}>
              <h4
                style={{
                  color: "#078586",
                  textDecoration: "underline",
                  fontSize: "30px",
                  fontWeight: "700",
                }}
              >
                Quote About
              </h4>
              <h2
                className="text-white"
                style={{
                  fontSize: "38px",
                  fontWeight: "700",
                  lineHeight: "50px",
                  marginBottom: "2rem",
                }}
              >
                Customers Experience
              </h2>
              <div
                className="stars-filled m-2 p-2"
                style={{
                  width: starWidth,
                  backgroundColor: "#f0f3f9",
                  width: "fit-content",
                  borderRadius: "30px",
                }}
              >
                <span style={{ backgroundColor: "transparent" }}>
                  ★ ★ ★ ★ ★
                </span>
              </div>
              <div
                className=" quote m-3"
                style={{
                  height: "120px",
                  width: "120px",
                  backgroundColor: "#078586",
                  borderRadius: "50%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid white",
                }}
              >
                <img
                  src={quote}
                  alt=""
                  style={{
                    width: "50px",
                    height: "60px",
                    color: "white",
                    objectFit: "contain",
                  }}
                />
              </div>
              <Slider
                ref={(slider) => {
                  sliderRef = slider;
                }}
                {...settings}
              >
                <div style={{}} key={1}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "3.5rem 0 ",
                    }}
                  >
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        style={{ borderRadius: "3rem" }}
                        src={Sunil}
                        alt=""
                        width={"80px"}
                        height={"80px"}
                      />
                    </div>
                    <div style={{ width: "500px" }}>
                      <h5
                        style={{
                          borderLeft: "2px solid #198754",
                          paddingLeft: "10px",
                          color: "#fff",
                        }}
                      >
                        Mr. Sunil
                      </h5>
                      <p
                        className="text-white"
                        style={{
                          fontSize: "18px",
                          lineHeight: "28px",
                          fontWeight: "500",
                        }}
                      >
                        The professional team of game developer @ Kanraj
                        Infotech has recently completed my project. This was my
                        third project experience with this company and the
                        results they have given me are excellent, we are fully
                        satisfied by their service.
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{}} key={2}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "3.5rem 0 ",
                    }}
                  >
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        src={Fasial}
                        alt=""
                        style={{ borderRadius: "3rem" }}
                        width={"80px"}
                        height={"80px"}
                      />
                    </div>
                    <div style={{ width: "500px" }}>
                      <h5
                        style={{
                          borderLeft: "2px solid #198754",
                          paddingLeft: "10px",
                          color: "#fff",
                        }}
                      >
                        Mr. Faisal
                      </h5>
                      <p
                        className="text-white"
                        style={{
                          fontSize: "18px",
                          lineHeight: "28px",
                          fontWeight: "500",
                        }}
                      >
                        We worked with Kanraj Infotech to develop game projects
                        all in one - a multi gaming platform. They have worked
                        enormously to the graphical look, gameplay and user
                        experience of the game. Both me and my team are
                        immensely satisfied with their work.
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{}} key={3}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "3.5rem 0 ",
                    }}
                  >
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        src={Melanie}
                        alt=""
                        width={"80px"}
                        height={"80px"}
                        style={{ borderRadius: "3rem" }}
                      />
                    </div>
                    <div style={{ width: "500px" }}>
                      <h5
                        style={{
                          borderLeft: "2px solid #198754",
                          paddingLeft: "10px",
                          color: "#fff",
                        }}
                      >
                        Ms. Melanie Blume
                      </h5>
                      <p
                        className="text-white"
                        style={{
                          fontSize: "18px",
                          lineHeight: "28px",
                          fontWeight: "500",
                        }}
                      >
                        Absolutely amazed at this service provider's service.
                        Everything is done right because they have so much
                        experience. Will be working with them again soon.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
              <div style={{ textAlign: "center" }}>
                <span
                  className="button"
                  onClick={previous}
                  style={{ background: "none" }}
                >
                  <img
                    src={arrowLeft}
                    alt=""
                    style={{
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                      fontSize: "32px",
                    }}
                  />
                </span>
                <span
                  className="text-white"
                  style={{ fontSize: "20px", padding: "0px 10px 0px 10px" }}
                >
                  {" "}
                  <span style={{ fontSize: "30px", fontWeight: "700" }}>
                    {currentSlide}
                  </span>{" "}
                  / 3
                </span>
                <span
                  className="button"
                  onClick={next}
                  style={{ background: "none" }}
                >
                  <img
                    src={arrowRight}
                    alt=""
                    style={{
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                      fontSize: "32px",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
