// import Solitaire from "../../assets/PortfolioImage/Solitaire.jpg";
// import Rummy from "../../assets/PortfolioImage/Rummy.jpg";
// import Teenpatti from "../../assets/PortfolioImage/Teen-patti.jpg";
// import Snakeandladder from "../../assets/PortfolioImage/Snake-and-ladder.jpg";
// import Blackjack from "../../assets/PortfolioImage/Blackjack.jpg";
// import pokertexasholdem from "../../assets/PortfolioImage/poker-texas-holdem.jpg";
// import CasinoAllinone from "../../assets/PortfolioImage/Casino-All-in-one.jpg";
// import VivaSlotsVegas from "../../assets/PortfolioImage/Viva-Slots-Vegas.jpg";
// import Ludo from "../../assets/PortfolioImage/Ludo.jpg";
// import RouletteVegas from "../../assets/PortfolioImage/Roulette-Vegas.jpg";
// import chess from "../../assets/PortfolioImage/chess.jpg";
// import GalaxyWar from "../../assets/PortfolioImage/Galaxy-War.jpg";
// import FormulaOne from "../../assets/PortfolioImage/Formula-One.jpg";
// import Mathtime from "../../assets/PortfolioImage/Math-time.jpg";
// import Schooldays from "../../assets/PortfolioImage/School-days.jpg";
// import MetalBlackOPS from "../../assets/PortfolioImage/Metal-Black-OPS.jpg";
// import ArmyCommando from "../../assets/PortfolioImage/Army-Commando.jpg";
// import DesertsniperCommando from "../../assets/PortfolioImage/Desert-sniper-Commando.jpg";
// import TYPERUN from "../../assets/PortfolioImage/TYPE-RUN.jpg";
// import PushemallCrowdsmash from "../../assets/PortfolioImage/Pushem-all-Crowd-smash.jpg";
// import Epicfunrace3D from "../../assets/PortfolioImage/Epic-fun-race-3D.jpg";
// import shortpathrun from "../../assets/PortfolioImage/short-path-run.jpg";
// import mballs2048 from "../../assets/PortfolioImage/mballs-2048.jpg";
// import Firetank3D from "../../assets/PortfolioImage/Fire-tank-3D.jpg";
// import Stackballtowerattack from "../../assets/PortfolioImage/Stack-ball-tower-attack.jpg";
// import tictactoe from "../../assets/PortfolioImage/tic-tac-toe.jpg";
// import HIDDENOBJECT from "../../assets/PortfolioImage/HIDDEN-OBJECT-1.jpg";
// import Watersortcolorpuzzle from "../../assets/PortfolioImage/Water-sort-color-puzzle.jpg";
// import PLUMBERPIPEOUT from "../../assets/PortfolioImage/PLUMBER-PIPEOUT.jpg";
// import Wordsearch from "../../assets/PortfolioImage/Word-search.jpg";
// import Blockpuzzle from "../../assets/PortfolioImage/Block-puzzle.jpg";
// import FindthedifferenceSpot from "../../assets/PortfolioImage/Find-the-difference-Spot-it.jpg";
// import HarvestGrasscut from "../../assets/PortfolioImage/Harvest-Grass-cut.jpg";
// import Ballpool from "../../assets/PortfolioImage/8-Ball-pool.jpg";
// import canonball from "../../assets/PortfolioImage/canon-ball.jpg";
// import Doctorcrush from "../../assets/PortfolioImage/Doctor-crush.jpg";
// import SoccerStriker from "../../assets/PortfolioImage/Soccer-Striker.jpg";
// import BusSubwaysurfers from "../../assets/PortfolioImage/Bus-Subway-surfers.jpg";
// import InjectionHitman from "../../assets/PortfolioImage/Injection-Hitman.jpg";
// import RescueHeroPullpin from "../../assets/PortfolioImage/Rescue-Hero-Pull-pin.jpg";
// import Bubbleshooterjungle from "../../assets/PortfolioImage/Bubble-shooter-jungle.jpg";
// import HuntermanAssassin from "../../assets/PortfolioImage/Hunterman-Assassin.jpg";
// import GamescartOnlinegames from "../../assets/PortfolioImage/Gamescart-Online-games.jpg";
// import playStoreImage from "../../assets/PortfolioImage/google-play-png-logo-3799.png";
// import AppStoreImage from "../../assets/PortfolioImage/app-store-png-logo-33116.png";
// import GoogledriveImage from "../../assets/PortfolioImage/GOOGLE-DRIVE-NEW-ICON-modified.png";

// const data = [
//   {
//     img: Solitaire,
//     name: "Solitaire",
//     overview:
//       "This solitaire game is The Most Relaxing & Addictive Card Game. Solitaire games, also known as Patience in Europe (such as the United Kingdom, French, etc.), are Solitaire card games. Enjoy the best free and popular Solitaire card games in the world for free at any time and place! It's time to train your brain, challenge yourself and be a Solitaire or Patience master, try this classic and free Patience Solitaire card game! With beautiful graphics, fun animations, and free, offline play, our basic Solitaire is the best card game to pass the time.   ",
//     playStoreImage: playStoreImage,
//     features: [
//       "Login with Facebook login/Mobile",
//       "Two game mode for difficulty - Easy and Hard",
//       "Offline game",
//       "In app Purchase ",
//       "Share and earn Module",
//       "Undo, Hint, etc functions",
//     ],

//     specification: [
//       { Technology: "Unity3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Card, Casual" },
//       { Type: "2D, Stylized," },
//     ],
//     type: "Card & Casino games",
//     aLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
//     pLink: `https://play.google.com/store/apps/details?id=com.fassor.android.solitaire&hl=en&gl=US`,
//   },
//   {
//     img: Rummy,
//     name: "Rummy",
//     overview:
//       "Rummyland is a famous Indian card game! Play this variant with thousands of people online for free! With Rummyland you can invite your friends, family and new people to play this game where and whenever you want. Rummy is easy to learn, simple to play and will keep you hooked.",
//     playStoreImage: playStoreImage,
//     features: [
//       "Different variation - Point rummy, Pool Rummy & Point rummy",
//       "Welcome bonus",
//       "Practice mode",
//       "Free to play with coin",
//     ],

//     specification: [
//       { Technology: "Frontend - Unity 3D, backend - Node JS" },
//       { Platform: "Android" },
//       { Genre: "Card, Table, Casino, Online Multiplayer" },
//       { Type: "Realistic, 2D" },
//     ],
//     type: "Card & Casino games",
//     aLink: `https://download.rummylands.com/RummyLand-release.apk`,
//     dLink: `https://download.rummylands.com/RummyLand-release.apk`,
//     pLink: `https://download.rummylands.com/RummyLand-release.apk`,
//   },
//   {
//     img: Teenpatti,
//     name: "Teen-patti",
//     overview:
//       "Teen Patti with millions of real friends! It’s a traditional online multiplayer Indian poker game but with more advanced social and gaming functions than ever. Invite friends and play cards together! In Teen patti, you can play with online friends, family, or any player around the world. Play different variations like, Teen patti classic, Joker, AK 47 & Muflish.",
//     // playStoreImage:
//     //   playStoreImage,
//     features: [
//       "Different variation",
//       "Welcome bonus",
//       "Refer and earn",
//       "Login with facebook and mobile",
//       "Payment gateway integration",
//     ],

//     specification: [
//       { Technology: "Frontend - Unity 3D, backend - Node JS" },
//       { Platform: "Android" },
//       { Genre: "Card, Table, Casino, Online Multiplayer" },
//       { Type: "Realistic, 2D" },
//     ],
//     type: "Card & Casino games",
//     aLink: `https://download.rummylands.com/RummyLand-release.apk`,
//     dLink: `https://download.rummylands.com/RummyLand-release.apk`,
//     pLink: `https://download.rummylands.com/RummyLand-release.apk`,
//   },
//   {
//     img: Snakeandladder,
//     name: "Snake and ladder Board game",
//     overview:
//       "This snakes and Ladders game is a fun and challenging dice game for the whole family and friends from Kanraj Infotech.Did you grow up playing board games with your friends, kids and family on game night? Or perhaps you grew up hearing your parents talk fondly about their favorite board games such as Snakes and Ladders game.",
//     playStoreImage: playStoreImage,
//     features: [
//       "Login with Facebook login/Mobile",
//       "Offline game",
//       "In app Purchase",
//       "Share and earn Module",
//       "Undo feature",
//       "Admob integration",
//     ],

//     specification: [
//       { Technology: "Unity3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Board, Dice, Realistic, Casual" },
//       { Type: "2D, Stylized," },
//     ],
//     type: "Card & Casino games",
//     aLink: `https://play.google.com/store/apps/details?id=com.rma.snakeandladder`,
//     dLink: `https://play.google.com/store/apps/details?id=com.rma.snakeandladder`,
//     pLink: `https://play.google.com/store/apps/details?id=com.rma.snakeandladder`,
//   },
//   {
//     img: Blackjack,
//     name: "Blackjack",
//     overview:
//       "Blackjack - now you have the famous gambling game always with you - for free and offline. Play Blackjack like a pro without having to bet real money. Adapt the rules to your favorite casino, train your skills and become a blackjack pro yourself!",
//     data: [
//       "Play Blackjack without the use of real money",
//       " Fully usable offline",
//     ],
//     playStoreImage: playStoreImage,
//     features: [
//       "Two different game mode - American & European",
//       "Welcome bonus",
//       "Refer and earn",
//       "In app purchase",
//       "Admob integration",
//       "Premium - No ads version",
//     ],

//     specification: [
//       { Technology: "Frontend - Unity 3D, backend - Node JS" },
//       { Platform: " Android" },
//       { Genre: " Table, Card, Casino, Single player,casual," },
//       { Type: "Realistic, 2D" },
//     ],
//     type: "Card & Casino games",
//     aLink: `https://play.google.com/store/apps/details?id=com.fassor.android.blackjack`,
//     dLink: `https://play.google.com/store/apps/details?id=com.fassor.android.blackjack`,
//     pLink: `https://play.google.com/store/apps/details?id=com.fassor.android.blackjack`,
//   },
//   {
//     img: pokertexasholdem,
//     name: "Poker - Texas Holdem",
//     overview:
//       "Poker All Day has a Texas holdem poker atmosphere, a lot of fun and poker games for free.Play online poker with friends and players all over the world. Experience real poker night experience and make new poker friends now! Poker All Day is a rising online star of the most popular poker game, Holdem Texas Poker. It provides an entertaining & interactive online Texas Holdem real poker experience for poker lovers! Use your skill to compete and win against",
//     playStoreImage: playStoreImage,
//     features: [
//       "Login with Facebook and Mobile",
//       "Different themes for tables",
//       "Lucky spin",
//       "Welcome bonus",
//       "Admob integration",
//       "Refer and earn",
//       "In app purchase",
//     ],

//     specification: [
//       { Technology: "Frontend - Unity 3D, backend - Node JS" },
//       { Platform: " Android" },
//       { Genre: " Table, Card, Casino, Single player" },
//       { Type: "Realistic, 2D" },
//     ],
//     type: "Card & Casino games",
//     aLink: `https://apps.apple.com/us/app/poker-all-day-texas-holdem/id1144458302`,
//     dLink: `https://apps.apple.com/us/app/poker-all-day-texas-holdem/id1144458302`,
//     pLink: `https://play.google.com/store/apps/details?id=com.playwpt.mobile.poker`,
//   },
//   {
//     img: CasinoAllinone,
//     name: "Casino - All in one",
//     overview:
//       "Casino all in one is the best Casino game for real money games. Casino offers 25 games with great graphics, smooth gameplay, authentic odds, and betting. The best part is that the 25-in-1 Casino is has following games: Rummy, Teen Patti, Andar Bahar, Dragon vs Tiger, Ludo, 7 up down, Fruit Cutter, Spin 2 win, Roulette, Variation, ICC T20, Sports, Baccarat, Crash, Fruit line, Slot machine, Black jack, 10 cards, Poker, Zoo Roulette, Best of 5, Fishing Rush, Car Roulette.",
//     playStoreImage: playStoreImage,
//     features: [
//       "All trending online games",
//       "Fully custom Admin panel for game control",
//       "Mobile, Facebook and guest login",
//       "Lucky spin",
//       "Banner from Admin panel",
//       "AI - Bot for multiplayer game",
//       "Leaderboard",
//       "User Support system",
//       "Refer and earn",
//       "In app purchase",
//     ],

//     specification: [
//       { Technology: "Frontend - Unity 3D, backend - Node JS" },
//       { Platform: " Android" },
//       { Genre: " Board, Casino, Card, Single player & multiplayer" },
//       { Type: "Realistic, 2D" },
//       {
//         Demo: "https://rummysharejoy.com/?from_gameid=7304966&channelCode=100000",
//       },
//     ],
//     type: "Card & Casino games",
//     aLink: `https://rummysharejoy.com/`,
//     dLink: `https://rummysharejoy.com/`,
//     pLink: `https://rummysharejoy.com/`,
//   },
//   {
//     img: VivaSlotsVegas,
//     name: "Viva Slots Vegas",
//     overview:
//       "Viva Vegas Slots Casino Game with all your favorite free casino slot machines online! With 100+ LAS VEGAS SLOTS, free credit sweepstakes, online casino slots free promotions, NEW GAMES EVERY WEEK and free bonus slots, Viva Slots Vegas is the BEST classic casino slot machine game for users. Discover the best free slot games on mobile, in the palm of your hands – get ready to win the biggest online slot",
//     playStoreImage: playStoreImage,
//     appStoreImage: AppStoreImage,
//     features: [
//       "100+ Slots, unlock new slots by winning",
//       "Different events set up from the Admin panel for users.",
//       "Lucky spin",
//       "Welcome bonus",
//       "Admob integration",
//       "Refer and earn",
//       "In app purchase",
//     ],
//     specification: [
//       { Technology: "Frontend - Unity 3D, backend - Node JS" },
//       { Platform: " Android & iOS" },
//       { Genre: "Table, Casino, Single player" },
//       { Type: " Realistic, 2D" },
//     ],
//     type: "Card & Casino games",
//     aLink: `https://apps.apple.com/us/app/viva-slots-vegas-slot-machines/id1017064541?mt=8`,
//     dLink: `https://play.google.com/store/apps/details?id=com.RocketGames.ClassicSlots&hl=en&gl=US`,
//     pLink: `https://play.google.com/store/apps/details?id=com.RocketGames.ClassicSlots&hl=en&gl=US`,
//   },
//   {
//     img: Ludo,
//     name: "Ludo",
//     overview:
//       "This Ludo is fun to play a multiplayer board game that can be played between 2, 3 or 4 players. It is the most popular and fun game to play with family and friends. Ludo is a mind refreshing game with its lucky dice rolls and strategic gameplay. This interesting 2D Ludo game has been around us for a long time as the best game to play in our spare time. We have developed a Ludo game for Real money and Normal coin, customize mode as per client requirement. We integrate Payment gateway in this game as per your requirement.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Main Feature - Ludo Mahasangram - Live championship",
//       "Online Multiplayer",
//       "Tournament",
//       "Play with online friends",
//       "Play with offline friends",
//       "play with computer (smart AI) ( Practice and Normal )",
//       "AI and Robot in all game modes available ( if required )",
//       "Leaderboard",
//       "Smooth game play",
//       "Easy tournament access",
//       "Emoji chat",
//       "Text chat",
//       "Refer and Earn module",
//       "Lucky coupon and skims",
//       "Profile selection with all facility",
//       "login with Phone number, Facebook and Gmail",
//       "Secured sign up and login with OTP, anti cheat facility",
//       "Safe Withdraw and Admin friendly money transaction facility",
//       "Admin Panel with full game access",
//     ],
//     specification: [
//       { Technology: "Front end - Unity3D C# " },
//       { Specification: " Node JS, Socket iO, Laravel" },
//       { Platform: "Android, iOS & Web & Mobile browsers" },
//       { Genre: "Abstract Strategy, Board,Casual, Multiplayer, Online" },
//       { Type: " 2D, Semi realistic, Stylized, Real money & Normal coin" },
//       { Numberofgamedeveloped: "12 Ludo games with different features" },
//     ],
//     Premiumfeature: [
//       "Audio chat",
//       "Buddy Request",
//       "Token color selection",
//       "Spin wheel",
//       "Daily Bonus",
//     ],
//     type: "Card & Casino games",
//     aLink: `https://drive.google.com/file/d/11wXr9yiG-xIii-LKV0QZ3c_kxyslunHV/view?usp=sharing`,
//     dLink: `https://drive.google.com/file/d/11wXr9yiG-xIii-LKV0QZ3c_kxyslunHV/view?usp=sharing`,
//     pLink: `https://drive.google.com/file/d/11wXr9yiG-xIii-LKV0QZ3c_kxyslunHV/view?usp=sharing`,
//   },
//   {
//     img: RouletteVegas,
//     name: "Roulette Vegas",
//     overview:
//       "Roulette Vegas Casino 2020 and experience premium casino gaming. Roulette game that will make you feel like you're in a real casino.No money risking, just a free roulette simulator. Master different strategies and improve your play with our statistics. Whole app is for offline use. Thrilling 3D casino to enjoy anywhere. Breathtaking experience.",
//     playStoreImage: playStoreImage,
//     features: [
//       "Different theme for Wheel and chips",
//       "Lucky spin",
//       "Admob integration",
//       "Welcome bonus",
//       "Refer and earn",
//       "In app purchase",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android" },
//       { Genre: " Table, Casino, Single player" },
//       { Type: " Realistic, 2D" },
//     ],
//     type: "Card & Casino games",
//     aLink: `https://play.google.com/store/apps/details?id=com.luckyjackpot.roulette.casino.games.free&hl=en_US`,
//     dLink: `https://play.google.com/store/apps/details?id=com.luckyjackpot.roulette.casino.games.free&hl=en_US`,
//     pLink: `https://play.google.com/store/apps/details?id=com.luckyjackpot.roulette.casino.games.free&hl=en_US`,
//   },
//   {
//     img: chess,
//     name: "Chess",
//     heading: "Online Board game",
//     overview:
//       "Chess is a two-player strategy board game played on a checkerboard with 64 squares arranged in an 8×8 grid. Each player begins with 16 pieces: one king, one queen, two rooks, two knights, two bishops, and eight pawns. The objective is to checkmate the opponent's king by placing it under an inescapable threat of capture.Take no prisoners and protect your King at all costs! This Chess game is the best way to play the classic Chess board game on the web version.",

//     features: [
//       "Different play mode - Play Alone, Private room, Play with AI - with different levels of AI,",
//       "Multiplayer mode",
//       "Online chat with opponent",
//       "In app purchase",
//     ],
//     specification: [
//       { Technology: "HTML5, PHP 8.0, Database - MySQL, Framework - Laravel" },
//       { Platform: " All browsers of Android, iOS, Windows" },
//       { Genre: "Board, Abstract strategy, Single player & multiplayer, " },
//       { Type: " Realistic, 2D" },
//     ],
//     type: "HTML5 games",
//   },
//   {
//     img: GalaxyWar,
//     name: "Galaxy War",
//     heading: "Action shooter game",
//     overview:
//       "Galaxy War is an arcade space shooter game. Experience the thrill of adrenaline-packed aerial battles and engaging enemies in classic shoot 'em ups space fights! Play Galaxy war today and become an ace pilot to protect earth from waves after waves of aliens! Control the lone spaceship, shoot down each formation of aliens, and prevent the enemy forces from destroying all of mankind now!",

//     features: [
//       "Global Achievements and leaderboards",
//       "Multiplayer mode",
//       "Multiple ships for purchase",
//       "Daily rewards",
//       "In app purchase",
//     ],
//     specification: [
//       { Technology: "HTML5, Construct 2&3" },
//       { Platform: "All browsers of Android, iOS, Windows" },
//       { Genre: "Action, shooter, Single player & multiplayer, Arcade" },
//       { Type: "Stylized" },
//     ],
//     type: "HTML5 games",
//   },
//   {
//     img: FormulaOne,
//     name: "Formula One",
//     heading: "Arcade Racing game",
//     overview:
//       "Formula one is a new addition of Web base car racing games with latest and new formula cars. Top speed formula car racing games is an astonishing idea in the world of car driving games where you are going to compete with your rivals in a car race. Do you love to play car race games with multiple players to become master of formula car stunt championship? Then this top speed formula car game will give you a fun racing experience. Put your hands on the steering wheel in a formula car racing car game and drive your motorsport car in this formula car racing game. You can race with a formula one racing car to win the title of best racer in this game.",

//     features: [
//       "Compatible with all browsers and servers",
//       "Compatible with most of the devices, android, iOS and Windows",
//       "Map during game play",
//     ],
//     specification: [
//       { Technology: "HTML5/js, CreateJs" },
//       { Platform: "All browsers of Android, iOS, Windows" },
//       { Genre: "Arcade, Single player, Racing " },
//       { Type: "2D, Stylized" },
//     ],
//     type: "HTML5 games",
//   },
//   {
//     img: Mathtime,
//     name: "Math time",
//     heading: "Casual Education game",
//     overview:
//       "Basic Math Games is the perfect way to help children learn math skills the easy way! This math game for kids is super fun! Solve a wide variety of math puzzles, brain teasers, and brain math puzzles using nothing more than basic arithmetic. Pick up new skills in addition ➕, subtraction ➖, multiplication ✖️, and division.",

//     features: [
//       "4 Options available for finding correct answers",
//       "Instructions for kids",
//     ],
//     specification: [
//       { Technology: " Construct 2 and Construct 3, HTML5" },
//       { Platform: "All browsers of Android, iOS, Windows" },
//       { Genre: "Casual, Single player, Educational, Offline" },
//       { Type: "2D, Stylized" },
//     ],
//     type: "Educational games",
//   },
//   {
//     img: Schooldays,
//     name: "School days",
//     heading: "Casual Education game",
//     overview:
//       "“School day“ is a game for kids to play and learn. Enjoy fun school days! Back to school, kids! Go to school, grab your backpacks, enter a school bus and do not be late for a class. Play fun school games, like: Clean the bus, Alphabets, Dress up, Food, Picture Puzzle, Body parts, Paint, connect the dot. Play as a teacher, student, parents and more!",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different minigames and task for kids",
//       "Instructions for kids",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android" },
//       { Genre: "Casual, Single player, offline, Simulation" },
//       { Type: "2D, Stylized" },
//     ],
//     type: "Educational games",
//     aLink: `https://drive.google.com/file/d/1CVPAzpLLBLvOwDyTKUVQEiipwUiJ3JEG/view`,
//     dLink: `https://drive.google.com/file/d/1CVPAzpLLBLvOwDyTKUVQEiipwUiJ3JEG/view`,
//     pLink: `https://drive.google.com/file/d/1CVPAzpLLBLvOwDyTKUVQEiipwUiJ3JEG/view`,
//   },
//   {
//     img: MetalBlackOPS,
//     name: "Metal Black OPS",
//     heading: "FPS Platformer game",
//     overview:
//       "Metal Black OPS is a first-person shooter with blocky graphics and fun competitive gameplay. Play an addictive online first-person shooter action game. Buy modern weapons, upgrade them, change the look of your weapon, as well as your character!",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different missions with increasing difficulty and enemies.",
//       "Different location for missions",
//       "Customize your character, Weapons and boosters",
//       "Daily Quest",
//       "Achievement",
//       "In app purchase",
//       "Difficulty levels - Normal, Hard and Crazy",
//       "Daily Bonus",
//       "Revive features - with Rewarded ads",
//       "Unity Ads integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Action, Shooting, Single player, Platformer" },
//       { Type: "2D, Stylized" },
//     ],
//     type: "Action/Shooting games",
//     aLink: `https://drive.google.com/file/d/1batqP5b6_aivLc-sIOwhR1zoFLi_Omuk/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1batqP5b6_aivLc-sIOwhR1zoFLi_Omuk/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1batqP5b6_aivLc-sIOwhR1zoFLi_Omuk/view?usp=share_link`,
//   },
//   {
//     img: ArmyCommando,
//     name: "Army Commando",
//     heading: "FPS Action game",
//     overview:
//       "Army Commando - Welcome into the new world of action-packed games with fps commando shooting missions, where you can play unlimited sniper gun and fps offline shooting game missions of gun strike games and gun shooting games. If you love to play a commando gun shooting game offline in a real commando game. This Gun Games - FPS Shooting Game is the best action game. In the advanced gun games of shooting games, you have to complete all fps commando missions to become the best commando in this gun strike game. This game offers you to play this sniper offline game and a huge collection of weapons for fighting against terrorist squads in different chapters and missions.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Three different chapter with different missions",
//       "Different location of Cities, Underground tunnel, Forest etc.",
//       "In app purchase",
//       "Map Integration",
//       "Daily Bonus",
//       "Revive features - with Rewarded ads",
//       "Unity Ads integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Action, Shooting, Single player, offline" },
//       { Type: " Realistic, 2D" },
//     ],
//     type: "Action/Shooting games",
//     aLink: `https://drive.google.com/file/d/1ljuXaIoDvgZ49GP5nJFEpjBDJTbEcASf/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1ljuXaIoDvgZ49GP5nJFEpjBDJTbEcASf/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1ljuXaIoDvgZ49GP5nJFEpjBDJTbEcASf/view?usp=share_link`,
//   },
//   {
//     img: DesertsniperCommando,
//     name: " Desert sniper Commando",
//     heading: "FPS Action game",
//     overview:
//       "This snakes and Ladders game is a fun and challenging dice game for the wholDesert Sniper commando: Battleground Battlefield! Welcome to the Arena of Rules of Desert Sniper The best hunting game along with rules of sniper shooting. Modern Sniper guns are ready for hunting and Critical Sniper Strikes! Take the best Sniper gun duty! Complete all the sniper army strikes in an offline war game. Start FPS gunfire battleground survival games. Get Ready for Desert Sniper commando Game on new Battleground. e family and friends from Kanraj Infotech. Did you grow up playing board games with your friends, kids and family on game night? Or perhaps you grew up hearing your parents talk fondly about their favorite board games such as Snakes and Ladders game. Welcome to the sniper shooting arena in the Desert.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Two different mode - Mission mode & survival mode",
//       "Different levels in mission mode",
//       "Map Integration",
//       "Revive features - with Rewarded ads",
//       "Unity Ads integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: " Action, Shooting, Single player, offline" },
//       { Type: "2D, Realistic" },
//     ],
//     type: "Action/Shooting games",
//     aLink: `https://drive.google.com/file/d/1p_qDa3cG724RtZYgKlPRnxqjP_hWdm7v/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1p_qDa3cG724RtZYgKlPRnxqjP_hWdm7v/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1p_qDa3cG724RtZYgKlPRnxqjP_hWdm7v/view?usp=share_link`,
//   },
//   {
//     img: TYPERUN,
//     name: "Type Run 3D",
//     heading: "Casual Trivia game",
//     overview:
//       "Race with other players by typing the correct answer to a specific question. The faster you type, the more chances you win, but it is not at all. Correct answers matter as well. Join this breathtaking typing race against quick sneaky opponents, go level by level to complete various missions, and become the best type runner. From simple typing games to tricky word trivia — this texting game has plenty of brain-busting and typing practice!",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different backgrounds for users interest",
//       "350+ levels - with increasing difficulty",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android" },
//       { Genre: "Trivia, Casual, Single player" },
//       { Type: "2D, Stylized" },
//     ],
//     type: "Hyper-casual Games",
//     aLink: `https://drive.google.com/file/d/19PmWX6-mnaVuh1VsaOJSVeLiGbEWHfXO/view`,
//     dLink: `https://drive.google.com/file/d/19PmWX6-mnaVuh1VsaOJSVeLiGbEWHfXO/view`,
//     pLink: `https://drive.google.com/file/d/19PmWX6-mnaVuh1VsaOJSVeLiGbEWHfXO/view`,
//   },
//   {
//     img: PushemallCrowdsmash,
//     name: "Push’em all - Crowd smash",
//     heading: "Casual Action game",
//     overview:
//       "This game is Casual action game. Trace your way to the end of the level and push the enemies with your stick on the way! Move your character and make all the enemies fall down. Be careful with giant enemies and the fast ones, It's their turn to try to take you down! Throw away through your stick to push all the obstacle crowd to the sides, just throw them and complete each level. Before finishing a level, you must throw away all the crowds down and out, don't let yourself down off the towers until all the crowd is smashed down.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different backgrounds for users interest",
//       "150+ levels - with increasing difficulty",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android" },
//       { Genre: "Action, Casual, Single player, Abstract" },
//       { Type: "2D, Stylized" },
//     ],
//     type: "Hyper-casual Games",
//     aLink: `https://drive.google.com/file/d/140hNBNX5NKagXs58zkadJo82ILol-SGG/view`,
//     dLink: `https://drive.google.com/file/d/140hNBNX5NKagXs58zkadJo82ILol-SGG/view`,
//     pLink: `https://drive.google.com/file/d/140hNBNX5NKagXs58zkadJo82ILol-SGG/view`,
//   },
//   {
//     img: Epicfunrace3D,
//     name: "Epic fun race 3D",
//     heading: "Racing game",
//     overview:
//       "Experience full parkour experience with different unique levels. Race with others, achieve levels , unlock new characters. Every level brings a new unique fun experience. Very easy to play. Hold to run, release to stop.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different backgrounds for users interest",
//       "100+ levels - with increasing difficulty",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android" },
//       { Genre: " Racing, Casual, Single player" },
//       { Type: "2D, Stylized" },
//     ],
//     type: "Hyper-casual Games",
//     aLink: `https://drive.google.com/file/d/1c7Lt_jnkdz_iv9hjB9PmDtJCg2vC0Z51/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1c7Lt_jnkdz_iv9hjB9PmDtJCg2vC0Z51/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1c7Lt_jnkdz_iv9hjB9PmDtJCg2vC0Z51/view?usp=share_link`,
//   },
//   {
//     img: shortpathrun,
//     name: "Short path run 3D",
//     heading: "Racing game",
//     overview:
//       "Race against opponents, and stack up all the floorboards you can pick up along the way! If you're clever enough, get out of the way and build bridges and take shortcuts to get to the finish line before everyone else! Run, Jump, and complete the race.",
//     playStoreImage: playStoreImage,
//     features: [
//       "Different backgrounds for users interest",
//       "100+ levels - with increasing difficulty",
//       "20+ characters",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android" },
//       { Genre: " Racing, Casual, Single player" },
//       { Type: "2D, Stylized" },
//     ],
//     type: "Hyper-casual Games",
//     aLink: `https://drive.google.com/file/d/1yZhyLTOMp0MtWhyRKNThxVEjs283MuQW/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1yZhyLTOMp0MtWhyRKNThxVEjs283MuQW/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1yZhyLTOMp0MtWhyRKNThxVEjs283MuQW/view?usp=share_link`,
//   },
//   {
//     img: mballs2048,
//     name: "2048 Merge balls",
//     heading: "Number merge puzzle game - Real money",
//     overview:
//       "Mballs 2048 is the most trending stack number ball puzzle game with smooth gameplay, which lets you think out of the box and sharpen your mind with numbers games. Merge endless number puzzle game! Unlimited play with no purchase required. Interesting features of hammer and swap. Get a $50 bonus on the first 1M ball, and then $5 for every new ball like, 2M, 4M, 8M…. Start with 2 and reach 16, 32, 128, 512, 1024, 2048 and higher Fun and addictive 2048 number puzzle game! Merge ball to get the highest score! 2048 Merge Endless is the classic 2048 puzzle game. Merge blob to get bigger number and merge them to reach 2048",
//     playStoreImage: playStoreImage,
//     features: [
//       "Real money game",
//       "Hammer and swap ball feature",
//       "Leaderboard",
//       "Admob integration",
//       "Increasing difficulty with level ups",
//       "Unlimited game play",
//       "Share and earn model",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       "Admin panel and backend - PHP laravel",
//       { Platform: "Android & iOS" },
//       { Genre: "Puzzle, Merge, Casual, Single player, Abstract" },
//       { Type: " 2D, Online, Real money game" },
//     ],
//     type: "Hyper-casual Games",
//   },
//   {
//     img: Firetank3D,
//     name: "Fire tank 3D",
//     heading: "Hyper casual game - online multiplayer",
//     overview:
//       "Fire Tank 3D is a single-tap hyper casual game that will keep you hooked for hours! Hold to make your dot fire forward, and be careful from moving obstacles. The game features unlimited levels and 20 beautiful color themes.",
//     playStoreImage: playStoreImage,
//     features: [
//       "Offline single player & online multiplayer game mode",
//       "Different skins of Tank",
//       "Admob integration",
//       "Increasing difficulty with level ups",
//       "Unlimited levels",
//       "Share and earn model",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Casual, Hyper casual, Single player, Multiplayer, Abstract" },
//       { Type: "2D, Online & offline" },
//     ],
//     type: "Hyper-casual Games",
//   },
//   {
//     img: Stackballtowerattack,
//     name: "Stack ball - tower attack",
//     heading: "Most popular Hyper casual game",
//     overview:
//       "Stack Ball - Tower Attack is a 3d arcade game where players smash, bump and bounce through revolving helix platforms to reach the end. Your ball smashes like a brick through colorful platforms that block its descent, but if you hit a black one, it's all over! Your ball shatters to pieces and you have to start your fall all over again. But even black platforms are no match for a fireball falling at full speed! Choose your strategy: speed up like a madman",
//     playStoreImage: playStoreImage,
//     features: [
//       "Offline single player & online multiplayer game mode",
//       "Admob integration",
//       "Increasing difficulty with level ups",
//       "Unlimited levels",
//       "Share and earn model",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: " Arcade, Hyper casual, Single player, Multiplayer, Abstract" },
//       { Type: "2D, Online & offline" },
//     ],
//     type: "Hyper-casual Games",
//   },
//   {
//     img: tictactoe,
//     name: "Tic Tac Toe",
//     heading: "Board game",
//     overview:
//       "This game has the smartest AI and 2 player mode that can satisfy all your needs. You will feel amazing with the glow effect and cool animation. The AI of this game is one of the smartest you can find, you can play with it for hours without getting bored. You can play with your friend on the same phone as well as with smart AI.",
//     playStoreImage: playStoreImage,
//     features: [
//       "Offline single player & multiplayer game mode",
//       "Play with AI",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "  Board, Puzzle, Multiplayer, Abstract strategy  " },
//       { Type: " 2D, offline" },
//     ],
//     type: "Trivia/Puzzle games",
//   },
//   {
//     img: HIDDENOBJECT,
//     name: "Hidden objects",
//     heading: "Picture puzzle game",
//     overview:
//       "Start hunting for hidden objects in this game. Hidden Objects is a new kind of search and find hidden object casual games. It's an adventure casual game where you'll be searching for hidden objects and finding them! Prepare for a great hidden object journey! On each level you'll have to find missing objects: seek and find highlighted.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different rooms for find the objects",
//       "Difficulty level - Normal, Shadow and fix time challenge",
//       "Hint feature - with Rewarded ads",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: " Puzzle, Casual, Single player, Adventure" },
//       { Type: "  2D, Stylized, Realistic" },
//     ],
//     type: "Trivia/Puzzle games",
//     aLink: `https://drive.google.com/file/d/1-7t_m0vaxobc6HYkEt4_daufnty--sPw/view`,
//     dLink: `https://drive.google.com/file/d/1-7t_m0vaxobc6HYkEt4_daufnty--sPw/view`,
//     pLink: `https://drive.google.com/file/d/1-7t_m0vaxobc6HYkEt4_daufnty--sPw/view`,
//   },
//   {
//     img: Watersortcolorpuzzle,
//     name: "Water sort - color puzzle",
//     heading: "Color sort puzzle game",
//     overview:
//       "This water sort color puzzle game is simple but addictive, fun, and challenging ! It is the best free water sorting puzzle game for you to train your brain, kill free time and relax! If you’d like to train your combinational logic, this water sort puzzle game is just for you! It’s the most relaxing and challenging puzzle game, and it’s not timed.Try to sort the colored water in the glasses until all colors are in the same glass. A challenging yet relaxing game to exercise your brain!",
//     howtoplay: [
//       " Tap any glass to pour water to another glass.",
//       "The rule is that you can only pour the water if it is linked to the same color and there’s enough space on the glass.",
//     ],
//     playStoreImage: GoogledriveImage,
//     features: ["Difficulty levels increase with levels", "Admob integration"],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: " Android & iOS" },
//       { Genre: " Puzzle, Casual, Single player" },
//       { Type: "2D, Stylized, offline" },
//     ],
//     type: "Trivia/Puzzle games",
//     aLink: `https://drive.google.com/file/d/17PTxWk2yWYst-vIeG_zAKInzqzNhChd3/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/17PTxWk2yWYst-vIeG_zAKInzqzNhChd3/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/17PTxWk2yWYst-vIeG_zAKInzqzNhChd3/view?usp=share_link`,
//   },
//   {
//     img: PLUMBERPIPEOUT,
//     name: "Plumber - Pipeout",
//     heading: "Connect the pipe puzzle game",
//     overview:
//       "Plumber - Pipeout is one of the best puzzle games in the market and without a doubt the best pipe connection game. All you need to do is to rotate pipes, connect them and make a working pipeline. Touch the pipes to turn them. Construct a water path from valve to container. Solve puzzles fast and get more experience points. You can always use hints if you have got stuck in some level.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different difficulty levels like, Easy, Medium, Advance, Hard and Expert",
//       "Hint and Undo Features",
//       "Game statistics for user in Time and level",
//       "Level skip option for Hard levels",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: " Android & iOS" },
//       { Genre: " Puzzle, Casual, Single player" },
//       { Type: "2D, Stylized, offline" },
//     ],
//     type: "Trivia/Puzzle games",
//     aLink: `https://drive.google.com/file/d/1Il5gOLHQGnnQbTnJ_EHqPf7wDzpzcM2D/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1Il5gOLHQGnnQbTnJ_EHqPf7wDzpzcM2D/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1Il5gOLHQGnnQbTnJ_EHqPf7wDzpzcM2D/view?usp=share_link`,
//   },
//   {
//     img: Wordsearch,
//     name: "Word search",
//     heading: "Word search puzzle game",
//     overview:
//       "Word Search Puzzle is a classic word search game! Swipe to match letters. Find the right letters into many different words.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different difficulty levels",
//       "Special bonus levels for extra coins",
//       "Hint button and get the first letter of the word",
//       "Daily Reward",
//       "Admob integration",
//       "Unlimited game play",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: " Puzzle, Word, Casual, Single player" },
//       { Type: "2D, Offline, Realistic" },
//     ],
//     type: "Trivia/Puzzle games",
//     aLink: `https://drive.google.com/file/d/1OaAi79BEs6uGKLovpW4r9zYSTOsoJlKr/view`,
//     dLink: `https://drive.google.com/file/d/1OaAi79BEs6uGKLovpW4r9zYSTOsoJlKr/view`,
//     pLink: `https://drive.google.com/file/d/1OaAi79BEs6uGKLovpW4r9zYSTOsoJlKr/view`,
//   },
//   {
//     img: Blockpuzzle,
//     name: "Block puzzle",
//     heading: "Wood block puzzle game",
//     overview:
//       "Block Puzzle - Classic Block Puzzle Game, is a classic addictive wooden style block puzzle game. Qblock challenges you to fit blocks of different shapes. Wood Block Puzzle is a real classic, totally elimination game. Block Puzzle -classic wood block puzzle game is a Tetris like block puzzle game, with woody style.",

//     features: [
//       "3 Game mode - Classic, Timed, Bomb mode",
//       "Booster - Block Removal and Undo",
//       "Admob integration",
//       "Increasing difficulty",
//       "Unlimited game play",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: " Android, iOS & Web " },
//       { Genre: " Puzzle, Casual, Single player" },
//       { Type: " 2D, Realistic" },
//     ],
//     type: "Trivia/Puzzle games",
//   },
//   {
//     img: FindthedifferenceSpot,
//     name: "Find the difference - Spot it",
//     heading: "Picture puzzle game",
//     overview:
//       "Enjoy a brand new find differences game for kids and adults! Spot the difference between 2 identical pictures and train your brain by playing the find differences puzzle games with different levels! This game with easy & hard levels will surely make a difference in your life. Feel yourself like a true detective. Scout both of the pictures, use hints and magnifier glass and find as many hidden differences as possible.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different difficulty levels",
//       "Hint and Magnifier glass feature",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Puzzle, Casual, Single player" },
//       { Type: "2D, Stylized" },
//     ],
//     type: "Trivia/Puzzle games",
//     aLink: `https://drive.google.com/file/d/1Pg1S6PKvtGDa0MWiBa1xjja5XvndSDsY/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1Pg1S6PKvtGDa0MWiBa1xjja5XvndSDsY/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1Pg1S6PKvtGDa0MWiBa1xjja5XvndSDsY/view?usp=share_link`,
//   },
//   {
//     img: HarvestGrasscut,
//     name: "Harvest Grass cut",
//     heading: "Casual simulation game",
//     overview:
//       "Clear the ground by grass cutter and complete the level and move to the next game level, difficulty will be increased with levels.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Offline single player",
//       "Admob integration",
//       "Infinite levels with increasing difficulty",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "  Casual, single player " },
//       { Type: "2D, Stylized," },
//     ],
//     type: "Arcade & Casual games",
//     aLink: `https://drive.google.com/file/d/1HcqVkTyzHpSktTKsyA7JTWxnmxIdhJDD/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1HcqVkTyzHpSktTKsyA7JTWxnmxIdhJDD/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1HcqVkTyzHpSktTKsyA7JTWxnmxIdhJDD/view?usp=share_link`,
//   },
//   {
//     img: Ballpool,
//     name: "8 Ball pool",
//     heading: "Casual Puzzle online multiplayer game",
//     overview:
//       "8 Ball Infinity is one of the 8 ball pool multiplayer games with online chat in the Android market. You can play with your online friends, compete with players all over the world! Shooting balls! Enjoy the modern arcade style of 3D 8 pool game!Here we have a unique 8 ball pool online game in live chat and exquisite pool tables! You can also start with a beginner tutorial. Enjoy the real-time multiplayer game.",
//     playStoreImage: playStoreImage,
//     features: [
//       "Tutorial for beginners",
//       "Online multiplayer game mode & facebook friend invitation",
//       "Players History & profile",
//       "Daily Rewards",
//       "Global Leaderboard",
//       "Different theme of tables and skins for cue sticks",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: " Sports, Table, Arcade, Puzzle, Online" },
//       { Type: "2D, Realistic" },
//     ],
//     type: "Arcade & Casual games",
//     aLink: `https://apps.apple.com/us/app/infinity-8-ball-pool-king/id1609032238`,
//     dLink: `https://play.google.com/store/apps/details?id=com.sports.eightball.pool.rival`,
//     pLink: `https://play.google.com/store/apps/details?id=com.sports.eightball.pool.rival`,
//   },
//   {
//     img: canonball,
//     name: "Canon ball",
//     heading: "Casual Puzzle online multiplayer game",
//     overview:
//       "Fill all the buckets with balls to complete a level. Use your finger to move various objects to change the direction of the balls you shoot. Aim smart!",
//     playStoreImage: playStoreImage,
//     features: [
//       "Offline single player & online multiplayer game mode",
//       "Admob integration",
//       "Increasing difficulty with level ups",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Casual, Single player, Puzzle  " },
//       { Type: " 2D, offline" },
//     ],
//     type: "Arcade & Casual games",
//   },
//   {
//     img: Doctorcrush,
//     name: "Doctor crush",
//     heading: "Match 3 Puzzle game",
//     overview:
//       "Master the legendary match 3 puzzle game from Kanraj Infotech! Doctor Crush is the best 3 puzzle game for users. Match, pop, and blast Medical kit objects in this medical puzzle adventure to progress to the next level and get a star! Matches 3 puzzles with quick thinking and smart matching moves to be rewarded with bonuses and kit combos. You will adventure with many kits, objects, CRUSH, & MATCH three or more objects and blast your way through hundreds of FUN levels in this FREE puzzle game.",
//     playStoreImage: playStoreImage,
//     features: [
//       "Offline single player",
//       "Admob integration",
//       "Increasing difficulty with level ups",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: " Casual, Single player, Puzzle, Match 3" },
//       { Type: "2D, Stylized, offline" },
//     ],
//     type: "Arcade & Casual games",
//   },

//   {
//     img: SoccerStriker,
//     name: "Soccer Striker",
//     heading: "Casual multiplayer game",
//     overview:
//       "Shoot the ball with simple finger control! You can compete with global users through an online match system. Online league, Cup mode, Trick shot challenge mode, Training mode supported",
//     data: ["1,000 Trick Shot Challenge"],
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Offline single player & Multiplayer mode",
//       "Tournament mode & Penalty Kicks",
//       "Create New team, New formation",
//       "Admob integration",
//       "Infinite levels with increasing difficulty",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Sports, Casual, single player & multiplayer, Abstract" },
//       { Type: "2D, Stylized, Physics" },
//     ],
//     type: "Arcade & Casual games",
//     aLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
//   },
//   {
//     img: BusSubwaysurfers,
//     name: "Bus Subway surfers",
//     heading: "Arcade endless runner game",
//     overview:
//       "Bus Subway surfers Runner, also known as Bus and subway, is an endless running game that appeals to hundreds of thousands of players. Run as fast as you can and dodge the trains or buses that are about to collide with you. Be careful of the rolling log! To dodge obstacles and collect coins, you need to take great reflexes to move, jump and slide.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Facebook login",
//       "Offline single player & Online Multiplayer game mode",
//       "Different game modes like, Mission mode, challenge mode, etc.",
//       "Record video of your great moments",
//       "Different boosters and life hacks",
//       "In app Purchase ",
//       "Different Avatars of Ninja, Boy, girl etc skins",
//       "Share and earn Module",
//       "Admob integration",
//       "Endless with increasing difficulty",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Arcade, single player, Online Multiplayer, Runner, " },
//       { Type: "2D, Stylized," },
//     ],
//     type: "Arcade & Casual games",
//     aLink: `https://drive.google.com/file/d/1Ya73LZCcGlBDr3s5kaMaeOxMQ2ipHij4/view`,
//     dLink: `https://drive.google.com/file/d/1Ya73LZCcGlBDr3s5kaMaeOxMQ2ipHij4/view`,
//     pLink: `https://drive.google.com/file/d/1Ya73LZCcGlBDr3s5kaMaeOxMQ2ipHij4/view`,
//   },
//   {
//     img: InjectionHitman,
//     name: "Knife hit injection",
//     heading: "Casual Online Multiplayer game",
//     overview:
//       "Throw the Injection into the Corona virus to break them. Slash the medicine capsule and unlock new knives. Each 5th stage is defended by a boss - beat them to get exclusive Injections.! Be careful to not hit the injections or the spikes. Time your actions, target carefully and become the Injection Hitman!",
//     playStoreImage: playStoreImage,
//     features: [
//       "Login with Facebook login/Mobile",
//       "Offline single player & Online Multiplayer game mode",
//       "In app Purchase ",
//       "Different Injection, Corona and Boss skins",
//       "Share and earn Module",
//       "Admob integration",
//       "Infinite levels with increasing difficulty",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Casual, single player, Online Multiplayer, " },
//       { Type: " 3D, Stylized," },
//     ],
//     type: "Arcade & Casual games",
//   },
//   {
//     img: RescueHeroPullpin,
//     name: "Rescue Hero - Pull pin",
//     heading: "Casual puzzle game",
//     overview:
//       "Rescue Hero - Pull pin is about helping the hero save the princess in the best pin rescue game. You have a task to pull the pin puzzle, and then kill monsters, rescue the princess and collect treasure. The beautiful princess and golden treasure are waiting for you to rescue in the Rescue Hero game, Train your intelligence by playing pull the pin puzzle game, just you can answer all our pin puzzles.",
//     playStoreImage: GoogledriveImage,
//     features: [
//       "Different missions and task",
//       "150+ levels - with increasing difficulty",
//       "Admob integration",
//       "Daily bonus",
//       "Different skins for Hero",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Casual, Single player, offline" },
//       { Type: "2D, Stylized" },
//     ],
//     type: "Arcade & Casual games",
//     aLink: `https://drive.google.com/file/d/1cSAle0FUyJnOZ7FwJrqJZz9G9quCpqFW/view?usp=share_link`,
//     dLink: `https://drive.google.com/file/d/1cSAle0FUyJnOZ7FwJrqJZz9G9quCpqFW/view?usp=share_link`,
//     pLink: `https://drive.google.com/file/d/1cSAle0FUyJnOZ7FwJrqJZz9G9quCpqFW/view?usp=share_link`,
//   },
//   {
//     img: Bubbleshooterjungle,
//     name: "Bubble shooter jungle",
//     heading: "Casual bubble shooter jungle theme",
//     overview:
//       "This bubble shooter game is based on a jungle theme. Match 3 to blast & drop colorful balls in this addictive bubble shooter game! Explore new levels and blast your way to the top! Use your bubble shooter to break different balls, collect gems, and more as you challenge various levels!",
//     howtoplay: [
//       "Use the guiding line to aim your shooter and blast bubbles!",
//       "Match 3+ bubbles & shoot to make them pop!",
//     ],
//     playStoreImage: playStoreImage,
//     features: [
//       "Login with Facebook login/Mobile",
//       "Offline single player game mode",
//       "In app Purchase ",
//       "Share and earn Module",
//       "Swap the ball option",
//       "300+ game levels with increasing difficulty",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Casual, Puzzle, offline, single player" },
//       { Type: "2D, Stylized," },
//     ],
//     type: "Arcade & Casual games",
//   },
//   {
//     img: HuntermanAssassin,
//     name: "Hunterman Assassin",
//     heading: "Casual survival action game",
//     overview:
//       "Hunerman Assassin is a Casual Action game which is popular among the users in 2019-20, it was the most trending game. Control the assassin and hunt down your targets one by one. Use your surroundings and shadows to stay hidden from flashlights. You're an assassin, trained to use shadows and your surroundings to defeat your enemies. Each eliminated target drops valuable items. Use them to unlock and upgrade new weapons and heroes. Make strategic decisions on how to avoid being cornered by your enemies, and move swiftly to evade their flashlights. Targets with automatic machine guns can be lurking around each corner. Attack them swiftly and escape without being spotted.",
//     playStoreImage: playStoreImage,
//     features: [
//       "Login with Facebook login/Mobile",
//       "Online Multiplayer & Offline single player game mode",
//       "Different character and skin",
//       "In app Purchase ",
//       "Admob integration",
//       "Share and earn Module",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android & iOS" },
//       { Genre: "Casual, Single player, Multiplayer, Action, shooter" },
//       { Type: "2D, Stylized," },
//     ],
//     type: "Arcade & Casual games",
//   },
//   {
//     img: GamescartOnlinegames,
//     name: "Gamescart - Online Multiplayer games",
//     heading: "Gamescart - Online Multiplayer games",
//     overview:
//       "Gamescart is All in one multiplayer games, users can play online games with their opponent or AI bot in absence of concurrent users, This game has 16 different games with different genres like,",
//     data: [
//       "Snake and Ladders",
//       "Solitaire",
//       "Hunterman Assassin",
//       "Injection Hitman - Knife hit",
//       "Tower attack - stack ball",
//       "Fire tank - Fire balls",
//       "Twisty Ball on road",
//       "Tic Tac Toe",
//     ],
//     data2: [
//       "Save the balloon - Rise up",
//       "Canon ball",
//       "Bubble shooter",
//       "Doctor crush",
//       "Jumping joker",
//       "Flappy Jump",
//       "Color ball switch",
//       "Gamescart Quiz",
//     ],
//     playStoreImage: playStoreImage,
//     features: [
//       "Login with Facebook login/Gamescart login/Guest login",
//       "Lucky Spin with Rewarded video and daily rewards",
//       "Single player and Multiplayer mode",
//       "In app Purchase",
//       "Remove ads function for VIP users",
//       "Share and earn Module",
//       "Suggestion Box for users",
//       "Admob integration",
//     ],
//     specification: [
//       { Technology: " Unity 3D" },
//       { Platform: "Android" },
//       { Genre: " All in one, Match-3 puzzle, Arcade, Casual" },
//       { Type: "Free to use with in-app purchases" },
//     ],
//     type: "Arcade & Casual games",
//     aLink: `#`,
//     dLink: `#`,
//     pLink: `#`,
//   },
// ];

// export default data;
import Solitaire from "../../assets/PortfolioImage/Solitaire.jpg";
import Rummy from "../../assets/PortfolioImage/Rummy.jpg";
import Teenpatti from "../../assets/PortfolioImage/Teen-patti.jpg";
import Snakeandladder from "../../assets/PortfolioImage/Snake-and-ladder.jpg";
import Blackjack from "../../assets/PortfolioImage/Blackjack.jpg";
import pokertexasholdem from "../../assets/PortfolioImage/poker-texas-holdem.jpg";
import CasinoAllinone from "../../assets/PortfolioImage/Casino-All-in-one.jpg";
import VivaSlotsVegas from "../../assets/PortfolioImage/Viva-Slots-Vegas.jpg";
import Ludo from "../../assets/PortfolioImage/Ludo.jpg";
import RouletteVegas from "../../assets/PortfolioImage/Roulette-Vegas.jpg";
import chess from "../../assets/PortfolioImage/chess.jpg";
import GalaxyWar from "../../assets/PortfolioImage/Galaxy-War.jpg";
import FormulaOne from "../../assets/PortfolioImage/Formula-One.jpg";
import Mathtime from "../../assets/PortfolioImage/Math-time.jpg";
import Schooldays from "../../assets/PortfolioImage/School-days.jpg";
import MetalBlackOPS from "../../assets/PortfolioImage/Metal-Black-OPS.jpg";
import ArmyCommando from "../../assets/PortfolioImage/Army-Commando.jpg";
import DesertsniperCommando from "../../assets/PortfolioImage/Desert-sniper-Commando.jpg";
import TYPERUN from "../../assets/PortfolioImage/TYPE-RUN.jpg";
import PushemallCrowdsmash from "../../assets/PortfolioImage/Pushem-all-Crowd-smash.jpg";
import Epicfunrace3D from "../../assets/PortfolioImage/Epic-fun-race-3D.jpg";
import shortpathrun from "../../assets/PortfolioImage/short-path-run.jpg";
import mballs2048 from "../../assets/PortfolioImage/mballs-2048.jpg";
import Firetank3D from "../../assets/PortfolioImage/Fire-tank-3D.jpg";
import Stackballtowerattack from "../../assets/PortfolioImage/Stack-ball-tower-attack.jpg";
import tictactoe from "../../assets/PortfolioImage/tic-tac-toe.jpg";
import HIDDENOBJECT from "../../assets/PortfolioImage/HIDDEN-OBJECT-1.jpg";
import Watersortcolorpuzzle from "../../assets/PortfolioImage/Water-sort-color-puzzle.jpg";
import PLUMBERPIPEOUT from "../../assets/PortfolioImage/PLUMBER-PIPEOUT.jpg";
import Wordsearch from "../../assets/PortfolioImage/Word-search.jpg";
import Blockpuzzle from "../../assets/PortfolioImage/Block-puzzle.jpg";
import FindthedifferenceSpot from "../../assets/PortfolioImage/Find-the-difference-Spot-it.jpg";
import HarvestGrasscut from "../../assets/PortfolioImage/Harvest-Grass-cut.jpg";
import Ballpool from "../../assets/PortfolioImage/8-Ball-pool.jpg";
import canonball from "../../assets/PortfolioImage/canon-ball.jpg";
import Doctorcrush from "../../assets/PortfolioImage/Doctor-crush.jpg";
import SoccerStriker from "../../assets/PortfolioImage/Soccer-Striker.jpg";
import BusSubwaysurfers from "../../assets/PortfolioImage/Bus-Subway-surfers.jpg";
import InjectionHitman from "../../assets/PortfolioImage/Injection-Hitman.jpg";
import RescueHeroPullpin from "../../assets/PortfolioImage/Rescue-Hero-Pull-pin.jpg";
import Bubbleshooterjungle from "../../assets/PortfolioImage/Bubble-shooter-jungle.jpg";
import HuntermanAssassin from "../../assets/PortfolioImage/Hunterman-Assassin.jpg";
import GamescartOnlinegames from "../../assets/PortfolioImage/Gamescart-Online-games.jpg";
import playStoreImage from "../../assets/PortfolioImage/google-play-png-logo-3799.png";
import AppStoreImage from "../../assets/PortfolioImage/app-store-png-logo-33116.png";
import GoogledriveImage from "../../assets/PortfolioImage/GOOGLE-DRIVE-NEW-ICON-modified.png";

const data = [
  {
    img: Solitaire,
    name: "Solitaire",
    overview:
      "This solitaire game is The Most Relaxing & Addictive Card Game. Solitaire games, also known as Patience in Europe (such as the United Kingdom, French, etc.), are Solitaire card games. Enjoy the best free and popular Solitaire card games in the world for free at any time and place! It's time to train your brain, challenge yourself and be a Solitaire or Patience master, try this classic and free Patience Solitaire card game! With beautiful graphics, fun animations, and free, offline play, our basic Solitaire is the best card game to pass the time.   ",
    playStoreImage: playStoreImage,
    features: [
      "Login with Facebook login/Mobile",
      "Two game mode for difficulty - Easy and Hard",
      "Offline game",
      "In app Purchase ",
      "Share and earn Module",
      "Undo, Hint, etc functions",
    ],

    specification: [
      { Technology: "Unity3D" },
      { Platform: "Android & iOS" },
      { Genre: "Card, Casual" },
      { Type: "2D, Stylized," },
    ],
    type: "Card & Casino games",
    aLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
    pLink: `https://play.google.com/store/apps/details?id=com.fassor.android.solitaire&hl=en&gl=US`,
  },
  {
    img: Rummy,
    name: "Rummy",
    overview:
      "Rummyland is a famous Indian card game! Play this variant with thousands of people online for free! With Rummyland you can invite your friends, family and new people to play this game where and whenever you want. Rummy is easy to learn, simple to play and will keep you hooked.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different variation - Point rummy, Pool Rummy & Point rummy",
      "Welcome bonus",
      "Practice mode",
      "Free to play with coin",
    ],

    specification: [
      { Technology: "Frontend - Unity 3D, backend - Node JS" },
      { Platform: "Android" },
      { Genre: "Card, Table, Casino, Online Multiplayer" },
      { Type: "Realistic, 2D" },
    ],
    type: "Card & Casino games",
    aLink: `https://download.rummylands.com/RummyLand-release.apk`,
    dLink: `https://download.rummylands.com/RummyLand-release.apk`,
    pLink: `https://download.rummylands.com/RummyLand-release.apk`,
  },
  {
    img: Teenpatti,
    name: "Teen-patti",
    overview:
      "Teen Patti with millions of real friends! It’s a traditional online multiplayer Indian poker game but with more advanced social and gaming functions than ever. Invite friends and play cards together! In Teen patti, you can play with online friends, family, or any player around the world. Play different variations like, Teen patti classic, Joker, AK 47 & Muflish.",
    // playStoreImage:
    //   playStoreImage,
    features: [
      "Different variation",
      "Welcome bonus",
      "Refer and earn",
      "Login with facebook and mobile",
      "Payment gateway integration",
    ],

    specification: [
      { Technology: "Frontend - Unity 3D, backend - Node JS" },
      { Platform: "Android" },
      { Genre: "Card, Table, Casino, Online Multiplayer" },
      { Type: "Realistic, 2D" },
    ],
    type: "Card & Casino games",
    aLink: `https://download.rummylands.com/RummyLand-release.apk`,
    dLink: `https://download.rummylands.com/RummyLand-release.apk`,
    pLink: `https://download.rummylands.com/RummyLand-release.apk`,
  },
  {
    img: Snakeandladder,
    name: "Snake and ladder Board game",
    overview:
      "This snakes and Ladders game is a fun and challenging dice game for the whole family and friends from Kanraj Infotech.Did you grow up playing board games with your friends, kids and family on game night? Or perhaps you grew up hearing your parents talk fondly about their favorite board games such as Snakes and Ladders game.",
    playStoreImage: GoogledriveImage,
    features: [
      "Login with Facebook login/Mobile",
      "Offline game",
      "In app Purchase",
      "Share and earn Module",
      "Undo feature",
      "Admob integration",
    ],

    specification: [
      { Technology: "Unity3D" },
      { Platform: "Android & iOS" },
      { Genre: "Board, Dice, Realistic, Casual" },
      { Type: "2D, Stylized," },
    ],
    type: "Card & Casino games",
    aLink: `https://play.google.com/store/apps/details?id=com.rma.snakeandladder`,
    dLink: `https://play.google.com/store/apps/details?id=com.rma.snakeandladder`,
    pLink: `https://play.google.com/store/apps/details?id=com.rma.snakeandladder`,
  },
  {
    img: Blackjack,
    name: "Blackjack",
    overview:
      "Blackjack - now you have the famous gambling game always with you - for free and offline. Play Blackjack like a pro without having to bet real money. Adapt the rules to your favorite casino, train your skills and become a blackjack pro yourself!",
    data: [
      "Play Blackjack without the use of real money",
      " Fully usable offline",
    ],
    playStoreImage: GoogledriveImage,
    features: [
      "Two different game mode - American & European",
      "Welcome bonus",
      "Refer and earn",
      "In app purchase",
      "Admob integration",
      "Premium - No ads version",
    ],

    specification: [
      { Technology: "Frontend - Unity 3D, backend - Node JS" },
      { Platform: " Android" },
      { Genre: " Table, Card, Casino, Single player,casual," },
      { Type: "Realistic, 2D" },
    ],
    type: "Card & Casino games",
    aLink: `https://play.google.com/store/apps/details?id=com.fassor.android.blackjack`,
    dLink: `https://play.google.com/store/apps/details?id=com.fassor.android.blackjack`,
    pLink: `https://play.google.com/store/apps/details?id=com.fassor.android.blackjack`,
  },
  {
    img: pokertexasholdem,
    name: "Poker - Texas Holdem",
    overview:
      "Poker All Day has a Texas holdem poker atmosphere, a lot of fun and poker games for free.Play online poker with friends and players all over the world. Experience real poker night experience and make new poker friends now! Poker All Day is a rising online star of the most popular poker game, Holdem Texas Poker. It provides an entertaining & interactive online Texas Holdem real poker experience for poker lovers! Use your skill to compete and win against",
    playStoreImage: playStoreImage,
    appStoreImage: AppStoreImage,
    features: [
      "Login with Facebook and Mobile",
      "Different themes for tables",
      "Lucky spin",
      "Welcome bonus",
      "Admob integration",
      "Refer and earn",
      "In app purchase",
    ],

    specification: [
      { Technology: "Frontend - Unity 3D, backend - Node JS" },
      { Platform: " Android" },
      { Genre: " Table, Card, Casino, Single player" },
      { Type: "Realistic, 2D" },
    ],
    type: "Card & Casino games",
    aLink: `https://apps.apple.com/us/app/poker-all-day-texas-holdem/id1144458302`,
    dLink: `https://apps.apple.com/us/app/poker-all-day-texas-holdem/id1144458302`,
    pLink: `https://play.google.com/store/apps/details?id=com.playwpt.mobile.poker`,
  },
  {
    img: CasinoAllinone,
    name: "Casino - All in one",
    overview:
      "Casino all in one is the best Casino game for real money games. Casino offers 25 games with great graphics, smooth gameplay, authentic odds, and betting. The best part is that the 25-in-1 Casino is has following games: Rummy, Teen Patti, Andar Bahar, Dragon vs Tiger, Ludo, 7 up down, Fruit Cutter, Spin 2 win, Roulette, Variation, ICC T20, Sports, Baccarat, Crash, Fruit line, Slot machine, Black jack, 10 cards, Poker, Zoo Roulette, Best of 5, Fishing Rush, Car Roulette.",
    playStoreImage: GoogledriveImage,
    features: [
      "All trending online games",
      "Fully custom Admin panel for game control",
      "Mobile, Facebook and guest login",
      "Lucky spin",
      "Banner from Admin panel",
      "AI - Bot for multiplayer game",
      "Leaderboard",
      "User Support system",
      "Refer and earn",
      "In app purchase",
    ],

    specification: [
      { Technology: "Frontend - Unity 3D, backend - Node JS" },
      { Platform: " Android" },
      { Genre: " Board, Casino, Card, Single player & multiplayer" },
      { Type: "Realistic, 2D" },
      {
        Demo: "https://rummysharejoy.com/?from_gameid=7304966&channelCode=100000",
      },
    ],
    type: "Card & Casino games",
    aLink: `https://rummysharejoy.com/`,
    dLink: `https://rummysharejoy.com/`,
    pLink: `https://rummysharejoy.com/`,
  },
  {
    img: VivaSlotsVegas,
    name: "Viva Slots Vegas",
    overview:
      "Viva Vegas Slots Casino Game with all your favorite free casino slot machines online! With 100+ LAS VEGAS SLOTS, free credit sweepstakes, online casino slots free promotions, NEW GAMES EVERY WEEK and free bonus slots, Viva Slots Vegas is the BEST classic casino slot machine game for users. Discover the best free slot games on mobile, in the palm of your hands – get ready to win the biggest online slot",
    playStoreImage: playStoreImage,
    appStoreImage: AppStoreImage,
    features: [
      "100+ Slots, unlock new slots by winning",
      "Different events set up from the Admin panel for users.",
      "Lucky spin",
      "Welcome bonus",
      "Admob integration",
      "Refer and earn",
      "In app purchase",
    ],
    specification: [
      { Technology: "Frontend - Unity 3D, backend - Node JS" },
      { Platform: " Android & iOS" },
      { Genre: "Table, Casino, Single player" },
      { Type: " Realistic, 2D" },
    ],
    type: "Card & Casino games",
    aLink: `https://apps.apple.com/us/app/viva-slots-vegas-slot-machines/id1017064541?mt=8`,
    dLink: `https://play.google.com/store/apps/details?id=com.RocketGames.ClassicSlots&hl=en&gl=US`,
    pLink: `https://play.google.com/store/apps/details?id=com.RocketGames.ClassicSlots&hl=en&gl=US`,
  },
  {
    img: Ludo,
    name: "Ludo",
    overview:
      "This Ludo is fun to play a multiplayer board game that can be played between 2, 3 or 4 players. It is the most popular and fun game to play with family and friends. Ludo is a mind refreshing game with its lucky dice rolls and strategic gameplay. This interesting 2D Ludo game has been around us for a long time as the best game to play in our spare time. We have developed a Ludo game for Real money and Normal coin, customize mode as per client requirement. We integrate Payment gateway in this game as per your requirement.",
    playStoreImage: GoogledriveImage,
    features: [
      "Main Feature - Ludo Mahasangram - Live championship",
      "Online Multiplayer",
      "Tournament",
      "Play with online friends",
      "Play with offline friends",
      "play with computer (smart AI) ( Practice and Normal )",
      "AI and Robot in all game modes available ( if required )",
      "Leaderboard",
      "Smooth game play",
      "Easy tournament access",
      "Emoji chat",
      "Text chat",
      "Refer and Earn module",
      "Lucky coupon and skims",
      "Profile selection with all facility",
      "login with Phone number, Facebook and Gmail",
      "Secured sign up and login with OTP, anti cheat facility",
      "Safe Withdraw and Admin friendly money transaction facility",
      "Admin Panel with full game access",
    ],
    specification: [
      { Technology: "Front end - Unity3D C# " },
      { Specification: " Node JS, Socket iO, Laravel" },
      { Platform: "Android, iOS & Web & Mobile browsers" },
      { Genre: "Abstract Strategy, Board,Casual, Multiplayer, Online" },
      { Type: " 2D, Semi realistic, Stylized, Real money & Normal coin" },
      { Numberofgamedeveloped: "12 Ludo games with different features" },
    ],
    Premiumfeature: [
      "Audio chat",
      "Buddy Request",
      "Token color selection",
      "Spin wheel",
      "Daily Bonus",
    ],
    type: "Card & Casino games",
    aLink: `https://drive.google.com/file/d/11wXr9yiG-xIii-LKV0QZ3c_kxyslunHV/view?usp=sharing`,
    dLink: `https://drive.google.com/file/d/11wXr9yiG-xIii-LKV0QZ3c_kxyslunHV/view?usp=sharing`,
    pLink: `https://drive.google.com/file/d/11wXr9yiG-xIii-LKV0QZ3c_kxyslunHV/view?usp=sharing`,
  },
  {
    img: RouletteVegas,
    name: "Roulette Vegas",
    overview:
      "Roulette Vegas Casino 2020 and experience premium casino gaming. Roulette game that will make you feel like you're in a real casino.No money risking, just a free roulette simulator. Master different strategies and improve your play with our statistics. Whole app is for offline use. Thrilling 3D casino to enjoy anywhere. Breathtaking experience.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different theme for Wheel and chips",
      "Lucky spin",
      "Admob integration",
      "Welcome bonus",
      "Refer and earn",
      "In app purchase",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android" },
      { Genre: " Table, Casino, Single player" },
      { Type: " Realistic, 2D" },
    ],
    type: "Card & Casino games",
    aLink: `https://play.google.com/store/apps/details?id=com.luckyjackpot.roulette.casino.games.free&hl=en_US`,
    dLink: `https://play.google.com/store/apps/details?id=com.luckyjackpot.roulette.casino.games.free&hl=en_US`,
    pLink: `https://play.google.com/store/apps/details?id=com.luckyjackpot.roulette.casino.games.free&hl=en_US`,
  },
  {
    img: chess,
    name: "Chess",
    heading: "Online Board game",
    overview:
      "Chess is a two-player strategy board game played on a checkerboard with 64 squares arranged in an 8×8 grid. Each player begins with 16 pieces: one king, one queen, two rooks, two knights, two bishops, and eight pawns. The objective is to checkmate the opponent's king by placing it under an inescapable threat of capture.Take no prisoners and protect your King at all costs! This Chess game is the best way to play the classic Chess board game on the web version.",

    features: [
      "Different play mode - Play Alone, Private room, Play with AI - with different levels of AI,",
      "Multiplayer mode",
      "Online chat with opponent",
      "In app purchase",
    ],
    specification: [
      { Technology: "HTML5, PHP 8.0, Database - MySQL, Framework - Laravel" },
      { Platform: " All browsers of Android, iOS, Windows" },
      { Genre: "Board, Abstract strategy, Single player & multiplayer, " },
      { Type: " Realistic, 2D" },
    ],
    type: "HTML5 games",
  },
  {
    img: GalaxyWar,
    name: "Galaxy War",
    heading: "Action shooter game",
    overview:
      "Galaxy War is an arcade space shooter game. Experience the thrill of adrenaline-packed aerial battles and engaging enemies in classic shoot 'em ups space fights! Play Galaxy war today and become an ace pilot to protect earth from waves after waves of aliens! Control the lone spaceship, shoot down each formation of aliens, and prevent the enemy forces from destroying all of mankind now!",

    features: [
      "Global Achievements and leaderboards",
      "Multiplayer mode",
      "Multiple ships for purchase",
      "Daily rewards",
      "In app purchase",
    ],
    specification: [
      { Technology: "HTML5, Construct 2&3" },
      { Platform: "All browsers of Android, iOS, Windows" },
      { Genre: "Action, shooter, Single player & multiplayer, Arcade" },
      { Type: "Stylized" },
    ],
    type: "HTML5 games",
  },
  {
    img: FormulaOne,
    name: "Formula One",
    heading: "Arcade Racing game",
    overview:
      "Formula one is a new addition of Web base car racing games with latest and new formula cars. Top speed formula car racing games is an astonishing idea in the world of car driving games where you are going to compete with your rivals in a car race. Do you love to play car race games with multiple players to become master of formula car stunt championship? Then this top speed formula car game will give you a fun racing experience. Put your hands on the steering wheel in a formula car racing car game and drive your motorsport car in this formula car racing game. You can race with a formula one racing car to win the title of best racer in this game.",

    features: [
      "Compatible with all browsers and servers",
      "Compatible with most of the devices, android, iOS and Windows",
      "Map during game play",
    ],
    specification: [
      { Technology: "HTML5/js, CreateJs" },
      { Platform: "All browsers of Android, iOS, Windows" },
      { Genre: "Arcade, Single player, Racing " },
      { Type: "2D, Stylized" },
    ],
    type: "HTML5 games",
  },
  {
    img: Mathtime,
    name: "Math time",
    heading: "Casual Education game",
    overview:
      "Basic Math Games is the perfect way to help children learn math skills the easy way! This math game for kids is super fun! Solve a wide variety of math puzzles, brain teasers, and brain math puzzles using nothing more than basic arithmetic. Pick up new skills in addition ➕, subtraction ➖, multiplication ✖️, and division.",

    features: [
      "4 Options available for finding correct answers",
      "Instructions for kids",
    ],
    specification: [
      { Technology: " Construct 2 and Construct 3, HTML5" },
      { Platform: "All browsers of Android, iOS, Windows" },
      { Genre: "Casual, Single player, Educational, Offline" },
      { Type: "2D, Stylized" },
    ],
    type: "Educational games",
  },
  {
    img: Schooldays,
    name: "School days",
    heading: "Casual Education game",
    overview:
      "“School day“ is a game for kids to play and learn. Enjoy fun school days! Back to school, kids! Go to school, grab your backpacks, enter a school bus and do not be late for a class. Play fun school games, like: Clean the bus, Alphabets, Dress up, Food, Picture Puzzle, Body parts, Paint, connect the dot. Play as a teacher, student, parents and more!",
    playStoreImage: GoogledriveImage,
    features: [
      "Different minigames and task for kids",
      "Instructions for kids",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android" },
      { Genre: "Casual, Single player, offline, Simulation" },
      { Type: "2D, Stylized" },
    ],
    type: "Educational games",
    aLink: `https://drive.google.com/file/d/1CVPAzpLLBLvOwDyTKUVQEiipwUiJ3JEG/view`,
    dLink: `https://drive.google.com/file/d/1CVPAzpLLBLvOwDyTKUVQEiipwUiJ3JEG/view`,
    pLink: `https://drive.google.com/file/d/1CVPAzpLLBLvOwDyTKUVQEiipwUiJ3JEG/view`,
  },
  {
    img: MetalBlackOPS,
    name: "Metal Black OPS",
    heading: "FPS Platformer game",
    overview:
      "Metal Black OPS is a first-person shooter with blocky graphics and fun competitive gameplay. Play an addictive online first-person shooter action game. Buy modern weapons, upgrade them, change the look of your weapon, as well as your character!",
    playStoreImage: GoogledriveImage,
    features: [
      "Different missions with increasing difficulty and enemies.",
      "Different location for missions",
      "Customize your character, Weapons and boosters",
      "Daily Quest",
      "Achievement",
      "In app purchase",
      "Difficulty levels - Normal, Hard and Crazy",
      "Daily Bonus",
      "Revive features - with Rewarded ads",
      "Unity Ads integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Action, Shooting, Single player, Platformer" },
      { Type: "2D, Stylized" },
    ],
    type: "Action/Shooting games",
    aLink: `https://drive.google.com/file/d/1batqP5b6_aivLc-sIOwhR1zoFLi_Omuk/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1batqP5b6_aivLc-sIOwhR1zoFLi_Omuk/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1batqP5b6_aivLc-sIOwhR1zoFLi_Omuk/view?usp=share_link`,
  },
  {
    img: ArmyCommando,
    name: "Army Commando",
    heading: "FPS Action game",
    overview:
      "Army Commando - Welcome into the new world of action-packed games with fps commando shooting missions, where you can play unlimited sniper gun and fps offline shooting game missions of gun strike games and gun shooting games. If you love to play a commando gun shooting game offline in a real commando game. This Gun Games - FPS Shooting Game is the best action game. In the advanced gun games of shooting games, you have to complete all fps commando missions to become the best commando in this gun strike game. This game offers you to play this sniper offline game and a huge collection of weapons for fighting against terrorist squads in different chapters and missions.",
    playStoreImage: GoogledriveImage,
    features: [
      "Three different chapter with different missions",
      "Different location of Cities, Underground tunnel, Forest etc.",
      "In app purchase",
      "Map Integration",
      "Daily Bonus",
      "Revive features - with Rewarded ads",
      "Unity Ads integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Action, Shooting, Single player, offline" },
      { Type: " Realistic, 2D" },
    ],
    type: "Action/Shooting games",
    aLink: `https://drive.google.com/file/d/1ljuXaIoDvgZ49GP5nJFEpjBDJTbEcASf/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1ljuXaIoDvgZ49GP5nJFEpjBDJTbEcASf/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1ljuXaIoDvgZ49GP5nJFEpjBDJTbEcASf/view?usp=share_link`,
  },
  {
    img: DesertsniperCommando,
    name: " Desert sniper Commando",
    heading: "FPS Action game",
    overview:
      "This snakes and Ladders game is a fun and challenging dice game for the wholDesert Sniper commando: Battleground Battlefield! Welcome to the Arena of Rules of Desert Sniper The best hunting game along with rules of sniper shooting. Modern Sniper guns are ready for hunting and Critical Sniper Strikes! Take the best Sniper gun duty! Complete all the sniper army strikes in an offline war game. Start FPS gunfire battleground survival games. Get Ready for Desert Sniper commando Game on new Battleground. e family and friends from Kanraj Infotech. Did you grow up playing board games with your friends, kids and family on game night? Or perhaps you grew up hearing your parents talk fondly about their favorite board games such as Snakes and Ladders game. Welcome to the sniper shooting arena in the Desert.",
    playStoreImage: GoogledriveImage,
    features: [
      "Two different mode - Mission mode & survival mode",
      "Different levels in mission mode",
      "Map Integration",
      "Revive features - with Rewarded ads",
      "Unity Ads integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: " Action, Shooting, Single player, offline" },
      { Type: "2D, Realistic" },
    ],
    type: "Action/Shooting games",
    aLink: `https://drive.google.com/file/d/1p_qDa3cG724RtZYgKlPRnxqjP_hWdm7v/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1p_qDa3cG724RtZYgKlPRnxqjP_hWdm7v/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1p_qDa3cG724RtZYgKlPRnxqjP_hWdm7v/view?usp=share_link`,
  },
  {
    img: TYPERUN,
    name: "Type Run 3D",
    heading: "Casual Trivia game",
    overview:
      "Race with other players by typing the correct answer to a specific question. The faster you type, the more chances you win, but it is not at all. Correct answers matter as well. Join this breathtaking typing race against quick sneaky opponents, go level by level to complete various missions, and become the best type runner. From simple typing games to tricky word trivia — this texting game has plenty of brain-busting and typing practice!",
    playStoreImage: GoogledriveImage,
    features: [
      "Different backgrounds for users interest",
      "350+ levels - with increasing difficulty",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android" },
      { Genre: "Trivia, Casual, Single player" },
      { Type: "2D, Stylized" },
    ],
    type: "Hyper-casual Games",
    aLink: `https://drive.google.com/file/d/19PmWX6-mnaVuh1VsaOJSVeLiGbEWHfXO/view`,
    dLink: `https://drive.google.com/file/d/19PmWX6-mnaVuh1VsaOJSVeLiGbEWHfXO/view`,
    pLink: `https://drive.google.com/file/d/19PmWX6-mnaVuh1VsaOJSVeLiGbEWHfXO/view`,
  },
  {
    img: PushemallCrowdsmash,
    name: "Push’em all - Crowd smash",
    heading: "Casual Action game",
    overview:
      "This game is Casual action game. Trace your way to the end of the level and push the enemies with your stick on the way! Move your character and make all the enemies fall down. Be careful with giant enemies and the fast ones, It's their turn to try to take you down! Throw away through your stick to push all the obstacle crowd to the sides, just throw them and complete each level. Before finishing a level, you must throw away all the crowds down and out, don't let yourself down off the towers until all the crowd is smashed down.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different backgrounds for users interest",
      "150+ levels - with increasing difficulty",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android" },
      { Genre: "Action, Casual, Single player, Abstract" },
      { Type: "2D, Stylized" },
    ],
    type: "Hyper-casual Games",
    aLink: `https://drive.google.com/file/d/140hNBNX5NKagXs58zkadJo82ILol-SGG/view`,
    dLink: `https://drive.google.com/file/d/140hNBNX5NKagXs58zkadJo82ILol-SGG/view`,
    pLink: `https://drive.google.com/file/d/140hNBNX5NKagXs58zkadJo82ILol-SGG/view`,
  },
  {
    img: Epicfunrace3D,
    name: "Epic fun race 3D",
    heading: "Racing game",
    overview:
      "Experience full parkour experience with different unique levels. Race with others, achieve levels , unlock new characters. Every level brings a new unique fun experience. Very easy to play. Hold to run, release to stop.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different backgrounds for users interest",
      "100+ levels - with increasing difficulty",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android" },
      { Genre: " Racing, Casual, Single player" },
      { Type: "2D, Stylized" },
    ],
    type: "Hyper-casual Games",
    aLink: `https://drive.google.com/file/d/1c7Lt_jnkdz_iv9hjB9PmDtJCg2vC0Z51/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1c7Lt_jnkdz_iv9hjB9PmDtJCg2vC0Z51/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1c7Lt_jnkdz_iv9hjB9PmDtJCg2vC0Z51/view?usp=share_link`,
  },
  {
    img: shortpathrun,
    name: "Short path run 3D",
    heading: "Racing game",
    overview:
      "Race against opponents, and stack up all the floorboards you can pick up along the way! If you're clever enough, get out of the way and build bridges and take shortcuts to get to the finish line before everyone else! Run, Jump, and complete the race.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different backgrounds for users interest",
      "100+ levels - with increasing difficulty",
      "20+ characters",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android" },
      { Genre: " Racing, Casual, Single player" },
      { Type: "2D, Stylized" },
    ],
    type: "Hyper-casual Games",
    aLink: `https://drive.google.com/file/d/1yZhyLTOMp0MtWhyRKNThxVEjs283MuQW/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1yZhyLTOMp0MtWhyRKNThxVEjs283MuQW/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1yZhyLTOMp0MtWhyRKNThxVEjs283MuQW/view?usp=share_link`,
  },
  {
    img: mballs2048,
    name: "2048 Merge balls",
    heading: "Number merge puzzle game - Real money",
    overview:
      "Mballs 2048 is the most trending stack number ball puzzle game with smooth gameplay, which lets you think out of the box and sharpen your mind with numbers games. Merge endless number puzzle game! Unlimited play with no purchase required. Interesting features of hammer and swap. Get a $50 bonus on the first 1M ball, and then $5 for every new ball like, 2M, 4M, 8M…. Start with 2 and reach 16, 32, 128, 512, 1024, 2048 and higher Fun and addictive 2048 number puzzle game! Merge ball to get the highest score! 2048 Merge Endless is the classic 2048 puzzle game. Merge blob to get bigger number and merge them to reach 2048",
    playStoreImage: playStoreImage,
    features: [
      "Real money game",
      "Hammer and swap ball feature",
      "Leaderboard",
      "Admob integration",
      "Increasing difficulty with level ups",
      "Unlimited game play",
      "Share and earn model",
    ],
    specification: [
      { Technology: " Unity 3D" },
      "Admin panel and backend - PHP laravel",
      { Platform: "Android & iOS" },
      { Genre: "Puzzle, Merge, Casual, Single player, Abstract" },
      { Type: " 2D, Online, Real money game" },
    ],
    type: "Hyper-casual Games",
  },
  {
    img: Firetank3D,
    name: "Fire tank 3D",
    heading: "Hyper casual game - online multiplayer",
    overview:
      "Fire Tank 3D is a single-tap hyper casual game that will keep you hooked for hours! Hold to make your dot fire forward, and be careful from moving obstacles. The game features unlimited levels and 20 beautiful color themes.",
    playStoreImage: playStoreImage,
    features: [
      "Offline single player & online multiplayer game mode",
      "Different skins of Tank",
      "Admob integration",
      "Increasing difficulty with level ups",
      "Unlimited levels",
      "Share and earn model",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Casual, Hyper casual, Single player, Multiplayer, Abstract" },
      { Type: "2D, Online & offline" },
    ],
    type: "Hyper-casual Games",
  },
  {
    img: Stackballtowerattack,
    name: "Stack ball - tower attack",
    heading: "Most popular Hyper casual game",
    overview:
      "Stack Ball - Tower Attack is a 3d arcade game where players smash, bump and bounce through revolving helix platforms to reach the end. Your ball smashes like a brick through colorful platforms that block its descent, but if you hit a black one, it's all over! Your ball shatters to pieces and you have to start your fall all over again. But even black platforms are no match for a fireball falling at full speed! Choose your strategy: speed up like a madman",
    playStoreImage: playStoreImage,
    features: [
      "Offline single player & online multiplayer game mode",
      "Admob integration",
      "Increasing difficulty with level ups",
      "Unlimited levels",
      "Share and earn model",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: " Arcade, Hyper casual, Single player, Multiplayer, Abstract" },
      { Type: "2D, Online & offline" },
    ],
    type: "Hyper-casual Games",
  },
  {
    img: tictactoe,
    name: "Tic Tac Toe",
    heading: "Board game",
    overview:
      "This game has the smartest AI and 2 player mode that can satisfy all your needs. You will feel amazing with the glow effect and cool animation. The AI of this game is one of the smartest you can find, you can play with it for hours without getting bored. You can play with your friend on the same phone as well as with smart AI.",
    playStoreImage: playStoreImage,
    features: [
      "Offline single player & multiplayer game mode",
      "Play with AI",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "  Board, Puzzle, Multiplayer, Abstract strategy  " },
      { Type: " 2D, offline" },
    ],
    type: "Trivia/Puzzle games",
  },
  {
    img: HIDDENOBJECT,
    name: "Hidden objects",
    heading: "Picture puzzle game",
    overview:
      "Start hunting for hidden objects in this game. Hidden Objects is a new kind of search and find hidden object casual games. It's an adventure casual game where you'll be searching for hidden objects and finding them! Prepare for a great hidden object journey! On each level you'll have to find missing objects: seek and find highlighted.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different rooms for find the objects",
      "Difficulty level - Normal, Shadow and fix time challenge",
      "Hint feature - with Rewarded ads",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: " Puzzle, Casual, Single player, Adventure" },
      { Type: "  2D, Stylized, Realistic" },
    ],
    type: "Trivia/Puzzle games",
    aLink: `https://drive.google.com/file/d/1-7t_m0vaxobc6HYkEt4_daufnty--sPw/view`,
    dLink: `https://drive.google.com/file/d/1-7t_m0vaxobc6HYkEt4_daufnty--sPw/view`,
    pLink: `https://drive.google.com/file/d/1-7t_m0vaxobc6HYkEt4_daufnty--sPw/view`,
  },
  {
    img: Watersortcolorpuzzle,
    name: "Water sort - color puzzle",
    heading: "Color sort puzzle game",
    overview:
      "This water sort color puzzle game is simple but addictive, fun, and challenging ! It is the best free water sorting puzzle game for you to train your brain, kill free time and relax! If you’d like to train your combinational logic, this water sort puzzle game is just for you! It’s the most relaxing and challenging puzzle game, and it’s not timed.Try to sort the colored water in the glasses until all colors are in the same glass. A challenging yet relaxing game to exercise your brain!",
    howtoplay: [
      " Tap any glass to pour water to another glass.",
      "The rule is that you can only pour the water if it is linked to the same color and there’s enough space on the glass.",
    ],
    playStoreImage: GoogledriveImage,
    features: ["Difficulty levels increase with levels", "Admob integration"],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: " Android & iOS" },
      { Genre: " Puzzle, Casual, Single player" },
      { Type: "2D, Stylized, offline" },
    ],
    type: "Trivia/Puzzle games",
    aLink: `https://drive.google.com/file/d/17PTxWk2yWYst-vIeG_zAKInzqzNhChd3/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/17PTxWk2yWYst-vIeG_zAKInzqzNhChd3/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/17PTxWk2yWYst-vIeG_zAKInzqzNhChd3/view?usp=share_link`,
  },
  {
    img: PLUMBERPIPEOUT,
    name: "Plumber - Pipeout",
    heading: "Connect the pipe puzzle game",
    overview:
      "Plumber - Pipeout is one of the best puzzle games in the market and without a doubt the best pipe connection game. All you need to do is to rotate pipes, connect them and make a working pipeline. Touch the pipes to turn them. Construct a water path from valve to container. Solve puzzles fast and get more experience points. You can always use hints if you have got stuck in some level.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different difficulty levels like, Easy, Medium, Advance, Hard and Expert",
      "Hint and Undo Features",
      "Game statistics for user in Time and level",
      "Level skip option for Hard levels",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: " Android & iOS" },
      { Genre: " Puzzle, Casual, Single player" },
      { Type: "2D, Stylized, offline" },
    ],
    type: "Trivia/Puzzle games",
    aLink: `https://drive.google.com/file/d/1Il5gOLHQGnnQbTnJ_EHqPf7wDzpzcM2D/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1Il5gOLHQGnnQbTnJ_EHqPf7wDzpzcM2D/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1Il5gOLHQGnnQbTnJ_EHqPf7wDzpzcM2D/view?usp=share_link`,
  },
  {
    img: Wordsearch,
    name: "Word search",
    heading: "Word search puzzle game",
    overview:
      "Word Search Puzzle is a classic word search game! Swipe to match letters. Find the right letters into many different words.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different difficulty levels",
      "Special bonus levels for extra coins",
      "Hint button and get the first letter of the word",
      "Daily Reward",
      "Admob integration",
      "Unlimited game play",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: " Puzzle, Word, Casual, Single player" },
      { Type: "2D, Offline, Realistic" },
    ],
    type: "Trivia/Puzzle games",
    aLink: `https://drive.google.com/file/d/1OaAi79BEs6uGKLovpW4r9zYSTOsoJlKr/view`,
    dLink: `https://drive.google.com/file/d/1OaAi79BEs6uGKLovpW4r9zYSTOsoJlKr/view`,
    pLink: `https://drive.google.com/file/d/1OaAi79BEs6uGKLovpW4r9zYSTOsoJlKr/view`,
  },
  {
    img: Blockpuzzle,
    name: "Block puzzle",
    heading: "Wood block puzzle game",
    overview:
      "Block Puzzle - Classic Block Puzzle Game, is a classic addictive wooden style block puzzle game. Qblock challenges you to fit blocks of different shapes. Wood Block Puzzle is a real classic, totally elimination game. Block Puzzle -classic wood block puzzle game is a Tetris like block puzzle game, with woody style.",

    features: [
      "3 Game mode - Classic, Timed, Bomb mode",
      "Booster - Block Removal and Undo",
      "Admob integration",
      "Increasing difficulty",
      "Unlimited game play",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: " Android, iOS & Web " },
      { Genre: " Puzzle, Casual, Single player" },
      { Type: " 2D, Realistic" },
    ],
    type: "Trivia/Puzzle games",
  },
  {
    img: FindthedifferenceSpot,
    name: "Find the difference - Spot it",
    heading: "Picture puzzle game",
    overview:
      "Enjoy a brand new find differences game for kids and adults! Spot the difference between 2 identical pictures and train your brain by playing the find differences puzzle games with different levels! This game with easy & hard levels will surely make a difference in your life. Feel yourself like a true detective. Scout both of the pictures, use hints and magnifier glass and find as many hidden differences as possible.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different difficulty levels",
      "Hint and Magnifier glass feature",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Puzzle, Casual, Single player" },
      { Type: "2D, Stylized" },
    ],
    type: "Trivia/Puzzle games",
    aLink: `https://drive.google.com/file/d/1Pg1S6PKvtGDa0MWiBa1xjja5XvndSDsY/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1Pg1S6PKvtGDa0MWiBa1xjja5XvndSDsY/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1Pg1S6PKvtGDa0MWiBa1xjja5XvndSDsY/view?usp=share_link`,
  },
  {
    img: HarvestGrasscut,
    name: "Harvest Grass cut",
    heading: "Casual simulation game",
    overview:
      "Clear the ground by grass cutter and complete the level and move to the next game level, difficulty will be increased with levels.",
    playStoreImage: GoogledriveImage,
    features: [
      "Offline single player",
      "Admob integration",
      "Infinite levels with increasing difficulty",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "  Casual, single player " },
      { Type: "2D, Stylized," },
    ],
    type: "Arcade & Casual games",
    aLink: `https://drive.google.com/file/d/1HcqVkTyzHpSktTKsyA7JTWxnmxIdhJDD/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1HcqVkTyzHpSktTKsyA7JTWxnmxIdhJDD/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1HcqVkTyzHpSktTKsyA7JTWxnmxIdhJDD/view?usp=share_link`,
  },
  {
    img: Ballpool,
    name: "8 Ball pool",
    heading: "Casual Puzzle online multiplayer game",
    overview:
      "8 Ball Infinity is one of the 8 ball pool multiplayer games with online chat in the Android market. You can play with your online friends, compete with players all over the world! Shooting balls! Enjoy the modern arcade style of 3D 8 pool game!Here we have a unique 8 ball pool online game in live chat and exquisite pool tables! You can also start with a beginner tutorial. Enjoy the real-time multiplayer game.",
    playStoreImage: playStoreImage,
    appStoreImage: AppStoreImage,
    features: [
      "Tutorial for beginners",
      "Online multiplayer game mode & facebook friend invitation",
      "Players History & profile",
      "Daily Rewards",
      "Global Leaderboard",
      "Different theme of tables and skins for cue sticks",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: " Sports, Table, Arcade, Puzzle, Online" },
      { Type: "2D, Realistic" },
    ],
    type: "Arcade & Casual games",
    aLink: `https://apps.apple.com/us/app/infinity-8-ball-pool-king/id1609032238`,
    dLink: `https://play.google.com/store/apps/details?id=com.sports.eightball.pool.rival`,
    pLink: `https://play.google.com/store/apps/details?id=com.sports.eightball.pool.rival`,
  },
  {
    img: canonball,
    name: "Canon ball",
    heading: "Casual Puzzle online multiplayer game",
    overview:
      "Fill all the buckets with balls to complete a level. Use your finger to move various objects to change the direction of the balls you shoot. Aim smart!",
    playStoreImage: playStoreImage,
    features: [
      "Offline single player & online multiplayer game mode",
      "Admob integration",
      "Increasing difficulty with level ups",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Casual, Single player, Puzzle  " },
      { Type: " 2D, offline" },
    ],
    type: "Arcade & Casual games",
  },
  {
    img: Doctorcrush,
    name: "Doctor crush",
    heading: "Match 3 Puzzle game",
    overview:
      "Master the legendary match 3 puzzle game from Kanraj Infotech! Doctor Crush is the best 3 puzzle game for users. Match, pop, and blast Medical kit objects in this medical puzzle adventure to progress to the next level and get a star! Matches 3 puzzles with quick thinking and smart matching moves to be rewarded with bonuses and kit combos. You will adventure with many kits, objects, CRUSH, & MATCH three or more objects and blast your way through hundreds of FUN levels in this FREE puzzle game.",
    playStoreImage: playStoreImage,
    features: [
      "Offline single player",
      "Admob integration",
      "Increasing difficulty with level ups",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: " Casual, Single player, Puzzle, Match 3" },
      { Type: "2D, Stylized, offline" },
    ],
    type: "Arcade & Casual games",
  },

  {
    img: SoccerStriker,
    name: "Soccer Striker",
    heading: "Casual multiplayer game",
    overview:
      "Shoot the ball with simple finger control! You can compete with global users through an online match system. Online league, Cup mode, Trick shot challenge mode, Training mode supported",
    data: ["1,000 Trick Shot Challenge"],
    playStoreImage: GoogledriveImage,
    features: [
      "Offline single player & Multiplayer mode",
      "Tournament mode & Penalty Kicks",
      "Create New team, New formation",
      "Admob integration",
      "Infinite levels with increasing difficulty",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Sports, Casual, single player & multiplayer, Abstract" },
      { Type: "2D, Stylized, Physics" },
    ],
    type: "Arcade & Casual games",
    aLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1J7KuQX0QIRRgVojET7eCu59HtED-qmMl/view?usp=share_link`,
  },
  {
    img: BusSubwaysurfers,
    name: "Bus Subway surfers",
    heading: "Arcade endless runner game",
    overview:
      "Bus Subway surfers Runner, also known as Bus and subway, is an endless running game that appeals to hundreds of thousands of players. Run as fast as you can and dodge the trains or buses that are about to collide with you. Be careful of the rolling log! To dodge obstacles and collect coins, you need to take great reflexes to move, jump and slide.",
    playStoreImage: GoogledriveImage,
    features: [
      "Facebook login",
      "Offline single player & Online Multiplayer game mode",
      "Different game modes like, Mission mode, challenge mode, etc.",
      "Record video of your great moments",
      "Different boosters and life hacks",
      "In app Purchase ",
      "Different Avatars of Ninja, Boy, girl etc skins",
      "Share and earn Module",
      "Admob integration",
      "Endless with increasing difficulty",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Arcade, single player, Online Multiplayer, Runner, " },
      { Type: "2D, Stylized," },
    ],
    type: "Arcade & Casual games",
    aLink: `https://drive.google.com/file/d/1Ya73LZCcGlBDr3s5kaMaeOxMQ2ipHij4/view`,
    dLink: `https://drive.google.com/file/d/1Ya73LZCcGlBDr3s5kaMaeOxMQ2ipHij4/view`,
    pLink: `https://drive.google.com/file/d/1Ya73LZCcGlBDr3s5kaMaeOxMQ2ipHij4/view`,
  },
  {
    img: InjectionHitman,
    name: "Knife hit injection",
    heading: "Casual Online Multiplayer game",
    overview:
      "Throw the Injection into the Corona virus to break them. Slash the medicine capsule and unlock new knives. Each 5th stage is defended by a boss - beat them to get exclusive Injections.! Be careful to not hit the injections or the spikes. Time your actions, target carefully and become the Injection Hitman!",
    playStoreImage: playStoreImage,
    features: [
      "Login with Facebook login/Mobile",
      "Offline single player & Online Multiplayer game mode",
      "In app Purchase ",
      "Different Injection, Corona and Boss skins",
      "Share and earn Module",
      "Admob integration",
      "Infinite levels with increasing difficulty",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Casual, single player, Online Multiplayer, " },
      { Type: " 3D, Stylized," },
    ],
    type: "Arcade & Casual games",
  },
  {
    img: RescueHeroPullpin,
    name: "Rescue Hero - Pull pin",
    heading: "Casual puzzle game",
    overview:
      "Rescue Hero - Pull pin is about helping the hero save the princess in the best pin rescue game. You have a task to pull the pin puzzle, and then kill monsters, rescue the princess and collect treasure. The beautiful princess and golden treasure are waiting for you to rescue in the Rescue Hero game, Train your intelligence by playing pull the pin puzzle game, just you can answer all our pin puzzles.",
    playStoreImage: GoogledriveImage,
    features: [
      "Different missions and task",
      "150+ levels - with increasing difficulty",
      "Admob integration",
      "Daily bonus",
      "Different skins for Hero",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Casual, Single player, offline" },
      { Type: "2D, Stylized" },
    ],
    type: "Arcade & Casual games",
    aLink: `https://drive.google.com/file/d/1cSAle0FUyJnOZ7FwJrqJZz9G9quCpqFW/view?usp=share_link`,
    dLink: `https://drive.google.com/file/d/1cSAle0FUyJnOZ7FwJrqJZz9G9quCpqFW/view?usp=share_link`,
    pLink: `https://drive.google.com/file/d/1cSAle0FUyJnOZ7FwJrqJZz9G9quCpqFW/view?usp=share_link`,
  },
  {
    img: Bubbleshooterjungle,
    name: "Bubble shooter jungle",
    heading: "Casual bubble shooter jungle theme",
    overview:
      "This bubble shooter game is based on a jungle theme. Match 3 to blast & drop colorful balls in this addictive bubble shooter game! Explore new levels and blast your way to the top! Use your bubble shooter to break different balls, collect gems, and more as you challenge various levels!",
    howtoplay: [
      "Use the guiding line to aim your shooter and blast bubbles!",
      "Match 3+ bubbles & shoot to make them pop!",
    ],
    playStoreImage: playStoreImage,
    features: [
      "Login with Facebook login/Mobile",
      "Offline single player game mode",
      "In app Purchase ",
      "Share and earn Module",
      "Swap the ball option",
      "300+ game levels with increasing difficulty",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Casual, Puzzle, offline, single player" },
      { Type: "2D, Stylized," },
    ],
    type: "Arcade & Casual games",
  },
  {
    img: HuntermanAssassin,
    name: "Hunterman Assassin",
    heading: "Casual survival action game",
    overview:
      "Hunerman Assassin is a Casual Action game which is popular among the users in 2019-20, it was the most trending game. Control the assassin and hunt down your targets one by one. Use your surroundings and shadows to stay hidden from flashlights. You're an assassin, trained to use shadows and your surroundings to defeat your enemies. Each eliminated target drops valuable items. Use them to unlock and upgrade new weapons and heroes. Make strategic decisions on how to avoid being cornered by your enemies, and move swiftly to evade their flashlights. Targets with automatic machine guns can be lurking around each corner. Attack them swiftly and escape without being spotted.",
    playStoreImage: playStoreImage,
    features: [
      "Login with Facebook login/Mobile",
      "Online Multiplayer & Offline single player game mode",
      "Different character and skin",
      "In app Purchase ",
      "Admob integration",
      "Share and earn Module",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android & iOS" },
      { Genre: "Casual, Single player, Multiplayer, Action, shooter" },
      { Type: "2D, Stylized," },
    ],
    type: "Arcade & Casual games",
  },
  {
    img: GamescartOnlinegames,
    name: "Gamescart - Online Multiplayer games",
    heading: "Gamescart - Online Multiplayer games",
    overview:
      "Gamescart is All in one multiplayer games, users can play online games with their opponent or AI bot in absence of concurrent users, This game has 16 different games with different genres like,",
    data: [
      "Snake and Ladders",
      "Solitaire",
      "Hunterman Assassin",
      "Injection Hitman - Knife hit",
      "Tower attack - stack ball",
      "Fire tank - Fire balls",
      "Twisty Ball on road",
      "Tic Tac Toe",
    ],
    data2: [
      "Save the balloon - Rise up",
      "Canon ball",
      "Bubble shooter",
      "Doctor crush",
      "Jumping joker",
      "Flappy Jump",
      "Color ball switch",
      "Gamescart Quiz",
    ],
    playStoreImage: playStoreImage,
    features: [
      "Login with Facebook login/Gamescart login/Guest login",
      "Lucky Spin with Rewarded video and daily rewards",
      "Single player and Multiplayer mode",
      "In app Purchase",
      "Remove ads function for VIP users",
      "Share and earn Module",
      "Suggestion Box for users",
      "Admob integration",
    ],
    specification: [
      { Technology: " Unity 3D" },
      { Platform: "Android" },
      { Genre: " All in one, Match-3 puzzle, Arcade, Casual" },
      { Type: "Free to use with in-app purchases" },
    ],
    type: "Arcade & Casual games",
    aLink: `#`,
    dLink: `#`,
    pLink: `#`,
  },
];

export default data;
