import React, { useState } from "react";
import Background from "../../../assets/3DGame/1-3D-Developers-Banner.jpg";
import Banner from "../Banner";
import CountUp from "react-countup";
import Unity3D from "../../../assets/3DGame/1-3D-GameWeb-1.webp";
import Unity from "../../../assets/3DGame/2-3D-GameWeb-pyiyny5pzvll5bu54dkpjew1b2v9hi2tcvxutoi2ts.webp";
import Extended from "../../../assets/3DGame/3-3D-GameWeb-pyiynz3k6pmvgxsryvzc3wnhwgqmp76jp0lcaygonk.webp";
import Realmoney from "../../../assets/3DGame/4-3D-GameWeb-pyiyo01edjo5sjretedyoeeyhulzwwaa158ts8fahc.webp";
import Typeofgames from "../Typeofgames";
import Action from "../../../assets/3DGame/1-action-game.png";
import Sports from "../../../assets/3DGame/2-sport-game.png";
import Arcade from "../../../assets/3DGame/3-Arcade.png";
import Adventure from "../../../assets/3DGame/4-Adventure.png";
import Money from "../../../assets/3DGame/5-Real-Money.png";
import Arvr from "../../../assets/3DGame/6-AR-VR.png";
import Puzzle from "../../../assets/3DGame/7-Puzzle.png";
import Casual from "../../../assets/3DGame/8-casual-game-1.png";
import Cardimg from "../../../assets/3DGame/9-card-game.png";
import Racing from "../../../assets/3DGame/10-race-game.png";
import Bord from "../../../assets/3DGame/11-board-game.png";
import Mmo from "../../../assets/3DGame/rpg-game-2.png";
import SliderData from "./SliderData";
import Infotech from "../../../assets/3DGame/5-3D-GameWeb.webp";
import Accordiondata from "../../Accordiondata";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  const gamesType = [
    {
      img: Action,
      title: "Action",
    },
    {
      img: Sports,
      title: "Sports",
    },
    {
      img: Arcade,
      title: "Arcade",
    },
    {
      img: Adventure,
      title: "Adventure",
    },
    {
      img: Money,
      title: "Real Money",
    },
    {
      img: Arvr,
      title: "AR / VR",
    },
    {
      img: Puzzle,
      title: "Puzzle",
    },
    {
      img: Casual,
      title: "Casual",
    },
    {
      img: Cardimg,
      title: "Card",
    },
    {
      img: Racing,
      title: "Racing",
    },
    {
      img: Bord,
      title: "Bord",
    },
    {
      img: Mmo,
      title: "MMO / RPG",
    },
  ];

  return (
    <div className='portfolio-item'>
      <SEOComponent
        title='Unity Game Development Company -  Kanraj Infotech'
        description='Experience the boundless world of Unity3D gaming. Kanraj Infotech is a one-stop destination for all Unity3D Game Development services. Redefine your gaming journey Now.'
        url='https://kanraj.com/unity3D'
      />
      <Banner banner={Background} text={"Unity 3D and Game Development"} />
      <div className='container'>
        <h6
          style={{
            color: "#078586",
            fontSize: "20px",
            fontWeight: "700",
            textDecoration: "underline",
            textDecorationColor: "#078586",
          }}>
          Game Development
        </h6>
        <h2 style={{ fontWeight: "700" }}>
          Unity 3D Game Development services
        </h2>
        <p style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
          Kanraj Infotech is a leading Unity Game Development Company that helps
          you turn your concepts into an interesting and amazing game. Our
          certified Unity Game Developers can efficiently develop astonishing
          cross-platform unity games packed with high-quality graphics using the
          latest trends and technologies.
        </p>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "60px", color: "#39C4A8" }}>
              <CountUp start={0} end={2} duration={3} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game designers
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "60px", color: "#39C4A8" }}>
              <CountUp start={0} end={20} duration={3} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game developers
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "60px", color: "#39C4A8" }}>
              <CountUp start={0} end={300} duration={3} />+
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Gaming projects
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 style={{ fontWeight: "700" }}>Top Unity3D Developers</h3>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Kanraj Infotech is a one-stop destination for all Unity3D Game
              Development services. We have a team of skilled Unity3D developers
              who have specialisation in customer game development catered
              towards the client’s needs. Our developers deliver unmatched unity
              gaming experience to the users.
            </p>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              We create business-oriented and user-focused apps for all the
              popular games, including football, cricket, basketball, tennis,
              hockey, kabaddi, etc. that not only attract users but also
              generate hefty returns. Share your requirements and leave the rest
              on us to create, ideate, and strategize.
            </p>
            <span
              style={{ color: "#39C4A8", fontWeight: "700", fontSize: "20px" }}>
              Next Generation Gaming Engine
            </span>
            <h3 style={{ fontWeight: "700" }}>Unity Game Engine</h3>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Unity is an open-source, cross-platform, game development engine
              that is used to develop 2D and 3D games. Developed by Unity
              Technologies; it supports more than 25 platforms and is considered
              as the best mobile game development engine. With Unity, anyone can
              develop any game that can be imagined.
            </p>
          </div>
          <div className='col-md-6 d-flex'>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={Unity3D}
                alt=''
                height={"450px"}
                width={"100%"}
                style={{ borderRadius: "12px" }}
              />
            </div>
          </div>
        </div>
        <div className='row py-5'>
          <h3 className='text-center' style={{ fontWeight: "700" }}>
            Unity3D Game Development Services
          </h3>
          <p style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
            The motto of Unity is to “Build once and publish everywhere”. With
            this, you can get a game or app developed, and then you can launch
            it over any platform that you want. Kanraj Infotech is a Unity3D
            Game and App development company that takes advantage of this
            platform to develop various mobile apps and games that provide a
            rich experience that completely immerses the user.
          </p>
        </div>

        <div className='row mb-3'>
          <div
            className='col-md-4'
            style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Unity}
              alt=''
              height={"220px"}
              width={"80%"}
              style={{ borderRadius: "12px" }}
            />
          </div>
          <div className='col-md-8 pt-4'>
            <h5 style={{ fontWeight: "700" }}>Unity 3D Games</h5>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Our developers develop engaging mobile games that are not just a
              visual treat, but they make the gameplay so remarkable that the
              users will not be able to stop themselves from playing your game.
              Whether you want a single-player game or a multiplayer game with
              advanced features, You just say the game, and we will develop
              exactly what you imagined.​
            </p>
          </div>
        </div>
        <div className='row mb-3'>
          <div
            className='col-md-4'
            style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Extended}
              alt=''
              height={"220px"}
              width={"80%"}
              style={{ borderRadius: "12px" }}
            />
          </div>
          <div className='col-md-8 pt-4'>
            <h5 style={{ fontWeight: "700" }}>Extended AR/VR Support</h5>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              When the latest technologies like AR/VR are used in the app and
              game development process, you get apps and games that provide the
              most satisfactory results. Our Unity Developers follow creative
              designing and technically advanced tools VR/AR mobile games and
              apps. Our AR/VR games and apps can transform your idea into a
              digital reality.
            </p>
          </div>
        </div>
        <div className='row'>
          <div
            className='col-md-4'
            style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Realmoney}
              alt=''
              height={"220px"}
              width={"80%"}
              style={{ borderRadius: "12px" }}
            />
          </div>
          <div className='col-md-8 pt-4'>
            <h5 style={{ fontWeight: "700" }}>Real Money Gambling Games</h5>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Gambling games are no fun if real money is not involved. There is
              a huge demand out there for real money games, and at Kanraj
              Infotech, we have a track record of successful real money gambling
              game development. The game has all the necessary features, along
              with various payment getaways.
            </p>
          </div>
        </div>
      </div>
      <div className='container-fluid my-4' style={{ background: "#009688" }}>
        <div className='container text-white py-4'>
          <span
            style={{
              fontFamily: "sans-serif",
              fontWeight: "700",
              fontSize: "22px",
            }}>
            We are Passionate About
          </span>
          <h3 className='pt-3' style={{ fontWeight: "800" }}>
            Unity Game Solutions
          </h3>
          <p
            className='pt-2'
            style={{ textAlign: "justify", fontSize: "18px" }}>
            Rich-featured and Visually Stunning Games
          </p>
          <p style={{ textAlign: "justify", fontSize: "18px" }}>
            Balanced Sound and Fantastic Graphics Effects
          </p>
          <p style={{ textAlign: "justify", fontSize: "18px" }}>
            Develops the game with an enriched story pattern that keeps the
            gamers engaged for a longer time.
          </p>
          <p style={{ textAlign: "justify", fontSize: "18px" }}>
            Complete the project within the time estimated prior and fulfill the
            requirements of our clients by creating a striking virtual world
            that keeps the gamers engaged.
          </p>
          <p style={{ textAlign: "justify", fontSize: "18px" }}>
            We charge the most comprehensive price in the industry for Unity
            game development service.
          </p>
        </div>
      </div>
      <div className='container'>
        <h2 className='text-center fw-bold'>Genre of Games We Serve</h2>
        <p>
          We use robust tools and technologies to transform your idea into a
          chartbuster game. Here are the different categories of games which we
          are developing.
        </p>

        <div className='row'>
          {gamesType?.map((item, index) => {
            return (
              <div className='col-md-3' key={index}>
                <Typeofgames image={item?.img} title={item?.title} />
              </div>
            );
          })}
        </div>
        <h2 className='text-center fw-bold pt-4' style={{ fontWeight: "700" }}>
          Unity 3D Game Development Advantages
        </h2>
        <p style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
          Unity is a game development engine that is packed with assets and
          features that allows you to shape your ideas into a perfect game. The
          power of the engine allows the developers to develop games that have
          fabulous graphics, epic feel, and astonishing storyline. Here are some
          features that makes Unity3D the best Game Development Engine:
        </p>
      </div>
      <SliderData />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 style={{ fontWeight: "700" }}>
              Why is Kanraj Infotech the best Unity game development company?
            </h3>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              Kanraj Infotech is a pioneer in the game development field. Our
              Unity game solutions provide the most effective environment for
              developing Unity games with attractive visuals and interactive
              gameplay. We have a team of certified and experienced Unity game
              developers who develop rich and unique games that will keep the
              users engaged for hours. We offer a full range of UI designs and
              character designs for your Unity game.
            </p>
            <p
              style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
              It is the forte of our magnificent Unity Game Developer to develop
              highly interactive and additive Unity games. We have a well-known
              track record of developing games infused with engaging storyline,
              stunning graphics, appealing backgrounds, and rich features, and
              thus all our games provide the ultimate gaming experience to the
              users.
            </p>
          </div>
          <div className='col-md-6'>
            <img
              src={Infotech}
              alt=''
              height={"450px"}
              width={"100%"}
              style={{ borderRadius: "10px", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <div className='container-fluid' style={{ background: "#009688" }}>
        <div className='container text-white text-center py-3'>
          <h1 className='pt-3' style={{ fontWeight: "700" }}>
            Unity Game Services FAQs
          </h1>
          <p
            className='pt-2'
            style={{ textAlign: "justify", fontSize: "18px" }}>
            We want to solve all the queries that you may have and answer all
            the questions that may enter your mind. Therefore, here is a curated
            list of Frequently Asked Questions about Unity Game Development that
            most of the clients ask. If you have any questions other than these,
            you can drop a message below or call us.
          </p>
        </div>
      </div>
      <div className='container my-4'>
        <Accordiondata
          eventKey={"0"}
          title={"How much time does it take to develop a Unity3D mobile game?"}
          details={
            " It will take around 15 - 24 weeks to develop a Unity3D Mobile App, and the timeline can increase up to 40 weeks, depending on the features and number of platforms."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />

        <Accordiondata
          eventKey={"1"}
          title={"Do you also develop Unity 2D mobile Games?"}
          details={
            "Unity 3D is just a name, and the engine is compatible to develop 2D games too, and similarly, we can also develop 2D mobile games using Unity 3D."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"2"}
          title={"Do you provide support for existing game projects?"}
          details={
            "Yes, We also provide extensive support for our existing Unity game projects."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"3"}
          title={"Do you help us to increase levels in existing mobile game?"}
          details={
            "Yes, we do help you in increasing as many levels in the game as you want. In fact, increasing the levels of the game falls under our game development process only, and therefore you don't have to worry about the same."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
      <Blogs id={6} />
    </div>
  );
};

export default Index;
