import React from "react";
import Banner from "../Banner";
import Background from "../../../assets/MetaverseGame/1-Metaverse-Game-Bannerb.jpg";
import CountUp from "react-countup";
import Metaverse from "../../../assets/MetaverseGame/1-Metaverse-GameWeb.webp";
import Smallcard from "../Smallcard";
import NFT from "../../../assets/MetaverseGame/1-NFT-Game.png";
import TwoD from "../../../assets/MetaverseGame/2-2D-Game.png";
import ThreeD from "../../../assets/MetaverseGame/3-3D-Game.png";
import Animation from "../../../assets/MetaverseGame/4-Annimation.png";
import Development from "../../../assets/MetaverseGame/2-Metaverse-GameWeb.webp";
import Genres from "../../../assets/MetaverseGame/3-Metaverse-GameWeb.webp";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  return (
    <div className='data'>
      <SEOComponent
        title='Metaverse Game Development Company - Kanraj Infotech'
        description='Experience our unique approach to game development, crafting immersive worlds that redefine reality. Join us and discover the endless possibilities of the metaverse games.'
        url='https://kanraj.com/metaversegame'
      />
      <Banner banner={Background} text={"Metaverse Game Development"} />
      <div className='container'>
        <h6
          style={{
            color: "#078586",
            fontSize: "20px",
            fontWeight: "700",
            textDecoration: "underline",
            textDecorationColor: "#078586",
          }}>
          Game Development
        </h6>
        <h2 style={{ fontWeight: "700" }}> Metaverse Game Development</h2>
        <p style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
          Kanraj Infotech provides innovative metaverse game development that
          offers an immersive gaming experience. We tap into cutting-edge
          technologies to unlock the gripping reality for players where they can
          do habitual activities: create their avatars, sell and purchase goods.{" "}
        </p>

        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div
              style={{ fontSize: "60px", color: "#39C4A8", fontWeight: "600" }}>
              <CountUp start={0} end={2} duration={3} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game designers
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div
              style={{ fontSize: "60px", color: "#39C4A8", fontWeight: "600" }}>
              <CountUp start={0} end={10} duration={3} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game developers
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div
              style={{ fontSize: "60px", color: "#39C4A8", fontWeight: "600" }}>
              <CountUp start={0} end={8} duration={3} />+
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Gaming projects
            </p>
          </div>
        </div>

        <div className='row my-3'>
          <div className='col-md-6'>
            <div>
              <h4 style={{ fontWeight: "600" }}>What is metaverse game?</h4>
              <p
                style={{
                  textAlign: "justify",
                  color: "#000",
                  fontSize: "18px",
                }}>
                Do you strive to keep up with global trends? Ready to join the
                army of asset owners on a parallel digital platform? Our
                metaverse game development company is a guide to the immersive
                world. We bridge real and virtual worlds, offering unrivaled
                gameplay.
              </p>
              <p
                style={{
                  textAlign: "justify",
                  color: "#000",
                  fontSize: "18px",
                }}>
                Our team creates a virtual game reality with the most realistic
                effects. We make a space where gamers can interact with each
                other in real-time, create new objects, travel around
                landscapes, and obtain distinguished provenance.
              </p>
            </div>
            <div>
              <h5
                style={{
                  color: "#078586",
                  fontSize: "25px",
                  fontWeight: "700",
                }}>
                Asset Creation
              </h5>
              <p
                style={{
                  textAlign: "justify",
                  color: "#000",
                  fontSize: "18px",
                }}>
                {" "}
                Based on our client’s requirements, our skilled and expert
                design team develop different types of art assets for iOS,
                whether cartoon or realistic. Our app development on iOS devices
                optimizes game sprites, environment, multi-touch screen controls
                so that they run smoothly on various iOS devices.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div>
              <img
                src={Metaverse}
                alt=''
                height={"400px"}
                width={"100%"}
                style={{ borderRadius: "20px", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <h3 className='text-center' style={{ fontWeight: "700" }}>
            Our Metaverse game development services
          </h3>
          <div className='col-md-6'>
            <Smallcard
              image={NFT}
              title={"Metaverse NFT Game Development"}
              text={
                "We at Kanraj Infotech develop the game from scratch and deliver a life-like experience to gamers. With NFTs in our blockchain games, users can play and trade their assets, benefiting from the decentralized crypto space."
              }
            />
          </div>
          <div className='col-md-6'>
            <Smallcard
              image={TwoD}
              title={"Metaverse 2D GAME ART Outsourcing"}
              text={
                "When outsourcing Metaverse 2D game art, you don't have to care about many processes. The Whimsy Games team offers turn-key services, from coding and designing 2D art to post-release assistance."
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Smallcard
              image={ThreeD}
              title={"Metaverse 3D GAME ART Outsourcing"}
              text={
                "Delegating 3D game art to the metaverse game development company like Kanraj Infotech is a fast and cost-effective way to get the result. Our mission is to deliver top-grade software with an eye-catching design."
              }
            />
          </div>
          <div className='col-md-6'>
            <Smallcard
              image={Animation}
              title={"Metaverse Game Animation Outsourcing"}
              text={
                "With our outsourcing services for Metaverse game animation, you receive well-thought-out animation art. Our specialists align it to the storytelling that responds to the overall gameplay. We use the latest tools to provide a high-quality animated game."
              }
            />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row mb-5'>
          <div className='col-md-6'>
            <div>
              <img
                src={Development}
                alt=''
                width={"100%"}
                height={"400px"}
                style={{
                  objectFit: "fill",
                  width: "min-contain",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <h3 className='mb-3' style={{ fontWeight: "700" }}>
              Metaverse game development platform
            </h3>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2' style={{ fontWeight: "700" }}>
                  Mobile Game Dev
                </h5>
              </div>
              <p
                className='pera'
                style={{
                  textAlign: "justify",
                  color: "#000",
                  fontSize: "18px",
                }}>
                Our team tailors games available on Android and iOS. The gamers
                can get to the virtual world with a few clocks.
              </p>
            </div>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2' style={{ fontWeight: "700" }}>
                  Web Game Dev
                </h5>
              </div>
              <p
                className='text-start'
                style={{
                  textAlign: "justify",
                  color: "#000",
                  fontSize: "18px",
                }}>
                Our company offers multiple robust metaverse game development
                services for the web. We not only create stunning, high-quality
                games but also help distribute the game across different
                channels.
              </p>
            </div>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2' style={{ fontWeight: "700" }}>
                  VR game dev
                </h5>
              </div>
              <p
                className='text-start'
                style={{
                  textAlign: "justify",
                  color: "#000",
                  fontSize: "18px",
                }}>
                We provide in-game VR experiences for mobile game consoles, PC,
                and mobile platforms. Our Whimsy Games specialists use Unity as
                a trusted soft for our products.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <h3 className='mb-3 font_700'>Genres we have created games in</h3>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2 font_700'>Adventure Games</h5>
              </div>
              <p className='pera'>
                With adventure games developed by our team, people immerse
                themselves in exciting gameplay. Our metaverse game developers
                create a world where gamers can delve in and become their
                integrated part.{" "}
              </p>
            </div>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2 font_700'>Puzzle Games</h5>
              </div>
              <p className='pera'>
                Puzzle-solving becomes a part of the virtual world. We create
                absorbing and immersive gameplay. Our puzzle games are not a
                piece of cake. Gamers will have to keep their focus and think
                hard if they want to win.
              </p>
            </div>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2 font_700'>Card games</h5>
              </div>
              <p className='pera'>
                With Metaverse game development, gamers can enjoy a casino-like
                experience within the comfort of their couches. Our specialists
                implement features to deliver the gameplay gamers haven't had a
                chance to experience before.
              </p>
            </div>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2 font_700'>Shooters</h5>
              </div>
              <p className='pera'>
                Our team holds professionals who build eye-pleasant, dynamic
                games with seamless functionality. Our illustrators and
                designers elaborate protagonists, enemies, and other secondary
                characters.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div>
              <img
                src={Genres}
                alt=''
                width={"100%"}
                height={"500px"}
                style={{
                  objectFit: "fill",
                  width: "min-contain",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <h3 className='text-center font_700'>
          How do we works on Metaverse games
        </h3>
        <div className='row my-2'>
          <h5
            style={{ color: "#078586", fontSize: "20px" }}
            className='font_700'>
            01. Goal Setting
          </h5>

          <p className='pera'>
            We align our goals with your expectations. We seek to create a
            comprehensive picture of the game concept, revenue streams, and
            rough project time estimates at the onset of our cooperation.
          </p>
        </div>
        <div className='row mb-2'>
          <h5
            className='font_700'
            style={{ color: "#078586", fontSize: "20px" }}>
            02. Analysis
          </h5>

          <p className='pera'>
            To reach our project goals, we create a step-by-step roadmap with
            milestones and deadlines based on a game design document. Our
            specialists tailor solutions that meet your needs. Yet, we remain
            flexible during the entire metaverse game development process.
          </p>
        </div>
        <div className='row mb-2'>
          <h5
            className='font_700'
            style={{ color: "#078586", fontSize: "20px" }}>
            03. Designing & Approving Game Concept
          </h5>

          <p className='pera'>
            This is a stage when we shed light on the future art style. Our
            designers and illustrators make the first sketches of your future
            game. They visualise and bring life to the new immersive world --
            characters, landscapes, objects, and assets.
          </p>
        </div>
        <div className='row mb-2'>
          <h5
            className='font_700'
            style={{ color: "#078586", fontSize: "20px" }}>
            04. Software development
          </h5>

          <p className='pera'>
            Code breathes life into the game and allows impeccable gameplay. Our
            metaverse game developers form a robust code to build blocks for the
            game full of actions and visual effects.
          </p>
        </div>
        <div className='row mb-2'>
          <h5
            className='font_700'
            style={{ color: "#078586", fontSize: "20px" }}>
            05. Testing
          </h5>

          <p>
            Game testing is a crucial stage that guarantees flawless gameplay.
            We check code, visual effects, art, design, and UX/ UX elements to
            make sure that all functions run seamlessly and align with the
            respective requirements of the metaverse.
          </p>
        </div>
        <div className='row mb-2'>
          <h5
            className='font_700'
            style={{ color: "#078586", fontSize: "20px" }}>
            06. Game release
          </h5>

          <p className='pera'>
            At this phase of our cooperation, your game becomes available to
            users. Our experts roll it out to the network and support its
            functions. We also ensure that the game complies with the platform
            standards and is safe for every player.
          </p>
        </div>
        <div className='row mb-2'>
          <h5
            className='font_700'
            style={{ color: "#078586", fontSize: "20px" }}>
            07. Game promotion
          </h5>

          <p className='pera'>
            Our specialists make sure that the game reaches out to the target
            audience, which we strive to enlarge. We promote the game across
            different channels to reach as many users as possible. Besides, our
            marketing team regularly boosts traffic and provides a comprehensive
            overview of the achieved results.
          </p>
        </div>
      </div>
      <Blogs id={7} />
    </div>
  );
};

export default Index;
