import React, { useEffect, useState } from "react";
// import Location from "../../assets/custom/images/location.png";
import Logo from "../../assets/custom/images/Logo.png";
// import Location from "../../assets/custom/images/location.png";
import { useNavigate, Link, NavLink } from "react-router-dom";

import Header from "./Header";
// import { useNavigate } from 'react-router-dom';

const Index = () => {
  const [block, setBlock] = useState(false);
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      setIsMenuOpen(!isMenuOpen);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      <div className='sticky-nav'>
        <div class='container' style={{ width: "100%" }}>
          <nav class='navbar navbar-expand-lg'>
            <div style={{ zIndex: "9" }}>
              <NavLink
                to='/'
                activeClassName='active'
                className='nav-NavLink navbar-brand'>
                <img style={{ zIndex: "99" }} src={Logo} width={"140px"} />
              </NavLink>
            </div>

            {/* <div style={{ display: "flex", justifyContent: "end" }}>
              <ink  to='contact-us' onClick={closeMenu}
                className="nav_list" class='nav-NavLink'>
                <button
                  type='submit'
                  class='navbar-toggler '
                  onClick={closeMenu}
                  className="nav_list"
                  style={{
                    border: "1px solid #08A7A8",
                    borderRadius: "15px 0px",
                    background: "#FFFFFF",
                    color: "#000000",
                    margin: "auto",
                    marginRight: "10px",
                    padding: "10px",
                  }}>
                  Contct Us
                </button>
              </ink>
              <button class='navbar-toggler' onClick={toggleMenu} type='button'>
                <span class='navbar-toggler-icon'></span>
              </button>
            </div> */}
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Link to='contact-us' onClick={closeMenu} class='nav-link'>
                <button
                  type='submit'
                  class='navbar-toggler '
                  onClick={closeMenu}
                  style={{
                    border: "1px solid #08A7A8",
                    borderRadius: "15px 0px",
                    background: "#FFFFFF",
                    color: "#000000",
                    margin: "auto",
                    marginRight: "10px",
                    padding: "10px",
                  }}>
                  Contct Us
                </button>
              </Link>
              <button class='navbar-toggler' onClick={toggleMenu} type='button'>
                <span class='navbar-toggler-icon'></span>
              </button>
            </div>

            <div
              class={`offcanvas offcanvas-start ${isMenuOpen ? "show" : ""}`}
              tabindex='-1'
              id='navbarOffcanvasLg'
              aria-labelledby='navbarOffcanvasLgLabel'>
              <div
                class='offcanvas-header'
                style={{
                  alignItems: "start",
                  marginTop: "0.5rem",
                  justifyContent: "end",
                }}>
                <button
                  type='button'
                  class='btn-close'
                  onClick={closeMenu}
                  className='nav_list btn-close'></button>
              </div>
              <div class='offcanvas-body justify-content-end'>
                <ul class='navbar-nav d-flex justify-content-between'>
                  <li
                    class='nav-item dropdown'
                    onClick={() => toggleDropdown()}>
                    {/* <a
                      class='nav-NavLink dropdown-toggle'
                      data-bs-toggle='dropdown'
                      data-bs-display='static'
                      aria-expanded='false'
                      href='/'
                      id='navbarDropdownMenuNavLink'
                      role='button'
                      style={{ fontWeight: "500" }}> */}
                    <NavLink
                      activeClassName='active'
                      className='nav-link dropdown-toggle'
                      data-bs-toggle='dropdown'
                      data-bs-display='static'
                      aria-expanded={isOpen}
                      to='/'
                      id='navbarDropdownMenuNavLink'
                      role='button'
                      style={{ fontWeight: "500" }}>
                      <span
                        className='nav_list'
                        onClick={() => {
                        
                        }}>
                        Game Development
                      </span>
                    </NavLink>
                    <ul
                      // className='dropdown-menu dropdown-menu-start dropdown-menu-lg-end  text-black'
                      className={`${
                        screenWidth < 983 ? (isOpen ? "d-block" : "d-none") : ""
                      }  dropdown-menu  dropdown-menu-start dropdown-menu-lg-end  text-black`}
                      // aria-labelledby='navbarDropdownMenuNavLink'
                      style={{
                        right: "0",
                        border: "none",
                        background: "transparent",
                      }}>
                      <div
                        className='dropdown_list_items'
                        style={{
                          display: "flex",
                          width: "60rem",
                          border: "none",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "none",
                          background: " #ffff",
                          marginTop: "1rem",
                        }}>
                        <div class='list_item_box style_one'>
                          <ul
                            className='text-dark'
                            style={{ lineHeight: "2", color: "#000" }}>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='mobilegame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Mobile Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='androidgame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Android Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='iosgame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                IOS Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='unity3D'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Unity 3D and Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='metaversegame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Metaverse Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='fantasygamling'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Fantasy gamling app
                              </NavLink>
                            </li>
                          </ul>
                        </div>

                        <div class='list_item_box style_one'>
                          <ul style={{ lineHeight: "2" }}>
                            {/* <li>
                              <NavLink
                                to='nftgame'
                               onClick={closeMenu}
className="nav_list"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                NFT Game Development
                              </NavLink>
                            </li> */}
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='casinogame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Casino Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='realmoneygame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Real Money Game development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='unrealengine'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Unreal Engine Game Development{" "}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='argame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                AR Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='vrgame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                VR Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                activeClassName='active'
                                to='facebookgame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Facebook Game Development
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div class='list_item_box style_one'>
                          <ul style={{ lineHeight: "2" }}>
                            <li>
                              <NavLink
                                to='html5webgame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                HTML5 Web Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to='onlinegame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Online Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to='windowsgame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Windows Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to='nftgame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                NFT Game Development
                              </NavLink>
                            </li>
                            {/* <li>
                               <NavLink
                                to="crossplatform"
                               onClick={closeMenu}
className="nav_list"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                              >
                                Cross-Platform Game Development
                              </NavLink> 
                            </li> */}
                            <li>
                              <NavLink
                                to='desktopPC'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Desktop &amp; PC Game Development
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to='consolegame'
                                onClick={closeMenu}
                                className='nav_list'
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}>
                                Console Game Development
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ul>
                  </li>

                  <li class='nav-item' style={{ fontWeight: "500" }}>
                    <NavLink
                      activeClassName='active'
                      to='portfolio'
                      onClick={closeMenu}
                      className='nav-link nav_list'>
                      Portfolio{" "}
                    </NavLink>
                  </li>
                  <li class='nav-item' style={{ fontWeight: "500" }}>
                    <NavLink
                      to='contact-us'
                      onClick={closeMenu}
                      className='nav-link nav_list'
                      activeClassName='active'>
                      {" "}
                      Contact Us{" "}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Index;
