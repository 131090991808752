import React, { useState } from "react";
import Banner from "../Banner/index";
import CountUp from "react-countup";
import Card from "./Card";
import Background from "../../../assets/RealMoneyGame/1-Real-Money-Game-Banner.jpg";
import RealMoney from "../../../assets/RealMoneyGame/1-Why-Real-Money-GameWeb.webp";
import RealMoney2 from "../../../assets/RealMoneyGame/2-play-with-friendWeb.webp";
import RealMoney3 from "../../../assets/RealMoneyGame/3-computer-modeWeb.webp";
import RealMoney4 from "../../../assets/RealMoneyGame/4-local-modeWeb.webp";
import RealMoney5 from "../../../assets/RealMoneyGame/5-multi-playerWeb.webp";
import RealMoney6 from "../../../assets/RealMoneyGame/6-Real-Money-ImagesWeb.webp";
import RealMoney7 from "../../../assets/RealMoneyGame/7-Real-Money-ImagesWeb.webp";
import RealMoney8 from "../../../assets/RealMoneyGame/8-Real-Money-ImagesWeb.webp";
import RealMoney9 from "../../../assets/RealMoneyGame/9-Real-Money-ImagesWeb.webp";
import cardImg from "../../../assets/RealMoneyGame/1-Snake-Ladder.png";
import cardImg2 from "../../../assets/RealMoneyGame/2-ludo.png";
import cardImg3 from "../../../assets/RealMoneyGame/3-tic-tac-toe.png";
import cardImg4 from "../../../assets/RealMoneyGame/4-Carrom.png";
import cardImg5 from "../../../assets/RealMoneyGame/5-Teen-Patti.png";
import cardImg6 from "../../../assets/RealMoneyGame/6-Archery.png";
import cardImg7 from "../../../assets/RealMoneyGame/7-8-Ball-Pool.png";
import cardImg8 from "../../../assets/RealMoneyGame/8-Chess.png";
import cardImg9 from "../../../assets/RealMoneyGame/9-Rummy.png";
import cardImg10 from "../../../assets/RealMoneyGame/10-Poker.png";
import cardImg11 from "../../../assets/RealMoneyGame/11-Casino.png";
import cardImg12 from "../../../assets/RealMoneyGame/12-Call-Break-Card.png";
import cardImg13 from "../../../assets/RealMoneyGame/13-Stock-Fantasy.png";
import cardImg14 from "../../../assets/RealMoneyGame/14-Fantasy-Sports.png";
import cardImg15 from "../../../assets/RealMoneyGame/15-Multi-Gaming.png";
import cardImg16 from "../../../assets/RealMoneyGame/16-much-more.png";
import Accordiondata from "../../Accordiondata";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  return (
    <div className='data'>
      <SEOComponent
        title='Real Money Game Development Company - Kanraj Infotech'
        description='As a leading Real Money Game Development Company, We built games such as Ludo, Rummy, Poker, Indian Teen Patti, fantasy sports app, carrom and many more. Explore our portfolio.'
        url='https://kanraj.com/realmoneygame'
      />
      <Banner banner={Background} text={"Real Money Game Development"} />
      <div className='container'>
        <div>
          <h6
            style={{
              color: "#078586",
              fontSize: "20px",
              textDecoration: "underline",
              textDecorationColor: " #078586",
            }}
            className='font_700'>
            Game development
          </h6>
          <h2 className='font_700'>Real Money Game Development Services</h2>
          <p className='py-2 pera'>
            Kanraj Infotech is a leading real money game development Company in
            India with customised service developing effective and collaborative
            real money services like Ludo game, Rummy game, poker game, Indian
            Teen Patti game, fantasy sports app, carrom game development.
          </p>
          <p className='py-2 pera'>
            We have experience in developing 50+ Real Money games around mobile
            interfaces and the web. The Real Money games that we develop are
            known to be quality and feature-rich, and the graphics and the UI/UX
            contribute to keeping the users hooked to the game. We also offer
            various integrations in the game like social media and payment
            gateway integrations to make the game more flexible and entertaining
            for the users.
          </p>
        </div>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "80px", fontWeight: "500" }}>
              <CountUp start={0} end={2} duration={5} />
            </div>
            <p className='font_700'>Game designers</p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "80px", fontWeight: "500" }}>
              <CountUp start={0} end={12} duration={3} />
            </div>
            <p className='font_700'>Game developers</p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "80px", fontWeight: "500" }}>
              <CountUp start={0} end={45} duration={3} />+
            </div>
            <p className='font_700'>Gaming projects</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 my-3'>
            <h2 className='font_700'>What is a Real Money Game?</h2>
            <p className='my-2 pera'>
              Real Money games are skilled based games in which the objective of
              playing and winning the game is to earn real money. These games
              are played against real online players. The players will bet a
              certain amount when they start the game, and the winner takes it
              all. The Real Money genre adds a very significant change in the
              games as now the player is not just playing the game to kill time,
              they are playing the game as they have confidence in their skills,
              and by winning they will earn real money.
            </p>
          </div>
          <div className='col-md-6'>
            {/* <img
              src={RealMoney}
              alt=''
              style={{
                height: "400px",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            /> */}

            <img
              src={RealMoney}
              alt=''
              style={{
                width: "80%",
                height: "90%",
                objectFit: "fill",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
        <div className='my-4 '>
          <h3 className='text-center font_700'>
            Types of Real Money Games We Develop
          </h3>
          <p
            className='text-center'
            style={{ fontSize: "18px", color: "#000" }}>
            We design and develop a plethora of real money games. Our developers
            are apt at building super-rich real money games for the game lovers.
            With our extensive experience, special skills, and super ability, we
            can create any kind of real money game. Here are some of the real
            money games that we have expertise in developing:
          </p>
          <div className='row' style={{ marginBottom: "1.3rem" }}>
            <div className='col-md-3'>
              <Card img={cardImg} title='Snake and Ladder' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg2} title='Ludo Game' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg3} title='Tic Tac Toe Game' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg4} title='Carrom Game' />
            </div>
          </div>

          <div className='row' style={{ marginBottom: "1.3rem" }}>
            <div className='col-md-3'>
              <Card img={cardImg5} title='Teen Patti Game' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg6} title='Archery Game' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg7} title='8 Ball Pool Game' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg8} title='Chess Game' />
            </div>
          </div>

          <div className='row' style={{ marginBottom: "1.3rem" }}>
            <div className='col-md-3'>
              <Card img={cardImg9} title='Rummy Game' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg10} title='Poker Game' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg11} title='Casino Game' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg12} title='Call Break Card' />
            </div>
          </div>

          <div className='row' style={{ marginBottom: "1.3rem" }}>
            <div className='col-md-3'>
              <Card img={cardImg13} title='Stock Fantasy App' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg14} title='Fantasy Sports App' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg15} title='Multi-Gaming App' />
            </div>
            <div className='col-md-3'>
              <Card img={cardImg16} title='Much More' />
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "#009688" }}>
        <div className='container py-4' style={{ color: "#fff" }}>
          <h1 className='text-center font_700 my-3'>
            Real Money Games For All Platforms
          </h1>
          <div className='row my-4'>
            <div className='col-md-6'>
              <h4 className='text-center font_700'>Web Version</h4>
              <p style={{ fontSize: "18px", textAlign: "justify" }}>
                Allow the players to access your game without downloading it.
                The web version can be accessed through desktop, mobile, and
                other internet-enabled devices. Get the benefits of a larger
                audience.
              </p>
            </div>
            <div className='col-md-6'>
              <h4 className='text-center font_700'>Mobile Version</h4>
              <p style={{ fontSize: "18px", textAlign: "justify" }}>
                A customised mobile version for mobile users so that they can
                play the Real Money games and earn money anytime and from
                anywhere. The mobile version will be backed with multiple
                features to enhance the experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container text-center py-2'>
        <h2 className='bold' style={{ fontWeight: "700", fontSize: "1.75em" }}>
          Launch Real Money Game & Provide the Ultimate Gaming Experience
        </h2>

        <p style={{ fontSize: "18px" }}>
          Games are fun to play, however, if they are merged with a bigger
          objective, the fun will go 10x up. This is what you can achieve with a
          real money game. With real money on the line, the players will play
          harder and with more spirit, and winning the game will be a big thing
          too. Take the fun and entertainment of your i game to the next level
          with Kanraj Infotech’s Real Money Game Development.
        </p>
      </div>
      <div className='container'>
        <h3 className='text-center font_700'>Real Money Game Modes</h3>
        <p className='pera'>
          Our Real Money games are equipped with various modes to keep the users
          entertained. Thanks to these modes, the user can toggle between them
          as per their preference and therefore they will get the ultimate
          gaming experience every time.
        </p>
        <div className='row'>
          <div className='col-md-6 '>
            <img
              src={RealMoney2}
              alt=''
              style={{
                height: "auto",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            <div className='d-flex align-items-center my-1'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Play with Friends:</h4>
            </div>
            <p
              className='text-start'
              style={{ fontSize: "18px", color: "#000" }}>
              Games become more interesting when they are played with our
              friends. With social media integration, you can play the game with
              all your friends and family members.
            </p>
          </div>
          <div className='col-md-6'>
            <img
              src={RealMoney3}
              alt=''
              style={{
                height: "auto",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            <div className='d-flex align-items-center my-1'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Computer mode:</h4>
            </div>
            <p
              className='text-start'
              style={{ fontSize: "18px", color: "#000" }}>
              You don’t need someone to play the game with you. You can play
              with the computer and you can use this to improve your skills too.
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 '>
            <img
              src={RealMoney4}
              alt=''
              style={{
                height: "auto",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            <div className='d-flex align-items-center my-1'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Local Mode:</h4>
            </div>
            <p
              className='text-start'
              style={{ fontSize: "18px", color: "#000" }}>
              Local mode allows you to play the game with your friends and
              family on the same device. Play turn by turn and enjoy the thrill
              and excitement of gaming.
            </p>
          </div>
          <div className='col-md-6'>
            <img
              src={RealMoney5}
              alt=''
              style={{
                height: "auto",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            <div className='d-flex align-items-center my-1'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2 font_700'>Multiplayer Mode:</h4>
            </div>
            <p
              className='text-start'
              style={{ fontSize: "18px", color: "#000" }}>
              Play with thousands of online players. You can play with multiple
              players depending on the type of application.
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <h3 className='text-center font_700'>
          Online Real Money Game Development Features
        </h3>
        <p className='text-center' style={{ fontSize: "18px", color: "#000" }}>
          The features that your app offers will make or break your app. We have
          done detailed research on the game niche and therefore we have created
          a list of all the common and advanced features that you can choose
          from to be incorporated in your Real Money game.
        </p>
        <div className='row my-2'>
          <div className='col-md-6'>
            <img
              src={RealMoney6}
              alt=''
              style={{
                height: "auto",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className='col-md-6'>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                1. Play online with real money
              </h5>
              <p className='pera'>
                Play games using your skills and win to earn real cash. Use your
                gaming skills and expertise to earn real money instead of just
                using it to pass time.
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                2. Quick Deposit and Money Withdrawal
              </h5>
              <p className='pera'>
                We want you to spend most of your time playing the game, and
                therefore we develop games that support quick deposit and money
                withdrawal.{" "}
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                3. Game History
              </h5>
              <p className='pera'>
                You will get a detailed history of all the games that you have
                played. It will also have details about the amount that you
                won/lost in each game.{" "}
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                4. Multiple Rooms
              </h5>
              <p className='pera'>
                There are multiple rooms in the games with different entry fees
                and therefore you can find and choose the right room for
                yourselves.
              </p>
            </div>
          </div>
        </div>

        <div className='row my-2'>
          <div className='col-md-6'>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                5. Withdraw History
              </h5>
              <p className='pera'>
                A detailed history of the money that you have withdrawn is
                available. You can refer to the list to know when and where you
                have withdrawn the money.
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                6. Buddy Live List or Invitation
              </h5>
              <p className='pera'>
                As you add friends, you can see them on the buddy list. You can
                also see who is online and you can also send them an invitation
                to play with them.
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                7. Live support features
              </h5>
              <p className='pera'>
                The live support feature lets the players connect to you in just
                a few clicks. It can be used 24*7 by the players.
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                8. Play as a guest feature
              </h5>
              <p className='pera'>
                You can also play the game as a guest if you do not wish to
                create an account using your email address or any social media
                account.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <img
              src={RealMoney7}
              alt=''
              style={{
                height: "auto",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>

        <div className='row my-2'>
          <div className='col-md-6'>
            <img
              src={RealMoney8}
              alt=''
              style={{
                height: "auto",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className='col-md-6'>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                9. Facebook/google/email login 1. Play online with real money
              </h5>
              <p className='pera'>
                You can simply sign up using your Facebook account/ Google
                account/ Email address. All your social media friends will be
                automatically added to your buddy list.
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                10. Phone number and Email OTP configuration
              </h5>
              <p className='pera'>
                In order to get only genuine players in the game, a player has
                to verify their phone number and email address. The verification
                would be done via OTP.
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                11. Smart Notifications
              </h5>
              <p className='pera'>
                Smart Notifications allow you to send push notifications to all
                the users. You can target these notifications to motivate them
                to play the game now.
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                12. Daily Bonus Coins
              </h5>
              <p className='pera'>
                Our Real Money games provide the users with daily bonus coins to
                motivate them to log in daily and to play the game.
              </p>
            </div>
          </div>
        </div>

        <div className='row my-2'>
          <div className='col-md-6'>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                13. In-app Wallet
              </h5>
              <p className='pera'>
                All the in-game coins/money that the player buys or wins will be
                stored in the wallet. The players can withdraw the amount
                anytime that they want.
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                14. Serving Ads
              </h5>
              <p className='pera'>
                Use Ads to earn more out of your game. Show an Advertisement
                that will provide the users with a bonus upon seeing the full
                advertisement. This way it’s a win-win situation.
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                15. Tournaments
              </h5>
              <p className='pera'>
                The tournaments will help in deciding who is the ultimate
                player, and it will also help the users in earning more money
                while keeping the game interesting.{" "}
              </p>
            </div>
            <div>
              <h5
                className='py-2'
                style={{
                  color: "#078672",
                  fontSize: "1.25rem",
                  fontWeight: "700",
                }}>
                16. Refer and Earn
              </h5>
              <p className='pera'>
                The players can refer the game to their friends and family by
                using the referral code. The player and the joining player will
                get bonus cash in their account.{" "}
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <img
              src={RealMoney9}
              alt=''
              style={{
                height: "auto",
                width: "100%",
                border: "none",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#009688", color: "#fff" }}>
        <div className='container py-4'>
          <h2 className='text-center font_700'>
            <>Admin Control Features for Real Money Game Development</>
          </h2>
          <p className='text-center'>
            There are multiple admin features in the app that the admin can use
            to customise the app. These features will allow the admin to make
            changes in the app as and when needed. Here is a list of admin
            features that we incorporate in our Real Money games:
          </p>
          <div className='row'>
            <div className='col-md-4'>
              <div className='d-flex align-items-center my-1'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "white",
                    alignItems: "center",
                  }}></div>
                <h4 className='px-2 font_700'>Dashboard:</h4>
              </div>
              <p
                className=''
                style={{
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "justify",
                }}>
                Dashboard refers to a very important feature that shows you how
                your game is performing against other games.
              </p>
            </div>
            <div className='col-md-4'>
              <div className='d-flex align-items-center my-1'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "white",
                    alignItems: "center",
                  }}></div>
                <h4 className='px-2 font_700 font_700'>
                  Game settings and management:
                </h4>
              </div>
              <p
                className=''
                style={{
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "justify",
                }}>
                Various game settings are available like the language, sound
                option, two-factor authentication, game tutorials,
                notifications, etc, to change or edit the game’s experience{" "}
              </p>
            </div>
            <div className='col-md-4'>
              <div className='d-flex align-items-center my-1'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "white",
                    alignItems: "center",
                  }}></div>
                <h4 className='px-2 font_700'>User Management:</h4>
              </div>
              <p
                className=''
                style={{
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "justify",
                }}>
                Manage and control the activity of all the users using the User
                Management feature. You can also remove a user if you sense
                something suspicious.{" "}
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-4'>
              <div className='d-flex align-items-center my-1'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "white",
                    alignItems: "center",
                  }}></div>
                <h4 className='px-2 font_700'>Leaderboard Management:</h4>
              </div>
              <p
                className=''
                style={{
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "justify",
                }}>
                Showcase the most skillful and earning players on the
                leaderboard to provide inspiration to others and to praise the
                top players of your game.{" "}
              </p>
            </div>
            <div className='col-md-4'>
              <div className='d-flex align-items-center my-1'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "white",
                    alignItems: "center",
                  }}></div>
                <h4 className='px-2 font_700'>Payment & wallet Management:</h4>
              </div>
              <p
                className=''
                style={{
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "justify",
                }}>
                Payment and wallet management features will let you know how
                many coins have been bought and redeemed by the users.{" "}
              </p>
            </div>
            <div className='col-md-4'>
              <div className='d-flex align-items-center my-1'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "white",
                    alignItems: "center",
                  }}></div>
                <h4 className='px-2 font_700'>Chips Management:</h4>
              </div>
              <p
                className=''
                style={{
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "justify",
                }}>
                Manage the chips that are bought by the users or redeemed. You
                will also know what you have earned in the process.{" "}
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-4'>
              <div className='d-flex align-items-center my-1'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "white",
                    alignItems: "center",
                  }}></div>
                <h4 className='px-2 font_700'>Notification Management:</h4>
              </div>
              <p
                className=''
                style={{
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "justify",
                }}>
                Manage all the notifications that a user receives via push
                notifications. You can also use smart notifications to motivate
                the users to play the game now.{" "}
              </p>
            </div>
            <div className='col-md-4'>
              <div className='d-flex align-items-center my-1'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "white",
                    alignItems: "center",
                  }}></div>
                <h4 className='px-2 font_700'>Reward Point Management:</h4>
              </div>
              <p
                className=''
                style={{
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "justify",
                }}>
                Reward point management lets you create a loyalty program in
                your game. The player that uses your game daily will get bigger
                rewards than those who don't.{" "}
              </p>
            </div>
            <div className='col-md-4'>
              <div className='d-flex align-items-center my-1'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "white",
                    alignItems: "center",
                  }}></div>
                <h4 className='px-2 font_700'>Advertisement Management:</h4>
              </div>
              <p
                className=''
                style={{
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "justify",
                }}>
                Control and manage ads as per your needs. You can use all types
                of ads anytime that you like. This feature provides you complete
                control of all the in-app advertisements.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <h2 className='text-center font_700'>
          <>
            Why is Kanraj Infotech the best solution for Real Money Game
            Development?
          </>
        </h2>
        <p
          className='text-center py-3'
          style={{ fontSize: "18px", color: "#ooo" }}>
          Kanraj Infotech is a leading Real Money Game development company. Our
          team of Game Developers ensures that all your requirements are met in
          the game, and with advanced development skills, they incorporate the
          best features in the game. We have developed multiple real money
          games, and we also provide the after-launch services and maintenance
          for all the games that we develop. Our developers use the latest
          technology to develop the most advanced game that is
          user-friendly.Here are some of the advantages of choosing Kanraj
          Infotech as your Real Money game development company:
        </p>
        <div className='row'>
          <div className='col-md-6'>
            <h2
              className='text-center font_700'
              style={{
                color: "#078586",
                fontSize: "22px",
                textDecoration: "underline",
                textDecorationColor: " #078586",
              }}>
              Multiplayer Game Development Specialist
            </h2>
            <p
              className='text-center'
              style={{
                fontSize: "18px",
                paddingBottom: "10px",
                color: "#000",
              }}>
              Our developers have years of experience in developing chartbuster
              multiplayer games.
            </p>
          </div>
          <div className='col-md-6'>
            <h2
              className='text-center font_700'
              style={{
                color: "#078586",
                fontSize: "22px",
                textDecoration: "underline",
                textDecorationColor: " #078586",
              }}>
              Supports cross-platform{" "}
            </h2>
            <p
              className='text-center'
              style={{
                fontSize: "18px",
                paddingBottom: "10px",
                color: "#000",
              }}>
              Your game should get full exposure and therefore we develop games
              that support cross-platform functionality.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <h2
              className='text-center font_700'
              style={{
                color: "#078586",
                fontSize: "22px",
                textDecoration: "underline",
                textDecorationColor: " #078586",
              }}>
              Seamless gaming experience
            </h2>
            <p
              className='text-center'
              style={{
                fontSize: "18px",
                paddingBottom: "10px",
                color: "#000",
              }}>
              Our games are designed to work seamlessly and effortlessly across
              all devices and across various platforms.
            </p>
          </div>
          <div className='col-md-6'>
            <h2
              className='text-center font_700'
              style={{
                color: "#078586",
                fontSize: "22px",
                textDecoration: "underline",
                textDecorationColor: " #078586",
              }}>
              Attractive UI and UX{" "}
            </h2>
            <p
              className='text-center'
              style={{
                fontSize: "18px",
                paddingBottom: "10px",
                color: "#000",
              }}>
              To provide the best gaming experience, we develop the most
              attractive UI and UX that feel amazing and user-friendly at the
              same time.
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <h2
              className='text-center font_700'
              style={{
                color: "#078586",
                fontSize: "22px",
                textDecoration: "underline",
                textDecorationColor: " #078586",
              }}>
              Expert 2D and 3D Game Developers
            </h2>
            <p
              className='text-center'
              style={{
                fontSize: "18px",
                paddingBottom: "10px",
                color: "#000",
              }}>
              We have a team of expert 2D and 3D game developers who are
              experienced in developing the best 2D and 3D games.
            </p>
          </div>
          <div className='col-md-6'>
            <h2
              className='text-center font_700'
              style={{
                color: "#078586",
                fontSize: "22px",
                textDecoration: "underline",
                textDecorationColor: " #078586",
              }}>
              Experienced Real Money Game Developers{" "}
            </h2>
            <p
              className='text-center'
              style={{
                fontSize: "18px",
                paddingBottom: "10px",
                color: "#000",
              }}>
              We have the biggest portfolio in the industry when it comes to
              developing real money games for mobile devices.
            </p>
          </div>
        </div>
      </div>
      <div className='container my-4'>
        <Accordiondata
          eventKey={"0"}
          title={"How much time does it take to create a Real Money game?"}
          details={
            "It will take around 4 weeks to develop a real money game and the timeline can increase up to 10 weeks depending on the features and number of platforms."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />

        <Accordiondata
          eventKey={"1"}
          title={
            "Do you provide continuous maintenance support for Real Money game development?"
          }
          details={
            "Yes, “Kanraj Infotech” provides you with continuous maintenance support for Real Money game development. Even after the development, you can opt for the monthly retention plan."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"2"}
          title={
            "Do you provide continuous maintenance support for Real Money game development?"
          }
          details={
            "We develop the real money Real Money game using the world-famous Unity Game Engine, Cocos2D and Android Studio.. For the backend, we use Node.JS and Socket.io and Photon, The database is maintained using MongoDB and the server is AWS. We are also developing games as per client requirements of custom technologies if they have preferred any technology."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
      <Blogs id={10} />
    </div>
  );
};

export default Index;
