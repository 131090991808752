import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slid1 from "../../../assets/NFTGame/1-Board-GameWeb.webp";
import Slid2 from "../../../assets/NFTGame/2-PvP-Battel-GameWeb.webp";
import Slid3 from "../../../assets/NFTGame/3-Adventure-GameWeb.webp";
import Slid4 from "../../../assets/NFTGame/4-Arcade-GameWeb.webp";
import Slid5 from "../../../assets/NFTGame/5-Action-GameWeb.webp";
import Slid6 from "../../../assets/NFTGame/6-Fantasy-GameWeb.webp";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const SilderData = () => {
  const settings = {
    // Add your Slick settings here
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      <div className='container-fluid g-0' style={{ background: "#0F0F0F" }}>
        <h1 className='text-center font_700' style={{ color: "#fff" }}>
          NFT game development features
        </h1>
        {/* <Slider {...settings}>
          <div>
            <div
              style={{
                backgroundImage: `url(${Slid1})`,
                height: "600px",
                width: "100%",
                color: "#fff",
                backgroundSize: "cover",
                objectFit: "cover",
              }}>
              <div className='container' style={{ height: "100%" }}>
                <div className='row' style={{ height: "100%" }}>
                  <div
                    className='col-md-6'
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                    <h2
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "800",
                        fontSize: "40px",
                      }}>
                      Board Games
                    </h2>
                    <p style={{ fontFamily: "Roboto", fontSize: "18px" }}>
                      Combining board games with NFT experiences and
                      technologies will bring the most unexpected results — have
                      you ever seen NFT board games? Try them out and see what
                      we're talking about!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Slid2})`,
                height: "600px",
                width: "100%",
                color: "#fff",
                backgroundSize: "cover",
                objectFit: "cover",
              }}>
              <div className='container' style={{ height: "100%" }}>
                <div className='row' style={{ height: "100%" }}>
                  <div
                    className='col-md-6'
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                    <h2
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "800",
                        fontSize: "40px",
                      }}>
                      PvP Battle Games
                    </h2>
                    <p style={{ fontFamily: "Roboto", fontSize: "18px" }}>
                      Our team allows you to customize your gameplay. You'll get
                      a super-chance to create NFTs for sale out of any weapon,
                      skill, skin, or power!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Slid3})`,
                height: "600px",
                width: "100%",
                color: "#fff",
                backgroundSize: "cover",
                objectFit: "cover",
              }}>
              <div className='container' style={{ height: "100%" }}>
                <div className='row' style={{ height: "100%" }}>
                  <div
                    className='col-md-6'
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                    <h2
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "800",
                        fontSize: "40px",
                      }}>
                      Adventure Games
                    </h2>
                    <p style={{ fontFamily: "Roboto", fontSize: "18px" }}>
                      For adventure games, we have a unique approach. So we
                      create not only an exquisite environment, characters, and
                      assets but also ad hoc gameplay. Our teams will develop
                      anything you need to make your NFT game marketplace
                      strive: from skins to maps.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Slid4})`,
                height: "600px",
                width: "100%",
                color: "#fff",
                backgroundSize: "cover",
                objectFit: "cover",
              }}>
              <div className='container' style={{ height: "100%" }}>
                <div className='row' style={{ height: "100%" }}>
                  <div
                    className='col-md-6'
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                    <h2
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "800",
                        fontSize: "40px",
                      }}>
                      Arcade Games
                    </h2>
                    <p style={{ fontFamily: "Roboto", fontSize: "18px" }}>
                      Our teams create appealing puzzle games with stunning
                      gameplay and memorable designs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Slid5})`,
                height: "600px",
                width: "100%",
                color: "#fff",
                backgroundSize: "cover",
                objectFit: "cover",
              }}>
              <div className='container' style={{ height: "100%" }}>
                <div className='row' style={{ height: "100%" }}>
                  <div
                    className='col-md-6'
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                    <h2
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "800",
                        fontSize: "40px",
                      }}>
                      Action Games
                    </h2>
                    <p style={{ fontFamily: "Roboto", fontSize: "18px" }}>
                      As action games are always among the top-selling ones, we
                      strive to create unique playing experiences with
                      non-repetitive storylines.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Slid6})`,
                height: "600px",
                width: "100%",
                color: "#fff",
                backgroundSize: "cover",
                objectFit: "cover",
              }}>
              <div className='container' style={{ height: "100%" }}>
                <div className='row' style={{ height: "100%" }}>
                  <div
                    className='col-md-6'
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                    <h2
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "800",
                        fontSize: "40px",
                      }}>
                      Fantasy Games{" "}
                    </h2>
                    <p style={{ fontFamily: "Roboto", fontSize: "18px" }}>
                      Feel like playing with magic? Our extensive experience in
                      non fungible token game development and out-of-the-box
                      creativity allow us to come up with extraordinary worlds
                      and settings for fantasy games. Magicians, wizards, or
                      elves — we can add them all!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider> */}
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          emulateTouch={false}
          showIndicators={false}>
          <div className='' style={{ display: "flex", alignItems: "center" }}>
            <img src={Slid4} />

            <div
              className='legend'
              style={{
                background: "transactions",
              }}>
              <p className='nft-header'>Arcade Games</p>
              <p className='nft-font'>
                Our teams create appealing puzzle games with stunning gameplay
                and memorable designs.
              </p>
            </div>
          </div>
          <div className='' style={{ display: "flex", alignItems: "center" }}>
            <img src={Slid5} />

            <div
              className='legend'
              style={{
                background: "transactions ",
              }}>
              <p className='nft-header'>Action Games</p>
              <p className='nft-font'>
                As action games are always among the top-selling ones, we strive
                to create unique playing experiences with non-repetitive
                storylines.
              </p>
            </div>
          </div>
          <div className='' style={{ display: "flex", alignItems: "center" }}>
            <img src={Slid6} />

            <div
              className='legend'
              style={{
                background: "transactions ",
              }}>
              <p className='nft-header'>Fantasy Games</p>
              <p className='nft-font'>
                Feel like playing with magic? Our extensive experience in non
                fungible token game development and out-of-the-box creativity
                allow us to come up with extraordinary worlds and settings for
                fantasy games. Magicians, wizards, or elves — we can add them
                all!
              </p>
            </div>
          </div>
          <div className='' style={{ display: "flex", alignItems: "center" }}>
            <img src={Slid1} />

            <div
              className='legend'
              style={{
                background: "transactions ",
              }}>
              <p className='nft-header'>Board Games</p>
              <p className='nft-font'>
                Combining board games with NFT experiences and technologies will
                bring the most unexpected results — have you ever seen NFT board
                games? Try them out and see what we're talking about!
              </p>
            </div>
          </div>
          <div className='' style={{ display: "flex", alignItems: "center" }}>
            <img src={Slid2} />

            <div
              className='legend'
              style={{
                background: "transactions ",
              }}>
              <p className='nft-header'>PvP Battle Games</p>
              <p className='nft-font'>
                Our team allows you to customize your gameplay. You'll get a
                super-chance to create NFTs for sale out of any weapon, skill,
                skin, or power!
              </p>
            </div>
          </div>
          <div className='' style={{ display: "flex", alignItems: "center" }}>
            <img src={Slid3} />

            <div
              className='legend'
              style={{
                background: "transactions ",
              }}>
              <p className='nft-header'>Adventure Games </p>
              <p className='nft-font'>
                For adventure games, we have a unique approach. So we create not
                only an exquisite environment, characters, and assets but also
                ad hoc gameplay. Our teams will develop anything you need to
                make your NFT game marketplace strive: from skins to maps.
              </p>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default SilderData;
