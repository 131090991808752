import React from "react";

const index = ({ image, title, text }) => {
  return (
    <>
      <div className='shadow py-2 px-2 my-3' style={{ display: "flex" }}>
        <div className='px-2'>
          <img src={image} alt='' width={"70px"} height={"70px"} />
        </div>
        <div className=''>
          <h5 style={{ fontWeight: "700" }}>{title}</h5>
          <p
            className=''
            style={{ textAlign: "justify", color: "#000", fontSize: "18px" }}>
            {text}
          </p>
        </div>
      </div>
    </>
  );
};

export default index;
