import React from "react";
import Banner from "../Banner/index";
import Background from "../../../assets/CrossPlatformGame/1-Cross-Platform-Game-Banner.jpg";
import CrossPlatformGameWeb from "../../../assets/CrossPlatformGame/1-Cross-Platform-GameWeb.webp";
import CrossPlatformGameWeb2 from "../../../assets/CrossPlatformGame/2-Cross-Platform-GameWeb.webp";
import CrossPlatformGameWeb3 from "../../../assets/CrossPlatformGame/3-Cross-Platform-GameWeb.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationArrow,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";

const Index = () => {
  return (
    <div className='data'>
      <Banner banner={Background} text={"Cross-Platform Game Development"} />
      <div className='container'>
        <h6 style={{ color: "#078586", fontSize: "20px" }}>
          Cross-Platform Game Development
        </h6>
        <span
          style={{
            border: "2px solid #078586 ",
            display: "block",
            width: "70px",
            marginBottom: "10px",
          }}></span>
        <h2>Games Development for Cross Platforms</h2>
        <p>
          Kanraj Infotech has the high-end expert team for providing the best
          cross-platform games development across many different devices like
          Smart phone, Tablet, PC, consoles and other devices in which users can
          play a game. Our main target is to attract the maximum number of
          gamers and deliver a high-quality game based on the latest
          technologies. We develop the best quality cross-platform game
          application that can play on multiple platforms.
        </p>
      </div>
      <div className='container'>
        <h2 className='text-center'>Multiple Platforms – One Game</h2>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faSquareCheck} className='px-3' />
              <p>We provide innovative ideas based on trending technologies.</p>
            </div>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faSquareCheck} className='px-3' />
              <p>
                We attract a huge number of gamers and release the highest
                quality engagement games.
              </p>
            </div>
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faSquareCheck} className='px-3' />
              <p>
                Our engagement tool is flexible; clients can hire the dedicated
                team or dedicated resource for the full-time, part-time or
                hourly base.
              </p>
            </div>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faSquareCheck} className='px-3' />
              <p>
                We provide quality assurance experts before releasing the final
                version of the game, which helps us to deliver the bug-free game
                to our client.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <h2 className='text-center'>
          Advantages of Cross Platform Development
        </h2>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <p>
              Developers can focus more on game mechanics and strategies of
              playing games with the support of cross-platform mobile game
              development rather than wasting their time on the different
              irrelevant game development platform. Our developers of the
              cross-platform will mention the code and publish it on all mobile
              OS like iOS, Android, Steam, etc. Through this, we not only
              consumed our time but also we spent fewer expenses and focused
              other work on it. We have uniformity in maintaining frequent
              design abstraction and experience on many devices by victimization
              cross-platform games development.Aaryavarta mobile game
              development company uses an essential development of
              cross-platform game technologies rather than going for other
              operating systems.
            </p>
          </div>
          <div className='col-xs-12 col-md-6'>
            <img
              src={CrossPlatformGameWeb}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#40444A" }}>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-md-6'>
              <img
                src={CrossPlatformGameWeb2}
                alt=''
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  border: "none",
                  borderRadius: "8px",
                }}
              />
            </div>
            <div className='col-xs-12 col-md-6'>
              <p style={{ color: "white" }}>
                The user can customize a single code base for multiple
                platforms, innovating one cross-platform game which is also more
                important than making multiple basic games from the content
                which is useful for all game development companies. The
                cross-platform mobile game incorporates a nice importance in
                numeration for having nice retention rates on it. A user will
                play these games on a totally different platform online and
                offline. Developers will simply fetch a plethora of playing
                games of their development and can easily make a great asset
                from the profit. Games that work across several platforms that
                secure important time by coding for each and every gadget. We
                are a renowned mobile game development company in cross-platform
                game development because we have given assistance in this field
                to a wider range of our clients on the different operating
                systems and also provided them with extra advantages of entering
                into a global market.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <h2>What We Offer</h2>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2'>Focused Quality</h4>
            </div>
            <p className='text-start px-3'>
              Kanraj Infotech is dedicated to deliver best quality, value and
              service to meet our client's needs. Quality is an attribute that
              we appreciate and If you are in search of high-quality IT
              services, that's where our expertise is at your fingertips.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2'>Accurate Rates</h4>
            </div>
            <p className='text-start px-3'>
              The Kanraj team always believes in delivering outstanding
              solutions for small to huge projects. We have a tailor-made
              pricing list as per client requirements. At Kanraj, we believe in
              client's satisfaction when it comes to work delivery.
            </p>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "#078586",
                  alignItems: "center",
                }}></div>
              <h4 className='px-2'>Robust Solutions</h4>
            </div>
            <p className='text-start px-3'>
              We always deliver extraordinary services to our global clients and
              we take care of everything related to IT solutions and customer
              queries. Our such qualities make us different from the
              competitors.
            </p>
          </div>
          <div className='col-xs-12 col-md-6'>
            <img
              src={CrossPlatformGameWeb3}
              alt=''
              style={{
                height: "auto",
                maxWidth: "100%",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
        </div>
      </div>
      <div className='container'>
        <h2>Why kanraj infotech</h2>
        <p>
          Be it a startup or big company, Kanraj Infotech provides brilliant
          tech solutions to the enterprises. We believe in prompt solutions when
          it comes to client projects.
        </p>
        <div>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon
              icon={faLocationArrow}
              style={{ height: "30px", paddingRight: "8px" }}
            />
            <h3>ON DEMAND DEVOTED RESOURCES</h3>
          </div>

          <div className='d-flex align-items-center'>
            <FontAwesomeIcon
              icon={faLocationArrow}
              style={{ height: "30px", paddingRight: "8px" }}
            />
            <h3>COST EFFECTIVE SOLUTION - FAST AND FLEXIBLE</h3>
          </div>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon
              icon={faLocationArrow}
              style={{ height: "30px", paddingRight: "8px" }}
            />
            <h3>SINGLE DAY FOR JUMP-START A PROJECT</h3>
          </div>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon
              icon={faLocationArrow}
              style={{ height: "30px", paddingRight: "8px" }}
            />

            <h3>SCHEDULED DELIVERY</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
