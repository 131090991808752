import React from "react";

const index = ({ image, title }) => {
  return (
    <div className='data'>
      <div
        className='px-3 py-4 shadow mx-2 my-2'
        // style={{  }}
        style={{
          height: "150px",
          width: "auto",
          display: "flex",
          // justifyContent: "center",
          // alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}>
        <img src={image} alt='' width={"60px"} height={"60px"} />
        <h5>{title}</h5>
      </div>
    </div>
  );
};

export default index;
