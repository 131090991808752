import React from "react";

const Card = ({ img, title }) => {
  return (
    <>
      <div
        class='contact_box_inner '
        style={{
          padding: "20px 30px",
          border: "1px solid #e4e7ee ",
          borderRadius: "10px",
          background: "#fff",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}>
        <div class='icon_bx'>
          <img
            src={img}
            class='img-fluid svg_image'
            alt='icon png'
            style={{
              height: "auto",
              maxWidth: "100%",
              border: "none",
              borderRadius: "0",
              boxShadow: "none",
            }}
          />
        </div>
        <div
          class='contnet'
          style={{ color: "#282f3b", fontSize: "1rem", fontWeight: "800" }}>
          <h4 style={{ fontSize: "1rem", fontWeight: "700" }} className='my-2'>
            {title}
          </h4>
        </div>
      </div>
    </>
  );
};

export default Card;
