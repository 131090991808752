import React from "react";

const index = ({ banner, text }) => {
  return (
    <>
      <div
        className='container-fluid banner'
        style={{
          backgroundImage: `url(${banner})`,
          // height: "500px",
          // display: "flex",
          // alignItems: "center",
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // position: "relative",
        }}>
        <div className='container'>
          <div
            className='row'
            style={
              {
                // position: 'absolute',
                // top: '50%',
                // left: '50%',
                //  transform: 'translate(-85%, -50%)',
              }
            }>
            <div className='col-md-6'>
              <h1
                className='text-white banner_text'
                style={{ fontSize: "50px" }}>
                {text}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
