import React, { useState } from "react";
import Banner from "../Banner/index";
import CountUp from "react-countup";
import Accordiondata from "../../Accordiondata";
import Background from "../../../assets/NFTGame/1-NFT-Game-Banner.jpg";
import NFTgame from "../../../assets/NFTGame/1-NFT-Games-Web.webp";
import NFTgame2 from "../../../assets/NFTGame/1-NFT-Game.png";
import NFTgame3 from "../../../assets/NFTGame/2-Custom.png";
import NFTgame4 from "../../../assets/NFTGame/3-Concept.png";
import NFTgame5 from "../../../assets/NFTGame/4-NFT-IOS-Game.png";
import NFTgame6 from "../../../assets/NFTGame/3-NFT-Game-features450x700-2.jpg";
import NFTgame7 from "../../../assets/NFTGame/3-NFT_Game_features_450x700_-2-removebg-preview-1.png";
import Star from "../../../assets/NFTGame/star.png";
import "./NFT.style.css";
import SliderData from "./SilderData";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [openSection, setOpenSection] = useState("0");

  const handleToggle = (eventKey) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === eventKey ? null : eventKey
    );
  };
  const [isHovered, setHovered] = useState(false);
  return (
    <div className='data'>
      <SEOComponent
        title='NFT Game Development Company - Kanraj Infotech'
        description='Embark on your NFT gaming journey with Kanraj Infotech, your trusted partner in NFT game development. We craft unforgettable and innovative NFT games tailored to captivate your target audience.'
        url='https://kanraj.com/nftgame'
      />
      <Banner banner={Background} text={"NFT Game Development"} />
      <div className='container'>
        <div className='pb-4'>
          <h6
            style={{
              color: "#078586",
              fontSize: "20px",
              fontWeight: "700",
              textDecoration: "underline",
              textDecorationColor: "#078586",
            }}>
            Game Development
          </h6>
        </div>
        <h2 className='font_700'>NFT game development service</h2>
        <p className='my-2 pera'>
          If you're looking for an experienced NFT game development company,
          Kanraj Infotech can be of great help to you. Our team has worked with
          a wide range of NFT projects and applied top-notch technologies to
          create memorable and unique games that would draw your target
          audience's attention.
        </p>
        <h2 className='font_700'>Quick facts about kanraj infotech</h2>
        <p className='pera'>
          Being an experienced NFT Game development company, Kanraj Intotech
          stands behind several successful NFT projects. Look at the numbers
          that demonstrate it.
        </p>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "70px", fontWeight: "600" }}>
              <CountUp start={0} end={2} duration={5} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>Game Designer</p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "70px", fontWeight: "600" }}>
              <CountUp start={0} end={5} duration={3} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              NFT Game developers{" "}
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div style={{ fontSize: "70px", fontWeight: "600" }}>
              <CountUp start={0} end={8} duration={3} />+
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              NFT Gaming projects{" "}
            </p>
          </div>
        </div>
        <div className='row py-3'>
          <div className='col-md-6'>
            <h2 className='font_700'>
              Make NFT game development effortless with whimsy games
            </h2>
            <p className='pera'>
              We are a group of motivated, skilled professionals with tangible
              expertise in NFT gaming platform development. We provide various
              related services: from developing tokens to producing entire NFT
              gaming platforms. Our goal is to provide your business with an
              excellent opportunity to establish your own NFT marketplace and
              drive the attention of thousands of potential users all over the
              globe. Creativity comes along with it! No matter what genre or
              type your game is, we can boost its quality and value using
              high-end assets and development tools. We can add new characters
              and give them new features, superpowers, and exceptional outlooks.
              With the Admin SDK built-in method, we can produce customizable
              NFTs that give you full authentication control and boost the
              players’ level of satisfaction.
            </p>
          </div>
          <div className='col-md-6'>
            <img
              src={NFTgame}
              alt=''
              style={{
                height: "auto",
                width: "100%",

                border: "none",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            />
          </div>
        </div>
        <div className='row'>
          <h3 className='font_700'>Our NFT game development services</h3>
          <div className='col-md-6'>
            <div
              className='px-4 py-2 my-2'
              style={{
                border: "1px solid #e4e7ee",
                borderRadius: "10px",
              }}>
              <img
                src={NFTgame2}
                alt=''
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  border: "none",
                  borderRadius: "none",
                  boxShadow: "none",
                }}
              />
              <h5 className='font_700'>NFT Game Development Services</h5>
              <p className='pera'>
                Our professional development teams apply the best practices and
                approaches to develop all kinds of NFT game genres — from
                adventure to sports.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='px-4 py-2 my-2'
              style={{
                border: "1px solid #e4e7ee",
                borderRadius: "10px",
              }}>
              <img
                src={NFTgame3}
                alt=''
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  border: "none",
                  borderRadius: "none",
                  boxShadow: "none",
                }}
              />
              <h5 className='font_700'>Customizable NFT Games</h5>
              <p className='pera'>
                Upon your request, we can create customizable NFT projects where
                your users will be able to set up their own universe and modify
                a variety of built-in assets.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div
              className='px-4 py-2 my-2'
              style={{
                border: "1px solid #e4e7ee",
                borderRadius: "10px",
              }}>
              <img
                src={NFTgame4}
                alt=''
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  border: "none",
                  borderRadius: "none",
                  boxShadow: "none",
                }}
              />
              <h5 className='font_700'>NFT Concept game</h5>
              <p className='pera'>
                At Whimsy Games, we create the concepts of NFT games, which
                allow you to test and improve characters, environment, and other
                assets.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='px-4 py-2 my-2'
              style={{
                border: "1px solid #e4e7ee",
                borderRadius: "10px",
              }}>
              <img
                src={NFTgame5}
                alt=''
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  border: "none",
                  borderRadius: "none",
                  boxShadow: "none",
                }}
              />
              <h5 className='font_700'>
                NFT Game Development for Android and iOS
              </h5>
              <p className='pera'>
                You don't have to choose an operating system — we'll help you
                create a high-quality, enjoyable product, whether you're using
                Android or iOS.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid' style={{ backgroundColor: "#009688" }}>
        <div className='container py-4' style={{ color: "#fff" }}>
          <h1 className='text-center my-4 font_700'>
            NFT game development features
          </h1>
          <div
            className='row'
            style={{ display: "flex", alignItems: "center" }}>
            <div className='col-md-4'>
              <div className='d-flex  flex-column justify-content-center align-items-center'>
                <img src={Star} alt='' />
                <h6
                  className='text-center font_700'
                  style={{ fontSize: "20px" }}>
                  Digital agreement on excluding malicious activities
                </h6>
                <p className='text-center' style={{ fontSize: "18px" }}>
                  Security is one of the main cornerstones in all kinds of non
                  fungible token game development services. With this in mind,
                  we focus on securing marketplace transactions and ensuring
                  full fund protection in our games.
                </p>
              </div>
              <div className='d-flex  flex-column justify-content-center align-items-center'>
                <img src={Star} alt='' />
                <h6
                  className='text-center font_700'
                  style={{ fontSize: "20px" }}>
                  Decentralised NFT platform
                </h6>
                <p className='text-center' style={{ fontSize: "18px" }}>
                  You have total freedom — update the NFT data at any convenient
                  time within a blockchain.
                </p>
              </div>
            </div>

            <div
              className={`col-md-4  Game-features ${
                isHovered ? "hovered" : ""
              }`}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}>
              <img
                src={isHovered ? NFTgame6 : NFTgame7}
                alt=''
                style={{ height: "auto", width: "100%" }}
              />
            </div>
            <div className='col-md-4'>
              <div className='d-flex  flex-column justify-content-center align-items-center'>
                <img src={Star} alt='' />
                <h6
                  className='text-center font_700'
                  style={{ fontSize: "20px" }}>
                  Instant payments
                </h6>
                <p className='text-center' style={{ fontSize: "18px" }}>
                  You don't have to provide your card details or any sort of
                  personal information to trade on the platform.
                </p>
              </div>
              <div className='d-flex  flex-column justify-content-center align-items-center'>
                <img src={Star} alt='' />
                <h6
                  className='text-center font_700'
                  style={{ fontSize: "20px" }}>
                  Algorithmic trade-off opportunity
                </h6>
                <p className='text-center' style={{ fontSize: "18px" }}>
                  The systems we develop help you automatically search for
                  sellers or buyers and reach the most relevant ones
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <div className='row'>
          <h2 className='text-center font_700'>
            Here’s why our clients choose us
          </h2>
          <div className='col-md-6'>
            <div className='py-3'>
              <h2
                className='text-center py-2'
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  color: "#078586",
                  textDecoration: "underline",
                  fontWeight: "600",
                }}>
                Professional expertise
              </h2>
              <p
                className='text-center'
                style={{ fontSize: "18px", color: "#000" }}>
                With a relevant background in the game development industry, we
                combine proven methods with innovative tactics to create
                tailored gaming solutions.
              </p>
            </div>
            <div className='py-3'>
              <h2
                className='text-center py-2'
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  color: "#078586",
                  textDecoration: "underline",
                  fontWeight: "600",
                }}>
                Transparent pricing
              </h2>
              <p
                className='text-center'
                style={{ fontSize: "18px", color: "#000" }}>
                Transparency is one of our core values. You'll know the final
                project cost as soon as we agree on all the terms. No hidden
                payment is applied ahead.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='py-3'>
              <h2
                className='text-center py-2'
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  color: "#078586",
                  textDecoration: "underline",
                  fontWeight: "600",
                }}>
                Unique approach
              </h2>
              <p
                className='text-center'
                style={{ fontSize: "18px", color: "#000" }}>
                To develop a game that meets all your requirements, we use the
                tools and engines that you requested.
              </p>
            </div>
            <div className='py-3'>
              <h2
                className='text-center py-2'
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  color: "#078586",
                  textDecoration: "underline",
                  fontWeight: "600",
                }}>
                Marketing trends
              </h2>
              <p
                className='text-center'
                style={{ fontSize: "18px", color: "#000" }}>
                To drive more attention to your game and engage more players, we
                apply the latest market trends and make necessary changes as new
                tendencies emerge.
              </p>
            </div>
          </div>
        </div>
      </div>
      <SliderData />
      <div className='container my-4'>
        <Accordiondata
          eventKey={"0"}
          title={"What is NFT in gaming?"}
          details={
            "Non-fungible tokens in games help you monetize the project. The main point of this is letting gamers gain profit as they're playing NFT games. You can use any object that you've found or collected during the gameplay and monetize it — starting with skins, rare collectible items, or avatars. Creating new projects with the help of an NFT game development company extends your opportunities in the game and enhances players' experience. This is a popular trend nowadays because of the worldwide crypto-boom and cybersecurity concerns."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"1"}
          title={"What is the point of adding NFTs in games?"}
          details={
            "Adding an NFT element to your game boosts engagement among the players and brings it to a whole new level. This way, your audience can get a more realistic, cooler experience and make an extra profit out of digital assets in the game. Plus, the value of the users' time spent playing also increases. Players from all over the world get a chance to trade their digital trophies for real money."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"2"}
          title={"What types of NFTs can you produce on the gaming platform?"}
          details={
            "You're free to create all sorts of tokens in the gaming platform — from characters to landscapes. The value of such NFTs depends upon their rarity, uniqueness, style, and utility. In some cases, a gaming platform can stick an NFT to a game character, so users will be able to trade them. Plus, you can get NFTs in the form of cyber-objects you've found while playing the game. Such types of projects a typical NFT gaming platform development company creates are called play-to-earn. It helps players get income while playing."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
        <Accordiondata
          eventKey={"3"}
          title={
            "What technologies do your development teams apply to create NFTs for games?"
          }
          details={
            "A typical technology used for creating most NFT gaming solutions is Flow. As for alternatives, we need to mention Tezos. Its FA2 NFT contracts are efficient for the development of an NFT marketplace. In Kanraj Infotech, we also use the Admin SDK built-in method for generating custom tokens. Finally, we always set up a decentralised NFT platform. It allows you to update NFT data in a blockchain every time a new block appears in the network."
          }
          openSection={openSection}
          onToggle={handleToggle}
        />
      </div>
      <Blogs id={18} />
    </div>
  );
};

export default Index;
