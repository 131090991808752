import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./Image.style.css";

export const Image = ({
  title,
  playStoreImage,
  smallImage,
  type,
  features,
  overview,
  specification,
  heading,
  data,
  data2,
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const handleClickImage = (type) => {
    if (location.pathname == "/") {
      navigate(`portfolio/${type}`);
    } else {
      navigate(`${type}`);
    }
  };
  return (
    <>
      <div
        className='portfolio-item'
        onClick={() => {
          handleClickImage(title);
        }}>
        <div className='hover-bg' title={title}>
          <div className='hover-text'>
            <h4>{title}</h4>
            <span>{type}</span>
          </div>
          <img
            src={smallImage}
            className='img-responsive'
            alt={title}
            style={{ borderRadius: "12px" }}
          />{" "}
        </div>
      </div>
    </>
  );
};
