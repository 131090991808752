import Carousel from "react-bootstrap/Carousel";
// import {Logo} from"../../assets/custom/images/Logo.png"
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import SlideOne from "../../assets/technologies/Slider-1.jpg";
import Slidetwo from "../../assets/technologies/Slider-2.jpg";

function Carouselfile() {
  return (
    <Carousel className='mb-5'>
      <Carousel.Item interval={1000}>
        <img src={SlideOne} width={"100%"} height={"600px"} />

        <Carousel.Caption
          className='d-flex align-items-center justify-content-center'
          style={{ height: "100%" }}>
          <div>
            <h5
              style={{
                fontWeight: "500",
                fontSize: "25px",
                border: "3px solid #08A7A8",
                borderRadius: "15px 0px",
              }}
              className='py-2'>
              <span>
                We are a game developer team which you are looking for
              </span>
            </h5>
            <h3 style={{ fontWeight: "700", fontSize: "50px" }}>
              Game Development Company{" "}
            </h3>
            <p
              style={{
                // textAlign: "justify",
                fontWeight: "600",
                fontSize: "18px",
              }}>
              Kanraj Infotech is one of the Best and Top game development
              companies in India & USA. We provide the best mobile game
              development services to develop your dream games.
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img src={Slidetwo} width={"100%"} height={"600px"} />
        <Carousel.Caption
          className='d-flex align-items-center justify-content-center'
          style={{ height: "100%" }}>
          <div>
            <h5
              style={{
                fontWeight: "500",
                fontSize: "25px",
                border: "3px solid #08A7A8",
                borderRadius: "15px 0px",
              }}
              className='py-2'>
              <span>We develop all type of games </span>
            </h5>
            <h3 style={{ fontWeight: "700", fontSize: "50px" }}>
              Game Development Company{" "}
            </h3>
            <p
              style={{
                // textAlign: "justify",
                fontWeight: "600",
                fontSize: "18px",
              }}>
              Kanraj Infotech has the best experienced game developer who will
              develop games for you, ex. Ludo game, Casino games, Action games,
              Metaverse games, NFT games, Teen Patti games, Multiple gaming
              platform - All in one etc.
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carouselfile;
