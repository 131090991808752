
import Series from "../../../assets/FantasyGame/1-Series-LeaderboardWeb.webp"
import Reverse from "../../../assets/FantasyGame/2-Reverse-FantasyWeb.webp"
import Bowling from "../../../assets/FantasyGame/4-Bowling-FantasyWeb.webp"
import Batting from "../../../assets/FantasyGame/3-Batting-FantasyWeb.webp"
import Live from "../../../assets/FantasyGame/5-Live-Score-CardWeb.webp"
import Livefantasy from "../../../assets/FantasyGame/6-Live-FantasyWeb.webp"
import Activity from "../../../assets/FantasyGame/7-Activity-LogWeb.webp"
import Affiliate from "../../../assets/FantasyGame/8-Affiliate-SystemWeb.webp"
import Special from "../../../assets/FantasyGame/9-Special-AnnouncementsWeb.webp"
import Inning from "../../../assets/FantasyGame/10-2nd-inningsWeb.webp"
import Estimated from "../../../assets/FantasyGame/11-Estimated-WinningsWeb.webp"
import Commentary from "../../../assets/FantasyGame/12-Commentary-CricketWeb.webp"
import User from "../../../assets/FantasyGame/13-User-LevelsWeb.webp"
import Bot from "../../../assets/FantasyGame/14-Bot-SystemWeb.webp"
import Chat from "../../../assets/FantasyGame/15-Chat-IntegrationWeb.webp"


const Data = [{

    img: Series,
    title: "Series Leaderboard",
    details: "Add on features like Series Leaderboard, visible to all the participants will boost their competitive spirit and increase excitement.",
},
{

    img: Reverse,
    title: "Reverse Fantasy",
    details: "A different type of Fantasy where the winner is that player who has low Fantasy points at the end of the contests. It is not easy to keep a minimum score.",
},
{

    img: Bowling,
    title: "Bowling Fantasy",
    details: "Something like bowling fantasy will be a whole new experience for the users, as it’s quite a new concept and fresh approach to increasing opportunities.",
}, {

    img: Batting,
    title: "Batting Fantasy",
    details: "Must explain too you how all this mistaken idea of denouncing pleasures praising pain was born and we will give you complete account of the system the actual teachings of the great explorer.",
}, {

    img:Live,
    title: "Live Score Card",
    details: "Showing a live scorecard will be kind of a unique feature that will be supporting the fantasy users' decisions while playing a particular match on the platform.",
}, {

    img: Livefantasy,
    title: "Live Fantasy",
    details: "A short fantasy of 5 overs for T20 and 10 overs for ODI to play and win money on the Fantasy platform. This will attract more fantasy users, for its short time and excitement.",
}, {

    img: Activity,
    title: "Activity Logs",
    details: "A feature to keep track of all the admin activity on the Fantasy Sports app to manage the whole application and changes done by multiple admins.",
}, {

    img: Affiliate,
    title: "Affiliate System",
    details: "Making the Fantasy Sports platform an affiliate system is one of the smartest ways to increase your platform reach among potential users.",
}, {

    img: Special,
    title: "Special Announcements",
    details: "Feature of sending customized notifications to the users about the match or contest related special updates. These announcements can also be used to announce the giveaway or the special rewards.",
}, {

    img: Inning,
    title: "2nd Inning",
    details: "Add 2nd innings as the advanced feature in your Fantasy Sports platform to provide more opportunities to sports fans in the same match.",
}, {

    img: Estimated,
    title: "Estimated Winnings",
    details: "Mentioning the estimated winnings of the top 5 users will boost competition among current users and will attract the new sports user towards the rewards.",
}, {

    img: Commentary,
    title: "Commentary (Cricket)",
    details: "Adding something like ball to the ball commentary will double the fun and excitement. Add it to the app for a live match-binging experience.",
}, {

    img: User,
    title: "User Levels",
    details: "A smart feature to mention the whole history of the user on the platform including details like the number of winning matches, total gaming score, number of played matches, etc.",
}, {

    img: Bot,
    title: "Bot System",
    details: "Bot imitate human behavior and perform all the required tasks in the absence of humans which will increase the platform efficiency and reduce human involvement.",
}, {

    img: Chat,
    title: "Chat Integration",
    details: "Live Chats offers immediate support with no need to wait on hold and build a trustable relationship with your users.",
}


]

export default Data