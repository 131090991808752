import React, { useEffect, useState } from "react";
import Banner from "../Banner/index";
import CountUp from "react-countup";
import Background from "../../../assets/FantasyGame/1-Fantasy-Game-Banner.jpg";
import Fantasy from "../../../assets/FantasyGame/1-FantasyWeb.webp";
import Fantasyleague from "../../../assets/FantasyGame/3-FantasyWeb.webp";
import Fantasysports from "../../../assets/FantasyGame/2-FantasyWeb.webp";
import Fantasycricket from "../../../assets/FantasyGame/5-FantasyWeb.webp";
import Fantasyanalytics from "../../../assets/FantasyGame/4-FantasyWeb.webp";
import Softwaresolution from "../../../assets/FantasyGame/6-FantasyWeb.webp";
import Data from "./Data";
import SEOComponent from "../../SEO/SEOComponent";
import Blogs from "../../Blog/Blogs";

const Index = () => {
  const [gameData, setGameData] = useState(Data);
  const [selectedType, setSelectedType] = useState("Series Leaderboard");
  const uniqueTypes = [...new Set(Data.map((item) => item.title))];

  useEffect(() => {
    // Filter data based on the selected type
    const filteredData =
      selectedType === "Series Leaderboard"
        ? Data.filter((item) => item.title === "Series Leaderboard")
        : Data.filter((item) => item.title === selectedType);
    setGameData(filteredData);
  }, [selectedType]);
  return (
    <div className='data'>
      <SEOComponent
        title='Fantasy Sports Development Company -  Kanraj Infotech'
        description='With hands-on experience of 50+ fantasy applications, our fantasy sports app developers know exactly what goes into building an amazing fantasy app that users love. Get started by sharing your requirements and be assured of receiving a scalable and future-ready solution.'
        url='https://kanraj.com/fantasygamling'
      />
      <Banner banner={Background} text={"fantasy Gamling App"} />
      <div className='container'>
        <h6
          style={{
            color: "#078586",
            fontSize: "20px",
            fontWeight: "700",
            textDecoration: "underline",
            textDecorationColor: "#078586",
          }}>
          Game Development
        </h6>
        <h2 className='font_700'>Fantasy Sports App Development Services</h2>
        <p className='pera'>
          We have customised and created 50+ fantasy applications with creative
          solutions. Immersive and intuitive fantasy applications backed by
          eminent technology and technical competence. With hands-on experience
          of 50+ fantasy applications, our fantasy sports app developers know
          exactly what goes into building an amazing fantasy app that users
          love. Get started by sharing your requirements and be assured of
          receiving a scalable and future-ready solution.
        </p>
        <div
          className='row'
          style={{ display: "flex", justifyContent: "center" }}>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div
              style={{ fontSize: "60px", color: "#39C4A8", fontWeight: "600" }}>
              <CountUp start={0} end={1} duration={3} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game designers
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div
              style={{ fontSize: "60px", color: "#39C4A8", fontWeight: "600" }}>
              <CountUp start={0} end={5} duration={3} />
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Game developers
            </p>
          </div>
          <div
            className='col-md-3'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div
              style={{ fontSize: "60px", color: "#39C4A8", fontWeight: "600" }}>
              <CountUp start={0} end={9} duration={3} />+
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Gaming projects
            </p>
          </div>
        </div>

        <div className='row my-3'>
          <div className='col-md-6'>
            <div>
              <h4 className='font_700'>
                One-stop Fantasy Sports App Solution Partner for All Games
              </h4>
              <p className='pera'>
                Cricket, kabaddi, Baseball, Basketball, Ice Hockey, Rugby,
                Football, Tennis, Soccer, Horse Racing, Hurling, Motor Sport
              </p>
              <p className='pera'>
                We create business-oriented and user-focused apps for all the
                popular games, including football, cricket, basketball, tennis,
                hockey, kabaddi, etc. that not only attract users but also
                generate hefty returns. Share your requirements and leave the
                rest on us to create, ideate, and strategize.
              </p>
            </div>
            <div>
              <h5
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Fantasy Sports Apps Loved By Users Around The World
              </h5>
              <p className='pera'>
                Highly engaging and interactive apps you can’t resist using. All
                of them have been developed taking care of clients’
                distinguishing requirements, next-gen features, modern
                architecture, and technology for a seamless user experience.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div>
              <img
                src={Fantasy}
                alt=''
                height={"400px"}
                width={"100%"}
                style={{ borderRadius: "20px", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <h3 className='text-center font_700' style={{ color: "#000" }}>
          Fantasy Sports App Development Features
        </h3>
        <div>
          {uniqueTypes.map((type) => (
            <button
              key={type}
              className='default-button mx-1 my-1'
              style={{
                background: selectedType === type ? "#495057" : "#fff",
                color: selectedType === type ? "#fff" : "#000",
                padding: "8px 12px",
                marginRight: "3px",
                marginBottom: "3px",
                fontSize: "15px",
                outline: "none",
                transition: "all 0.3s ease-in-out",
                borderRadius: "12px",
                border:
                  selectedType === type
                    ? "1px solid #ffff"
                    : "1.5px solid #000",
              }}
              onClick={() => {
                setSelectedType(type);
              }}>
              {type.toUpperCase()}
            </button>
          ))}
        </div>

        <div className='row my-3'>
          {gameData
            ? gameData.map((item, i) => (
                <>
                  <div key={`${i}-${i}`} className='col-md-6'>
                    <div>
                      <img
                        src={item?.img}
                        alt=''
                        width={"100%"}
                        height={"300px"}
                        style={{
                          objectFit: "fill",
                          borderRadius: "10px",
                          width: "-webkit - fill - available",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className='col-md-6 px-3'
                    style={{ display: "flex", alignItems: "top" }}>
                    <div>
                      <h3 className='font_700' style={{ color: "#078586C7" }}>
                        {item?.title}
                      </h3>
                      <p className='pera'>{item?.details}</p>
                    </div>
                  </div>
                </>
              ))
            : "Loading..."}
        </div>
      </div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-md-4'>
            <h4 className='font_700 text-center'>User Panel</h4>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700 text-center'
                style={{ color: "#078586", fontSize: "20px" }}>
                User Login and Signup
              </h6>
              <p className='pera'>
                One-click login and signup are desirable. Make sure to integrate
                the social login option for easy and quick login. Other login
                options are via phone number and email.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Paid and Practice contest
              </h6>
              <p className='pera'>
                Practice contests with dummy credits so users can become better
                at playing before entering paid contests with real cash.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Live Score and Updates
              </h6>
              <p className='pera'>
                Real-time access to live match scores along with other
                insightful information like expert advice, match previews, team
                selection ideas, and more.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Multiple payment methods
              </h6>
              <p className='pera'>
                Restricting users to pay via limited means isn’t a good idea,
                instead, empower them to pay through multiple mediums.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Private Contest
              </h6>
              <p className='pera'>
                Play with an enclosed group option that enables users to create
                private groups and contest with their gang, friends, or family
                members.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Invite & Earn
              </h6>
              <p className='pera'>
                Refer and earn is an option to attract new users while you
                reward the existing ones for referring. Both parties end up
                winning rewards.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Quick Withdrawal
              </h6>
              <p className='pera'>
                Withdrawals are quick and simple for verified users. Get a
                verification process in place to streamline and fast-forward
                withdrawals.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Multiple Leagues
              </h6>
              <p className='pera'>
                A user can participate in multiple leagues and contest multiple
                games at once, either with the same team or a different one.
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <h4 className='font_700 text-center'>Admin Panel</h4>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700 text-center'
                style={{ color: "#078586", fontSize: "20px" }}>
                Admin Login
              </h6>
              <p className='pera'>
                Just like user registration, the admin also needs to sign up to
                access the portal.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Admin Dashboard
              </h6>
              <p className='pera'>
                A robust dashboard with clean visuals for multiple app aspects
                like live contests, revenue generated, user and game stats, app
                analytics, etc.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                User Management
              </h6>
              <p className='pera'>
                For managing users of your app. Admin has complete control –
                add/remove/modify user profiles as per the app guidelines.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Contest Management
              </h6>
              <p className='pera'>
                Admin has the power to manage live as well as the upcoming
                contests from the backend. Read stats, data, details, etc and
                modify, if required.{" "}
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Reward points Management
              </h6>
              <p className='pera'>
                Rewards gained by users at the end of each game can be managed
                and monitored by the admin from this section.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Revenue Management
              </h6>
              <p className='pera'>
                It is one of the crucial aspects of a fantasy sports app that
                displays revenue generated from multiple matches and leagues in
                a consolidated form.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Cash Bonus Management
              </h6>
              <p className='pera'>
                Like the rewards system, the admin can also announce an
                additional cash bonus for winners, management of the same can be
                done from the admin.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Bank Withdrawal Request Management
              </h6>
              <p className='pera'>
                Users make the withdrawal request from the front-end which then
                comes for approval from the admin of the app.
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <h4 className='font_700 text-center'>Advance Features</h4>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700 text-center'
                style={{ color: "#078586", fontSize: "20px" }}>
                2nd Innings Team Creation
              </h6>
              <p className='pera'>
                Unlike many apps that allow team creation before the game
                starts, this feature enables users to create a team until the
                start of the second inning.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Fully Automated Backend Panel
              </h6>
              <p className='pera'>
                A lot of sections of the app can be automated including player
                and team mapping, games, auto-league creation, auto withdrawals,
                etc.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                VIP Subscription
              </h6>
              <p className='pera'>
                This gives premium users an added advantage of availing referral
                bonuses, discounts on joining new leagues or games.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Full Scorecard
              </h6>
              <p className='pera'>
                Users can view a detailed scorecard of an ongoing game or
                recently finished one, including runs scored, wickets taken,
                goals, etc as per the game.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Advanced Affiliation Program
              </h6>
              <p className='pera'>
                Benefit for affiliates who work to bring new users to the app
                through different mediums. After users sign-up and enter a game,
                commissions can be processed.
              </p>
            </div>

            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Commodity League
              </h6>
              <p className='pera'>
                Exciting surprise gifts or prices are up for grab for the users
                who turn out to be a winner after the end of the league or
                tournament.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Custom Referral Code
              </h6>
              <p className='pera'>
                Users get exclusive referral codes that they can share with
                their friends and family for joining a game and later enjoy the
                benefits of the same.
              </p>
            </div>
            <div className='mb-3' style={{ borderBottom: "1px solid #8e8b8b" }}>
              <h6
                className='font_700'
                style={{ color: "#078586", fontSize: "20px" }}>
                Mail Reminder System
              </h6>
              <p className='pera'>
                Users get personalized emails about upcoming tournaments,
                leagues, and games, so they don’t miss out on any available
                opportunity.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <h2 className='text-center font_700'>
          Advanced Future-ready Fantasy Sports
        </h2>
        <h3 className='text-center font_700'>App Development Solutions</h3>
        <div className='row'>
          <div className='col-md-4 pb-2'>
            <div>
              <img
                src={Fantasyleague}
                alt=''
                height={"200px"}
                width={"100%"}
                style={{
                  borderRadius: "10px",
                  objectFit: "fill",
                  width: "min-contain",
                }}
              />
            </div>
          </div>
          <div
            className='col-md-8'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2 font_700'>
                  Fantasy League App Development Solutions
                </h5>
              </div>
              <p className='pera'>
                Rely on our experienced group of fantasy sports app developers
                to build fantasy apps that serve the purpose of engaging users
                and generating revenues. Share your requirements and we’ll do
                the rest.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 pb-2'>
            <div>
              <img
                src={Fantasysports}
                alt=''
                height={"200px"}
                width={"100%"}
                style={{
                  borderRadius: "10px",
                  objectFit: "fill",
                  width: "min-contain",
                }}
              />
            </div>
          </div>
          <div
            className='col-md-8'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2 font_700'>
                  Fantasy Sports Website Development Solution
                </h5>
              </div>
              <p className='pera'>
                We offer fantasy sports website development services that are
                independent of the platform and highly responsive to different
                screen sizes and devices. We build feature-rich websites/apps
                that work seamlessly on any platform.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 pb-2'>
            <div>
              <img
                src={Fantasycricket}
                alt=''
                height={"200px"}
                width={"100%"}
                style={{
                  borderRadius: "10px",
                  objectFit: "fill",
                  width: "min-contain",
                }}
              />
            </div>
          </div>
          <div
            className='col-md-8'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2 font_700'>
                  Fantasy Cricket App Development Solution
                </h5>
              </div>
              <p className='pera'>
                Building a fantasy cricket app is a big project, it requires
                industry awareness, technical competence, and experience that
                developers at only premier fantasy sports development companies
                possess. Kanraj Infotech is one of them so without giving a
                second thought get started by pouring out your thoughts and
                ideas.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4  pb-2'>
            <div>
              <img
                src={Fantasyanalytics}
                alt=''
                height={"200px"}
                width={"100%"}
                style={{
                  borderRadius: "10px",
                  objectFit: "fill",
                  width: "min-contain",
                }}
              />
            </div>
          </div>
          <div
            className='col-md-8'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2 font_700'>
                  Fantasy Analytics Software Services
                </h5>
              </div>
              <p className='pera'>
                Look no further than Kanraj Infotech for fantasy analytics
                software services, we have been doing it for the past several
                years. We know how it’s done and we do it in the best way
                possible. Real-time monitoring, precise data, modern techniques,
                and much more.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 pb-2'>
            <div>
              <img
                src={Softwaresolution}
                alt=''
                height={"200px"}
                width={"100%"}
                style={{
                  borderRadius: "10px",
                  objectFit: "fill",
                  width: "min-contain",
                }}
              />
            </div>
          </div>
          <div
            className='col-md-8'
            style={{ display: "flex", alignItems: "center" }}>
            <div>
              <div className='d-flex align-items-center'>
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: "#078586",
                    alignItems: "center",
                  }}></div>
                <h5 className='px-2 font_700'>
                  Fantasy Sports Software Solution
                </h5>
              </div>
              <p className='pera'>
                Put an end to your search, Kanraj Infotech is here to assist you
                in building the industry’s best fantasy sports software your
                business needs and users want. Create a customized sports
                solution that fits within your budget.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container text-center'>
        <h2 className='font_700'>Additional Benefits</h2>
        <div className='row my-3'>
          <div className='col-md-6'>
            <h5
              className='font_700'
              style={{ color: "#078586", fontSize: "20px" }}>
              Unmatched Popularity
            </h5>
            <p style={{ color: "#000", fontSize: "18px" }}>
              Brand awareness to a great extent, increasing the overall profit.
            </p>
          </div>
          <div className='col-md-6'>
            <h5
              className='font_700'
              style={{ color: "#078586", fontSize: "20px" }}>
              Maximum User Engagement
            </h5>
            <p style={{ color: "#000", fontSize: "18px" }}>
              Grabs more customer attention to the platform and shows a relevant
              user increase.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <h5
              className='font_700'
              style={{ color: "#078586", fontSize: "20px" }}>
              Multiplying Revenue
            </h5>
            <p style={{ color: "#000", fontSize: "18px" }}>
              Enhancement in the sales and conversion by leaps and bounds
            </p>
          </div>
          <div className='col-md-6'>
            <h5
              className='font_700'
              style={{ color: "#078586", fontSize: "20px" }}>
              Single Sports Junction
            </h5>
            <p style={{ color: "#000", fontSize: "18px" }}>
              One-stop solution for multiple sports without switching platforms
            </p>
          </div>
        </div>
      </div>
      <Blogs id={8} />
    </div>
  );
};

export default Index;
