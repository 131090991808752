// import React, { useState } from "react";
// import "./Accordion.css";
// import Arrow from "../../assets/custom/images/right-arrow.png";

// const Index = ({ eventKey, title, details, openSection, onToggle }) => {
//   const isOpen = openSection === eventKey;

//   const handleToggle = () => {
//     onToggle(eventKey);
//   };

//   return (
//     <div className='accordion'>
//       <div className={`accordion-item ${isOpen ? "open" : ""}`}>
//         <div className='accordion-header' onClick={handleToggle}>
//           <div style={{ marginRight: "15px" }}>
//             <span
//               className='d-flex  justify-content-center align-items-center'
//               style={{
//                 background: "#078586",
//                 border: "none",
//                 borderRadius: "8px",
//                 width: "35px",
//                 height: "35px",
//               }}>
//               <img src={Arrow} alt='' height={"15px"} width={"15px"} />
//             </span>
//           </div>
//           <div>
//             <h4 style={{ fontSize: "21px", fontWeight: "600" }}>{title}</h4>
//           </div>
//         </div>
//         <div
//           className='accordion-content'
//           style={{
//             display: isOpen ? "block" : "none",
//             fontSize: "18px",
//             textAlign: "justify",
//           }}
//           dangerouslySetInnerHTML={{ __html: details }}>
//           {/* {details} */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Index;

import React, { useState } from "react";
import "./Accordion.css";
import Arrow from "../../assets/custom/images/right-arrow.png";

// const Index = ({ eventKey, title, details }) => {
const Index = ({ eventKey, title, details }) => {
  const [isOpen, setIsOpen] = useState(true); // Initialize isOpen state to true to have all sections open by default

  const handleToggle = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state when clicked
  };

  return (
    <div className='accordion'>
      <div
        className='accordion - item  `${isOpen ? "open" : ""}`'
        style={{ border: "1px solid #e9e9e9" }}>
        <div className='accordion-header' onClick={handleToggle}>
          {/* <div className={accordion - item ${isOpen ? "open" : ""}}></div> */}
          <div style={{ marginRight: "15px" }}>
            <span
              className='d-flex  justify-content-center align-items-center'
              style={{
                background: "#078586",
                border: "none",
                borderRadius: "8px",
                width: "35px",
                height: "35px",
              }}>
              <img src={Arrow} alt='' height={"15px"} width={"15px"} />
            </span>
          </div>
          <div>
            <h4 style={{ fontSize: "21px", fontWeight: "600" }}>{title}</h4>
          </div>
        </div>
        <div
          className='accordion-content'
          style={{
            display: isOpen ? "block" : "none",
            fontSize: "18px",
            textAlign: "justify",
          }}
          dangerouslySetInnerHTML={{ __html: details }}>
          {/* {details} */}
        </div>
      </div>
    </div>
  );
};

export default Index;
